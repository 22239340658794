import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip'
import { GoDeviceMobile } from "react-icons/go";
import '../custom.css';

import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

function researchApp(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }
  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

class DevicesDetailsUnwantedApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      researchUnwantedApp: "",
    };

      this.researchUnwantedAppHandle = this.researchUnwantedAppHandle.bind(this);
  }


      researchUnwantedAppHandle(event) {
        this.setState({researchUnwantedApp: event.target.value});
      }


// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [
      {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.1"})}</b>
        </span>),
        accessor: 'name'
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.2"})}
          </b>
        </span>),
        accessor: 'latestKnownVersion'
      }
    ]

    return (<div>
      <div className="card bg-light">
        <div className="card-header">

          <div className="form-inline">

            <input className="form-control mr-sm-2 row-auto" style={{
                height: "36px"
              }} type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" value={this.state.researchUnwantedApp} onChange={this.researchUnwantedAppHandle}/>

              {this.props.applistFetching ?
                <div className="col d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                : null
              }

          </div>
        </div>

        <MenuProvider id="menu_devicesDetailsUnwantedAppContextMenu">
          <ReactTable getTheadThProps={getTheadThProps}

            getTdProps={(state, rowInfo, column, instance) => {
        return {

          onContextMenu: (e) => {
              if(rowInfo){
              this.props.contextMenu(rowInfo.original)}
              else {this.props.contextMenu("empty")}
          }
        }
      }}

            showPagination={false}
            data={researchApp(this.props.devicesDetailsUnwantedApplist, this.state.researchUnwantedApp).sort((a, b) => a.name.localeCompare(b.name))}
            noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
            TbodyComponent={CustomTbodyComponent}
            columns={columns}
            resizable={false}
            pageSize={this.props.devicesDetailsUnwantedApplist.length}
            style={{
              height: "41vh",
              width: 'auto'
            }} className=" text-center border-0 -clickable"/>
        </MenuProvider>

        <div className="col text-center border-top">
          <b className="text-dark"><FormattedMessage id="terminauxDetails.7"/> {this.props.devicesDetailsUnwantedApplist.length}</b>
        </div>
      </div>
    </div>)
  }
}

DevicesDetailsUnwantedApp.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsUnwantedApp = injectIntl(DevicesDetailsUnwantedApp, {withRef: true});

export default DevicesDetailsUnwantedApp
