import React, {Component} from 'react';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import QRCode from "qrcode.react";

import {varMmapp} from '../variables';


  class MMAppQRCodeTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        relais: "",
        allSystemApps: true,
        qrCodeValue: "",
        addWifiOneQRCode: false,
        currentProfil: {
          newProfil: true,
          profilName: "",
          qRCodeRelais: "",
          skipEncryption:	false,
          allSystemApps:	true,
          useWifi:	false,
          wifiName:	"",
          wifiTypeSecurity:	"NONE",
          mdpWifi:	"",
      },
      };

      this.afterSubmission = this.afterSubmission.bind(this);
      this.saveProfil = this.saveProfil.bind(this);
      this.profilSelectHandle = this.profilSelectHandle.bind(this);
    }

    downloadQR(value, qrCodeName){
      const canvas = document.getElementById("qrCodeImage");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = qrCodeName + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    afterSubmission(event) {
        event.preventDefault();
    }

    profilSelectHandle = (event) => {

        if (event.target.value !== "newProfil") {
          var data = this.props.dataQRCodeProfil;
          for (var i = 0; i < data.length; i++) {
            if (data[i].profilName === event.target.value) {
              var item = new Object();
              item.newProfil = false;
              this.props.dataQRCodeProfil[i].profilName !== null ? item.profilName	= this.props.dataQRCodeProfil[i].profilName : item.profilName	= "";
              this.props.dataQRCodeProfil[i].qRCodeRelais !== null ? item.qRCodeRelais	= this.props.dataQRCodeProfil[i].qRCodeRelais : item.qRCodeRelais	= "";
              this.props.dataQRCodeProfil[i].allSystemApps !== null ? item.allSystemApps	= this.props.dataQRCodeProfil[i].allSystemApps : item.allSystemApps	= false;
              this.props.dataQRCodeProfil[i].skipEncryption !== null ? item.skipEncryption	= this.props.dataQRCodeProfil[i].skipEncryption : item.skipEncryption	= false;
              this.props.dataQRCodeProfil[i].useWifi !== null ? item.useWifi	= this.props.dataQRCodeProfil[i].useWifi : item.useWifi	= false;
              this.props.dataQRCodeProfil[i].wifiName !== null ? item.wifiName	= this.props.dataQRCodeProfil[i].wifiName : item.wifiName	= "";
              this.props.dataQRCodeProfil[i].wifiTypeSecurity !== null ? item.wifiTypeSecurity	= this.props.dataQRCodeProfil[i].wifiTypeSecurity : item.wifiTypeSecurity	= "";
              this.props.dataQRCodeProfil[i].mdpWifi !== null ? item.mdpWifi	= this.props.dataQRCodeProfil[i].mdpWifi : item.mdpWifi	= "";
              this.setState({currentProfil: item}
              , () => {
                  this.CreateQrCodeValue();
              });

              return;
            }
          }
        } else {
          var item = new Object();
          item.newProfil = true;
          item.profilName= "";
          item.qRCodeRelais= "";
          item.skipEncryption=	false;
          item.allSystemApps=	true;
          item.useWifi=	false;
          item.wifiName=	"";
          item.wifiTypeSecurity=	"";
          item.mdpWifi=	"";
          this.setState({currentProfil: item, qrCodeValue: ""})
        }



    }

    handleProfilChange = (event, field) => {
      switch (field) {
        case "skipEncryption":
          this.setState({
            currentProfil: { ...this.state.currentProfil, [field]: !this.state.currentProfil.skipEncryption }
          });
          break;
        case "allSystemApps":
          this.setState({
            currentProfil: { ...this.state.currentProfil, [field]: !this.state.currentProfil.allSystemApps }
          });
          break;
        case "useWifi":
        console.log(this.state.currentProfil.useWifi);
          this.setState({
            currentProfil: { ...this.state.currentProfil, [field]: !this.state.currentProfil.useWifi }
          });
          break;
        default:
          this.setState({
            currentProfil: { ...this.state.currentProfil, [field]: event.target.value }
          });
      }
    }


    handleChange = (event, field) => {
      switch (field) {
        case "addWifiOneQRCode":
            this.setState({addWifiOneQRCode: !this.state.addWifiOneQRCode})
          break;
        case "qrCodeValue":
            this.setState({qrCodeValue: JSON.stringify(event)})
          break;
      }
    }


    CreateQrCodeValue(){

      var relaisURL;
      if (document.getElementById('qRCodeRelais').value.substring(document.getElementById('qRCodeRelais').value.length -1, document.getElementById('qRCodeRelais').value.length) === "/") {
        relaisURL = document.getElementById('qRCodeRelais').value + "mmapp/download/com.mobileprocess.mmapp";
      } else {
        relaisURL = document.getElementById('qRCodeRelais').value + "/mmapp/download/com.mobileprocess.mmapp";
      }

      if (this.state.currentProfil.useWifi) {
        if (document.getElementById('qRCodeRelais').value && document.getElementById('wifiName').value && document.getElementById('mdpWifi').value) {
          var value = {
          "android.app.extra.PROVISIONING_LOCALE": this.props.intl.locale + "_" + this.props.intl.locale.toUpperCase(),

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME":
          "com.mobileprocess.mmapp/com.mobileprocess.mmapp.SampleAdminReceiver",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM":
          "rOEstN8ECs1f--ssSb7npLZkTPxbgta5z8thVKBkb_8",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION":
          relaisURL,
          "android.app.extra.PROVISIONING_SKIP_ENCRYPTION": document.getElementById('skipEncryption').checked,

          "android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED": document.getElementById('allSystemApps').checked,

          "android.app.extra.PROVISIONING_WIFI_SSID" : document.getElementById('wifiName').value,
          "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE": document.getElementById('wifiTypeSecurity').value,
          "android.app.extra.PROVISIONING_WIFI_PASSWORD": document.getElementById('mdpWifi').value,

          "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE": {
          }

          };

        } else if (document.getElementById('qRCodeRelais').value && document.getElementById('wifiName').value) {
          var value = {
          "android.app.extra.PROVISIONING_LOCALE": this.props.intl.locale + "_" + this.props.intl.locale.toUpperCase(),

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME":
          "com.mobileprocess.mmapp/com.mobileprocess.mmapp.SampleAdminReceiver",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM":
          "rOEstN8ECs1f--ssSb7npLZkTPxbgta5z8thVKBkb_8",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION":
          relaisURL,
          "android.app.extra.PROVISIONING_SKIP_ENCRYPTION": document.getElementById('skipEncryption').checked,

          "android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED": document.getElementById('allSystemApps').checked,

          "android.app.extra.PROVISIONING_WIFI_SSID" : document.getElementById('wifiName').value,
          "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE": document.getElementById('wifiTypeSecurity').value,

          "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE": {
          }

          };


        }

        this.handleChange(value, "qrCodeValue");

      } else {
        if (document.getElementById('qRCodeRelais').value) {
          var value = {
          "android.app.extra.PROVISIONING_LOCALE": this.props.intl.locale + "_" + this.props.intl.locale.toUpperCase(),

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME":
          "com.mobileprocess.mmapp/com.mobileprocess.mmapp.SampleAdminReceiver",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM":
          "rOEstN8ECs1f--ssSb7npLZkTPxbgta5z8thVKBkb_8",

          "android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION":
          relaisURL,
          "android.app.extra.PROVISIONING_SKIP_ENCRYPTION": document.getElementById('skipEncryption').checked,

          "android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED": document.getElementById('allSystemApps').checked,

          "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE": {
          }

          };

          this.handleChange(value, "qrCodeValue");
        }
      }


      var test = document.getElementById('qRCodeRelais').value
      var test2 = document.getElementById('allSystemApps').checked

    }

    saveProfil(){


            if (this.state.currentProfil.useWifi) {
              if (document.getElementById('qRCodeRelais').value && document.getElementById('wifiName').value && document.getElementById('mdpWifi').value) {
                var value = {

                "qRCodeRelais":document.getElementById('qRCodeRelais').value,
                "skipEncryption": document.getElementById('skipEncryption').checked,

                "allSystemApps": document.getElementById('allSystemApps').checked,

                "useWifi" : this.state.currentProfil.useWifi,
                "wifiName" : document.getElementById('wifiName').value,
                "wifiTypeSecurity": document.getElementById('wifiTypeSecurity').value,
                "mdpWifi": document.getElementById('mdpWifi').value,


                };

              } else if (document.getElementById('qRCodeRelais').value && document.getElementById('wifiName').value) {
                var value = {

                "qRCodeRelais":document.getElementById('qRCodeRelais').value,
                "skipEncryption": document.getElementById('skipEncryption').checked,

                "allSystemApps": document.getElementById('allSystemApps').checked,

                "useWifi" : this.state.currentProfil.useWifi,
                "wifiName" : document.getElementById('wifiName').value,
                "wifiTypeSecurity": document.getElementById('wifiTypeSecurity').value,


                };

              }

            } else {
              if (document.getElementById('qRCodeRelais').value) {
                var value = {

                "qRCodeRelais":document.getElementById('qRCodeRelais').value,
                "skipEncryption": document.getElementById('skipEncryption').checked,

                "allSystemApps": document.getElementById('allSystemApps').checked,

                "useWifi" : this.state.currentProfil.useWifi,

                };
              }
            }

            if (value) {
              $('#modalSaveQRConfig').modal('show')
              console.log(this.state.currentProfil);
            }

    }

    modalDeleteProfil(){
      $('#modalConfirmDelete').modal('show')
    }

    deleteProfil(profilName){
      fetch(varMmapp.apiQRCodeProfil + profilName, {
          method: "DELETE",
          body: null,
          headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          }
        }).then(response => {
          var x = response;
          if(response.status === 200){
            var item = new Object();
            item.newProfil = true;
            item.profilName= "";
            item.qRCodeRelais= "";
            item.skipEncryption=	false;
            item.allSystemApps=	true;
            item.useWifi=	false;
            item.wifiName=	"";
            item.wifiTypeSecurity=	"";
            item.mdpWifi=	"";
            this.setState({currentProfil: item, qrCodeValue: ""})
            this.props.fetchQRCodeProfil();
          }
         })
    }

    modalSaveProfil(){
      if (document.getElementById('profilName').value) {
        $('#modalSaveQRConfig').modal('hide')
        var data = this.state.currentProfil;
        data.profilName = document.getElementById('profilName').value;

        console.log(data);


        fetch(varMmapp.apiQRCodeProfil, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            }
          }).then(response => {
            var x = response;
            if(response.status === 200){var item = new Object();
              item.newProfil = true;
              item.profilName= "";
              item.qRCodeRelais= "";
              item.skipEncryption=	false;
              item.allSystemApps=	true;
              item.useWifi=	false;
              item.wifiName=	"";
              item.wifiTypeSecurity=	"";
              item.mdpWifi=	"";
              this.setState({currentProfil: item, qrCodeValue: ""})
              this.props.fetchQRCodeProfil();
            }
           })

      }
    }

// ------------------------------- Render -------------------------------------- //

    render() {

        return (
          <div className="p-3">

            <p className="text-center text-info border"><b><FormattedMessage id="mmapp.menuTab.qr_code.title"/></b></p>

            <div className="row">
              <div className="col p-3">

                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="profilSaved"><FormattedMessage id="mmapp.menuTab.qr_code.savedProfils"/></label>
                  </div>
                  <div className="col">
                    <select className="form-control" id="profilSaved" value={this.state.currentProfil.profilName} onChange={e => this.profilSelectHandle(e)}>
                        <option value="newProfil">{this.props.intl.formatMessage({id: "mmapp.menuTab.qr_code.newProfil"})}</option>
                        {this.props.dataQRCodeProfil ?
                          this.props.dataQRCodeProfil.map((hit,index) =>{
                              return(<option key={hit.profilName} value={hit.profilName}>{hit.profilName}</option>)
                            })
                          :
                          null
                        }
                    </select>
                  </div>
                </div>

                <form className="needs-validation" onSubmit={this.afterSubmission}>
                    <div className="row">

                      <div className="col-5">
                        <label htmlFor="validationTooltip03"><FormattedMessage id="mmapp.menuTab.qr_code.relay"/></label>
                      </div>
                      <div className="col">
                        <input type="text" className="form-control" id="qRCodeRelais" value={this.state.currentProfil.qRCodeRelais} onChange={e => this.handleProfilChange(e, "qRCodeRelais")} required/>
                      </div>

                    </div>

                    <div className="custom-control custom-checkbox mt-2 mb-2">
                      <input type="checkbox" className="custom-control-input" id="allSystemApps" checked={this.state.currentProfil.allSystemApps} onChange={e => this.handleProfilChange(e, "allSystemApps")}/>
                      <label className="custom-control-label" htmlFor="allSystemApps"><FormattedMessage id="mmapp.menuTab.qr_code.installSystemApp"/></label>
                    </div>

                    <div className="custom-control custom-checkbox mt-2 mb-2">
                      <input type="checkbox" className="custom-control-input" id="skipEncryption" checked={this.state.currentProfil.skipEncryption} onChange={e => this.handleProfilChange(e, "skipEncryption")}/>
                      <label className="custom-control-label" htmlFor="skipEncryption"><FormattedMessage id="mmapp.menuTab.qr_code.skipEncryption"/></label>
                    </div>


                    <div className="custom-control custom-checkbox mt-2 mb-2">
                      <input type="checkbox" className="custom-control-input" id="useWifi"
                      checked={this.state.currentProfil.useWifi} onChange={e => this.handleProfilChange(e, "useWifi")}/>
                      <label className="custom-control-label" htmlFor="useWifi"><FormattedMessage id="mmapp.menuTab.qr_code.addWifi"/></label>
                    </div>

                    {
                      this.state.currentProfil.useWifi ?
                      <div>

                        <div className="row">
                          <div className="col-4">
                            <label><FormattedMessage id="mmapp.menuTab.qr_code.wifiName"/></label>
                          </div>
                          <div className="col">
                            <input type="text" className="form-control" id="wifiName"value={this.state.currentProfil.wifiName} onChange={e => this.handleProfilChange(e, "wifiName")} required/>
                          </div>
                        </div>

                        <div className="form-group mt-3">

                          <div className="row">
                            <div className="col-4">
                              <label htmlFor="wifiTypeSecurity"><FormattedMessage id="mmapp.menuTab.qr_code.wifiSecurityType"/></label>
                            </div>
                            <div className="col">
                              <select className="form-control" id="wifiTypeSecurity" value={this.state.currentProfil.wifiTypeSecurity} onChange={e => this.handleProfilChange(e, "wifiTypeSecurity")}>
                                <option value="NONE">NONE</option>
                                <option value="WPA">WPA</option>
                                <option value="WEP">WEP</option>
                                <option value="EAP">EAP</option>
                              </select>
                            </div>
                          </div>

                        </div>

                        <div className="row">
                          <div className="col-4">
                            <label><FormattedMessage id="mmapp.menuTab.qr_code.wifiPassword"/></label>
                          </div>
                          <div className="col">
                            <input type="password" className="form-control" id="mdpWifi" value={this.state.currentProfil.mdpWifi} onChange={e => this.handleProfilChange(e, "mdpWifi")}/>
                          </div>
                        </div>

                      </div>
                      :
                      null
                    }

                    <div className=" d-flex justify-content-center mt-4">
                      <button className="btn btn-primary btn-sm mr-3" onClick={this.saveProfil.bind(this)}>
                        <FormattedMessage id="mmapp.menuTab.qr_code.saveProfil"/>
                      </button>
                      <button className="btn btn-primary btn-sm"
                      onClick={this.CreateQrCodeValue.bind(this)}><FormattedMessage id="mmapp.menuTab.qr_code.generate"/></button>

                      { !this.state.currentProfil.newProfil && document.getElementById('profilSaved').value !== "newProfil" ?
                        <button className="btn btn-danger btn-sm ml-3" onClick={this.modalDeleteProfil.bind(this)}>
                          <FormattedMessage id="supprimer"/>
                        </button>
                        :
                        null
                      }

                    </div>
                </form>

              </div>

              <div className="col-4">
                <div className="p-3 d-flex justify-content-center">

                  {
                    this.state.qrCodeValue ?
                      <QRCode id="qrCodeImage"
                        className="p-1"
                        style={{border:'1px solid rgb(194,194,194)', borderRadius: 2 + 'px'}}
                        size={256}
                        value={this.state.qrCodeValue}/>

                    :
                    null
                  }

                </div>

                <div className="d-flex justify-content-center">
                  {
                    this.state.qrCodeValue ?
                      <button type="button" className="btn btn-sm btn-primary" onClick={this.downloadQR.bind(this, this.state.qrCodeValue, this.state.currentProfil.profilName)} disabled={!this.state.currentProfil.profilName}><FormattedMessage id="download"/></button>
                    :
                    null
                  }
                </div>
              </div>
            </div>


            <div className="modal fade" id="modalSaveQRConfig" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle"><FormattedMessage id="mmapp.menuTab.qr_code.modal.save"/></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>


                    <form className="needs-validation" onSubmit={this.saveProfil}>

                      <div className="modal-body">

                      <div className="row mb-2">
                        <div className="col-4">
                          <label><FormattedMessage id="mmapp.menuTab.qr_code.modal.profil.name"/></label>
                        </div>
                        <div className="col">
                          <input type="text" className="form-control" id="profilName" value={this.state.currentProfil.profilName} onChange={e => this.handleProfilChange(e, "profilName")} required/>
                        </div>
                      </div>

                      <p>{this.state.currentProfil.qRCodeRelais != null ? this.props.intl.formatMessage({id:"mmapp.menuTab.qr_code.relay"}) + " : " + this.state.currentProfil.qRCodeRelais : null}</p>
                      <p>{this.state.currentProfil.allSystemApps != null ? this.props.intl.formatMessage({id:"mmapp.menuTab.qr_code.installSystemApp"})+ " : " + this.state.currentProfil.allSystemApps : null}</p>

                      <p>{this.state.currentProfil.skipEncryption != null ? this.props.intl.formatMessage({id:"mmapp.menuTab.qr_code.skipEncryption"}) + " : " + this.state.currentProfil.skipEncryption : null}</p>

                      <p>{this.state.currentProfil.wifiName != null && this.state.currentProfil.wifiName.length > 0 ? this.props.intl.formatMessage({id:"mmapp.menuTab.qr_code.wifiName"}) + " : " + this.state.currentProfil.wifiName : null}</p>
                      <p>{this.state.currentProfil.wifiTypeSecurity != null && this.state.currentProfil.wifiTypeSecurity.length > 0 ? this.props.intl.formatMessage({id:"mmapp.menuTab.qr_code.wifiSecurityType"}) + " : " + this.state.currentProfil.wifiTypeSecurity : null}</p>
                      {
                        //<p>{this.state.currentProfil.mdpWifi != null ? this.props.intl.formatMessage({id: "mmapp.menuTab.qr_code.wifiPassword"}) + " : " + this.state.currentProfil.mdpWifi : null}</p>
                      }

                      </div>

                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/></button>
                        <button type="button" className="btn btn-primary" onClick={this.modalSaveProfil.bind(this)} disabled={!this.state.currentProfil.profilName}><FormattedMessage id="mmapp.menuTab.qr_code.modal.save"/></button>
                        {
                          //<button type="button" className="btn btn-primary" onClick={this.downloadQR.bind(this, this.state.qrCodeValue, this.state.currentProfil.profilName)} disabled={!this.state.currentProfil.profilName}><FormattedMessage id="mmapp.menuTab.qr_code.modal.save_as"/></button>
                        }

                      </div>

                    </form>


                </div>
              </div>
              </div>


              <div className="modal fade" id="modalConfirmDelete" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className={`modal-content modalConfirm border-danger rounded-0`} style={{zIndex: '4000'}}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel">
                        {this.props.intl.formatMessage({id: "supprimer"})} <b className="text-primary">{this.state.currentProfil.profilName}</b>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel">
                        {this.props.intl.formatMessage({id: "confirmer"})}
                      </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.deleteProfil.bind(this, this.state.currentProfil.profilName)}><FormattedMessage id="valider"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

          </div>

      )
    }
  }

MMAppQRCodeTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppQRCodeTab = injectIntl(MMAppQRCodeTab, {withRef: true});

export default MMAppQRCodeTab
