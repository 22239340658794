import React from 'react'
import $ from "jquery";
import find from 'lodash/find';
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import {varGroupDetail} from '../variables';

import TablePlus from '../Components/TablePlus';

class GroupsWallpaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
  }

  onChangeFileForWallpaper(event) {

    event.stopPropagation();
    event.preventDefault();

    this.setState({fileName: event.target.files[0].name});
    var file = event.target.files[0];
    this.uploadFile(file, "wallpaper");
    this.setState({fileUpload: file});
  }

  onChangeFileForLockscreen(event) {

    event.stopPropagation();
    event.preventDefault();

    this.setState({fileName: event.target.files[0].name});
    var file = event.target.files[0];
    this.uploadFile(file, "lockscreen");
    this.setState({fileUpload: file});
  }


  uploadFile(file, type){

    var data = new FormData();
    data.append("file", file);

    fetch(varGroupDetail.apiGroupWallpaperUpload, {
        method: "POST",
        body: data
      }).then(response => {
        $('#modalUploadLoading').modal('hide');
        console.log("response end");
        var x = response;
        if(response.status === 200){
          this.props.handleGroupWallpaperAndLockscreen(file, type);
          return response.json().then(responseRetour => {
            this.importResult(responseRetour);
          })
        }
       })

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

// ------------------------------- Action handle ------------------------------- //


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div>

        <h5 className="text-primary text-center mt-2">
          <b><FormattedMessage id="groupsDetails.8"/></b>
        </h5>
        <hr/>

        <div className="col-8 mt-4 container mb-3">

          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-center">
                <h5 className="text-primary"><FormattedMessage id="groupsDetails.wallpaper"/></h5>
              </div>
              <div className="row d-flex justify-content-center">
                {this.props.dataGroupWallpaperAndLockscreen.wallpaper && this.props.dataGroupWallpaperAndLockscreen.wallpaper.imageLink ?
                  <img className="embed-responsive-item border rounded mb-2" src= {varGroupDetail.apiGroupWallpaperPreview + this.props.dataGroupWallpaperAndLockscreen.wallpaper.imageLink}
                  style={{width: "12vw", minHeight: "47vh"}}/>
                  :
                  null
                }

              </div>

              <div className="d-flex justify-content-center">
                <input
                  ref="fileInputWallpaper"
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.onChangeFileForWallpaper.bind(this)}
                />
                <button className="btn btn-default btn-info btn-choose" type="file"
                  onClick={() => this.refs.fileInputWallpaper.click()}><FormattedMessage id="importer"/></button>
                  <button className="btn btn-danger float-right" data-toggle="modal" data-target="#ConfirmationSupressionWallpaper">X</button>
              </div>


            </div>

            <div className="col">
              <div className="row d-flex justify-content-center">
                <h5 className="text-primary"><FormattedMessage id="groupsDetails.lockscreen"/></h5>
              </div>
              <div className="row d-flex justify-content-center">
                {this.props.dataGroupWallpaperAndLockscreen.lockscreen && this.props.dataGroupWallpaperAndLockscreen.lockscreen.imageLink ?
                  <img className="embed-responsive-item border rounded mb-2" src= {varGroupDetail.apiGroupWallpaperPreview + this.props.dataGroupWallpaperAndLockscreen.lockscreen.imageLink}
                  style={{width: "12vw", minHeight: "47vh"}}/>
                  :
                  null
                }
              </div>

              <div className="d-flex justify-content-center">
                <input
                  ref="fileInputLockscreen"
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.onChangeFileForLockscreen.bind(this)}
                />
                <button className="btn btn-default btn-info btn-choose" type="file"
                  onClick={() => this.refs.fileInputLockscreen.click()}><FormattedMessage id="importer"/></button>
                  <button className="btn btn-danger float-right" data-toggle="modal" data-target="#ConfirmationSupressionLockscreen">X</button>
              </div>
            </div>
          </div>

      </div>



      <div className="modal" id="modalUploadLoading" tabIndex="-1" role="dialog" aria-hidden="true"
      data-backdrop="static" data-keyboard="false">

      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-light">
              <h6 className="modal-title text-info">
                <b>
                  <FormattedMessage id="chargement"/> ...
                </b>
              </h6>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          <div className="modal-body">
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{width: `${Math.floor(this.state.progress)}%`}} aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div className="uploadStatus">
            <p><FormattedMessage id="estimate"/> :<br/> {`${Math.floor(this.state.estimated/60)} minutes ${Math.floor(this.state.estimated%60)} `}<FormattedMessage id="secondes"/> </p>
          </div>
          </div>
        </div>
      </div>
      </div>

      <div className="modal fade" id="ConfirmationSupressionWallpaper" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalSecurite rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "supprimer"})} : "<b className="text-info"><FormattedMessage id="groupsDetails.wallpaper"/></b>"
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteGroupWallpaperOrLockscreen.bind(this, false)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ConfirmationSupressionLockscreen" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalSecurite rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "supprimer"})} : "<b className="text-info"><FormattedMessage id="groupsDetails.lockscreen"/></b>"
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.props.deleteGroupWallpaperOrLockscreen.bind(this, true)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
    )
  }
}
GroupsWallpaper.propTypes = {
  intl: intlShape.isRequired
};
GroupsWallpaper = injectIntl(GroupsWallpaper,
                        {withRef: true});

export default GroupsWallpaper
