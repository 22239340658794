import React from 'react'
import Select, { components } from 'react-select';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import { varTerminaux, varUser } from '../variables';

function validate(name, model, typeHardware) {
  // true means invalid, so our conditions got reversed
  return {
    name: name.length === 0,
    model: model.length === 0,
    typeHardware: typeHardware.length === 0
  };
}

class NewDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: {
        name: false,
        model: false,
        typeHardware: false
      },

      "name": "",
      "os": "",
      "imei": "",
      "model": "",
      "typeHardware": "",
      "operator": "",
      selectedValue: "",
      setSelectedValue: "",
    };


  }

  static backPage() {
    window.history.back();
  }

// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {

    if (this.props.location.data) {
      const data = this.props.location.data;
      this.setState({
        name: data.model + "_" + data.users[0].login,
        os: data.os,
        imei: data.imei ? data.imei : "",
        model: data.model,
        typeHardware: data.os.toLowerCase().includes("android") ? "android" : "",
        operator: data.operator ? data.operator : ""
      });
    }

    Promise.all([fetch(varUser.api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varTerminaux.apiDevicesModelList ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resAPIUsers, resAPIDevicesModelList]) => Promise.all([resAPIUsers.json(), resAPIDevicesModelList.json()]))
        .then(([dataAPIUsers, dataAPIDevicesModelList]) => (this.setState({
            dataAPIUsers: this.props.location.data? this.checkForUser(dataAPIUsers) : dataAPIUsers, dataAPIDevicesModelList: dataAPIDevicesModelList ,isLoading: false
        })));
  }

  checkForUser(data){
    const userList = this.adaptUsersList(data);
    for (const user of userList) {
      if (user.value.email != "" && this.state.name.includes(user.value.email)) {
        this.setState({ selectedUser: user });
      }
    }
    return data
  }

// ------------------------------- Action handle ------------------------------- //

handleChange= (event, field) => {
  if (field === "model") {
    this.setState({selectedModel: ""});
  }
  this.setState({ [field]: event.target.value });
}

handleBlur = field => evt => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };



  // Gérer le changement de l'input
  handleInputChange = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  handleChangeSelectModel = selectedOption => {
    this.setState({ selectedModel: selectedOption, model: selectedOption.value });
  }

  handleChangeSelectUser = selectedOption => {
    this.setState({ selectedUser: selectedOption });
  }

  adaptUsersList(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;

        item.value = list[i];
        item.userId = list[i].userId;
        item.label = list[i].name + " / " + list[i].login;

        if (item.label !== undefined) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  adaptDevicesList(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;

        item.value = list[i];
        item.label = list[i]

        if (item.label !== undefined) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }


sendData(){

  var data = {
  "name": document.getElementById('newDeviceName').value,
  "os": document.getElementById('newDeviceOs').value,
  "imei": document.getElementById('newDeviceImei').value,
  "model": document.getElementById('newDeviceModel').value,
  "typeHardware": document.getElementById('newDeviceTypeHW').value,
  "operator": document.getElementById('newDeviceMobileOperator').value,
  "userId": this.state.selectedUser ? this.state.selectedUser.userId : null,
};

  this.setState({isFetching: true});

  fetch( varTerminaux.api + "add", {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': this.props.tokenCSRF
    },
      body: JSON.stringify(data)
    })
    .then(response => {
      var x = response;
      if(response.status === 200){
        return response.json().then(responseRetour => {
            this.props.history.push("/Devices/");
        })
      }
      else{
        console.log(response.headers);
       return response.json().then(responseError => {
         console.log(responseError);
         this.requeteResponse(x,responseError);
       })
     }
     })

    this.setState({isFetching: false});
}

// ------------------------------- DATA fetching ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  render() {

      const {isLoading, error} = this.state;

      const errors = validate(
        this.state.name,
        this.state.model,
        this.state.typeHardware);
      const isDisabled = Object.keys(errors).some(x => errors[x]);

      const shouldMarkError = field => {
        const hasError = errors[field];
        const shouldShow = this.state.touched[field];

        return hasError ? shouldShow : false;
      };

    return(
      <div className="col-6 small mt-4 mb-2">

        <h5 className="text-primary text-center mb-3">
          <b>
            <span className="text-success">
              <FormattedMessage id="newDevice.1"/></span>
          </b>
        </h5>

        <div className="card border mb-5">
          <div className="col-8 mt-3 container mb-2">
              <div>

                  <h6 className="text-info"><b><FormattedMessage id="newDevice.general.1"/></b></h6>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.name"/>
                      </div>
                      <div className="col">
                          <input
                            className={`form-control ${shouldMarkError("name") ? "error" : ""}`}
                            id="newDeviceName"
                            value={this.state.name}
                            onChange={e => this.handleChange(e, "name")}
                            onBlur={this.handleBlur("name")}/>
                      </div>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.os"/>
                      </div>
                      <div className="col">
                          <input
                            className={`form-control ${shouldMarkError("os") ? "error" : ""}`}
                            id="newDeviceOs"
                            value={this.state.os}
                            onChange={e => this.handleChange(e, "os")}
                            onBlur={this.handleBlur("os")}/>
                      </div>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.imei"/>
                      </div>
                      <div className="col">
                          <input
                            className={`form-control ${shouldMarkError("imei") ? "error" : ""}`}
                            id="newDeviceImei"
                            value={this.state.imei}
                            onChange={e => this.handleChange(e, "imei")}
                            onBlur={this.handleBlur("imei")}/>
                      </div>
                  </div>
                  <div className="col">
                      <FormattedMessage id="newDevice.model"/>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col mt-2">
                          <input
                            className={`form-control ${shouldMarkError("model") ? "error" : ""}`}
                            id="newDeviceModel"
                            value={this.state.model}
                            onChange={e => this.handleChange(e, "model")}
                            onBlur={this.handleBlur("model")}/>
                      </div>
                      <div className="col">
                          <Select className="mt-2 mb-3"
                            id="newDeviceModelSelect"
                            value={this.state.selectedModel}
                            maxMenuHeight={235}
                            onChange={this.handleChangeSelectModel}
                            options={this.adaptDevicesList(this.state.dataAPIDevicesModelList)}
                          />
                      </div>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.typeHW"/>
                      </div>
                      <div className="col">
                          <input
                            className={`form-control ${shouldMarkError("typeHardware") ? "error" : ""}`}
                            id="newDeviceTypeHW"
                            value={this.state.typeHardware}
                            onChange={e => this.handleChange(e, "typeHardware")}
                            onBlur={this.handleBlur("typeHardware")}/>
                      </div>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.mobileOperator"/>
                      </div>
                      <div className="col">
                          <input
                            className={`form-control ${shouldMarkError("operator") ? "error" : ""}`}
                            id="newDeviceMobileOperator"
                            value={this.state.operator}
                            onChange={e => this.handleChange(e, "operator")}
                            onBlur={this.handleBlur("operator")}/>
                      </div>
                  </div>
                  <div className="row mb-2 mt-3">
                      <div className="col">
                          <FormattedMessage id="newDevice.user"/>
                      </div>
                      <div className="col">
                        <Select className="mt-2 mb-3"
                          id="newDeviceUser"
                          value={this.state.selectedUser}
                          maxMenuHeight={235}
                          onChange={this.handleChangeSelectUser}
                          options={this.adaptUsersList(this.state.dataAPIUsers)}
                          isClearable={true}
                        />
                      </div>


                  </div>
                  <div className="row mb-2 mt-3">


                  </div>

              </div>

          </div>
        </div>


        <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
            <div className="text-center mr-1">
                <button type="button" className="btn btn-secondary" onClick={NewDevice.backPage}><FormattedMessage id="retour"/>
                </button>
            </div>

            <div className="text-center ml-1">
                <button type="button" className="btn btn-info" onClick={this.sendData.bind(this)} disabled={isDisabled}>
                    <FormattedMessage id="valider"/>
                </button>
            </div>
        </div>


      </div>

    )
  }
}
NewDevice.propTypes = {
  intl: intlShape.isRequired
};
NewDevice = injectIntl(NewDevice,
                        {withRef: true});
export default NewDevice
