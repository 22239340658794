import React from 'react'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';
import find from 'lodash/find';

import classNames from "classnames";
import { MenuProvider } from 'react-contexify';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


function changeToDate(int, langue){
  var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
  return (ds)
}

const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);

class ServeurAccessTab extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

      const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [
      {
        Header: () => (<span>
          <b><FormattedMessage id="general.modal.10.1"/></b>
        </span>),
        accessor: 'account',
        minWidth: 100,
      },{
        Header: () => (<span>
          <b><FormattedMessage id="general.modal.10.2"/></b>
        </span>),
        accessor: 'email',
        minWidth: 170,
      }, {
        Header: () => (<span>
          <b>
            <FormattedMessage id="general.modal.10.3"/>
          </b>
        </span>),
        accessor: 'date',
        minWidth: 110,

        Cell: row => (
            <div>
              <span>
                {changeToDate(row.value, this.props.intl.locale)}
              </span>
            </div>
          )

      }, {
        Header: () => (<span>
          <b>
            <FormattedMessage id="general.modal.10.4"/>
          </b>
        </span>),
        accessor: 'topic',
        minWidth: 160,
      }, {
        Header: () => (<span>
          <b>
            <FormattedMessage id="general.modal.10.5"/>
          </b>
        </span>),
        accessor: 'occurrence',
        minWidth: 60,
      }
    ]

    return (<div key={this.props.type}>

      <MenuProvider id={this.props.type === "errorGroupware" ? "menu_serverAccessActionGRP" : this.props.type === "errorImap" ? "menu_serverAccessActionImap" : "menu_serverAccessActionLdap"} >
        <ReactTable getTheadThProps={getTheadThProps}

        getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            onContextMenu: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            style: {
                background: rowInfo.original.enable === false ? "rgb(241,241,241)"
                  : rowInfo.index === this.props.selected ? '#e4eef4' : null,
                color: rowInfo.index === this.props.selected ? 'black' : null
            }
          }
        }else{
          return {}
        }
      }}

          getTdProps={(state, rowInfo, column, instance) => {
      return {

        onContextMenu: (e) => {
            if(rowInfo){
            this.props.contextMenu(rowInfo.original)}
            else {this.props.contextMenu("empty")}
        }
      }
    }}
          showPagination={false}
          data={this.props.data}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={false}
          pageSizeOptions={[10, 20, 25, 50, 100]}
          showPageSizeOptions={false}
          pageSize={this.props.data.length} style={{
            maxHeight: "61vh"
          }} className=" text-center border-0 small -clickable"/>
      </MenuProvider>
    </div>)
  }
}

ServeurAccessTab.propTypes = {
  intl: intlShape.isRequired
};
ServeurAccessTab = injectIntl(ServeurAccessTab, {withRef: true});

export default ServeurAccessTab
