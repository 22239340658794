import React from 'react'
import $ from "jquery";
import validator from 'validator';
import DevicesToolBar from './DevicesToolBar';
import DevicesTab from './DevicesTab';
import DevicesDetails from './DevicesDetails';
import DevicesContextMenu from './DevicesContextMenu';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import '../App.css';

import { varTerminaux, varUser, url, varParametres } from '../variables';

const API = varTerminaux.api;
const APIdevicesDetails = varTerminaux.apiDevicesDetails;
const APIdevicesDetailsApp = varTerminaux.apiDevicesDetailsApp;
const APIMMAppServerGroups = varTerminaux.apiMMAppServerGroups;
const APIusers = varUser.api;
const deviceOldDataDelay = varTerminaux.deviceOldDataDelay;
const ApiConfigServerMMApp = varParametres.apiConfigServerMMApp;

var time=0;
var newRequest = false;
var firstFetch = true;
var firstFetchTab = true;
var defaultOldDataDelay = 604800000;


function getApplicationName(appli, language){
    var name = "";

    switch (language) {
      case "fr":
          if (appli.names.fr !== undefined) {
            name = appli.names.fr;
          } else if (appli.names.en !== undefined){
            name = appli.names.en + " (en)";
          }
        break;
      case "en":
          if (appli.names.en !== undefined) {
            name = appli.names.en;
          } else if (appli.names.fr !== undefined){
            name = appli.names.fr + " (fr)";
          }
        break;
      default:
          name = appli.packageName;
        break;
    }

   return name;
  }


function researchApp(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }
  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

export function research(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
      if(valeur.toLowerCase().indexOf("state:new") !== -1 ){
        if(array[i].state.toLowerCase() === "new"){
          testArray.push(array[i]);
        }
      }
      else if(valeur.toLowerCase().indexOf("state:disabled") !== -1 ){
        if(array[i].etat.toLowerCase() === "disabled"){
          testArray.push(array[i]);
        }
      }

      else if(array[i].listusers){
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
        || array[i].imei.indexOf(valeur) !== -1
        || array[i].model.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
        || array[i].listusers.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

          testArray.push(array[i]);

        }}else{
          if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].imei.indexOf(valeur) !== -1
          || array[i].model.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
        }
    }
  }

  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}


var showDET = false;

class devices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      array: [],
      modalName: "modalName",
      modalAddress: "",
      isLoading: true,
      error: null,
      errorModal: null,
      errorDoubleResult: null,
      fileName: "Choix du fichier ...",
      researchValue: "",
      researchApp: "",
      researchMissApp: "",
      researchUnwantedApp: "",
      researchNotifications: "",
      showDetails: false,
      selected: null,
      showPagination: false,
      hit: [],
      allDevices: [],
      devicesDetails: [],
      deviceApplist: [],
      deviceMissingApplist: [],
      deviceUnwantedApplist: [],
      deviceNotifications: [],
    };
    this.tick = this.tick.bind(this);
    this.tableShowPaginationHandle = this.tableShowPaginationHandle.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
    this.researchHandle = this.researchHandle.bind(this);
    this.researchAppHandle = this.researchAppHandle.bind(this);
    this.researchMissAppHandle = this.researchMissAppHandle.bind(this);
    this.researchUnwantedAppHandle = this.researchUnwantedAppHandle.bind(this);
    this.researchNotificationsHandle = this.researchNotificationsHandle.bind(this);
    this.contextMenu = this.contextMenu.bind(this);
    this.contextIncidentHandle = this.contextIncidentHandle.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

componentDidMount() {
  if(this.props.location.defaultResearch){this.setState({researchValue: this.props.location.defaultResearch})}
  if(varTerminaux.enableTimer === "on")this.timer = setInterval(this.tick, 1000);
  if(process.env.NODE_ENV === "development")console.log("REFRESH AUTO terminaux : " + varTerminaux.enableTimer +" , 1sec, " + varTerminaux.timer+"sec");
  this.setState({ isLoading: true });

  this.fetchData(API);
  this.fetchAllDevices();
  this.fetchMMServerGroups();


  Promise.all([this.fetchApi(ApiConfigServerMMApp)])
  .then(([dataConfigServerMMApp]) => {
    this.setState({
    dataConfigServerMMApp: dataConfigServerMMApp,});
  })
}

componentDidUpdate(){
  document.title = this.props.intl.formatMessage({id: "app.menu.3"});
}

componentWillUnmount(){
    time = 0;
    firstFetch = true;
    firstFetchTab = true;
    clearInterval(this.timer);
}


// ------------------------------- Action handle ------------------------------- //

  researchHandle(event) {
    this.setState({researchValue: event.target.value});
  }
  researchAppHandle(event) {
    this.setState({researchApp: event.target.value});
  }
  researchMissAppHandle(event) {
    this.setState({researchMissApp: event.target.value});
  }
  researchUnwantedAppHandle(event) {
    this.setState({researchUnwantedApp: event.target.value});
  }
  researchNotificationsHandle(event) {
    this.setState({researchNotifications: event.target.value});
  }

  selectedHandle(x){
    this.setState({selected : x});
  }

  tableShowPaginationHandle(){
    this.setState({showPagination : !this.state.showPagination })
  }

  contextIncidentHandle(rowHit, value, event){
    var x = rowHit;
    x.incident = value;
    this.setState({hit: x});

    var data = {
        "incident": value
      };

    fetch(varTerminaux.api + "/" + rowHit.deviceId, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
          this.setState({isSending: false});
        }
        var x = response;
       })

  }

  onChangeFile(event) {
      event.stopPropagation();
      event.preventDefault();

      this.setState({fileName: event.target.files[0].name});
      var file = event.target.files[0];
      if(process.env.NODE_ENV == "development")console.log(file);
      this.setState({file});
  }

  mobalGraph(x){
    if (x.users) {
      this.setState({modalName: x.users[0].login, modalAddress: this.getGraph(x.users[0].login, x.name)});
      $('#ModalGraph').modal('show')
    }
  }

  devicesDetailsModal(x, data){
    $('#ModalDevicesDetails').modal('show');
  }


// ------------------------------- Auto refresh table -------------------------- //

  tick(){
    if(newRequest === true){
      if(time === varTerminaux.timer){
        time = 0;
        newRequest=false;
        this.fetchData(API)
        this.fetchAllDevices();
        this.fetchMMServerGroups();
      }
        time = time + 1;
    }
  }

  refresh(){
    this.fetchData(API)
    this.fetchAllDevices();
    this.fetchMMServerGroups();
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchApi(API){
    return fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        //throw new Error('fetchData : Something went wrong ...');
      }
    })
  }


  fetchData(API) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if(process.env.NODE_ENV == "development")console.log("fetch : "+hours+":"+min+":"+sec);
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchData : unable to fetchData');
      }
    }).then(data => this.adaptData(data)).catch(error => this.setState({ error}));
  }

  fetchDevicesDetails(API) {
    this.setState({applistFetching: true, errorModal: null, errorDoubleResult: null})
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 500) {
          this.setState({errorDoubleResult: true})
        }
        throw new Error('fetchDevicesDetails : unable to fetchDevicesDetails');
      }
    }).then(data => this.adaptDeviceApplist(data))
    .catch(errorModal => this.setState({ errorModal}));
  }

  fetchDevicesNotifications(API) {
    this.setState({applistFetching: true})
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchDevicesNotifications : unable to fetchDevicesNotifications');
      }
    }).then(data => this.adaptDeviceNotifications(data))
    .catch(errorModal => this.setState({ errorModal}));
  }

  fetchAllDevices() {
    if(process.env.NODE_ENV == "development")console.log("fetch allDevices");
    fetch(APIdevicesDetails ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchAllDevices : unable to fetchAllDevices');
      }
    }).then(data => this.adaptAllDevices(data))
    .catch(errorModal => this.setState({ errorModal}));
  }

  fetchMMServerGroups() {
    if(process.env.NODE_ENV == "development")console.log("fetch MMAppServerGroups");
    fetch(APIMMAppServerGroups ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchMMAppServerGroups : unable to fetchMMAppServerGroups');
      }
    }).then(data => this.setState({MMAppServerGroups: data}))
    .catch(errorModal => this.setState({ errorModal}));
  }

  adaptAllDevices(tab){
    if (firstFetch) {
      this.fetchData(API)
      firstFetch = false;
    }
    this.setState({allDevices : tab, errorModal: null})
  }


  checkDeviceAppState(device){
    var appUptodate = 0;

    var oldDataDelay;
    if (this.state.dataConfigServerMMApp && this.state.dataConfigServerMMApp.deviceOldDataDelay) {
      oldDataDelay = Number(this.state.dataConfigServerMMApp.deviceOldDataDelay);
    } else {
      oldDataDelay = Number(defaultOldDataDelay);
    }


    if(device.exception === true){
      appUptodate = 4;
    }
    else if(device.compliant){
      if (device.compliant == "2" || device.compliant == "-1") {
        appUptodate = 6;
      } else {
        if (device.location_state_old !== null) {
          appUptodate = 7;
        } else {
          appUptodate = 3;
        }
      }
    }
    else {
      appUptodate = 0;
    }


   if(Date.parse(device.updated_at) + oldDataDelay < Date.parse(new Date())){

    switch (appUptodate) {
      case 0:
        appUptodate = 100;
        break;
      case 3:
        appUptodate = 103;
        break;
      case 4:
        appUptodate = 104;
        break;
      case 6:
        appUptodate = 106;
        break;
      case 7:
        appUptodate = 107;
        break;
      default:

    }


    }

    return appUptodate
  }

  fuseDevicesTab(mproDevicesTab, mmappDevicesTab) {
    const fusedDevicesTab = [];

    for (const mproDevice of mproDevicesTab) {
      var matchingUser = null;
      if (mproDevice.users[0]){
        matchingUser = mmappDevicesTab.find((mmappDevice) => mmappDevice.email === mproDevice.users[0].email && mmappDevice.model === mproDevice.model);
      }

      if (matchingUser) {
        const fusedDevice = {
          ...mproDevice,
          ...matchingUser,
          presentInMpro: true,
          presentInMMapp: true,
        };

        fusedDevicesTab.push(fusedDevice);
      } else {
        const deviceToAdd = {
        ...mproDevice,
        presentInMpro: true,
        presentInMMapp: false,
      };
      fusedDevicesTab.push(deviceToAdd);
      }
    }

    for (const mmappDevice of mmappDevicesTab) {
      const matchingDevice = fusedDevicesTab.find((fusedDevice) => fusedDevice.email === mmappDevice.email && fusedDevice.model === mmappDevice.model);
      if (!matchingDevice) {
          const deviceToAdd = {
          ...mmappDevice,
          presentInMpro: false,
          presentInMMapp: true,
          users: [{login: mmappDevice.email}]
        };
        fusedDevicesTab.push(deviceToAdd);
      }
    }

    return fusedDevicesTab;
  }

  adaptData(mproDataTab){
    const fusedDevicesTab = this.fuseDevicesTab(mproDataTab, this.state.allDevices);

    var adaptedTabForDisplay = [];
    fusedDevicesTab.map((x, index) => {
      var item = new Object();

      if(x.os){ item.os = x.os; } else { item.os = ""; };
      item.appUptodate = 5;
      item.simcard_compliant = 5;
      var needMMApp = false;

      if(x.users && x.users.length > 0 && x.users[0] != null)
      {
        item.users = x.users;
        item.listusers = this.getListUsers(x.users)
        item.email = x.users[0].email ? x.users[0].email : null;
        item.groupName = x.users[0].group ? x.users[0].group.name : null;

        if (this.state.MMAppServerGroups && x.users[0].group) {
            for (var i = 0; i < this.state.MMAppServerGroups.length; i++) {
              if (x.users[0].group.groupId === this.state.MMAppServerGroups[i].groupId) {
                needMMApp = true;
              }
            }
          }

        if(x.presentInMMapp){
          item.os = "Android " + x.os_Version;
          if(x.phone_number){ item.phoneNumber = x.phone_number; }
          if (x.phoneImei) { item.imei = x.phoneImei; } else { item.imei = ""; };
          if(x.mmapp_test){ item.mmapp_test = x.mmapp_test; }
          item.updatedAt = x.updated_at;
          item.percentage_used_for_filter = x.percentage_used;
          item.percentage_used = Number(x.percentage_used).toFixed(0);

          if (x.mobile_data_usage) {
            var value = x.mobile_data_usage;
            var affichedValue = value.substring(0, value.indexOf(" "));
            var newValue;
            var i = value.substring(value.indexOf(" ")+1);
            switch (i) {
              case "B":
                    newValue = parseFloat(affichedValue.replace(',', '.')) * 1024;
                break;
              case "MB":
                    newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024);
                break;
              case "GB":
                    newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024);
                break;
              case "TB":
                    newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024 * 1024);
                break;
              default:
                newValue = null;
              break;
            }
            item.mobile_data_usage_for_filter = newValue;
          }

          item.mobile_data_usage = x.mobile_data_usage;
          item.server_simcard_mobile_data_limit = x.server_simcard_mobile_data_limit;
          item.androidDbId = x.id;
          item.post = x;
          item.simcard_compliant = x.simcard_compliant;
          item.appUptodate = this.checkDeviceAppState(x);
        } else if(needMMApp && !x.presentInMMapp){
          item.appUptodate = 2;
        }
      }

      item.etat = this.etatTerminal(x.reset, x.enable);
      item.state = x.state;
      item.name = x.name ? x.name : "";
      item.enable = x.enable;
      item.userslength = x.users ? x.users.length : "";
      item.exception = x.exception;
      item.imei = x.imei ? x.imei : null;
      item.model = x.model ? x.model : "";
      item.operator = x.operator ? x.operator : "";
      item.deviceId = x.deviceId;
      item.extensionDate = x.extensionDate;
      if (!item.phoneNumber) { item.phoneNumber = x.phoneNumber; }
      item.provider = x.provider
      item.purchasingDate = x.purchasingDate;
      item.purchasingPrice = x.purchasingPrice;
      item.compliant = x.compliant;
      item.noRestriction = x.noRestriction;
      item.incident = x.incident;
      item.presentInMpro = x.presentInMpro;
      item.presentInMMapp = x.presentInMMapp;

        switch (x.updateStatus) {
          case "UPDATED":
              item.uptodate = 1;
            break;
          case "UNKNOWN":
              item.uptodate = x.noRestriction ? 2 : -1;
          break;
          case "TOUPDATE":
              item.uptodate = x.noRestriction ? 2 : 0;
            break;
          default:
              item.uptodate = -1;
        }

      adaptedTabForDisplay.push(item);
    });
    newRequest=true;

    if(adaptedTabForDisplay.length > 50 && firstFetchTab === true){
      firstFetchTab = false;
      this.setState({showPagination: true})
    }

     this.setState({array: adaptedTabForDisplay, isLoading: false})

  }


  adaptDeviceApplist(tab){
    this.fetchDevicesNotifications(varTerminaux.apiMMAppServerNotifications + tab.android_id);

    var newAccountList = [];
    if (tab.accountList && tab.accountList.length > 0) {
      for (var i = 0; i < tab.accountList.length; i++) {
        if (tab.accountList[i].name !== tab.user.email) {
          newAccountList.push(tab.accountList[i]);
        }
      }
    }
    tab.accountList = newAccountList;

    this.setState({devicesDetails: tab})

    var newTableauApplist = [];
    var newTableauMissingApplist = [];
    var newTableauUnwantedApplist = [];

    if (tab.applist !== null) {

        if (tab.exception) {
          for (var i = 0; i < tab.applist.length; i++) {
            var item = new Object;

            item = tab.applist[i];
            // valeur à z pour le tri sur la colonne conforme
            item.compliantStatus = "0";

            newTableauApplist.push(item);
          }
        } else {

          for (var i = 0; i < tab.applist.length; i++) {
            var item = new Object;
            item = tab.applist[i];

            // compliant = 2 // uncompliant = 1 // default = 3
            switch (item.compliantStatus) {
              case "SUSPENDED":
                  item.compliantStatus = "4";
                break;
              case "UNWANTED":
                  item.compliantStatus = "3";
                break;
              case "COMPLIANT":
                  item.compliantStatus = "2";
                break;
              case "UNCOMPLIANT":
                  item.compliantStatus = "1";
                break;
              default: item.compliantStatus = "0";

            }

            newTableauApplist.push(item);
          }
        }

    } else {
        newTableauApplist = [];
    }

    if (tab.missingApplications !== null) {
        for (var i = 0; i < tab.missingApplications.length; i++) {
          var item = new Object;

          item.latestKnownVersion = tab.missingApplications[i].latestKnownVersion;
          item.latestKnownVersionCode = tab.missingApplications[i].latestKnownVersionCode;
          item.neededVersion = tab.missingApplications[i].latestKnownVersion;
          item.neededVersionCode = tab.missingApplications[i].latestKnownVersionCode;

          if (tab.missingApplications[i].storeVersion) {
            item.storeVersion = tab.missingApplications[i].storeVersion;
            item.neededVersion = tab.missingApplications[i].storeVersion;
          }
          if (tab.missingApplications[i].storeVersionCode) {
            item.storeVersionCode = tab.missingApplications[i].storeVersionCode;
            item.neededVersionCode = tab.missingApplications[i].storeVersionCode;
          }
          item.name = getApplicationName(tab.missingApplications[i], this.props.intl.locale);
          item.packageName = tab.missingApplications[i].packageName;

          newTableauMissingApplist.push(item);
        }

    } else {
        newTableauMissingApplist = [];
    }

    if (tab.unwantedApplications !== null) {
        for (var i = 0; i < tab.unwantedApplications.length; i++) {
          var item = new Object;

          item.latestKnownVersion = tab.unwantedApplications[i].latestKnownVersion;
          item.latestKnownVersionCode = tab.unwantedApplications[i].latestKnownVersionCode;
          if (tab.unwantedApplications[i].storeVersion) {
            item.storeVersion = tab.unwantedApplications[i].storeVersion;
          }
          if (tab.unwantedApplications[i].storeVersionCode) {
            item.storeVersionCode = tab.unwantedApplications[i].storeVersionCode;
          }
          item.name = getApplicationName(tab.unwantedApplications[i], this.props.intl.locale);
          item.packageName = tab.unwantedApplications[i].packageName;

          newTableauUnwantedApplist.push(item);
        }

    } else {
        newTableauUnwantedApplist = [];
    }

    this.setState({deviceApplist: newTableauApplist, deviceMissingApplist: newTableauMissingApplist, deviceUnwantedApplist: newTableauUnwantedApplist, devicesDetails: tab, applistFetching: false})

   }

   adaptDeviceNotifications(tab){

      var newTabNotifications = [];

      for (var i = 0; i < tab.length; i++) {
        var item = new Object;


        if (tab[i].version && tab[i].versionCode) {
          item.version = tab[i].version;
          item.versionCode = tab[i].versionCode;
        } else if (tab[i].application && tab[i].application.latestKnownVersion && tab[i].application.latestKnownVersionCode){
          item.version = tab[i].application.latestKnownVersion;
          item.versionCode = tab[i].application.latestKnownVersionCode;
        }

        if (tab[i].application) {

          item.title = getApplicationName(tab[i].application, this.props.intl.locale);
        } else {
          item.title = tab[i].title;
        }

        if (tab[i].text) {
          item.text = tab[i].text;
        }
        if (tab[i].link) {
          item.link = tab[i].link;
        }

        item.state = tab[i].state;
        item.date = tab[i].date;
        item.id = tab[i].id;

        newTabNotifications.push(item);
      }

      this.setState({deviceNotifications: newTabNotifications, applistFetching: false})
   }


  etatTerminal(reset, enable) {
    var data;

    switch (reset) {
      case 1:
        data = "needReset";
        break;
      case 2:
        data = "asBeenReset"
        break;
      default:
        data = "default";
    }

    if (enable === false) {
      data = "disabled";
    }

    return (data)
  }


// ------------------------------- Function with visual ------------------------ //

  fetching () {
    if(newRequest === false){
    return(
      <div className="col d-flex justify-content-center">
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )}
  }


  getListUsers(array){

    var listUsers = "";

    array.map(x => {
      listUsers = listUsers + x.login +", ";
    })

    listUsers = listUsers.substring(0, listUsers.length - 2)

    return (listUsers)
  }


   getDetailsContextMenu(terminal) {

     var txt = "";
     var id = "";

     if (terminal.userslength > 0) {
       terminal.users.map(x => {
         txt = x.login;
         id = x.userId;

       });

        return (true)
      }
      return(false)
    }


    getGraph(login, devices) {
      return (`${url}/Microsoft-Server-ActiveSync/?Cmd=Activity&User=${login}&Login=${login}&Device=${devices}&lang=fr&context=null`)
    }



  contextMenu(x){
      if(x === "empty"){
      this.setState({hit : "empty"});
    }else {

      this.setState({hit : x, androidDbId: x.androidDbId});

      if (x.post && x.post.presentInMMapp && x.post.email) {
        this.fetchDevicesDetails(APIdevicesDetailsApp + x.model + "/" + x.post.email ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }});
        showDET = true;
      } else {
        showDET = false;
        this.setState({errorDoubleResult: false});
      }
    }

  }


// ------------------------------- Render -------------------------------------- //

  render() {

        const { isLoading, error } = this.state;

        if (error) {
          return (
              <div>
                  <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                      <span>Error : {error.message}</span>
                  </div>

                  <div className="container small">
                  </div>
              </div>
          );
        }

        if (isLoading) {

          return (
            <div className="card mt-5 small">
              <div className="card-header text-info">
                <b>
                  {this.props.intl.formatMessage({id: "chargement"})} ...
                </b>
              </div>
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
            </div>);
        }

    return (<div className="card small bg-light mt-3">
      <div className="card-header">

            <DevicesToolBar researchValue={this.state.researchValue}
            researchHandle={this.researchHandle}
            isFetching={this.fetching()}
            fileName={this.state.fileName}
            onChangeFile={this.onChangeFile.bind(this)}
            showPagination={this.state.showPagination}
            tableShowPaginationHandle={this.tableShowPaginationHandle.bind(this)}
            errorModal={this.state.errorModal}
            userAdministration={this.props.userAdministration}/>

      </div>

            <DevicesTab array={this.state.array}
            mobalGraph={this.mobalGraph.bind(this)}
            getGraph={this.getGraph}
            contextMenu={this.contextMenu}
            selected={this.state.selected}
            selectedHandle={this.selectedHandle}
            showPagination={this.state.showPagination}
            researchValue={this.state.researchValue}
            />



          <DevicesContextMenu hit={this.state.hit}
          mobalGraph={this.mobalGraph.bind(this)}
          modalName={this.state.modalName}
          modalAddress={this.state.modalAddress}
          devicesDetails={this.state.devicesDetails}
          devicesDetailsModal={this.devicesDetailsModal.bind(this)}
          getGraph={this.getGraph}
          getDetailsContextMenu={this.getDetailsContextMenu}
          showDetails={this.state.showDetails}
          contextIncidentHandle={this.contextIncidentHandle}
          showDET={showDET}
          androidDbId={this.state.androidDbId}
          userAdministration={this.props.userAdministration}
          tokenCSRF={this.props.tokenCSRF}
          refresh={this.refresh.bind(this)}
          errorDoubleResult={this.state.errorDoubleResult}
          />


          {this.state.deviceApplist && this.state.dataConfigServerMMApp && this.state.devicesDetails.accountList || this.state.errorModal ?
            <DevicesDetails
            userAdministration={this.props.userAdministration}
            hit={this.state.hit}
            devicesDetailsApplist={this.state.deviceApplist}
            devicesDetailsMissApplist={this.state.deviceMissingApplist}
            devicesDetailsUnwantedApplist={this.state.deviceUnwantedApplist}
            devicesDetails={this.state.devicesDetails}
            errorModal={this.state.errorModal}
            applistFetching={this.state.applistFetching}
            fetchAllDevices={this.fetchAllDevices.bind(this)}
            adaptAllDevices={this.adaptAllDevices.bind(this)}
            dataConfigServerMMApp={this.state.dataConfigServerMMApp}
            deviceNotifications={researchApp(this.state.deviceNotifications, this.state.researchNotifications)}
            researchNotificationsHandle={this.researchNotificationsHandle}
            refresh={this.refresh.bind(this)}
            fetchDevicesDetails={this.fetchDevicesDetails.bind(this)}
            tokenCSRF={this.props.tokenCSRF}
            /> : <div/>}


    </div>)
  }
}
devices.propTypes = {
  intl: intlShape.isRequired
};
devices = injectIntl(devices,
                        {withRef: true});

export default devices
