import React from 'react'
import {FormattedMessage} from "react-intl";

class CompteAutoGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="">

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.compteAuto.general.1"/></b>
      </h5>
      <hr/>
      <h6 className="text-info mt-2 mb-2">
        <b><FormattedMessage id="parametres.compteAuto.general.2"/></b>
      </h6>

      <div className="col mt-3 ">
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="ConfigActive"/>
          <label className="custom-control-label" htmlFor="ConfigActive">
            <FormattedMessage id="parametres.compteAuto.general.3"/>
          </label>
        </div>

        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="parametres.compteAuto.general.4"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.state.portLDAP} onChange={this.portHandle}/>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="parametres.compteAuto.general.5"/>
          </div>

          <div className="col">
            <select id="inputState" className="form-control mr-4" defaultValue="LDAP">
              <option className="list-group-item small">LDAP</option>
            </select>
          </div>

        </div>
      </div>

      <h6 className="text-info mt-2 mb-2">
        <b><FormattedMessage id="parametres.compteAuto.general.6"/></b>
      </h6>

      <div className="col mt-3 ">
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="filterLDAP"/>
          <label className="custom-control-label" htmlFor="filterLDAP">
            <FormattedMessage id="parametres.compteAuto.general.7"/>
          </label>
        </div>

        <div className="row mb-3">
          <div className="col">
            <FormattedMessage id="parametres.compteAuto.general.8"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.state.portLDAP} onChange={this.portHandle}/>
          </div>
        </div>

      </div>

    </div>)
  }
}

export default CompteAutoGeneral
