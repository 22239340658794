import React from 'react'
import {MdLens} from "react-icons/md";
import {NavLink} from 'react-router-dom'
import {IoMdSearch} from "react-icons/io";

import {FormattedMessage} from 'react-intl';

import GeneralModals from "../dashboard/GeneralModals";

const drawSize = 'calc(3px + 3vmin)';

function getTexte(text, link) {
  if (link) {
    return (<NavLink className="text-info" to={{
        pathname: "/" + link,
        tabActive: 5
      }}>{text}</NavLink>)
  } else {
    return (text)
  }
}

function getValeur(value) {
  if (value) {
    return (<span className="card float-right text-center key" style={{
        minWidth: '27px',
        fontSize: 'calc(5px + 1vmin)',
        fontFamily: 'Consolas',
        color: 'rgba(255,255,255,1)'
      }}>
      <b>{value}</b>
    </span>)
  }
}

function getEtat(state) {
  if (state === true || state === false) {
    var color = "rgba(224,0,3,0.92)";

    if (state === true) {
      color = "rgba(61,202,0,0.87)"
    }
    return (<span style={{
        color: color
      }}>
      <MdLens className="float-right" size={drawSize}/>
    </span>)
  }

}

function getButtonError(id) {
  if (id === "imap" || id === "groupware" || id === "ldap") {
    return (<button type="button" className="btn btn-sm btn-info mb-3">
      <FormattedMessage id={"general.modal.errorbutton"}/>
    </button>)
  }
}

function getBouton(button, value, state, id, tooltip) {
  var loupeState = "loupe";
  var target = "#" + id;
  if (id === "imap" || id === "groupware" || id === "ldap") {
    if (state === true) {
      loupeState = "loupe_disable";
      target = null;
    }
  }
  if (value === "0") {
    loupeState = "loupe_disable";
    target = null;
  }
  if (button === "on" && id) {
    return (<span className={`d-inline-block float-right ml-1 ${loupeState}`} title={tooltip}>
      <NavLink className="border-0 float-right " data-toggle="modal" to="" data-target={target}>
        <span>
          <IoMdSearch size={drawSize}/>
        </span>
      </NavLink>
    </span>)
  }

}

const LineGeneral = ({
  texte,
  link,
  valeur,
  etat,
  bouton,
  tooltip,
  id,
  modalTitle
}) => {

  return (<div className="ml-1 mr-1" style={{minHeight: '27px'}}>
    {getTexte(texte, link)}

    <span>
      <span style={{
          color: "rgba(255,255,255,0)"
        }}>
        <MdLens size={drawSize}/>
      </span>
    </span>

    <div className="float-right ml-2">
      {getValeur(valeur)}
      {getEtat(etat)}
      {getBouton(bouton, valeur, etat, id, tooltip)}
    </div>
  </div>)

}
export default LineGeneral
