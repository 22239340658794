import React from 'react'
import { intlShape, injectIntl } from 'react-intl';
import {NavLink} from 'react-router-dom'
import {GoPlus} from "react-icons/go";
import {MdPhonelinkSetup} from "react-icons/md";

class DevicesToolBar extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div className="form-inline">

          <input className="form-control mr-sm-2 row-auto"
          style={{height: "35px"}}
          type="search"
          placeholder={this.props.intl.formatMessage({id: "recherche"})}
          aria-label="Search" value={this.props.researchValue}
          onChange={this.props.researchHandle}/>

          { this.props.userAdministration === "ADMINISTRATOR" ?
          <NavLink className="btn btn-sm btn-info mr-2" title={this.props.intl.formatMessage({id: "devices.newdevice"})} to="/Devices/NewDevice">
            <span style={{
                color: "rgb(255,255,255)"
              }}>
              <GoPlus size={20}/>
            </span>
          </NavLink>

          : null
          }


          {this.props.userAdministration === "ADMINISTRATOR" || this.props.userAdministration === "REMOTE_ADMIN" ?
          <NavLink className="btn btn-sm btn-info ml-2" title={this.props.intl.formatMessage({id: "devices.linkdevice"})} to="/Devices/LinkDevice">
            <span style={{
                color: "rgb(255,255,255)"
              }}>
              <MdPhonelinkSetup size={22}/>
            </span>
          </NavLink>

          : null
          }


            {this.props.errorModal ?
              <div className="card ml-5 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)"}}>
                <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error MM-App server : {this.props.errorModal.message}</span>
              </div>

              : null
            }

              {this.props.isFetching}

      </div>
    )
  }
}

DevicesToolBar.propTypes = {
  intl: intlShape.isRequired
};
DevicesToolBar = injectIntl(DevicesToolBar,
                        {withRef: true});

export default DevicesToolBar
