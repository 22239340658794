import React from 'react'
import { FaPlus } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import TablePlus from '../Components/TablePlus';


class UserMMF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adressesMails: [
        "Root", "Test", "Shared"
      ],
      mail: "Root"
    };

    this.addMail = this.addMail.bind(this);
    this.handleMail = this.handleMail.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

  selectedServerFile(e){
    this.setState({selectedServerFileId: e.target.value})
  }


  handleMail(event) {
    this.setState({mail: event.target.value});
  }

  addMail() {
    const tableau = this.state.adressesMails;
    for (var i = 0; i < tableau.length; i++) {
      if (tableau[i] === (this.state.mail)) {
        return
      }
    }
    if (this.state.mail) {
      tableau.push(this.state.mail)
    }
    this.setState({adressesMails: tableau});
    this.setState({mail: ""});
  }


  removeMail(index) {
    const tab = this.state.adressesMails;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({adressesMails: newTab});
  }

  modalConfirm(name, index){
    console.log(index);
    this.setState({modalTXT: name, modalIndex: index})
  }

  getGroupName (grp) {
    if(grp){return(grp.name)}
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="col mt-2 ml-2 mb-2">

      <div className="col ">

      <h6 className="text-info mt-2 mb-2">
        <b>Configuration MM-File</b>
      </h6>


                <TablePlus
                  smallTitle={this.props.intl.formatMessage({id: "userDetails.MMI.config.fileServ"})}
                  data={this.props.userInfo.fileServers.sort((a, b) => a.name.localeCompare(b.name))}
                  modalAddId="modalAjoutServeurFichier"
                  deleteHandler={this.modalConfirm.bind(this)}
                  modalRemoveId="modalConfirmSuprPJ"
                  userAdministration={this.props.userAdministration}
                  maxHeight="235px"
                />


            {/*<div className="card rounded-0 mb-3">

              <div className="card-header" style={{
                  background: 'rgb(251,251,251)'
                }}>
                <div className="row">
                  <div className="col">

                    <FormattedMessage id="userDetails.MMI.config.fileServ"/>
                  </div>

                  { this.props.userAdministration === "ADMINISTRATOR" ?
                  <div className="text-primary col-2 float-right">
                    <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutServeurFichier">
                      <span style={{
                          color: "rgb(255,255,255)"
                        }}>
                        <FaPlus size={14}/>
                      </span>
                    </button>

                  </div>
                  : null
                }

                </div>
              </div>


                <PerfectScrollbar>
                <ul className="list-group list-group-flush" style={{maxHeight: "235px"}}>
                  {
                    this.props.userInfo.fileServers.sort((a, b) => a.name.localeCompare(b.name)).map((ListServer, index) => (
                      <li key={index} className="list-group-item text-center py-2">
                      <div>
                      {ListServer.name}
                      { this.props.userAdministration === "ADMINISTRATOR" ?
                      <button
                              style={{outline: 'none'}}
                              className="close"
                              data-toggle="modal" data-target="#modalConfirmSuprPJ"
                              onClick={this.modalConfirm.bind(this, ListServer.name, index)}>
                              <span style={{
                                  color: "rgb(110,110,110)"
                                }}>
                                <IoIosCloseCircleOutline size={28}/>
                              </span>
                      </button>
                      : null
                    }
                    </div>
                    </li>))
                  }
              </ul>
              </PerfectScrollbar>

            </div>*/}



            <h6 className="text-info mb-3">
              <b><FormattedMessage id="userDetails.MMI.config.fileServConnexion"/></b>
            </h6>

              <div className="col">
                <div className="row">
                <div className="col mb-2">
                  <div className="custom-control custom-checkbox mb-2">
                    <input className="custom-control-input" type="checkbox" value="" id="loginDIFF"/>
                    <label className="custom-control-label" htmlFor="loginDIFF">
                      <FormattedMessage id="userDetails.MMI.config.logindifferent"/>
                    </label>
                  </div>
                </div>
                </div>
                <div className="col">
                  <div className="row mb-3">
                      <div className="col">
                          Login
                      </div>
                      <div className="col">
                          <input className="form-control" value={this.props.userInfo.loginMMfile ? this.props.userInfo.loginMMfile : ""} onChange={e => this.props.handleChange(e, "loginMMfile")}/>
                      </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalAjoutServeurFichier" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="userDetails.MMI.config.addServ"/></h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <select id="inputState" className="form-control mr-4" value={this.props.selectedServerFileId}  onChange={e => this.props.selectedServerFile(e)}>
                      {
                        this.props.availableServer.map((ListServerDispo, index) => (
                          <option key={index} className="list-group-item small" value={ListServerDispo.serverId ? ListServerDispo.serverId : ListServerDispo.id}>{ListServerDispo.name}</option>))

                      }
                      </select>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                      </button>
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addServerFile.bind(this, this.props.selectedServerFileId)}><FormattedMessage id="ajouter"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalConfirmSuprPJ" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className={`modal-content modalConfirm border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel"> {this.props.intl.formatMessage({id: "supprimer"})} </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel"> {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ? </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.props.removeServerFile.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>


      </div>

    </div>)
  }
}


UserMMF.propTypes = {
  intl: intlShape.isRequired
};
UserMMF = injectIntl(UserMMF,{withRef: true});

export default UserMMF
