import React from 'react'
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

class GroupsGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAdministration: [
        { value: '0', label: 'Utilisateur'},
        { value: '1', label: 'Administrateur' },
        { value: '2', label: 'Superviseur' }
      ],
      selectRights: [
        { value: '0', label: '000'},
        { value: '1', label: '111' },
        { value: '2', label: '222' },
        { value: '3', label: '333' }
      ],
    };

  }


// ------------------------------- Action handle ------------------------------- //

  handleChange(event){
    console.log(event.value);
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    return (<div className="col-8 mt-3 mb-3 container">

        <h6 className="text-info">
          <b><FormattedMessage id="groupsDetails.General.1"/></b>
        </h6>
        <div className="row mb-2 mt-3">
          <div className="col">
            <FormattedMessage id="groupsDetails.General.2"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.props.data.name} onChange={this.props.nameHandle}/>
          </div>
        </div>

        <h6 className="text-info">
          <b><FormattedMessage id="groupsDetails.General.3"/>
          </b>
        </h6>
        <div className="row mb-3">
          <div className="col ml-2 mt-2 mr-2">

          <select id="inputState"
            className="form-control"
            onChange={e => this.props.administHandle(e, "administration")}
            defaultValue={this.props.data.administration}>
            <option className="list-group-item small " value="0">{this.props.intl.formatMessage({id: "group.administration.0"})}</option>
            <option className="list-group-item small " value="2">{this.props.intl.formatMessage({id: "group.administration.2"})}</option>
            <option className="list-group-item small " value="3">{this.props.intl.formatMessage({id: "group.administration.3"})}</option>
            {this.props.userAdministration === "REMOTE_ADMIN" ?
              null
              :
              <option className="list-group-item small " value="1">{this.props.intl.formatMessage({id: "group.administration.1"})}</option>
            }

          </select>

          </div>
        </div>

    </div>)
  }
}

GroupsGeneral.propTypes = {
  intl: intlShape.isRequired
};
GroupsGeneral = injectIntl(GroupsGeneral, {withRef: true});

export default GroupsGeneral
