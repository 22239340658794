import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom'
import {FaUserPlus} from "react-icons/fa";


class UsersToolBar extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div className="form-inline">

          <input className="form-control mr-sm-2" type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search"
          value={this.props.researchValue}
          onChange={this.props.rechercheHandle}
          style={{height: "35px"}}/>

          {this.props.userAdministration === "ADMINISTRATOR" ?
          <NavLink className="btn btn-sm btn-info ml-2" title={this.props.intl.formatMessage({id: "users.newuser"})} to="/Users/NewUser">
            <span style={{
                color: "rgb(255,255,255)"
              }}>
              <FaUserPlus size={22}/>
            </span>
          </NavLink>

          : null
          }

          {this.props.isFetching}

      </div>
    )
  }
}

UsersToolBar.propTypes = {
  intl: intlShape.isRequired
};
UsersToolBar = injectIntl(UsersToolBar,
                        {withRef: true});

export default UsersToolBar
