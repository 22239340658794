import React from 'react'
import {FormattedMessage} from "react-intl";

function testIFGrp(tab) {
  if (tab) {
    return (tab.name)
  } else {
    return ("")
  }
}

class UserMMI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adressesMails: [
        "Root", "Test", "Shared"
      ],
      mail: ""
    };

    this.addMail = this.addMail.bind(this);
    this.handleMail = this.handleMail.bind(this);

  }


// ------------------------------- Action handle ------------------------------- //

  handleMail(event) {
    this.setState({mail: event.target.value});
  }

  addMail() {
    const tableau = this.state.adressesMails;
    for (var i = 0; i < tableau.length; i++) {
      if (tableau[i] === (this.state.mail)) {
        return
      }
    }
    if (this.state.mail) {
      tableau.push(this.state.mail)
    }
    this.setState({adressesMails: tableau});
    this.setState({mail: ""});
  }

  getGroupName (grp) {
    if(grp){return(grp.name)}
  }

  removeMail(index) {
    const tab = this.state.adressesMails;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({adressesMails: newTab});
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="col mt-2 ml-2 mb-2">

      <div className="col">


            <h6 className="text-info mt-2 mb-3">
              <b>Configuration MM-Instant</b>
            </h6>
            <div className="row border-0">
              <div className="col">

                <div className="col mb-4">
                  <div className="custom-control custom-checkbox mb-2">
                    <input className="custom-control-input" type="checkbox" id="enableMMI" checked={this.props.userInfo.mminstant} onChange={e => this.props.handleChange(e, "mminstant")}/>
                    <label className="custom-control-label" htmlFor="enableMMI">
                      <FormattedMessage id="userDetails.MMI.config.useMMI"/>
                    </label>
                  </div>
                </div>

                <div className="mb-3">
                  <a><FormattedMessage id="userDetails.MMI.config.msgServ"/></a>
                  <select className="custom-select" id="inputGroupSelect01" value={this.props.userInfo.mailServer} onChange={e => this.props.handleChange(e, "mailServer")}>
                    <option>
                      -
                    </option>
                    {(this.props.userInfo.availableMailServers.map((list, index) => (
                      <option key={index} value={list.serverId ? list.serverId : list.id}>{list.name}</option>
                    )))}
                  </select>
                </div>

                <div>
                  <a><FormattedMessage id="userDetails.MMI.config.groupwareServ"/></a>
                  <select className="custom-select mb-4" id="inputGroupSelect01" value={this.props.userInfo.groupwareServer} onChange={e => this.props.handleChange(e, "groupwareServer")}>
                    <option>
                      -
                    </option>
                    {(this.props.userInfo.availableGroupwareServers.map((list, index) => (
                      <option key={index} value={list.serverId ? list.serverId : list.id}>{list.name}</option>
                    )))}
                  </select>
                </div>

              </div>
            </div>
      </div>

    </div>)
  }
}

export default UserMMI
