import React from 'react'
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import 'bootstrap/dist/css/bootstrap.css';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {TiPlus} from "react-icons/ti";
import CT from '../CT';

import {FaCheck} from "react-icons/fa";

import {varGroupDetail, varParametres} from '../variables';

const API = varGroupDetail.apiPolitiquesSecu;
const ApiExtansions = varParametres.apiExtansions;

const tentativeArray = [4,5,6,7,8,9,10,11,12,13,14];


class GroupsPolitiqueSecu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedOption: null,
      selectFocused: false,
      selectPeriodeSynchro: [
        { value: 'Tout synchroniser', label: 'Tout synchroniser'}
      ],

      extensions:"",
      tabModif: {},
      dataExtansions: [],
      limitSize: false,
      checked: true,
      disabl: false,
      valideMSG: false,
    };

    this.formatValide = this.formatValide.bind(this);
    this.formatEnabled = this.formatEnabled.bind(this);
    this.enableConfigGENE = this.enableConfigGENE.bind(this);

    this.submit = this.submit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //



  componentWillMount() {
    this.setState({isLoading: true});

        Promise.all([
            fetch(ApiExtansions ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
        ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data1]) => this.setState({
            dataExtansions: this.getExtensions(data1),
            isLoading: false
        }));
  }

  componentDidUpdate() {
    if (this.props.data !== this.state.data) {
      this.setState({data: this.props.data});
    }
  }

// ------------------------------- Action handle ------------------------------- //

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  }

  static backPage() {
    window.history.back();
  }

  enableConfigGENE() {
    this.setState({
      enableConfig: !this.state.enableConfig,
      disabl: !this.state.disabl
    })
  }

  setArray(data) {
    this.setState({
      policiesData: data,
      groupName: data.name,
      isLoading: false,
      enableConfig: data._default,
      disabl: data._default,
    })
  }

  changeState(name, value) {
    this.setState({
      [name]: !value
    });
    console.log(name + " : " + !value)
  }

  handleCheckboxChange(field){
    if(!this.state.policiesData[field]){this.setState({
      policiesData: { ...this.state.policiesData, [field]: false }
    });
    ;}
    this.setState({
      modalCheckboxTitle : "Confirmation de modification",
      modalCheckboxButton : !this.state.policiesData[field] ? this.props.intl.formatMessage({id: "activer"}) : this.props.intl.formatMessage({id: "désactiver"}),
      modalCheckboxMsg : this.warningMessage(field, !this.state.policiesData[field]),
      modalCheckboxField : [field]
    });
    $('#modalCheckbox').modal('show')
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchData(API, txt) {
    if(process.env.NODE_ENV == "development")console.log("Fetch API : " + API);
    if (txt === "defaut") {
      this.setState({
        titleModal: this.props.intl.formatMessage({id: "groupsDetails.PolitiqueSecu.btn"})
      })
    };
    if (txt === "restriction") {
      this.setState({titleModal: "Restriction"})
    };
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => this.setArray(data))
    .catch(error => this.setState({error, isLoading: false}));
  }

  getPos(txt) {
    const tab = Object.values(this.state.tabFormat);
    for (var i = 0; i < tab.length; i++) {
      if (tab[i].format === txt) {
        return (i)
      }
    }
  }

  getFormat(x) {
    if (x)
      return (<div className="col">
        {
          (x.map((list, index) => {
            return (<div key={index} className="custom-control custom-checkbox">
              <input className="custom-control-input" type="checkbox" id={`${list.format}`} checked={list.etat} onChange={this.checked.bind(this, index)}/>
              <label className="custom-control-label" htmlFor={`${list.format}`}>
                {list.format}
              </label>
            </div>)
          }))
        }
      </div>)
  }

  getExtensions(data){

    var newArray = [];
    const list = data;

      for (var i = 0; i < list.length; i++) {
        var item = new Object;
        item.value = list[i];
        item.label = list[i];

          newArray.push(item);
      }

      return newArray
  }

  checked(index) {

    var tab = this.state.tabFormat;
    tab[index].etat = !tab[index].etat;
    this.setState({tabFormat: tab});
  }

  formatList(list){
    var txt = "";
    list.map((x, index) => {
      if(x.etat === true){
        txt = txt + " " + x.format;
      }
    })
    this.setState({extensions: txt})
  }

  formatEnabled(tab) {
    var array = "";
    if (tab)
      (tab.map(list => {
        if (list.etat === true) {
          array = array + "/" + (
          list.format)
        }
      }));
    return (array)

  }

  formatValide(tab) {
    this.setState({tabModif: tab});
  }

  changeValide() {
    if (this.state.valideMSG === false) {
      return (<FormattedMessage id="valider"/>)
    } else {
      return (<span style={{
          color: "rgb(255,255,255)"
        }}>
        <FaCheck size={16}/>
      </span>)
    }
  }

  submit() {
    if (this.state.valideMSG === false) {
      this.setState({valideMSG: true});
      console.log("submit")
    }
  }

  closeModal() {
    this.setState({valideMSG: false});
    this.fetchData(API + this.props.data.groupId)
  }

  onFocus() {
    this.setState({ selectFocused: true })
  }
  onBlur() {
      this.setState({ selectFocused: false })
  }



// ------------------------------- Render -------------------------------------- //

  render() {

      return (<div className="col mt-3">

        <div className="custom-control custom-checkbox mb-2 text-center">
          <input className="custom-control-input" type="checkbox" id="enableConfigGENE" checked={this.props.defaultPolicies} onChange={this.props.handleDefaultPolicies}/>
          <label className="custom-control-label" htmlFor="enableConfigGENE">
            <FormattedMessage id="groupsDetails.PolitiqueSecu.1"/>
          </label>

        </div>
        <hr/>

        <div>
          <h6 className="text-info text-center mb-3">
            <b><FormattedMessage id="groupsDetails.PolitiqueSecu.2"/>
            </b>
          </h6>
          <div className="row">
            <div className="col">

              <CT texte="groupsDetails.PolitiqueSecu.3"
              id="enableWIFI"
              checked={this.props.data.wifi}
              onChange={e => this.props.handlePoliciesChange(e, "wifi")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.4"
              id="enableWEB"
              checked={this.props.data.browser}
              onChange={e => this.props.handlePoliciesChange(e, "browser")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.5"
              id="enableBluetooth"
              checked={this.props.data.bluetooth}
              onChange={e => this.props.handlePoliciesChange(e, "bluetooth")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.6"
              id="enableCAM"
              checked={this.props.data.camera}
              onChange={e => this.props.handlePoliciesChange(e, "camera")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.7"
              id="enableSTOCKAGE"
              checked={this.props.data.storagecard}
              onChange={e => this.props.handlePoliciesChange(e, "storagecard")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.8"
              id="enableUnsighAPP"
              checked={this.props.data.unsignedapplications}
              onChange={e => this.props.handlePoliciesChange(e, "unsignedapplications")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.9"
              id="enablePCSync"
              checked={this.props.data.desktopsync}
              onChange={e => this.props.handlePoliciesChange(e, "desktopsync")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.10"
              id="enableSMS"
              checked={this.props.data.textmessaging}
              onChange={e => this.props.handlePoliciesChange(e, "textmessaging")}
              disabl={this.props.defaultPolicies}
              />

              <FormattedMessage id="groupsDetails.PolitiqueSecu.11"/>

              <select id="inputState" value={this.props.extansionsAutorisation} onChange={this.props.handleChangeExtansionsAutorisation}
                className="form-control mt-1 mb-2"
                disabled={this.props.defaultPolicies}>
                <option className="list-group-item small" value={true}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.allowAll"})}</option>
                <option className="list-group-item small" value={false}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.personalize"})}</option>
              </select>



              <div className="col mt-3">
                <div className="row mb-3">
                  <div className="col">
                    <CT texte="groupsDetails.PolitiqueSecu.12"
                    id="limitPJ"
                    checked={this.props.limitSize}
                    onChange={e => this.props.handleLimitSize(e)}
                    disabl={this.props.defaultPolicies}
                    />

                  </div>
                  <div className="col-5">
                    <input className="form-control" value={this.props.data.maxattachmentsize}
                      onChange={e => this.props.handlePoliciesModif(e, "maxattachmentsize")}
                      disabled={!this.props.limitSize}/>
                  </div>
                </div>
              </div>

            </div>
            <div className="col">

              <FormattedMessage id="groupsDetails.PolitiqueSecu.13"/>

              <select id="inputState" value={this.props.data.emailfilter} onChange={this.props.handleChangeEmailfilter}
                className="form-control mt-1 mb-2"
                disabled={this.props.defaultPolicies}>
                <option className="list-group-item small" value={0}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.0"})}</option>
                <option className="list-group-item small" value={1}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.1"})}</option>
                <option className="list-group-item small" value={2}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.2"})}</option>
                <option className="list-group-item small" value={3}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.3"})}</option>
                <option className="list-group-item small" value={4}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.4"})}</option>
                <option className="list-group-item small" value={5}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.5"})}</option>
              </select>

              <div className=" mt-2">

                <FormattedMessage id="groupsDetails.PolitiqueSecu.14"/>

                        <Select className="mt-2 mb-3"
                          maxMenuHeight={190}
                          isDisabled={this.props.defaultPolicies || this.props.extansionsAutorisation}
                          isMulti={true}
                          value={this.props.selectedOptionExtensions}
                          onChange={this.props.handleChangeSelect}
                          options={this.state.dataExtansions}
                          closeMenuOnSelect={false}
                          removeSelected={false}
                        />
              </div>

              <CT texte="groupsDetails.PolitiqueSecu.15"
              id="Synchro"
              checked={this.props.data.requiremanualsyncwhenroaming}
              onChange={e => this.props.handlePoliciesChange(e, "requiremanualsyncwhenroaming")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.16"
              id="enteteMail"
              checked={this.props.data.onlyheader}
              onChange={e => this.props.handlePoliciesChange(e, "onlyheader")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.17"
              id="cryptTerminal"
              checked={this.props.data.requiredevicencryption}
              onChange={e => this.props.handlePoliciesChange(e, "requiredevicencryption")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.18"
              id="cryptMemory"
              checked={this.props.data.requirestoragecardencryption}
              onChange={e => this.props.handlePoliciesChange(e, "requirestoragecardencryption")}
              disabl={this.props.defaultPolicies}
              />

              <CT texte="groupsDetails.PolitiqueSecu.19"
              id="verouillage"
              checked={this.props.data.password}
              onChange={e => this.props.handlePoliciesChange(e, "password")}
              disabl={this.props.defaultPolicies}
              />

              <div className="row mb-3 mt-3">
                <div className="col">
                  <FormattedMessage id="groupsDetails.PolitiqueSecu.20"/>
                </div>
                <div className="col" disabled={this.props.defaultPolicies}>
                  <select id="inputState"
                  className="form-control mr-4 text-center"
                  defaultValue={this.props.data.maxdevicepasswordfailedattempts}
                  onChange={e => this.props.handlePoliciesModif(e, "maxdevicepasswordfailedattempts")}
                  disabled={this.props.defaultPolicies || !this.props.data.password}>
                    <option className="list-group-item small" value={-1}>-</option>
                    {
                      (tentativeArray.map((x, index) => {
                        return (<option key={index} className="list-group-item small" value={x}>{x}</option>)
                      }))
                    }
                  </select>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>)
  }
}

GroupsPolitiqueSecu.propTypes = {
  intl: intlShape.isRequired
};
GroupsPolitiqueSecu = injectIntl(GroupsPolitiqueSecu, {withRef: true});

export default GroupsPolitiqueSecu
