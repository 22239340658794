import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import ParametresSecurite from "./ParametresSecurite";
import ParametresNotifications from "./ParametresNotifications";
import ParametresPiecesJointes from "./ParametresPiecesJointes";
import ParametresReferenceApplications from "./ParametresReferenceApplications";
import ParametresCompteAuto from "./ParametresCompteAuto";

import CompteAutoGeneral from "./CompteAutoGeneral";
import CompteAutoParametres from "./CompteAutoParametres";
import ParametresMDM from "./ParametresMDM";
import ParametresAutoEnrollment from "./ParametresAutoEnrollment";

import { varParametres, varConfigServeur } from '../variables';

const ApiMDM = varParametres.apiMDM;
const ApiSMTP = varParametres.apiSMTP;
const ApiNotification = varParametres.apiNotification;
const ApiExtansions = varParametres.apiExtansions;
const ApiUserPattern = varParametres.apiUserPattern;
const ApiConfigServerMMApp = varParametres.apiConfigServerMMApp;

const ApiAutoEnrollmentAccounts = varParametres.apiAutoEnrollmentAccounts;

const ApiServFile = varConfigServeur.apiServFile;

var itemActiveSecurity = "active";
var tabActiveSecurity = "show active";

var itemActiveMDM = null;
var tabActiveMDM = null;

class parameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataMDM: null,
      indexModel: 0,
      model: null,
      os: null,
      validButton: "info",
      extansions: "",
      refAppName: "",
      refAppPackageName: "",
      refAppVersion: "",
      refAppVersionCode: "",
      mailNotifications: "",
      availableServer: [],
      dataConfigServerMMApp : "",
      userInfo: {},
      selectValue: "1",
      selectSimcardMobileDataLimitValue: "1",
      autoEnrollmentAccounts:"",
      autoEnrollmentBrands:"",
    };

    this.addCompliant = this.addCompliant.bind(this);
    this.handleSelectMDM = this.handleSelectMDM.bind(this);

    this.handleCheckboxNotifications = this.handleCheckboxNotifications.bind(this);
    this.addMailNotifications = this.addMailNotifications.bind(this);
    this.addExtansions = this.addExtansions.bind(this);
    this.removeMailNotifications = this.removeMailNotifications.bind(this);
    this.removeExtansions = this.removeExtansions.bind(this);
    this.handleMailNotifications = this.handleMailNotifications.bind(this);
    this.handleExtansions = this.handleExtansions.bind(this);
    this.addAutoEnrollmentAccounts = this.addAutoEnrollmentAccounts.bind(this);
    this.removeAutoEnrollmentAccounts = this.removeAutoEnrollmentAccounts.bind(this);
    this.handleAutoEnrollmentAccounts = this.handleAutoEnrollmentAccounts.bind(this);
    this.addAutoEnrollmentBrands = this.addAutoEnrollmentBrands.bind(this);
    this.removeAutoEnrollmentBrands = this.removeAutoEnrollmentBrands.bind(this);
    this.handleAutoEnrollmentBrands = this.handleAutoEnrollmentBrands.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMDM = this.handleChangeMDM.bind(this);
    this.handleStorageSelectChange = this.handleStorageSelectChange.bind(this);
    this.handleSimcardMobileDataLimitSelectChange = this.handleSimcardMobileDataLimitSelectChange.bind(this);

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  getAllDataFetch(){
  return Promise.all([this.fetchApi(ApiMDM), this.fetchApi(ApiNotification), this.fetchApi(ApiExtansions), this.fetchApi(ApiUserPattern), this.fetchApi(ApiServFile), this.fetchApi(ApiSMTP), this.fetchApi(ApiAutoEnrollmentAccounts)])
}

  componentDidMount() {

    document.title = this.props.intl.formatMessage({id: "app.menu.7"});

    this.setState({isLoading: true});
    this.getAllDataFetch()
      .then(([dataMDM, dataNotifications, dataExtansions, dataUserPattern, dataServFile, dataSMTP, dataAutoEnrollment]) => {
        const newListPossible = this.searchDoubleCombinaison(dataMDM.compliantDeviceList, dataMDM.possibleCompliantDeviceList);
        const [newUserPaternListUsed, newUserPaternListPossible] = this.getUserPaternFileList(dataUserPattern.fileServerIds, dataServFile);
        this.setState({
          dataAutoEnrollment: dataAutoEnrollment,
          dataMDM: dataMDM,
          usedCombinaison: dataMDM.compliantDeviceList,
          listCombinaison: dataMDM.possibleCompliantDeviceList,
          unUsedCombinaison: newListPossible,
          dataNotifications: dataNotifications,
          dataExtansions: dataExtansions,
          dataUserPattern: dataUserPattern,
          userPaternListUsed: newUserPaternListUsed,
          userPaternListPossible: newUserPaternListPossible,
          dataServFile: dataServFile,
          dataSMTP: dataSMTP,
          isLoading: false});
      })


      Promise.all([this.fetchApi(ApiConfigServerMMApp)])
      .then(([dataConfigServerMMApp]) => {
        this.setState({
        dataConfigServerMMApp: this.adaptStorageLimiteAndSimcardMobileDataLimit(dataConfigServerMMApp),});
    })

  }

  adaptAccounts(data){
    var newData = [];

    for (var i = 0; i < data.length; i++) {
        newData.push(data[i].name);
    }
    return newData;
  }


  adaptBrands(data){
    var newData = [];

    for (var i = 0; i < data.length; i++) {
      if (data[i].id_model) {
        newData.push(data[i].id_brand + " / " + data[i].id_model);
      } else {
        newData.push(data[i].id_brand);
      }
    }
    return newData;
  }

  adaptStorageLimiteAndSimcardMobileDataLimit(data){
    console.log(data);

    if (data.serverStorageLimit) {
      var selectValue = "1";
      var value = data.serverStorageLimit;
      var newValue;
      if(value > Math.pow(10, 12)){
        newValue = Number(parseInt(value)/(1024 * 1024 * 1024 * 1024)).toFixed(0);
        selectValue = "3";
      } else if(value > Math.pow(10, 9)){
        newValue = Number(parseInt(value)/(1024 * 1024 * 1024)).toFixed(0);
        selectValue = "2";
      } else {
        newValue = Number(parseInt(value)/(1024 * 1024)).toFixed(0);
        selectValue = "1";
      }

      data.serverStorageLimit = newValue;
      this.setState({selectValue: selectValue})
    }

    if (data.serverSimcardMobileDataLimit) {
      var selectValue = "1";
      var value = data.serverSimcardMobileDataLimit;
      var newValue;
      if(value > Math.pow(10, 12)){
        newValue = Number(parseInt(value)/(1024 * 1024 * 1024 * 1024)).toFixed(0);
        selectValue = "3";
      } else if(value > Math.pow(10, 9)){
        newValue = Number(parseInt(value)/(1024 * 1024 * 1024)).toFixed(0);
        selectValue = "2";
      } else {
        newValue = Number(parseInt(value)/(1024 * 1024)).toFixed(0);
        selectValue = "1";
      }

      data.serverSimcardMobileDataLimit = newValue;
      this.setState({selectSimcardMobileDataLimitValue: selectValue})
    }

    return data
  }

  searchDoubleCombinaison(usedCombinaison, listCombinaison){

    var unUsedCombinaison = [];

    for (var i = 0; i < listCombinaison.length; i++) {
      var finded = false;
      usedCombinaison.map((x) => {
        if(x.model === listCombinaison[i].model && x.os === listCombinaison[i].os){
          finded = true;
        }
      })
      if (!finded) {
        unUsedCombinaison.push(listCombinaison[i]);
      }
    }


      var newTab = [];

      for (var i = 0; i < unUsedCombinaison.length; i++) {

        var finded = false;

        var os = []
        os.push(unUsedCombinaison[i].os);

        for (var x = 0; x < newTab.length; x++) {
          if(newTab[x].model === unUsedCombinaison[i].model){
            finded = true;
            var item = new Object();
            item = unUsedCombinaison[i].os;
            newTab[x].os.push(item);
          }
        }

        if(!finded){
          var item = new Object();
          item.model = unUsedCombinaison[i].model;
          item.os = os;

          newTab.push(item);
        }
      }

      if(newTab.length > 0){this.setState({model: newTab[0].model, os: newTab[0].os[0]})}else{this.setState({model: "", os: ""})}

      return(newTab);
  }


  getUserPaternFileList(listUsed, listAll){
    var newTab = [];
    var unUsedTab = [];

    if (listUsed && listAll) {
      for (var y = 0; y < listAll.length; y++) {
        var finded = false;
        for (var i = 0; i < listUsed.length; i++) {
          if (listUsed[i] === listAll[y].id) {
            newTab.push(listAll[y]);
            finded = true;
          }
        }
        if (!finded) {
          unUsedTab.push(listAll[y]);
        }
      }
      var selectedDefault = 0;
      if (unUsedTab.length > 0) {
        selectedDefault = unUsedTab[0].id;
      }
      this.setState({selectedServerFileId: selectedDefault})
    }
    return([newTab, unUsedTab]);

  }


  fetchApi(api){
    return fetch(api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchData : Something went wrong ...');
      }
    })
  }


  componentDidUpdate() {
    document.title = this.props.intl.formatMessage({id: "app.menu.7"});
  }

  componentWillUnmount() {
    itemActiveSecurity = "active";
    tabActiveSecurity = "show active";

    itemActiveMDM = null;
    tabActiveMDM = null;
  }


// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  handleStorageSelectChange(event){
    this.setState({selectValue: event.target.value})
  }

  handleSimcardMobileDataLimitSelectChange(event){
    this.setState({selectSimcardMobileDataLimitValue: event.target.value})
  }

  handleChange= (event, field) => {
    switch (field) {
      case "host":
      case "login":
      case "password":
      case "domain":
            this.setState({
              dataSMTP: { ...this.state.dataSMTP, [field]: event.target.value }
            });
        break;
      case "refAppName":
          this.setState({refAppName: event.target.value})
        break;
      case "refAppPackageName":
          this.setState({refAppPackageName: event.target.value})
        break;
      case "refAppVersion":
          this.setState({refAppVersion: event.target.value})
        break;
      case "refAppVersionCode":
          this.setState({refAppVersionCode: event.target.value})
        break;
      case "enable":
      case "allAccess":
      case "externalConnection":
            this.setState({
              dataUserPattern: { ...this.state.dataUserPattern, [field]: !this.state.dataUserPattern[field] }
            });
        break;

      case "clientEmail":
      case "unwantedApplications":
      case "numIMEI":
      case "versionOS":
            this.setState({
              dataAutoEnrollment: { ...this.state.dataAutoEnrollment, [field]: !this.state.dataAutoEnrollment[field] }
            });
        break;

      default:
          this.setState({
            dataUserPattern: { ...this.state.dataUserPattern, [field]: event.target.value }
          });
      break;

    }
  }


  handleChangeMDM= (event, field) => {
    switch (field) {
      case "singleAssociationDeviceUser":
      case "requiredNewDeviceValidation":
      case "notificationAndRestriction":
            this.setState({
              dataMDM: { ...this.state.dataMDM, [field]: !this.state.dataMDM[field] }
            });
        break;

      case "compliancyPeriod":
            this.setState({
              dataMDM: { ...this.state.dataMDM, [field]: parseInt(event.target.value) }
            });
        break;

      case "notifAppTrue":
            this.setState({
              dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, ["notifApp"]: "true" }
            });
        break;

      case "notifAppFalse":
            this.setState({
              dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, ["notifApp"]: "false" }
            });
        break;

        case "enableLocation":
        case "autoUninstallNewApp":
        case "enableSaveDeviceData":
        case "defaultDataSavedImages":
        case "defaultDataSavedFiles":
        case "defaultDataSavedSMS":
        case "defaultDataSavedContacts":
        var x;
        if (this.state.dataConfigServerMMApp[field] === 'true') {
          x = "false";
        } else {x = "true"}
              this.setState({
                dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, [field]: x }
              });
          break;


        case "deleteDataOnceSaved":
        var x;
        if (this.state.dataConfigServerMMApp[field] === 'true') {
          x = "false";
        } else {x = "true"}
              this.setState({
                dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, [field]: x, ["autoStorageManagement"]: false }
              });
          break;

        case "autoStorageManagement":
        var x;
        if (this.state.dataConfigServerMMApp[field] === 'true') {
          x = "false";
        } else {x = "true"}
              this.setState({
                dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, [field]: x, ["deleteDataOnceSaved"]: false }
              });
          break;

        case "serverStorageLimit":
          if (/^\d+$/.test(event.target.value)) {
            this.setState({
              dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, [field]: event.target.value }
            });
          }
          break;

        case "serverSimcardMobileDataLimit":
          if (/^\d+$/.test(event.target.value)) {
            this.setState({
              dataConfigServerMMApp: { ...this.state.dataConfigServerMMApp, [field]: event.target.value }
            });
          }
          break;


    }
  }


    addServerFile() {
      var newUsedList = this.state.dataUserPattern.fileServerIds;
      newUsedList.push(this.state.selectedServerFileId);
      this.setState({
        dataUserPattern: { ...this.state.dataUserPattern, ["fileServerIds"]: newUsedList }
      });

      const [newUserPaternListUsed, newUserPaternListPossible] = this.getUserPaternFileList(newUsedList, this.state.dataServFile);
      this.setState({userPaternListUsed: newUserPaternListUsed, userPaternListPossible: newUserPaternListPossible})
    }

    removeServerFile(indexFileServer) {
      var currentUsedList = this.state.dataUserPattern.fileServerIds;
      var newUsedList = [];
      for (var i = 0; i < currentUsedList.length; i++) {
        if (currentUsedList[i] !== indexFileServer) {
          newUsedList.push(currentUsedList[i]);
        }
      }
      console.log(newUsedList);
      this.setState({
        dataUserPattern: { ...this.state.dataUserPattern, ["fileServerIds"]: newUsedList }
      });

      const [newUserPaternListUsed, newUserPaternListPossible] = this.getUserPaternFileList(newUsedList, this.state.dataServFile);
      this.setState({userPaternListUsed: newUserPaternListUsed, userPaternListPossible: newUserPaternListPossible})
    }

    selectedServerFile(e){
      this.setState({selectedServerFileId: parseInt(e.target.value)})
    }

    getAvailableServerFile(data){
      var allServer = data.availableFileServers;
      var usedServer = data.fileServers;
      var availableServer = [];

      for (var i = 0; i < allServer.length; i++) {
        var finded=false;
        for (var y = 0; y < usedServer.length; y++) {
          if (allServer[i].serverId === usedServer[y].serverId && finded === false) {
            finded = true;
          }
        }
        if (!finded) {
          availableServer.push(allServer[i]);
        }
      }

      if(availableServer.length > 0){this.setState({selectedServerFileId: availableServer[0].serverId})}else{this.setState({selectedServerFileId: ""})}
      this.setState({availableServer: availableServer});
    }

  testTabActive(index) {
    if (index === 5) {

      itemActiveSecurity = null;
      tabActiveSecurity = null;

      itemActiveMDM = "active";
      tabActiveMDM = "show active";
    }
  }


  sendParametresModification(){

    this.setState({sendingModif: true, validButton: "info"});

    var adaptMDM = new Object();
    adaptMDM.singleAssociationDeviceUser = this.state.dataMDM.singleAssociationDeviceUser;
    adaptMDM.requiredNewDeviceValidation = this.state.dataMDM.requiredNewDeviceValidation;
    adaptMDM.compliancyPeriod = this.state.dataMDM.compliancyPeriod;
    adaptMDM.notificationAndRestriction = this.state.dataMDM.notificationAndRestriction;
    adaptMDM.compliantDeviceList = this.state.dataMDM.compliantDeviceList;

    this.props.click(adaptMDM.singleAssociationDeviceUser,
      this.state.dataConfigServerMMApp.enableLocation === "true" ? true : false,
      this.state.dataConfigServerMMApp.autoUninstallNewApp === "true" ? true : false,
      this.state.dataConfigServerMMApp.enableSaveDeviceData === "true" ? true : false,
      this.state.dataConfigServerMMApp.deleteDataOnceSaved === "true" ? true : false,
      this.state.dataConfigServerMMApp.autoStorageManagement === "true" ? true : false,
      this.state.dataConfigServerMMApp.defaultDataSavedImages === "true" ? true : false,
      this.state.dataConfigServerMMApp.defaultDataSavedFiles === "true" ? true : false,
      this.state.dataConfigServerMMApp.defaultDataSavedSMS === "true" ? true : false,
      this.state.dataConfigServerMMApp.defaultDataSavedContacts === "true" ? true : false
    )

    //console.log(adaptMDM);

    var adaptDataConfigServerMMApp = this.state.dataConfigServerMMApp;

    if (adaptDataConfigServerMMApp && !adaptDataConfigServerMMApp.serverStorageLimit) {
      adaptDataConfigServerMMApp.serverStorageLimit = 0;
    }
    if (adaptDataConfigServerMMApp && !adaptDataConfigServerMMApp.serverSimcardMobileDataLimit) {
      adaptDataConfigServerMMApp.serverSimcardMobileDataLimit = 0;
    }

    if (adaptDataConfigServerMMApp && adaptDataConfigServerMMApp.serverStorageLimit) {
      var visualDataStorageLimit = this.state.dataConfigServerMMApp.serverStorageLimit;
      switch (this.state.selectValue) {
        case "3":
            adaptDataConfigServerMMApp.serverStorageLimit = (adaptDataConfigServerMMApp.serverStorageLimit * (1024 * 1024 * 1024 * 1024)).toFixed(0);
          break;
        case "2":
            adaptDataConfigServerMMApp.serverStorageLimit = (adaptDataConfigServerMMApp.serverStorageLimit * (1024 * 1024 * 1024)).toFixed(0);
          break;
        case "1":
            adaptDataConfigServerMMApp.serverStorageLimit = (adaptDataConfigServerMMApp.serverStorageLimit * (1024 * 1024)).toFixed(0);
          break;
      }
    }

    if (adaptDataConfigServerMMApp && adaptDataConfigServerMMApp.serverSimcardMobileDataLimit) {
        var visualDataSimcardMobileDataLimit = this.state.dataConfigServerMMApp.serverSimcardMobileDataLimit;
        switch (this.state.selectSimcardMobileDataLimitValue) {
          case "3":
              adaptDataConfigServerMMApp.serverSimcardMobileDataLimit = (adaptDataConfigServerMMApp.serverSimcardMobileDataLimit * (1024 * 1024 * 1024 * 1024)).toFixed(0);
            break;
          case "2":
              adaptDataConfigServerMMApp.serverSimcardMobileDataLimit = (adaptDataConfigServerMMApp.serverSimcardMobileDataLimit * (1024 * 1024 * 1024)).toFixed(0);
            break;
          case "1":
              adaptDataConfigServerMMApp.serverSimcardMobileDataLimit = (adaptDataConfigServerMMApp.serverSimcardMobileDataLimit * (1024 * 1024)).toFixed(0);
            break;
        }
    }


      this.sendModif(ApiNotification, this.state.dataNotifications)
      this.sendModif(ApiSMTP, this.state.dataSMTP)
      this.sendModif(ApiExtansions, this.state.dataExtansions)
      this.sendModif(ApiUserPattern, this.state.dataUserPattern)
      this.sendModif(ApiAutoEnrollmentAccounts, this.state.dataAutoEnrollment)
      this.sendModif(ApiMDM, adaptMDM)

      if (adaptDataConfigServerMMApp && adaptDataConfigServerMMApp.serverStorageLimit && adaptDataConfigServerMMApp.serverSimcardMobileDataLimit) {
        this.sendModif(ApiConfigServerMMApp, adaptDataConfigServerMMApp)
        console.log(adaptDataConfigServerMMApp);
        adaptDataConfigServerMMApp.serverStorageLimit = visualDataStorageLimit;
        adaptDataConfigServerMMApp.serverSimcardMobileDataLimit = visualDataSimcardMobileDataLimit;
      }
  }

  sendModif(api, data){

        var data = data;

      fetch(api, {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(data)
        })
        .then(response => {
          if(process.env.NODE_ENV == "development")console.log(response);
          var x = response;
          if(response.status === 200){
            this.setState({sendingModif: false, validButton: "success"})
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
           return response.json().then(responseError => {
             console.log(responseError);
             this.requeteResponse(x,responseError);
           })
         }
         })

  }

  handleSelectMDM = (event, field) => {
    this.setState({[field]: event.target.value});
    if(field === "model"){
      this.state.unUsedCombinaison.map((x, index) => {
        if(x.model === event.target.value){
          this.setState({os: x.os[0], indexModel: index})
        }
      })
    }
  }


  handleCheckboxNotifications = (event, field) => {
    this.setState({
      dataNotifications: { ...this.state.dataNotifications, [field]: !this.state.dataNotifications[field] }
    });
  }

  handleChangeSelectBrand = autoEnrollmentBrands => {
    this.setState({ autoEnrollmentBrands });
  }

  handleChangeSelectAccount = autoEnrollmentAccounts => {
    this.setState({ autoEnrollmentAccounts });
  }


  addMailNotifications(){
    const array = this.state.dataNotifications.emails;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === (this.state.mailNotifications)) {
        return
      }
    }
    if (this.state.mailNotifications) {
      array.push(this.state.mailNotifications)
    }
    this.setState({
      dataNotifications: { ...this.state.dataNotifications, ["emails"]: array }
    });
    this.setState({mailNotifications: ""});
  }

  addExtansions(){
    if (this.state.extansions) {
      const array = this.state.dataExtansions;
      for (var i = 0; i < array.length; i++) {
        if (array[i] === (this.state.extansions)) {
          this.setState({extansions: ""});
          return
        }
      }
      array.push(this.state.extansions);
      this.setState({dataExtansions: array, extansions: ""});
    }
  }

  addAutoEnrollmentAccounts(){
    if (this.state.autoEnrollmentAccounts) {
      const tab = this.state.dataAutoEnrollment;

      const updatedUsedAccount = this.state.dataAutoEnrollment.usedAccount.concat(
        this.state.autoEnrollmentAccounts
      );
      tab.usedAccount = updatedUsedAccount;

      this.setState({dataAutoEnrollment: tab, autoEnrollmentAccounts: ""});

      document.getElementById('accountNameInput').value = "";
      document.getElementById('accountTypeInput').value = "";
    }
  }

  addAutoEnrollmentBrands(){
    if (this.state.autoEnrollmentBrands) {
      const tab = this.state.dataAutoEnrollment;

      const updatedUsedModel = this.state.dataAutoEnrollment.usedModel.concat(
        this.state.autoEnrollmentBrands
      );

      tab.usedModel = updatedUsedModel;

      this.setState({dataAutoEnrollment: tab, autoEnrollmentBrands: ""});

      document.getElementById('brandNameInput').value = "";
      document.getElementById('modelNameInput').value = "";
    }
  }


  removeMailNotifications(index) {
    const tab = this.state.dataNotifications.emails;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({
      dataNotifications: { ...this.state.dataNotifications, ["emails"]: newTab }
    });
  }

  removeExtansions(index) {
    const tab = this.state.dataExtansions;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({dataExtansions: newTab})
  }

  removeAutoEnrollmentAccounts(index) {
    const tab = this.state.dataAutoEnrollment.usedAccount;
    var newTab = this.state.dataAutoEnrollment;
    var newTabForUsedAccount = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTabForUsedAccount[j++] = tab[i]
      }
    }
    newTab.usedAccount = newTabForUsedAccount;
    this.setState({dataAutoEnrollment: newTab})
  }

  removeAutoEnrollmentBrands(index) {
    const tab = this.state.dataAutoEnrollment.usedModel;
    var newTab = this.state.dataAutoEnrollment;
    var newTabForUsedModel = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTabForUsedModel[j++] = tab[i]
      }
    }
    newTab.usedModel = newTabForUsedModel;
    this.setState({dataAutoEnrollment: newTab})
  }


  handleMailNotifications = (event) => {
    this.setState({mailNotifications: event.target.value});
  }

  handleExtansions = (event) => {
    this.setState({extansions: event.target.value});
  }

  handleAutoEnrollmentAccounts = (event, field) => {
    this.setState({
      autoEnrollmentAccounts: { ...this.state.autoEnrollmentAccounts, [field]: event.target.value }
    });
  }

  handleAutoEnrollmentBrands = (event, field) => {
    this.setState({
      autoEnrollmentBrands: { ...this.state.autoEnrollmentBrands, [field]: event.target.value }
    });
  }


  addCompliant() {

    const newArray = this.state.usedCombinaison;

    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i] === (this.state.model + " , " + this.state.os)) {
        return
      }
    }

    if (this.state.model && this.state.os) {
      var item = new Object();
      item.model = this.state.model;
      item.os = this.state.os;
      newArray.push(item);
    }
    this.setState({usedCombinaison: newArray, indexModel: 0});
    this.setState({unUsedCombinaison: this.searchDoubleCombinaison(newArray, this.state.listCombinaison)})

  }

  removeCompliant() {
    const array = this.state.usedCombinaison.sort((a, b) => a.model.localeCompare(b.model));
    array.splice(this.state.modalIndex, 1);

    this.setState({usedCombinaison: array});
    this.setState({unUsedCombinaison: this.searchDoubleCombinaison(array, this.state.listCombinaison)})

  }

  modalConfirm(name, index) {
    console.log(index);
    this.setState({modalTXT: name, modalIndex: index})
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    this.testTabActive(this.props.location.tabActive);

    const { isLoading, error } = this.state;

    if (error) {
      return (
          <div>
              <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <span>Error : {error.message}</span>
              </div>

              <div className="container small">
              </div>
          </div>
      );
    }

    if (isLoading) {

      return (
        <div className="card mt-5 small">
          <div className="card-header text-info">
            <b>
              {this.props.intl.formatMessage({id: "chargement"})} ...
            </b>
          </div>
          <div className="card-body d-flex justify-content-center">

            <div className="spinner-border text-info" role="status">
              <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
            </div>
          </div>
        </div>);
    }

    if(isLoading === false)
    return (<div className=" small col-7 mb-5">

      <h5 className="text-primary text-center mt-3">
        <b><FormattedMessage id="app.menu.7"/></b>
      </h5>

      <ul className="nav nav-tabs mt-1 justify-content-center" id="activeServ" role="tablist">

{/*
        <li className="nav-item">
          <a className={`nav-link ${itemActiveSecurity}`} id="home-tab" data-toggle="tab" href="#Sécurité" role="tab" aria-controls="home" aria-selected="true"><FormattedMessage id="parametres.1"/></a>
        </li>
*/}

        <li className="nav-item">
          <a className={`nav-link ${itemActiveSecurity}`} id="profile-tab" data-toggle="tab" href="#Notifications" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.2"/></a>
        </li>
        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#Piècesjointes" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.3"/></a>
        </li>

        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#CompteAuto" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.4"/></a>
        </li>

        {/*<li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><FormattedMessage id="parametres.4"/>
          </a>
          <div className="dropdown-menu text-center">
            <a className="nav-link border-0" id="profile-tab" data-toggle="tab" href="#CompteAutoGeneral" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.5"/></a>
            <a className="nav-link border-0" id="profile-tab" data-toggle="tab" href="#CompteAutoParametres" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.6"/></a>
          </div>
        </li>*/}

        <li className="nav-item">
          <a className={`nav-link ${itemActiveMDM}`} id="profile-tab" data-toggle="tab" href="#MDM" role="tab" aria-controls="profile" aria-selected="false">MDM</a>
        </li>
        {/*
          this.state.dataReferenceApplications ?
        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#ReferenceApplications" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.7"/></a>
        </li>
        : null
        */
        }

        <li className="nav-item">
          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#AutoEnrollment" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="parametres.8"/></a>
        </li>
      </ul>

      <div className="tab-content  border-0" id="myTabContent">


{/*
        <div className={`tab-pane ${tabActiveSecurity}`} id="Sécurité" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-0">
            <div className="col">
              <ParametresSecurite/>
            </div>
          </div>
        </div>
*/}


        <div className={`tab-pane ${tabActiveSecurity}`} id="Notifications" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <ParametresNotifications dataNotifications={this.state.dataNotifications}
              dataSMTP={this.state.dataSMTP}
              handleChange={this.handleChange}
              mailNotifications={this.state.mailNotifications}
              handleCheckboxNotifications={this.handleCheckboxNotifications}
              handleMailNotifications={this.handleMailNotifications}
              addMailNotifications={this.addMailNotifications}
              removeMailNotifications={this.removeMailNotifications}
              userAdministration={this.props.userAdministration}
              />
            </div>
          </div>
        </div>

        <div className="tab-pane " id="Piècesjointes" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <ParametresPiecesJointes dataExtansions={this.state.dataExtansions}
              addExtansions={this.addExtansions}
              removeExtansions={this.removeExtansions}
              extansions={this.state.extansions}
              handleExtansions={this.handleExtansions}
              userAdministration={this.props.userAdministration}
              />
            </div>
          </div>
        </div>

        <div className="tab-pane " id="CompteAuto" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <ParametresCompteAuto dataUserPattern={this.state.dataUserPattern}
              userInfo={this.state.userInfo}
              handleChange={this.handleChange}
              availableServer={this.state.availableServer}
              addServerFile={this.addServerFile.bind(this)}
              removeServerFile={this.removeServerFile.bind(this)}
              selectedServerFileId={this.state.selectedServerFileId}
              selectedServerFile={this.selectedServerFile.bind(this)}
              userPaternListUsed={this.state.userPaternListUsed}
              userPaternListPossible={this.state.userPaternListPossible}
              userAdministration={this.props.userAdministration}
              tokenCSRF={this.props.tokenCSRF}
              />
            </div>
          </div>
        </div>

        <div className="tab-pane " id="CompteAutoGeneral" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <CompteAutoGeneral/>
            </div>
          </div>
        </div>

        <div className="tab-pane " id="CompteAutoParametres" role="tabpanel" aria-labelledby="home-tab">

          <div className="col  border-top-0">
            <div className="col">
              <CompteAutoParametres/>
            </div>
          </div>
        </div>

        <div className={`tab-pane ${tabActiveMDM}`} id="MDM" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <ParametresMDM dataMDM={this.state.dataMDM}
              dataConfigServerMMApp={this.state.dataConfigServerMMApp}
              usedCombinaison={this.state.usedCombinaison}
              listCombinaison={this.state.listCombinaison}
              unUsedCombinaison={this.state.unUsedCombinaison}
              indexModel={this.state.indexModel}
              model={this.state.model}
              os={this.state.os}
              handleSelectMDM={this.handleSelectMDM}
              handleChangeMDM={this.handleChangeMDM}
              addCompliant={this.addCompliant.bind(this)}
              removeCompliant={this.removeCompliant.bind(this)}
              modalConfirm={this.modalConfirm.bind(this)}
              userAdministration={this.props.userAdministration}
              selectValue={this.state.selectValue}
              selectSimcardMobileDataLimitValue={this.state.selectSimcardMobileDataLimitValue}
              handleStorageSelectChange={this.handleStorageSelectChange.bind(this)}
              handleSimcardMobileDataLimitSelectChange={this.handleSimcardMobileDataLimitSelectChange.bind(this)}

              />
            </div>
          </div>
        </div>

        <div className={`tab-pane ${tabActiveMDM}`} id="AutoEnrollment" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              <ParametresAutoEnrollment
              handleChange={this.handleChange}
              dataAutoEnrollment={this.state.dataAutoEnrollment}
              addAutoEnrollmentAccounts={this.addAutoEnrollmentAccounts}
              addAutoEnrollmentBrands={this.addAutoEnrollmentBrands}
              removeAutoEnrollmentAccounts={this.removeAutoEnrollmentAccounts}
              removeAutoEnrollmentBrands={this.removeAutoEnrollmentBrands}
              autoEnrollmentAccounts={this.state.autoEnrollmentAccounts}
              autoEnrollmentBrands={this.state.autoEnrollmentBrands}
              handleAutoEnrollmentAccounts={this.handleAutoEnrollmentAccounts}
              handleAutoEnrollmentBrands={this.handleAutoEnrollmentBrands}
              handleChangeSelectBrand={this.handleChangeSelectBrand}
              handleChangeSelectAccount={this.handleChangeSelectAccount}
              userAdministration={this.props.userAdministration}
              />
            </div>
          </div>
        </div>



        <div className="tab-pane " id="ReferenceApplications" role="tabpanel" aria-labelledby="home-tab">

          <div className="col card border-top-0">
            <div className="col">
              {/*
                this.state.dataReferenceApplications ?
              <ParametresReferenceApplications dataReferenceApplications={this.state.dataReferenceApplications}
                dataExtansions={this.state.dataExtansions}
              removeReferenceApplication={this.removeReferenceApplication}
              addReferenceApplication={this.addReferenceApplication}
              extansions={this.state.extansions}
              handleExtansions={this.handleExtansions}
              handleChange={this.handleChange}
              refAppName={this.state.refAppName}
              refAppPackageName={this.state.refAppPackageName}
              refAppVersion={this.state.refAppVersion}
              refAppVersionCode={this.state.refAppVersionCode}/>
              : null
              */
              }
            </div>
          </div>
        </div>

      </div>

      <div className="modal fade" id="modalConfirmSuprTermConf" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`} style={{width: "auto"}}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                {this.props.intl.formatMessage({id: "supprimer"})}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.removeCompliant.bind(this)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
        <div className="text-center mr-1">
          <button type="button" className="btn btn-secondary" onClick={parameters.backPage}><FormattedMessage id="retour"/>
          </button>
        </div>

        {this.props.userAdministration === "ADMINISTRATOR" ?

        <div className="text-center ml-1">
          <button type="button" className={`btn btn-${this.state.validButton}`} onClick={this.sendParametresModification.bind(this)} style={{width: "5vw"}}>
          {this.state.sendingModif ?
            <div className="spinner-border text-light spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            : <FormattedMessage id="valider"/>
          }
          </button>
        </div>
        :
        <div className="text-center ml-1">
          <button type="button" className={`btn btn-${"info"}`} disabled title={this.props.intl.formatMessage({id: "supervisor.restriction.msg"})}>
            <FormattedMessage id="valider"/>
          </button>
        </div>
        }

      </div>

    </div>)

    return null
  }

}
parameters.propTypes = {
  intl: intlShape.isRequired
};
parameters = injectIntl(parameters, {withRef: true});

export default parameters
