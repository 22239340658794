import React from 'react'
import $ from 'jquery'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import {MdLens} from "react-icons/md";

import ConfigServeurLDAP from './ConfigServeurLDAP';
import ConfigServeurProxy from './ConfigServeurProxy';
import ConfigDescriptionReseau from "./ConfigDescriptionReseau";
import ConfigServeurRelais from "./ConfigServeursRelais";
import ConfigServeurDNS from "./ConfigServeurDNS";

import ServeurMessagerie from "./ServeurMessagerie";
import ServeurGroupware from "./ServeurGroupware";
import ServeurFichiers from "./ServeurFichiers";

import {varConfigServeur} from '../variables';


class config extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataServices: [],
      dataDNS: [],
      dataRelais: [],
      dataServMessagerie: [],
      dataServGroupware: [],
      dataLDAP: [],
      dataProxy: [],
      dataServFile: [],
      value: "",
      isLoading: false,
      sendingModif: false,
      validButton: "info",
      testUserLogin:"",
      testUserPassword:"",
    };

    config.backPage = config.backPage.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);

    this.handleModalChange = this.handleModalChange.bind(this);


    this.handleLDAPTestButton = this.handleLDAPTestButton.bind(this);
    this.handleLDAPTestUserButton = this.handleLDAPTestUserButton.bind(this);

  }


// ------------------------- Component MOUNT / UNMOUNT ------------------------- //

  componentDidMount() {
    document.title = 'Configuration';

    this.setState({isLoading: true});

    Promise.all([fetch(varConfigServeur.apiServices ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiLdap ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiProxy ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiDNS ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiRelais ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiServMessagerie ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiServGroupware ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varConfigServeur.apiServFile ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resServices, resLDAP, resProxy, resDNS, resRelais, resServMessagerie, resServGroupware, resServFile]) => Promise.all([resServices.json(), resLDAP.json(), resProxy.json(), resDNS.json(), resRelais.json(), resServMessagerie.json(), resServGroupware.json(), resServFile.json()]))
        .then(([dataServices, dataLDAP, dataProxy, dataDNS, dataRelais, dataServMessagerie, dataServGroupware, dataServFile]) => (this.setState({
            dataServices, dataLDAP, dataProxy, dataDNS, dataRelais, dataServMessagerie, dataServGroupware, dataServFile, isLoading: false
        })));

  }


// ------------------------- Action handle ------------------------------------- //

  static backPage() {
    window.history.back();
  }


  handleLDAPTestButton(){
      var data = this.state.dataLDAP;

    fetch(varConfigServeur.apiLdap + "test", {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        $('#TestModal').modal('show');
        if(process.env.NODE_ENV == "development")console.log(response);
        var x = response;
        if(response.status === 200){

            return response.json().then(responseRetour => {
              this.setState({modalTestResponse: responseRetour})
            })
        }
        else{
         return response.json().then(responseError => {
           if(process.env.NODE_ENV == "development")console.log(responseError);
         })
       }
       })

  }


  handleLDAPTestUserButton(){

          var data = this.state.dataLDAP;
          data.userLogin = this.state.testUserLogin;
          data.userPassword = this.state.testUserPassword;

          console.log(data);

        fetch(varConfigServeur.apiLdap + "testuser", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: JSON.stringify(data)
          })
          .then(response => {
            if(process.env.NODE_ENV == "development")console.log(response);
            var x = response;
            if(response.status === 200){

                return response.json().then(responseRetour => {
                  this.setState({modalTestUserResponse: responseRetour})
                })
            }
            else{
             return response.json().then(responseError => {
               this.setState({modalTestUserResponse: responseError.message})
             })
           }
           })
  }


  handleCheckbox = (event, field) => {
    this.setState({
      dataServices: { ...this.state.dataServices, [field]: !this.state.dataServices[field] }
    });
  }

  handleModalChange = (event, field) => {
    this.setState({[field]: event.target.value });
  }


    handleChildModification(data, dataType, field){

      switch (dataType) {
        case "dataDNS":
            this.setState({ dataDNS: data });
          break;
        case "dataRelais":
            this.setState({ dataRelais: data });
          break;
        case "dataLDAP":
          this.setState({
            dataLDAP: { ...this.state.dataLDAP, [field]: data }
          });
          break;
        case "dataProxy":
          if (field === "proxyEnabled") {
            this.setState({
              dataProxy: { ...this.state.dataProxy, [field]: !this.state.dataProxy.proxyEnabled }
            });
          } else {
            this.setState({
              dataProxy: { ...this.state.dataProxy, [field]: data }
            });
          }
          break;
        default:

      }
    }


  sendConfigurationsModification(){

    //this.sendData(varConfigServeur.apiServices, this.state.dataServices);
    //this.sendData(varConfigServeur.apiDNS, this.state.dataDNS);
    this.verifBeforeSending(varConfigServeur.apiDNS, this.state.dataDNS, "DNS");
    //this.sendData(varConfigServeur.apiRelais, this.state.dataRelais);
    this.verifBeforeSending(varConfigServeur.apiRelais, this.state.dataRelais, "relais");
    this.sendData(varConfigServeur.apiLdap, this.state.dataLDAP);
    this.sendData(varConfigServeur.apiProxy, this.state.dataProxy);

  }

  verifBeforeSending(api, data, type){
    var newData = data;
    switch (type) {
      case "DNS":
            var newDNS = []
            for (var i = 0; i < data.length; i++) {
              if (data[i].indexOf(' ') >= 0) {
                newDNS.push(data[i].replace(/ /g,''));
              } else {
                newDNS.push(data[i])
              }
            }
            newData = newDNS;
        break;
      case "relais":
            var newRelais = []
            for (var i = 0; i < data.length; i++) {
              if (data[i].indexOf(' ') >= 0) {
                newRelais.push(data[i].replace(/ /g,''));
              } else {
                newRelais.push(data[i])
              }
            }
            newData = newRelais;
        break;
      default:

    }
    this.sendData(api, newData);
  }


  sendData(api, data){

    this.setState({sendingModif: true, validButton: "info"});

        fetch(api, {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: JSON.stringify(data)
          })
          .then(response => {
            if(process.env.NODE_ENV == "development")console.log(response);
            var x = response;
            if(response.status === 200){this.setState({sendingModif: false, validButton: "success"})}
            else{
              this.setState({sendingModif: false, validButton: "danger"})
             return response.json().then(responseError => {
               console.log(responseError);
             })
           }
           })
         }



// ------------------------- Function with visual ------------------------------ //

  servActive(service, name, link) {
    if (service === true) {
      return (<li className="nav-item">
        <a className="nav-link" id="contact-tab" data-toggle="tab" href={"#" + link} role="tab" aria-controls="contact" aria-selected="false">
          <FormattedMessage id={name}/>
        </a>
      </li>)
    }
  }


// ------------------------- Render -------------------------------------------- //

  render() {

    const { isLoading, error } = this.state;

    if (error) {
      return (
          <div>
              <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <span>Error : {error.message}</span>
              </div>

              <div className="container small">
              </div>
          </div>
      );
    }

    if (isLoading) {

      return (
        <div className="card mt-5 small">
          <div className="card-header text-info">
            <b>
              {this.props.intl.formatMessage({id: "chargement"})} ...
            </b>
          </div>
          <div className="card-body d-flex justify-content-center">

            <div className="spinner-border text-info" role="status">
              <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
            </div>
          </div>
        </div>);
    }

    return (<div className="container small mt-3 mb-4">
      <h5 className="text-primary text-center mt-3 mb-4">
        <b><FormattedMessage id="ConfigurationServer.title"/></b>
      </h5>

      <div className="row">
        <div className="col-3 mt-2">

          <ul className="nav nav-pills nav-fill flex-column card" id="activeServ" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="home-tab" data-toggle="tab" href="#ServiceActive" role="tab" aria-controls="home" aria-selected="true">
                <FormattedMessage id="ConfigurationServer.menu.1"/>
              </a>
            </li>
{/*
            <li className="nav-item">
              <a className="nav-link" id="profile-tab" data-toggle="tab" href="#DescriptionReseau" role="tab" aria-controls="profile" aria-selected="false">
                <FormattedMessage id="ConfigurationServer.menu.2"/>
              </a>
            </li>
*/}
            <li className="nav-item">
              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#ServeurDNS" role="tab" aria-controls="contact" aria-selected="false">
                <FormattedMessage id="ConfigurationServer.menu.3"/>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#ServeurRelais" role="tab" aria-controls="contact" aria-selected="false">
                <FormattedMessage id="ConfigurationServer.menu.4"/>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#ServeurLDAP" role="tab" aria-controls="contact" aria-selected="false">
                <FormattedMessage id="ConfigurationServer.menu.5"/>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="contact-tab" data-toggle="tab" href="#ServeurProxy" role="tab" aria-controls="contact" aria-selected="false"><FormattedMessage id="ConfigurationServer.menu.6"/></a>
            </li>

            {this.servActive(this.state.dataServices.mminstant, "ConfigurationServer.menu.7", "ServeurMessagerie")}
            {this.servActive(this.state.dataServices.mminstant, "ConfigurationServer.menu.8", "ServeurGroupware")}
            {this.servActive(this.state.dataServices.mmfile, "ConfigurationServer.menu.9", "ServeurFichiers")}

          </ul>
        </div>

        <div className="col">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane show active" id="ServiceActive" role="tabpanel" aria-labelledby="home-tab">

              <div className="row card border-0">
                <h5 className="text-primary text-center mt-2">
                  <b>
                    <FormattedMessage id="ConfigurationServer.general.1"/>
                    <hr className="col-11"/>
                  </b>
                </h5>
                <div className="justify-content-center d-flex">
                  <ul className="list-group list-group-horizontal mb-4">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="MMI" checked={this.state.dataServices.mminstant} onChange={e => this.handleCheckbox(e, "mminstant")}/>

                        <label className="custom-control-label" htmlFor="MMI">MM-Instant</label>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="MDM" checked={this.state.dataServices.mdm} onChange={e => this.handleCheckbox(e, "mdm")}/>

                        <label className="custom-control-label" htmlFor="MDM">MDM</label>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="MMF" checked={this.state.dataServices.mmfile} onChange={e => this.handleCheckbox(e, "mmfile")}/>

                        <label className="custom-control-label" htmlFor="MMF">MM-File</label>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="VPN" checked={this.state.dataServices.vpn} onChange={e => this.handleCheckbox(e, "vpn")}/>

                        <label className="custom-control-label" htmlFor="VPN">VPN</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
                <div className="text-center mr-1">
                  <button type="button" className="btn btn-secondary" onClick={config.backPage}><FormattedMessage id="retour"/>
                  </button>
                </div>

                <div className="text-center ml-1">
                  <button type="button" className="btn btn-info">
                    <FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>

            <div className="tab-pane " id="DescriptionReseau" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ConfigDescriptionReseau/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurDNS" role="tabpanel" aria-labelledby="contact-tab">
              <div className="row card border-0">
                <ConfigServeurDNS
                dataDNS={this.state.dataDNS}
                handleChildModification={this.handleChildModification.bind(this)}
                userAdministration={this.props.userAdministration}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurRelais" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ConfigServeurRelais
                dataRelais={this.state.dataRelais}
                handleChildModification={this.handleChildModification.bind(this)}
                userAdministration={this.props.userAdministration}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurLDAP" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ConfigServeurLDAP
                dataLDAP={this.state.dataLDAP}
                handleChildModification={this.handleChildModification.bind(this)}
                handleLDAPTestButton={this.handleLDAPTestButton}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurProxy" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ConfigServeurProxy
                dataProxy={this.state.dataProxy}
                handleChildModification={this.handleChildModification.bind(this)}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurMessagerie" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ServeurMessagerie
                dataServMessagerie={this.state.dataServMessagerie}
                tokenCSRF={this.props.tokenCSRF}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurGroupware" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ServeurGroupware
                dataServGroupware={this.state.dataServGroupware}
                tokenCSRF={this.props.tokenCSRF}/>
              </div>
            </div>

            <div className="tab-pane " id="ServeurFichiers" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row card border-0">
                <ServeurFichiers
                dataServFile={this.state.dataServFile}
                tokenCSRF={this.props.tokenCSRF}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
          <div className="text-center mr-1">
            <button type="button" className="btn btn-secondary" onClick={config.backPage}><FormattedMessage id="retour"/>
            </button>
          </div>

          <div className="text-center ml-1">
            <button type="button" className={`btn btn-${this.state.validButton}`} onClick={this.sendConfigurationsModification.bind(this)} style={{width: "5vw"}}>
            {this.state.sendingModif ?
              <div className="spinner-border text-light spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              : <FormattedMessage id="valider"/>
            }
            </button>
          </div>
        </div>



                <div className="modal fade" id="TestModal" tabIndex="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="modalAjoutDNSLabel">TEST connexion</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      Test server LDAP :
                        <span style={{
                            color: this.state.modalTestResponse ? "rgb(36,232,45)"
                            : "rgb(233,44,33)"
                          }}>
                           <MdLens size={28}/>
                        </span>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="modal fade" id="TestUserModal" tabIndex="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="modalAjoutDNSLabel">TEST User</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                          <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Login</span>
                          </div>
                          <input type="text" className="form-control" placeholder="Username" aria-label="Username" value={this.state.testUserLogin} onChange={e => this.handleModalChange(e, "testUserLogin")}/>
                          </div>

                          <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">Password</span>
                          </div>
                          <input type="password" className="form-control" placeholder="Password" aria-label="Password" value={this.state.testUserPassword} onChange={e => this.handleModalChange(e, "testUserPassword")}/>
                          </div>

                          Test Response :
                          {this.state.modalTestUserResponse != null ?
                          <span style={{
                              color: this.state.modalTestUserResponse ? "rgb(36,232,45)"
                              : "rgb(233,44,33)"
                            }}>
                             <MdLens size={28}/>
                          </span>
                          : null
                          }
                      </div>

                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                        </button>
                        <button type="button" className="btn btn-primary" onClick={this.handleLDAPTestUserButton}><FormattedMessage id="valider"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


      </div>
    </div>)
  }

}
config.propTypes = {
  intl: intlShape.isRequired
};
config = injectIntl(config,
                        {withRef: true});
export default config
