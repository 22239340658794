import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {FaPlus} from "react-icons/fa";

import {varConfigServeur} from '../variables';

class ServeurGroupware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroupwareData: {name:"",host:"",type:"",ssl:""},
      dataServGroupware: this.props.dataServGroupware,
      newServeurName: "",
      newServeurContext: "",
      newType: "OPENXCHANGE",
      newHost: "",
      serv: ""
    };

    this.selectGroupware = this.selectGroupware.bind(this);

    this.handleMail = this.handleMail.bind(this);
    this.addServeurGroupware = this.addServeurGroupware.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectedInputChange = this.handleSelectedInputChange.bind(this)

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount(){
    this.initialisation(this.props.dataServGroupware)
  }

  initialisation(data) {
    this.setState({
      dataServGroupware: data
    })
  }


// ------------------------- Action handle ------------------------------------- //

  handleInputChange(e, field){
    if (field === "newSSL") {
      this.setState({[field]: !this.state.newSSL})
    } else {
      this.setState({[field]: e.target.value})
    }
  }

  handleSelectedInputChange(e, field){
    if (field === "ssl") {
      this.setState({
        selectedGroupwareData: { ...this.state.selectedGroupwareData, [field]: !this.state.selectedGroupwareData.ssl }
      });
    } else {
      this.setState({
        selectedGroupwareData: { ...this.state.selectedGroupwareData, [field]: e.target.value }
      });
    }
  }

  selectGroupware(name, index) {
    this.setState({selectedGroupwareData: this.props.dataServGroupware[index], selectedGroupware: name, selectedGroupwareIndex: index});
  }

  handleMail(event) {
    this.setState({serv: event.target.value});
  }

  addServeurGroupware() {

                var data = {
                    "name": this.state.newServeurName,
                    "type": this.state.newType,
                    "host": this.state.newHost,
                    "ssl": this.state.newSSL,
                    "context": this.state.newServeurContext
                  };


                fetch(varConfigServeur.apiServGroupware, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': this.props.tokenCSRF
                  },
                    body: JSON.stringify(data)
                  })
                  .then(response => {
                    var x = response;
                    if(response.status === 200){
                      return response.json().then(responseRetour => {
                        this.addNewServeurGroupware(responseRetour);
                      })
                    }
                    else{
                      console.log(response.headers);
                     return response.json().then(responseError => {
                       console.log(responseError);
                       this.requeteResponse(x,responseError);
                     })
                   }
                   })

  }

  addNewServeurGroupware(data){
      const array = this.state.dataServGroupware;

      var item = new Object();
      item.id = data.id;
      item.name = data.name;
      item.type = data.type;
      item.host = data.host;
      item.ssl = data.ssl;

      array.push(item);

      this.setState({dataServGroupware: array});
      this.setState({newServeurName: "", newType: "OPENXCHANGE", newHost: "", newSSL: ""});
  }

  sendSelectedModif(data, index){

      fetch(varConfigServeur.apiServGroupware + data.id, {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(data)
        })
        .then(response => {
          var x = response;
          if(response.status !== 200){
           return response.json().then(responseError => {
             console.log(responseError);
           })
         }
       })

       const array = this.state.dataServGroupware;
       array[index] = data;
       this.setState({dataServGroupware: array});
  }

  removeServeurGroupware(index, id){

        fetch(varConfigServeur.apiServGroupware + id, {
            method: 'DELETE',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: ""
          })
          .then(response => {
            var x = response;
            if(response.status !== 200){
             return response.json().then(responseError => {
               console.log(responseError);
             })
           }
         })

         const array = this.state.dataServGroupware;
         var newArray = [];

         for (var i = 0, j = 0; i < array.length; i++) {
           if (i !== index) {
             newArray[j++] = array[i]
           }
         }
         this.setState({dataServGroupware: newArray});
  }

  confirmDelete(x, index, id) {
    this.setState({modalTxt: x, modalIndex: index, deleteId: id})
  }


// ------------------------- Render -------------------------------------------- //

  render() {

    return (<div className="col-5 mt-2 mb-3 text-center container" id="groupware" role="tabpanel" aria-labelledby="home-tab">

      <div className="list-group text-center">
        <h5 className="text-info mt-2">
          <b>
            <FormattedMessage id="ConfigurationServer.serveurGroupware"/>
          </b>
        </h5>


        {
          this.state.dataServGroupware.map((server, index) => (<div key={index} className="row">
            <a href="#" className="list-group-item list-group-item-action col rounded-0" data-toggle="modal" data-target=".servGRP-modal-lg" onClick={this.selectGroupware.bind(this, server.name, index)}>
              {server.name}
            </a>
            <button className="btn btn-secondary rounded-0" data-toggle="modal" data-target="#modalSuprServGRP" onClick={this.confirmDelete.bind(this, server.name, index, server.id)}>
              <span>&times;</span>
            </button>
          </div>))
        }

      </div>
      <div className="mt-2">
        <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutServGRP">
          <div style={{
              color: "rgb(255,255,255)"
            }}>
            <FaPlus size={14}/>

          </div>
        </button>
      </div>

      <div className="modal fade servGRP-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body ">

              <h6 className="text-primary text-center"><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.1"/> {this.state.selectedGroupware}</h6>

              <h5 className="text-primary text-center">
                <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.2"/></b>
              </h5>

              <h6 className="text-info ">
                <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.2"/></b>
              </h6>

              <div className="col mt-2 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.3"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "name")} value={this.state.selectedGroupwareData.name}/>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.4"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "host")} value={this.state.selectedGroupwareData.host}/>
                  </div>
                </div>
              </div>

              <h5 className="text-primary text-center">
                <b>Groupware</b>
              </h5>
              <h6 className="text-info mt-2 mb-2">
                <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.5"/></b>
              </h6>

              <div className="row mb-1">
                <div className="col">
                  <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.6"/>
                </div>

                <div className="col">
                  <select id="inputState" className="form-control mr-4" onChange={e => this.handleSelectedInputChange(e, "type")} value={this.state.selectedGroupwareData.type}>
                    <option className="list-group-item small" value={"EXCHANGE"}>MS Exchange</option>
                    <option className="list-group-item small" value={"OPENXCHANGE"}>Open-Xchange (0.8.x)</option>
                    <option className="list-group-item small" value={"OPENXCHANGE6"}>Open-Xchange 6</option>
                    <option className="list-group-item small" value={"OBM"}>OBM</option>
                    <option className="list-group-item small" value={"GROUPDAV"}>Group DAV</option>
                    <option className="list-group-item small" value={"EXCHANGEWEBSERVICE"}>Exchange Web Service</option>
                  </select>
                </div>

              </div>

              <div className="row mb-1">
                <div className="col">
                  Context
                </div>
                <div className="col">
                  <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "context")} value={this.state.selectedGroupwareData.context ? this.state.selectedGroupwareData.context : ""}/>
                </div>
              </div>

              <div className="custom-control custom-checkbox mb-2">
                <input className="custom-control-input" type="checkbox" checked={this.state.selectedGroupwareData.ssl} onChange={e => this.handleSelectedInputChange(e, "ssl")} id="enableSSL"/>
                <label className="custom-control-label" htmlFor="enableSSL">
                  SSL
                </label>
              </div>

              <div className="row mb-1">
                <div className="col">
                  <h5 className="text-primary text-center mt-2">
                    <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.7"/></b>
                  </h5>
                  <h6 className="text-info mt-2 mb-2">
                    <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.8"/></b>
                  </h6>

                  <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.9"/>

                  <button type="button" className="btn btn-danger float-right">Test</button>
                </div>
                <div className="col">

                  <h5 className="text-primary text-center mt-2">
                    <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.10"/></b>
                  </h5>
                  <div className="card text-center">Pas de problème à signaler sur ce serveur.</div>

                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.sendSelectedModif.bind(this, this.state.selectedGroupwareData, this.state.selectedGroupwareIndex)}><FormattedMessage id="valider"/></button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalAjoutServGRP" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.2.1"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">



                <h6 className="text-primary text-center"><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.1"/></h6>

                <h5 className="text-primary text-center">
                  <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.2"/></b>
                </h5>

                <h6 className="text-info ">
                  <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.2"/></b>
                </h6>

                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.3"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newServeurName")} value={this.state.newServeurName}/>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.4"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newHost")} value={this.state.newHost}/>
                    </div>
                  </div>
                </div>

                <h5 className="text-primary text-center">
                  <b>Groupware</b>
                </h5>
                <h6 className="text-info mt-2 mb-2">
                  <b><FormattedMessage id="ConfigurationServer.serveurGroupware.modal.5"/></b>
                </h6>

                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurGroupware.modal.6"/>
                  </div>

                  <div className="col">
                    <select id="inputState" className="form-control mr-4" value={this.state.newType} onChange={e => this.handleInputChange(e, "newType")}>
                    <option className="list-group-item small" value={"EXCHANGE"}>MS Exchange</option>
                    <option className="list-group-item small" value={"OPENXCHANGE"}>Open-Xchange (0.8.x)</option>
                    <option className="list-group-item small" value={"OPENXCHANGE6"}>Open-Xchange 6</option>
                    <option className="list-group-item small" value={"OBM"}>OBM</option>
                    <option className="list-group-item small" value={"GROUPDAV"}>Group DAV</option>
                    <option className="list-group-item small" value={"EXCHANGEWEBSERVICE"}>Exchange Web Service</option>
                    </select>
                  </div>

                </div>

                <div className="row mb-1">
                  <div className="col">
                    Context
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleInputChange(e, "newServeurContext")} value={this.state.newServeurContext}/>
                  </div>
                </div>

                <div className="custom-control custom-checkbox mb-2">
                  <input className="custom-control-input" type="checkbox" checked={this.state.newSSL} onChange={e => this.handleInputChange(e, "newSSL")} id="enableNewSSL"/>
                  <label className="custom-control-label" htmlFor="enableNewSSL">
                    SSL
                  </label>
                </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addServeurGroupware} disabled={!(this.state.newServeurName && this.state.newHost)}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={"modalSuprServGRP"} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="confirmDelete"/> : "<b className="text-info">{this.state.modalTxt}</b>" ?</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.removeServeurGroupware.bind(this, this.state.modalIndex, this.state.deleteId)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}
export default ServeurGroupware
