import React from 'react'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';
import find from 'lodash/find';

import classNames from "classnames";
import { MenuProvider } from 'react-contexify';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {MdLaptopChromebook} from "react-icons/md";


const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);

class ComputersTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        pageSizeForComputersTab: localStorage.getItem('pageSizeForComputersTab'),
      };

    }

    componentWillUnmount(){
      if (this.state.pageSizeForComputersTab) {
        localStorage.setItem('pageSizeForComputersTab', this.state.pageSizeForComputersTab);
      }
    }


// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

      const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [
      {

        Header: '',
        accessor: 'state',
        minWidth: 60,
        maxWidth: 80,
        Cell: row => (
            <div>
              <span style={{
                  color: row.value === true ? "rgb(44,165,255)"
                  : row.value === false ? "rgb(190,190,190)"
                  : "rgb(0,0,0)"
                }}>
                <MdLaptopChromebook size={28}/>
              </span>
            </div>
          )


      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "ordinateurs.1"})}
          </b>
        </span>),
        accessor: 'name',
        minWidth: 240
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "ordinateurs.2"})}
          </b>
        </span>),
        accessor: 'usersLength',
        minWidth: 160
      }, {
        Header: () => (<span>
          <b>
            O.S.
          </b>
        </span>),
        accessor: 'os',
        minWidth: 100
      }
    ]

    return (<div>

      <MenuProvider id="menu_computersAction" >
        <ReactTable getTheadThProps={getTheadThProps}

        getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            onContextMenu: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            style: {
                background: rowInfo.original.enable === false ? "rgb(241,241,241)"
                  : rowInfo.index === this.props.selected ? '#e4eef4' : null,
                color: rowInfo.index === this.props.selected ? 'black' : null
            }
          }
        }else{
          return {}
        }
      }}

          getTdProps={(state, rowInfo, column, instance) => {
      return {

        onContextMenu: (e) => {
            if(rowInfo){
            this.props.contextMenu(rowInfo.original)}
            else {this.props.contextMenu("empty")}
        }
      }
    }}

          showPagination={this.props.showPagination}
          data={this.props.array}
          noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
          rowsText={this.props.intl.formatMessage({id: "rows"})}
          previousText={this.props.intl.formatMessage({id: "previous"})}
          nextText={this.props.intl.formatMessage({id: "next"})}
          loadingText={this.props.intl.formatMessage({id: "chargement"})}
          pageText= "Page"
          ofText={this.props.intl.formatMessage({id: "sur"})}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={true}
          pageSizeOptions= {[10, 20, 25, 50, 100]}
          defaultPageSize= {50}
          showPageSizeOptions={true}
          onPageSizeChange={(pageSize) => {
            this.setState({pageSizeForComputersTab: pageSize})
          }}
          pageSize={!this.props.showPagination ? this.props.array.length : this.state.pageSizeForComputersTab != null ? this.state.pageSizeForComputersTab : this.defaultPageSize}
          style={{
            minHeight: "15vh",
            maxHeight: "70vh",
            width: '60vw'
          }} className=" text-center border-0 small -clickable"/>
      </MenuProvider>

      <div className="col text-center border-top small">
        <b className="text-dark"><FormattedMessage id="ordinateurs.nbordinateurs"/> {this.props.array.length}</b>
      </div>
    </div>)
  }
}

ComputersTab.propTypes = {
  intl: intlShape.isRequired
};
ComputersTab = injectIntl(ComputersTab, {withRef: true});

export default ComputersTab
