import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import './index.css';
import App from './App';

addLocaleData([...locale_en, ...locale_fr]);

const routing = (
		<App />
);

ReactDOM.render(routing, document.getElementById('root'));
