import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import Select from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import {MdLens} from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

import { components } from 'react-select';
const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>
      <span
        style={{color: props.data.value == 1 ? "rgb(210,15,15)"
        : props.data.value == 2 ? "rgb(216,226,36)"
        : "rgb(33,235,29)"}}>
      <IoMdCheckmark size={24}/>
    </span>
       {props.data.label}
    </Option>
);

const optionsType = [
  { value: 'IMAP', label: 'IMAP' },
  { value: 'Groupware', label: 'Groupware' },
  { value: 'LDAP', label: 'LDAP' },
  { value: 'ActiveSync', label: 'ActiveSync' },
];

const optionsLevel = [
  { value: '3', label: 'Info / Note' },
  { value: '2', label: 'Warning' },
  { value: '1', label: 'Error' },
];

function changeToDate(int, lang){
  var options = {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${lang}-${lang.toUpperCase()}`, options);
  return (ds)
}

export function research(array, type, level) {

  var testArray = [];


  if (level && type) {

    for (var i = 0; i < array.length; i++) {

        if(array[i].level === parseInt(level) && array[i].type === type){

          testArray.push(array[i]);

        }
    }

    return (testArray)

  }


  if (level) {
    for (var i = 0; i < array.length; i++) {

       if(array[i].level){
        if(array[i].level === parseInt(level)){

          testArray.push(array[i]);

        }}
    }


  if(level.length > 0){
    return (testArray)
  }

  }

  if (type) {
    for (var i = 0; i < array.length; i++) {

       if(array[i].type){
        if(array[i].type === type){

          testArray.push(array[i]);

        }}
    }


  if(type.length > 0){
    return (testArray)
  }

  }

  return (array)
}


class JournalEvenements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: {}
    };

    this.handleChange = this.handleChange.bind(this);
  }


// ------------------------------- Render -------------------------------------- //

  handleChange= (event, field) => {
    var value = "";
    if(event)value=event.value;
    this.setState({
      selectedFilter: { ...this.state.selectedFilter, [field]: value }
    });
  }

  render()
  {


// ------------------------------- Setting of the table ------------------------ //

          const getTheadThProps = (table, row, col) => {
         const sortedCol = find(table.sorted, { id: col.id });
         const boxShadow =
           sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
         return {
           style: {
             boxShadow,
           },
         };
       };

           const columns = [{

             Header: () => (
             <span>
               <b> {this.props.intl.formatMessage({id: "userDetails.Journal.tab.1"})} </b>
             </span>
           ),
             accessor: 'type',
             maxWidth: 120,

           },{
             Header: () => (
             <span>
               <b> {this.props.intl.formatMessage({id: "userDetails.Journal.tab.2"})} </b>
             </span>
           ),
           accessor: 'level',
           maxWidth: 100,
           Cell: row => (
             <div>
               <span
                 style={{color: row.value == 1 ? "rgb(210,15,15)"
                 : row.value == 2 ? "rgb(216,226,36)"
                 : "rgb(44,207,56)"}}>
               <IoMdCheckmark size={26}/>
             </span>
             </div>
             )

           },{
             Header: () => (
             <span>
               <b> {this.props.intl.formatMessage({id: "userDetails.Journal.tab.3"})} </b>
             </span>
           ),
             accessor: 'date',
             maxWidth: 180,

             Cell: row => (
                 <div>
                 {row.value ?
                   <span>
                     {changeToDate(row.value, this.props.intl.locale)}
                   </span>
                   :null
                 }
                 </div>
               )

           },{

             Header: () => (
             <span>
               <b> {this.props.intl.formatMessage({id: "userDetails.Journal.tab.4"})} </b>
             </span>
           ),
             accessor: 'comment',

           }]

  return (<div className="col mt-3 ml-2 mb-3">
    <div className="card bg-light mt-3 mb-2">
      <div className="card-header rounded-top">
        <div className="row">
        <Select className="col-4"
          onChange={e => this.handleChange(e, "type")}
          placeholder={this.props.intl.formatMessage({id: "userDetails.Journal.tab.1"})}
          isClearable
          options={optionsType}

        />
        <Select className="col-4"
          onChange={e => this.handleChange(e, "level")}
          placeholder={this.props.intl.formatMessage({id: "userDetails.Journal.tab.2"})}
          isClearable
          options={optionsLevel}
          components={{ Option: IconOption }}

        />

      </div>
    </div>

    {this.props.userInfo.events && this.state.selectedFilter.type !== null && this.state.selectedFilter.level !== null ?
      <ReactTable

        getTheadThProps={getTheadThProps}

        getTdProps={(state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
      }
    }
  }}

        showPagination={false}
        noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
        columns={columns}
        data={research(this.props.userInfo.events, this.state.selectedFilter.type, this.state.selectedFilter.level).sort((a, b) => b.date.localeCompare(a.date))}
        resizable={false}
        pageSize={research(this.props.userInfo.events, this.state.selectedFilter.type, this.state.selectedFilter.level).length}
              style={{
                maxHeight: "40vh",
                minHeight: "15vh",
                width: 'auto',
                zIndex: '0'
              }}
              className=" text-center border-0 small"
            />
      :
      null

    }

    </div>
  </div>)
  }
}

JournalEvenements.propTypes = {
  intl: intlShape.isRequired
};
JournalEvenements = injectIntl(JournalEvenements, {withRef: true});

export default JournalEvenements
