import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline} from "react-icons/io";

class CompteAutoParametres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailAddress: [
        "Root", "Test", "Shared"
      ],
      mail: "Root"
    };

    this.addMail = this.addMail.bind(this);
    this.handleMail = this.handleMail.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

  handleMail(event) {
    this.setState({mail: event.target.value});
  }

  addMail() {
    const array = this.state.mailAddress;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === (this.state.mail)) {
        return
      }
    }
    if (this.state.mail) {
      array.push(this.state.mail)
    }
    this.setState({mailAddress: array});
    this.setState({mail: ""});
  }

  removeMail(index) {
    const array = this.state.mailAddress;
    var newArray = [];

    for (var i = 0, j = 0; i < array.length; i++) {
      if (i !== index) {
        newArray[j++] = array[i]
      }
    }
    this.setState({mailAddress: newArray});
  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="row">
      <div className="col-3 mt-2">

        <ul className="nav nav-pills nav-fill flex-column card" id="activeServ" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#VPN" role="tab" aria-controls="home" aria-selected="true">VPN</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#MMI" role="tab" aria-controls="profile" aria-selected="false">MM-Instant</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#MMF" role="tab" aria-controls="contact" aria-selected="false">MM-File</a>
          </li>
        </ul>
      </div>
      <div className="col mt-2">

        <div className="tab-content  border-0" id="myTabContent">

          <div className="tab-pane show active" id="VPN" role="tabpanel" aria-labelledby="home-tab">

            <div className="col card">

              <h5 className="text-primary text-center mt-2">
                <b>VPN</b>
              </h5>
              <hr/>

              <h6 className="text-info mt-1 mb-1">
                <b><FormattedMessage id="parametres.compteAuto.parametres.2"/></b>
              </h6>

              <div className="col mt-1 ">
                <div className="custom-control custom-checkbox mb-1">
                  <input className="custom-control-input" type="checkbox" value="" id="noRestric"/>
                  <label className="custom-control-label" htmlFor="noRestric">
                    <FormattedMessage id="parametres.compteAuto.parametres.3"/>
                  </label>
                </div>
                <div className="custom-control custom-checkbox mb-1">
                  <input className="custom-control-input" type="checkbox" value="" id="enableAccesResExt"/>
                  <label className="custom-control-label" htmlFor="enableAccesResExt">
                    <FormattedMessage id="parametres.compteAuto.parametres.4"/>
                  </label>
                </div>
              </div>

              <h6 className="text-info mt-2 mb-1">
                <b><FormattedMessage id="parametres.compteAuto.parametres.5"/></b>
              </h6>

              <div className="col mt-1 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="parametres.compteAuto.parametres.6"/>
                  </div>

                  <div className="col">
                    <select id="inputState" className="form-control mr-4" defaultValue="-">
                      <option className="list-group-item small">-</option>
                    </select>
                  </div>
                </div>
              </div>

              <h6 className="text-info mt-1 mb-2">
                <b><FormattedMessage id="parametres.compteAuto.parametres.7"/></b>
              </h6>

              <div className="col mt-1 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="parametres.compteAuto.parametres.8"/>
                  </div>

                  <div className="col">
                    <select id="inputState" className="form-control mr-4" defaultValue="-">
                      <option className="list-group-item small">-</option>
                    </select>
                  </div>

                </div>
              </div>
              <div className="col">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="parametres.compteAuto.parametres.9"/>
                  </div>

                  <div className="col mb-3">
                    <select id="inputState" className="form-control mr-4" defaultValue="Internet possible">
                      <option className="list-group-item small">Internet possible</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane  " id="MMI" role="tabpanel" aria-labelledby="home-tab">

            <div className="col card">

              <h5 className="text-primary text-center mt-2">
                <b>MM-Instant</b>
              </h5>
              <hr/>
              <h6 className="text-info mb-2 mt-1">
                <b><FormattedMessage id="parametres.compteAuto.MMI.1"/></b>
              </h6>

              <div className="col mt-2 ">
                <div className="custom-control custom-checkbox mb-3">
                  <input className="custom-control-input" type="checkbox" value="" id="enableMMInstant"/>
                  <label className="custom-control-label" htmlFor="enableMMInstant">
                    <FormattedMessage id="parametres.compteAuto.MMI.2"/>
                  </label>
                </div>

                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="parametres.compteAuto.MMI.3"/>
                  </div>

                  <div className="col">
                    <select id="inputState" className="form-control mr-4" defaultValue="mail">
                      <option className="list-group-item small">mail</option>
                    </select>
                  </div>

                </div>

                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="parametres.compteAuto.MMI.4"/>
                  </div>

                  <div className="col mb-3">
                    <select id="inputState" className="form-control mr-4" defaultValue="aragorn">
                      <option className="list-group-item small">aragorn</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane  " id="MMF" role="tabpanel" aria-labelledby="home-tab">

            <div className="col card">

              <h5 className="text-primary text-center mt-2">
                <b>MM-File</b>
              </h5>
              <hr/>

              <div className="mt-2 col-10 container">

                <div className="card border-0 mb-4">

                  <div className="card-header border rounded-top" style={{
                      background: 'rgb(251,251,251)'
                    }}>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-info mt-1 mb-2">
                          <b><FormattedMessage id="parametres.compteAuto.MMI.5"/></b>
                        </h6>
                      </div>

                      <div className="text-primary col-2 float-right">
                        <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutServeurFichier" title={this.props.intl.formatMessage({id: "parametres.notifications.2"})}>
                          <span style={{
                              color: "rgb(255,255,255)"
                            }}>
                            <FaPlus size={14}/>
                          </span>
                        </button>

                      </div>

                    </div>
                  </div>

                  <div className="list-group">
                    {
                      this.state.mailAddress.sort((a, b) => a.localeCompare(b)).map((ListFormat, index) => (<div key={index} className="list-group-item text-center">
                        {ListFormat}
                        <button style={{
                            outline: 'none'
                          }} className="close" data-toggle="modal" data-target="#modalConfirmSuprMMF" onClick={this.modalConfirm.bind(this, ListFormat, index)}>
                          <span style={{
                              color: "rgb(110,110,110)"
                            }}>
                            <IoIosCloseCircleOutline size={28}/>
                          </span>
                        </button>
                      </div>))
                    }
                  </div>

                </div>
              </div>

              <div className="modal fade" id="modalAjoutServeurFichier" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="parametres.compteAuto.MMI.modal"/></h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <select id="inputState" className="form-control mr-4" onClick={this.handleMail}>
                        <option className="list-group-item small">Root</option>
                        <option className="list-group-item small">Test</option>
                        <option className="list-group-item small">Shared</option>
                        <option className="list-group-item small">testBAT</option>
                      </select>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                      </button>
                      <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addMail}><FormattedMessage id="ajouter"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modalConfirmSuprMMF" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className={`modal-content modalConfirm border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel">
                        Supprimer
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel">
                        Confirmer la suppression de "<b className="text-info">{this.state.modalTXT}</b>" ?
                      </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.removeMail.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>)
  }
}

CompteAutoParametres.propTypes = {
  intl: intlShape.isRequired
};
CompteAutoParametres = injectIntl(CompteAutoParametres, {withRef: true});

export default CompteAutoParametres
