import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {TiPlus} from "react-icons/ti";
import {FaPlus} from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";

class GroupsAvance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabPlage: [],
      value1: "",
      value2: "",
    };

    this.PlageADD1Change = this.PlageADD1Change.bind(this);
    this.PlageADD2Change = this.PlageADD2Change.bind(this);
    this.addPlage = this.addPlage.bind(this);
    this.removePlage = this.removePlage.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  PlageADD1Change(event) {
    this.setState({value1: event.target.value});
  }

  PlageADD2Change(event) {
    this.setState({value2: event.target.value});
  }

  addPlage() {
    const tableau = this.state.tabPlage;
    for (var i = 0; i < tableau.length; i++) {
      if (tableau[i] === (this.state.value1 + " : " + this.state.value2)) {
        return
      }
    }
    if (this.state.value1 && this.state.value2) {
      tableau.push(this.state.value1 + " : " + this.state.value2)
    }
    this.setState({tabPlage: tableau});
    this.setState({value1: "", value2: ""});
  }

  removePlage(index) {
    const tab = this.state.tabPlage;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({tabPlage: newTab});
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="container col-8 mt-3">
      <div>

        <h6 className="text-info">
          <b><FormattedMessage id="groupsDetails.Avance.1"/></b>
        </h6>

        <div className="custom-control custom-radio mb-2">
          <input className="custom-control-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" defaultChecked={true}/>
          <label className="custom-control-label" htmlFor="exampleRadios1">
            <FormattedMessage id="groupsDetails.Avance.2"/>
          </label>
        </div>
        <div className="custom-control custom-radio mb-2">
          <input className="custom-control-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
          <label className="custom-control-label" htmlFor="exampleRadios2">
            <FormattedMessage id="groupsDetails.Avance.3"/>
          </label>
        </div>
        <div className="custom-control custom-radio mb-2">
          <input className="custom-control-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3"/>
          <label className="custom-control-label" htmlFor="exampleRadios3">
            <FormattedMessage id="groupsDetails.Avance.4"/>
          </label>
        </div>
        <div className="custom-control custom-radio mb-2">
          <input className="custom-control-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option4"/>
          <label className="custom-control-label" htmlFor="exampleRadios4">
            <FormattedMessage id="groupsDetails.Avance.5"/>
          </label>
        </div>


        <div className="card border-0 mb-4 mt-3">

          <div className="card-header border" style={{
              background: 'rgb(251,251,251)'
            }}>
            <div className="row">
              <div className="col">
                <h6>
                  <b className="text-info">
                    <FormattedMessage id="groupsDetails.Avance.6"/>
                  </b>
                </h6>
              </div>

              <div className="text-primary col-3 float-right">
                <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutDNS" title={this.props.intl.formatMessage({id: "parametres.notifications.2"})}>
                  <span style={{
                      color: "rgb(255,255,255)"
                    }}>
                    <FaPlus size={14}/>
                  </span>
                </button>

              </div>

            </div>
          </div>

          <div className="list-group">
            {
              this.state.tabPlage.map((listPlage, index) => (<div key={index} className="list-group-item text-center">
                {listPlage}
                <button
                        style={{outline: 'none'}}
                        className="close"
                        onClick={this.removePlage.bind(this, index)}>
                        <span style={{
                            color: "rgb(110,110,110)"
                          }}>
                          <IoIosCloseCircleOutline size={28}/>
                        </span>
                </button>
              </div>))
            }
          </div>

        </div>


        <div className="modal fade" id="modalAjoutDNS" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="groupsDetails.Avance.modal"/></h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="input-group">

                  <input type="text" className="form-control text-center border-right-0" value={this.state.value1} onChange={this.PlageADD1Change}/>

                  <div className="input-group-prepend">
                    <span className="input-group-text">:</span>
                  </div>

                  <input type="text" className="form-control text-center" value={this.state.value2} onChange={this.PlageADD2Change}/>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                </button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addPlage}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

        <h6 className="text-info mt-1 mb-2">
          <b><FormattedMessage id="groupsDetails.Avance.7"/></b>
        </h6>
        <div className="row mb-3">
          <div className="col">
            <FormattedMessage id="groupsDetails.Avance.8"/>
          </div>
          <div className="col-auto">
            <input className="form-control"/>
          </div>
        </div>

      </div>
    </div>)
  }

}
GroupsAvance.propTypes = {
  intl: intlShape.isRequired
};
GroupsAvance = injectIntl(GroupsAvance, {withRef: true});

export default GroupsAvance
