import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux, varGroupDetail } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose,
  IoIosInformationCircle,
  IoIosWarning,
  IoIosAlert
} from "react-icons/io";

import { FaUser, FaUsers } from "react-icons/fa";
import { GoDeviceMobile } from "react-icons/go";
import { MdGroup, MdMessage } from "react-icons/md";

var contextMenu = {
  "messageDelete": false,
  "messageDetails": false,
}


const iconSize = 22;

function changeToDate(int, langue){
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
}


class DevicesDetailsNotificationsContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

  checkData() {

    const hit = this.props.hit;

    if (hit != "empty" && hit) {


      if (this.props.userAdministration === "ADMINISTRATOR") {
        contextMenu.messageDelete = true;
      } else {
        contextMenu.messageDelete = false;
      }
      contextMenu.messageDetails = true;

    } else {
        contextMenu.messageDelete = false;
        contextMenu.messageDetails = false;
    }
  }

// ------------------------------- Action handle ------------------------------- //

showModal(type, test, data){
  this.setState({modalTXT: test, modalData: data})
  $('#modalMMAppMessage').modal('show')
}

  hideModal(){
    $('#modalMMAppMessage').modal('hide')
  }


  actionMenu(id){
    switch (id) {
      case "forceAppInstall":

          var data = {};

          data.deviceId = this.props.devicesDetails.android_id;
          data.email = this.props.devicesDetails.user.email;
          data.title = this.props.appHit.packageName;
          data.state = "action_install_app";

          if(process.env.NODE_ENV == "development")
          {
            console.log(data);
          }

          fetch(varTerminaux.apiMMAppServerAction, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: JSON.stringify(data)
            })
            .then(response => {
              if(process.env.NODE_ENV == "development")
              {
                console.log(response);
              }
             })

        break;

      default:
    }
    this.hideModal();
  }


// ------------------------------- Function with visual ------------------------ //

testMenu(id, state, name) {
  if (state === true) {
    switch (id) {
      case "messageDelete":
        return (<Item onClick={this.actionMenu.bind(this, "messageDelete")}>
          <span style={{
              color: "rgb(232,35,35)"
            }}>
            <IoMdClose size={iconSize}/>
          </span>
            {name}
        </Item>)
        break;

      case "messageDetails":
        return (<Item onClick={this.showModal.bind(this, "messageDetails", this.props.hit.title, this.props.hit.text)}>
          <span style={{
              color: "rgb(51,154,232)"
            }}>
            <MdMessage size={iconSize}/>
          </span>
            {name}
        </Item>)
        break;

      default:
        break;
    }
  }
}


// ------------------------------- Render -------------------------------------- //

render() {

  {
    this.checkData()
  }

  if (this.props.hit != "empty")
    return (<div>

      <Menu id='menu_DevicesDetailsNotificationsContextMenu' className="border" theme={theme.light} style={{
          fontSize: 'calc(4px + 1vmin)',
          zIndex: '2000'
        }}>
        {this.testMenu("messageDetails", contextMenu.messageDetails, this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.showMessageDetails"}))}
        {this.testMenu("messageDelete", contextMenu.messageDelete, this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.deleteMessage"}))}

      </Menu>


      <div className="modal fade" id="modalMMAppMessage" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.showMessageDetails.modal.title"})}</h5>
              <button type="button" className="close" onClick={this.hideModal.bind(this)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

                <div className="row mb-3">

                  {(this.props.hit.state == "info" || this.props.hit.state == "warning" || this.props.hit.state == "error") ?
                  <div className="col-auto">

                    {this.props.hit.state == "info" ?
                      <span style={{ color: "rgb(44,165,255)" }}>
                        <IoIosInformationCircle size={34}/>
                      </span>
                      :
                      this.props.hit.state == "warning" ?
                        <span style={{ color: "rgb(255,159,47)" }}>
                          <IoIosWarning size={34}/>
                        </span>
                      :
                      this.props.hit.state == "error" ?
                        <span style={{ color: "rgb(250,31,31)" }}>
                          <IoIosAlert size={34}/>
                        </span>
                      :
                      null
                    }

                  </div>
                    :
                    null
                  }


                  <div className="col">

                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.title"})}</span>
                      </div>
                      <input type="text" style={{ backgroundColor: "rgb(255, 255, 255)"}} className="form-control" maxLength="100" value={this.props.hit.title} readOnly/>
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{this.props.intl.formatMessage({id: "terminauxDetails.tab.notif.date"})}</span>
                      </div>
                      <input type="text" className="form-control" style={{ backgroundColor: "rgb(255, 255, 255)"}} maxLength="100" value={changeToDate(this.props.hit.date, this.props.intl.locale)} readOnly/>
                    </div>
                  </div>


                </div>


              {!(this.props.hit.version || this.props.hit.versionCode) ?
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.message"})}</span>
                  </div>
                  <textarea className="form-control" rows={2} style={{ backgroundColor: "rgb(255, 255, 255)", resize: "none" }} maxLength="250" value={this.props.hit.text} readOnly></textarea>
                </div>
                :
                null
              }


                {this.props.hit.link ?
                  <div className="input-group input-group-sm mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.link"})}</span>
                    </div>
                    <input type="text" className="form-control" style={{ backgroundColor: "rgb(255, 255, 255)"}} maxLength="100" value={this.props.hit.link} readOnly/>
                  </div>
                  :
                  null
                }

                {this.props.hit.version ?
                  <div className="input-group input-group-sm mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "terminauxDetails.tab.notif.version"})}</span>
                    </div>
                    <input type="text" className="form-control" style={{ backgroundColor: "rgb(255, 255, 255)"}} maxLength="100" value={this.props.hit.version} readOnly/>
                  </div>
                  :
                  null
                }

                {this.props.hit.versionCode ?
                  <div className="input-group input-group-sm mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "terminauxDetails.tab.notif.versionCode"})}</span>
                    </div>
                    <input type="text" className="form-control" style={{ backgroundColor: "rgb(255, 255, 255)"}} maxLength="100" value={this.props.hit.versionCode} readOnly/>
                  </div>
                  :
                  null
                }



            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.hideModal.bind(this)}><FormattedMessage id={"fermer"}/></button>
            </div>
          </div>
        </div>
      </div>

    </div>)
    else {
      return (<div/>)
    }
  }
}

DevicesDetailsNotificationsContextMenu.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsNotificationsContextMenu = injectIntl(DevicesDetailsNotificationsContextMenu, {withRef: true});

export default DevicesDetailsNotificationsContextMenu
