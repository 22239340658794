import React from 'react'
import {FormattedMessage} from "react-intl";

class ParametresSecurite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.securite.1"/></b>
      </h5>
      <hr/>

      <h6 className="text-info mb-2">
        <b><FormattedMessage id="parametres.securite.2"/></b>
      </h6>
      <div className="col">

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="enableSSLSecu"/>
          <label className="custom-control-label" htmlFor="enableSSLSecu">
            <FormattedMessage id="parametres.securite.3"/>
          </label>
        </div>
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="enableCryptSym"/>
          <label className="custom-control-label" htmlFor="enableCryptSym">
            <FormattedMessage id="parametres.securite.4"/>
          </label>
        </div>
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="enableMAJwSSL"/>
          <label className="custom-control-label" htmlFor="enableMAJwSSL">
            <FormattedMessage id="parametres.securite.5"/>
          </label>
        </div>
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="enableKeepPass"/>
          <label className="custom-control-label" htmlFor="enableKeepPass">
            <FormattedMessage id="parametres.securite.6"/>
          </label>
        </div>

      </div>

      <div>
        <h6 className="text-info mt-2 mb-2">
          <b><FormattedMessage id="parametres.securite.7"/></b>
        </h6>
        <div className="col">
          <div className="custom-control custom-radio mb-2">
            <input className="custom-control-input" type="radio" name="exampleRadios" id="permissif" value="option1"/>
            <label className="custom-control-label" htmlFor="permissif">
              <FormattedMessage id="parametres.securite.8"/>
            </label>
          </div>

          <div className="custom-control custom-radio mb-2">
            <input className="custom-control-input" type="radio" name="exampleRadios" id="restrictif" value="option1" defaultChecked={true}/>
            <label className="custom-control-label" htmlFor="restrictif">
              <FormattedMessage id="parametres.securite.9"/>
            </label>
          </div>

        </div>

      </div>
      <div>
        <h6 className="text-info mt-2 mb-2">
          <b><FormattedMessage id="parametres.securite.10"/></b>
        </h6>
        <div className="col">
          <div className="custom-control custom-checkbox mb-4">
            <input className="custom-control-input" type="checkbox" value="" id="enableRadius"/>
            <label className="custom-control-label" htmlFor="enableRadius">
              <FormattedMessage id="parametres.securite.11"/>
            </label>
          </div>
        </div>

      </div>
    </div>)
  }

}

export default ParametresSecurite
