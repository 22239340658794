import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import {MdPhonelinkErase, MdWebAsset, MdPhonelinkRing} from "react-icons/md";

import {varUser, varTerminaux, varUserDetail} from '../variables';

var contextMenu = {
  "graph": true,
  "redeploiement": true,
  "delete": true
}

const iconSize = 22;

class UserDevicesContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

// ------------------------------- Action handle ------------------------------- //

  showModal(type, color, id) {
    this.setState({modalBorderColor: color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenu').modal('show')
  }

  actionMenu(id) {
    switch (id) {

      case "redeploiement":

        fetch(varTerminaux.apiDeviceReset + "cleanAccount/" + this.props.hit.deviceId + "/" + this.props.userInfo.login, {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: ""
          })
          .then(response => {
            if(process.env.NODE_ENV == "development")
            {
              console.log(response);
            }
           })
        break;

      case "delete":

          fetch(varUser.api + this.props.userId + "/devices/" + this.props.hit.deviceId, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
            body: ""
          }).then(response => {
            var x = response;
            if (response.status === 200) {
              this.props.refreshData(varUserDetail.api)
            } else if (response.status !== 200) {
              return response.json().then(responseError => {
                console.log(responseError);
              })
            }
          })

        break;
      default:
    }
  }


  handleContextMenuClick(event) {
    console.log(event);
  }

// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "graph":
          return (<Item onClick={this.props.modalGraph.bind(this, this.props.hit)}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdWebAsset size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "redeploiement":
          return (<Item onClick={this.showModal.bind(this, name, "info", "redeploiement")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelinkRing size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "delete":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "delete")}>
            <span style={{
                color: "rgb(238,83,83)"
              }}>
              <MdPhonelinkErase size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        default:
          break;
      }
    }
  }

  shouldSeparator(state) {
    if (state === true)
      return (<Separator/>)
  }

// ------------------------------- Render -------------------------------------- //

  render() {

    if (this.props.hit !== "empty")
      return (<div>

        <Menu id='menu_userDevicesContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)'
          }}>
          {this.testMenu("graph", contextMenu.graph, this.props.intl.formatMessage({id: "userDetails.terminals.ContextMenu.1"}))}
          {this.testMenu("redeploiement", contextMenu.redeploiement, this.props.intl.formatMessage({id: "userDetails.terminals.ContextMenu.2"}))}
          {this.testMenu("delete", contextMenu.delete, this.props.intl.formatMessage({id: "userDetails.terminals.ContextMenu.3"}))}
        </Menu>

        <div className="modal fade" id="modalConfirmActionContextMenu" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

UserDevicesContextMenu.propTypes = {
  intl: intlShape.isRequired
};
UserDevicesContextMenu = injectIntl(UserDevicesContextMenu, {withRef: true});

export default UserDevicesContextMenu
