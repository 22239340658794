import React, {Component} from 'react';
import ReactTable from 'react-table'
import find from 'lodash/find';
import MMAppMessagesTabContextMenu from './MMAppMessagesTabContextMenu';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import { MenuProvider } from 'react-contexify';
import Select, { components } from 'react-select';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { IoIosInformationCircle, IoIosWarning, IoIosAlert, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FaUser, FaUsers } from "react-icons/fa";
import { MdGroup, MdMessage } from "react-icons/md";
import { GoDeviceMobile } from "react-icons/go";

import {varMmapp, varGroups} from '../variables';

  const { Option } = components;
  const IconOption = (props) => (
      <Option {...props}>

             {props.data.label}  
      {
        props.data.value == "info" ?
        <span
          style={{color: "rgb(44,165,255)"}}>
          <IoIosInformationCircle size={24}/>
        </span>
        :
        props.data.value == "warning" ?
        <span
          style={{color: "rgb(255,159,47)"}}>
          <IoIosWarning size={24}/>
        </span>
        :
        props.data.value == "error" ?
        <span
          style={{color: "rgb(250,31,31)"}}>
          <IoIosAlert size={24}/>
        </span>
        :
        null
      }

      </Option>
  );

  const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>);

  function changeToDate(int, langue){
    var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


 function researchMessage(array, valeur, nameAllDevices) {

    var testArray = [];

    if (valeur) {
      for (var i = 0; i < array.length; i++) {
        if(array[i].title.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].destinataire && array[i].destinataire.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].destinataire == null && nameAllDevices.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
      }
    }

    if(valeur.length > 0){
      return (testArray)
    }

    return (array)
  }



  class MMAppMessagesTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedType: {value: 0, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})},
        selectedState: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
        selectedExpire: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
        researchMessageValue: "",
        selected: null,
        pageSizeForMMAppMessagesTab: localStorage.getItem('pageSizeForMMAppMessagesTab'),
      };
      this.selectedHandle = this.selectedHandle.bind(this);
    }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

    componentWillUnmount(){
      if (this.state.pageSizeForMMAppMessagesTab) {
        localStorage.setItem('pageSizeForMMAppMessagesTab', this.state.pageSizeForMMAppMessagesTab);
      }
    }


// ------------------------------- Action handle ------------------------------- //

    selectedHandle(x){
      this.setState({selected : x});
    }

    handleChangeSelectType = selectedType => {
      this.setState({ selectedType, selectedGroup: null, selectedUser: null, selectedDevice: null });
    }

    handleChangeSelectGroup = selectedGroup => {
      this.setState({ selectedGroup });
    }

    handleChangeSelectUser = selectedUser => {
      this.setState({ selectedUser, selectedDevice: null});
    }

    handleChangeSelectDevice = selectedDevice => {
      this.setState({ selectedDevice });
    }

    handleChangeSelectState = selectedState => {
      this.setState({ selectedState });
    }

    handleChangeCheckboxLink = useLink => {
      this.setState({ useLink: !this.state.useLink });
    }

    handleChangeSelectExpire = selectedExpire => {
      this.setState({ selectedExpire });
    }

    rechercheMessageHandle(event) {
      this.setState({researchMessageValue: event.target.value});
    }


    createMessage(){

            var url = varMmapp.apiMessage;

            var date = new Date();
            if (this.state.selectedExpire.value != 0) {
              date.setDate(date.getDate() + this.state.selectedExpire.value);
            } else {
              date = null;
            }

            var title =document.getElementById('inputForMessageTitle').value;
            var msg = document.getElementById('inputForMessageText').value;
            var link = document.getElementById('inputForLink') ? document.getElementById('inputForLink').value : null;

            if (this.state.selectedType && this.state.selectedType.value == 1 && this.state.selectedGroup) {
              url += "?groupId=" + this.state.selectedGroup.value;
            }


            var data = {
              "deviceId" : this.state.selectedDevice ? this.state.selectedDevice.value : null,
              "email" : this.state.selectedUser ? this.state.selectedUser.value : null,
              "expireDate": date ? date : null,
              "link": this.state.useLink ? link : null,
              "message": msg,
              "state": this.state.selectedState ? this.state.selectedState.value : null,
              "title": title,
                  }


            if (this.state.selectedType && title.length > 0 && msg.length > 0) {
              fetch(url, {
                  method: 'POST',
                  headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-XSRF-TOKEN': this.props.tokenCSRF
                },
                  body: JSON.stringify(data)
                })
                .then(response => {
                  this.props.fetchMessage();
                  if(process.env.NODE_ENV == "development")
                  {
                    console.log(response);
                  }
                 })

                this.setState({
                  selectedUser: null,
                  selectedExpire: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
                  selectedState: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
                  selectedType: {value: 0, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})},
                  selectedGroup: null,
                  useLink: false
                });

                document.getElementById('inputForMessageText').value = null;
                document.getElementById('inputForMessageTitle').value = null;

            }

          }


// ------------------------------- Function with visual ------------------------ //

    createTabColumn(id){

      switch (id) {
        case "TabApp":
        var columnsApp = [
          {
          Header: () => (<span>
            <b>
               
            </b>
          </span>),
          accessor: 'type',
          minWidth: 50,
          maxWidth: 50,
          Cell: row => (
              <div>
                {row.value === "user" ?
                <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.user.tooltip"})}>
                  <FaUser size={24}/>
                </span>
               :
                 row.value === "group" ?
                 <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.group.tooltip"})}>
                   <FaUsers size={28}/>
                 </span>
                 :
                 row.value === "device" ?
                 <span style={{ color: "rgb(151,183,208)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.device.tooltip"})}>
                   <GoDeviceMobile size={28}/>
                 </span>
                 :
                 row.value === "all" ?
                 <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.all.tooltip"})}>
                   <GoDeviceMobile size={28}/>
                 </span>
                 :
                 null
               }
              </div>
            )
        },{
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.messageTab.destinataire"})}
            </b>
          </span>),
          accessor: 'destinataire',
          minWidth: 100,
          Cell: row => (
              <div>
                {row.original.type === "all" ?
                  this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})
                  :
                  row.value
               }
              </div>
            )
        },{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.messageTab.title"})}
              </b>
            </span>),
            accessor: 'title',
            minWidth: 100
          },{
          Header: () => (<span>
            <b>
               
            </b>
          </span>),
          accessor: 'state',
          minWidth: 50,
          maxWidth: 50,
          Cell: row => (
              <div>
                {row.value === "info" ?
                <span style={{ color: "rgb(44,165,255)" }}>
                  <IoIosInformationCircle size={28}/>
                </span>
               :
                 row.value === "warning" ?
                 <span style={{ color: "rgb(255,159,47)" }}>
                   <IoIosWarning size={28}/>
                 </span>
                 :
                 row.value === "error" ?
                 <span style={{ color: "rgb(250,31,31)" }}>
                   <IoIosAlert size={28}/>
                 </span>
                 :
                 null
               }
              </div>
            )
        },{
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.messageTab.createdAt"})}
            </b>
          </span>),
          accessor: 'createdAt',
          minWidth: 50,
          Cell: row => (
              <div>
                <span>
                {row.value ?
                  changeToDate(row.value, this.props.intl.locale)
                : "-"}
                </span>
              </div>
            )
        },{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.messageTab.sent"})}
              </b>
            </span>),
            minWidth: 50,
            Cell: row => (
                <div>
                    {row.original.messagesLeft == 0 ?
                      <span style={{ color: "rgb(73,213,31)" }}>
                        <IoMdCheckmark size={28}/>
                      </span>
                      :
                      row.original.messagesLeft == row.original.nbOfMessages ?
                      <span style={{ color: "rgb(250,31,31)" }}>
                        <IoMdClose size={28}/>
                      </span>
                      :
                      <span>
                        {(((row.original.nbOfMessages - row.original.messagesLeft)/row.original.nbOfMessages)*100).toFixed(0) + "%"}
                      </span>
                    }
                </div>
              )
          }
        ];
          break;

        default:

      }

      return columnsApp;
    }

    getTheadThProps = (table, row, col) => {
        const sortedCol = find(table.sorted, {id: col.id});
        const boxShadow = sortedCol
          ? `inset 0px ${sortedCol.desc
            ? -2
            : 2}px 0px 0px white`
          : '';
        return {
          style: {
            boxShadow
          },
          onContextMenu: (e) => {
            this.props.contextMenuMessageTab("empty");
          }
        };
      }


      getGroupSelectFormat(){

        var newArray = [];
        const list = this.props.dataGroup;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;
          if (list[i].link) {
            item.link = 1;
          } else {item.link = 0;}

          item.value = list[i].groupId;

          item.label = list[i].name;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }

      getUserSelectFormat(){

        var newArray = [];
        const list = this.props.dataUser;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;

          item.value = list[i].email;

          item.label = list[i].email;

          item.devices = list[i].devices;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }

      getDeviceSelectFormat(){

        var newArray = [];
        const list = this.state.selectedUser.devices;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;

          item.value = list[i].android_id;

          item.label = list[i].model;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }



// ------------------------------- Render -------------------------------------- //

    render() {

        return (<div className="card border-0">
          <div className="card-header">
            <div className="form-inline">

              <input className="form-control mr-sm-2" type="search" value={this.state.researchMessageValue} onChange={this.rechercheMessageHandle.bind(this)}
                placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" style={{height: "35px"}}/>

              <button type="button" className="btn btn-primary btn-sm ml-3" data-toggle="modal" data-target="#modalAddMessage">
                  {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.headTitle"})}
              </button>

            </div>
          </div>

          <div style={{maxHeight: "83vh"}}>

            <MenuProvider id="menu_mmAppMessagesTabContextMenu">

            <ReactTable
              getTheadThProps={this.getTheadThProps}
              getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e) => {
                    this.selectedHandle(rowInfo.index);
                  },
                  onContextMenu: (e) => {
                    this.selectedHandle(rowInfo.index);
                  },
                  style: {
                    background: rowInfo.index === this.state.selected ? '#e4eef4' : null,
                    color: rowInfo.index === this.state.selected ? 'black' : null
                  }
                }
              }else{
                return {}
              }
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {

                  onContextMenu: (e) => {
                      if(rowInfo){
                      this.props.contextMenuMessageTab(rowInfo.original)}
                      else {this.props.contextMenuMessageTab("empty")}
                  }
                }
              }}
              data={researchMessage(this.props.dataMessage , this.state.researchMessageValue, this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})).sort((a, b) => b.createdAt.localeCompare(a.createdAt))}
              showPagination={true}
              noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
              rowsText={this.props.intl.formatMessage({id: "rows"})}
              previousText={this.props.intl.formatMessage({id: "previous"})}
              nextText={this.props.intl.formatMessage({id: "next"})}
              loadingText={this.props.intl.formatMessage({id: "chargement"})}
              pageText= "Page"
              ofText={this.props.intl.formatMessage({id: "sur"})}
              TbodyComponent={CustomTbodyComponent}
              columns={this.createTabColumn("TabApp")}
              resizable={true}
              pageSizeOptions={[10, 20, 25, 50, 100, 200]}
              defaultPageSize= {50}
              showPageSizeOptions={true}
              onPageSizeChange={(pageSize) => {
                this.setState({pageSizeForMMAppMessagesTab: pageSize})
              }}
              pageSize={this.state.pageSizeForMMAppMessagesTab != null ? this.state.pageSizeForMMAppMessagesTab : this.defaultPageSize}
               style={{
                maxHeight: "71vh",
                minHeight: "15vh"
              }} className=" text-center border-0 small -clickable"/>

            </MenuProvider>

            <MMAppMessagesTabContextMenu
              userAdministration={this.props.userAdministration}
              hit={this.props.messageHit}
              refetch={this.props.fetchAll}
              tokenCSRF={this.props.tokenCSRF}/>

              <div className="col text-center border-top small">
                <b className="text-dark">
                  <FormattedMessage id="mmapp.messageTab.nbMessages"/>
                   {this.props.dataMessage.length}</b>
              </div>

          </div>

          <div className="modal fade" id="modalAddMessage" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.headTitle"})}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <div className="row">
                    <div className="col-3">

                      {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.destinataire"})}

                      <Select className="mt-2 mb-3"
                        id="newSelectGroupForMessageType"
                        maxMenuHeight={235}
                        value={this.state.selectedType}
                        onChange={this.handleChangeSelectType}
                        options={[
                                  { value: 0, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"}) },
                                  { value: 1, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.group"}) },
                                  { value: 2, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.user"}) }
                                ]}
                      />

                    </div>

                    {this.state.selectedType && this.state.selectedType.value == 1 ?
                      <div className="col-6">

                        {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.group"})}

                        <Select className="mt-2 mb-3"
                          id="newSelectGroupForMessageGroupe"
                          maxMenuHeight={235}
                          value={this.state.selectedGroup}
                          onChange={this.handleChangeSelectGroup}
                          options={this.getGroupSelectFormat()}
                        />

                      </div>
                      :
                      this.state.selectedType && this.state.selectedType.value == 2 ?
                      <div className="col-6">

                        {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.user"})}

                        <Select className="mt-2 mb-3"
                          id="newSelectGroupForMessageUser"
                          maxMenuHeight={235}
                          value={this.state.selectedUser}
                          onChange={this.handleChangeSelectUser}
                          options={this.getUserSelectFormat()}
                        />
                      </div>
                      :
                      <div className="col-6">
                        <span className="disable_text_highlighting"> </span>
                        <Select className="mt-2 mb-3"
                          value={this.state.selectedUser}
                          isDisabled={true}
                        />
                      </div>

                    }

                    {this.state.selectedUser && this.state.selectedUser.devices.length > 1 ?
                      <div className="col">

                          {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.user.device"})}

                          <Select className="mt-2 mb-3"
                            id="newSelectGroupForMessageDevice"
                            maxMenuHeight={235}
                            value={this.state.selectedDevice}
                            onChange={this.handleChangeSelectDevice}
                            options={this.getDeviceSelectFormat()}
                            isClearable={true}
                          />

                      </div>
                      :
                      null
                    }

                  </div>


                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.title"})}</span>
                    </div>
                    <input type="text" className="form-control" id="inputForMessageTitle" maxLength="100"/>
                  </div>

                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.message"})}</span>
                    </div>
                    <textarea className="form-control" id="inputForMessageText" rows={2} style={{ resize: "none" }} maxLength="250"></textarea>
                  </div>

                 <div className="row mt-3 ml-2 mr-2">
                   <div className="col-auto form-check custom-checkbox mb-2">
                     <input className="custom-control-input" type="checkbox" id="useLink" checked={this.state.useLink} onChange={this.handleChangeCheckboxLink}/>
                     <label className="custom-control-label" htmlFor="useLink">
                       <FormattedMessage id="mmapp.modal.addMessage.useLink"/>
                     </label>
                   </div>

                   { this.state.useLink ?
                     <div className="col input-group input-group-sm">
                       <div className="input-group-prepend">
                         <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.link"})}</span>
                       </div>
                       <input type="text" className="form-control" id="inputForLink" maxLength="100"/>
                     </div>
                     :
                     null

                   }
                 </div>



                  <div className="row mt-2">

                      <div className="col-3">

                        {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.state"})}

                        <Select className="mt-2 mb-3"
                          maxMenuHeight={235}
                          value={this.state.selectedState}
                          onChange={this.handleChangeSelectState}
                          options={[
                                    { value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"}) },
                                    { value: 'info', label: 'Info' },
                                    { value: 'warning', label: 'Warning' },
                                    { value: 'error', label: 'Error' }
                                  ]}
                          components={{ Option: IconOption }}
                        />

                      </div>



                      {/*
                        <div className="col-3">

                        {this.props.intl.formatMessage({id: "mmapp.modal.addMessage.expireDate"})}

                        <Select className="mt-2 mb-3"
                          maxMenuHeight={235}
                          value={this.state.selectedExpire}
                          onChange={this.handleChangeSelectExpire}
                          options={[
                                    { value: 0, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"}) },
                                    { value: 1, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.expireDate.1"}) },
                                    { value: 3, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.expireDate.3"}) }
                                  ]}
                        />

                      </div>
                    */}

                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.createMessage.bind(this)}
                    disabled={this.state.selectedGroup || this.state.selectedUser || this.state.selectedType.value == 0 ? false : true}
                    title={this.state.selectedGroup || this.state.selectedUser || this.state.selectedType.value == 0 ? null
                      : this.props.intl.formatMessage({id: "mmapp.modal.addMessage.noDestinataire"})}><FormattedMessage id={"ajouter"}/></button>
                </div>
              </div>
            </div>
          </div>

        </div>)
    }
  }

MMAppMessagesTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppMessagesTab = injectIntl(MMAppMessagesTab, {withRef: true});

export default MMAppMessagesTab
