import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip'
import { GoDeviceMobile } from "react-icons/go";
import '../custom.css';

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);


  function researchAccounts(array, valeur) {

    var testArray = [];

    if (valeur) {
      for (var i = 0; i < array.length; i++) {
          if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
        || array[i].type.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
            testArray.push(array[i]);
          }
      }
    }
    if(valeur.length > 0){
      return (testArray)
    }

    return (array)
  }


class DevicesDetailsAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      researchAccounts: "",
    };

    this.researchAccountsHandle = this.researchAccountsHandle.bind(this);
  }

    researchAccountsHandle(event) {
      this.setState({researchAccounts: event.target.value});
    }



// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, {id: col.id});
      const boxShadow = sortedCol
        ? `inset 0px ${sortedCol.desc
          ? -2
          : 2}px 0px 0px white`
        : '';
      return {style: {
          boxShadow
        }};
    };

    const columns = [
      {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.1"})}</b>
        </span>),
        accessor: 'name',
        minWidth: 200,
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "userDetails.Journal.tab.1"})}
          </b>
        </span>),
        accessor: 'type',
        minWidth: 160,
      }
    ]

    return (<div>
      <div className="card bg-light">
        <div className="card-header">

          <div className="form-inline">

            <input className="form-control mr-sm-2 row-auto" style={{
                height: "36px"
              }} type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" value={this.state.researchAccounts} onChange={this.researchAccountsHandle}/>

              {this.props.applistFetching ?
                <div className="col d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                : null
              }

          </div>
        </div>

        <ReactTable getTheadThProps={getTheadThProps}
          showPagination={false}
          data={researchAccounts(this.props.data, this.state.researchAccounts).sort((a, b) => a.name.localeCompare(b.name))}
          noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={false}
          pageSize={this.props.data.length}
          style={{
            height: "41vh",
            width: 'auto'
          }} className=" text-center border-0"/>

        <div className="col text-center border-top">
          <b className="text-dark"><FormattedMessage id="terminauxDetails.nbAccount"/> {this.props.data.length}</b>
        </div>
      </div>
    </div>)
  }
}

DevicesDetailsAccounts.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsAccounts = injectIntl(DevicesDetailsAccounts, {withRef: true});

export default DevicesDetailsAccounts
