import React from 'react'
import ComputersTab from './ComputersTab';
import ComputersToolBar from './ComputersToolBar';
import ComputersContextMenu from './ComputersContextMenu';
import {intlShape, injectIntl} from 'react-intl';

import {MdLaptopChromebook} from "react-icons/md";

import {varComputers} from '../variables';

const API = varComputers.api;

var time = 0;
var newRequest = false;
var firstFetchTab = true;

function research(array, value) {

  var testArray = [];

  if (value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        testArray.push(array[i]);
      }
    }
  }

  if (value.length > 0) {
    return (testArray)
  }

  return (array)
}

class ComputersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      array: [],
      showPagination: false,
      isLoading: false,
      error: null,
      selected: null,
      hit: [],
      researchValue: "",
    };

    this.tick = this.tick.bind(this);
    this.researchHandle = this.researchHandle.bind(this);

    this.contextMenu = this.contextMenu.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    if (varComputers.enableTimer === "on")
      this.timer = setInterval(this.tick, 1000);
    if (process.env.NODE_ENV === "development")
      console.log("REFRESH AUTO computers : " + varComputers.enableTimer + " , 1sec, " + varComputers.timer + "sec");
    this.setState({isLoading: true});
    this.fetchData(API);

  }

  componentDidUpdate(time) {
    document.title = this.props.intl.formatMessage({id: "app.menu.4"});

    if (time === varComputers.timer) {
      this.fetchData(API);
    }
  }

  componentWillUnmount() {
    time = 0;
    firstFetchTab = true;
    clearInterval(this.timer);
  }


// ------------------------------- Action handle ------------------------------- //

  researchHandle(event) {
    this.setState({researchValue: event.target.value});
  }

  contextMenu(x){
    if(x === "empty"){
    this.setState({hit : "empty"});
  }else {
    this.setState({hit : x});
    }
  }

  selectedHandle(x){
    this.setState({selected : x});
  }


// ------------------------------- Auto refresh table -------------------------- //

  tick() {
    if (newRequest === true) {
      if (time === varComputers.timer) {
        time = 0;
        newRequest = false;
        this.fetchData(API);
      }
      time = time + 1;
    }
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchData(API) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if (process.env.NODE_ENV === "development")
      console.log("fetch : " + hours + ":" + min + ":" + sec);
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    }).then(data => this.adaptData(data)).catch(error => this.setState({error, isLoading: false}));
  }

  adaptData(tab) {

    var newArray = [];
    var listName = "";

    tab.map((x, index) => {

      if (listName.toLowerCase().indexOf(x.name.toLowerCase()) === -1) {
        var item = new Object();

        item.deviceId = x.deviceId;

        item.state = x.enable;
        item.name = x.name;
        item.usersLength = x.users.length;
        if(x.users){
          item.users = x.users;
        }
        if (x.os) {
          item.os = x.os;
        } else {
          item.os = ""
        };
        if (x.imei) {
          item.ip = x.imei;
        } else {
          item.ip = ""

        };
        listName += x.name;
        newArray.push(item);
      }
    })
    newRequest = true;

    if(newArray.length > 50 && firstFetchTab === true){
      firstFetchTab = false;
      this.setState({showPagination: true})
    }
    this.setState({array: newArray, isLoading: false})
  }


// ------------------------------- Function with visual ------------------------ //

  fetching() {
    if (newRequest === false) {
      return (<div className="col d-flex justify-content-center">
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>)
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Error : {error.message}</span>
        </div>

        <div className="container"/>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    return (<div className="card small bg-light mt-3">
      <div className="card-header">

        <ComputersToolBar researchValue={this.state.researchValue} researchHandle={this.researchHandle} isFetching={this.fetching()}/>

      </div>

      <ComputersTab array={(research(this.state.array, this.state.researchValue)).sort((a, b) => a.name.localeCompare(b.name))}
      rowClickHandle={this.rowClickHandle}
      showPagination={this.state.showPagination}
      contextMenu={this.contextMenu}
      selected={this.state.selected}
      selectedHandle={this.selectedHandle}/>

      <ComputersContextMenu hit={this.state.hit}
      fetchData={this.fetchData.bind(this)}
      adaptData={this.adaptData}
      userAdministration={this.props.userAdministration}
      tokenCSRF={this.props.tokenCSRF}
      />

    </div>)
  }
}
ComputersPage.propTypes = {
  intl: intlShape.isRequired
};
ComputersPage = injectIntl(ComputersPage, {withRef: true});

export default ComputersPage
