import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import { intlShape, injectIntl } from 'react-intl';
import {IoMdPerson} from "react-icons/io";
import { varGroupDetail } from '../variables';

const API = varGroupDetail.apiUsers;

class GroupsUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      value: "",
    };
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

componentDidMount() {
  this.setState({isLoading: true});
  fetch(API + this.props.dataGroupName + "/users" ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Something went wrong ...');
    }
  }).then(data => this.groupFilter(data)).catch(error => this.setState({error, isLoading: false}));
}

groupFilter(data){
  var newTableau = [];

  data.map(x => {
    if(this.getGRP(x.group) == this.props.dataGroupName){
    newTableau.push(x);}
  });

  this.setState({tableau: newTableau, isLoading: false})
}

// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  getGRP(grp) {
    if (grp) {
      return (grp.groupId);
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;


               const getTheadThProps = (table, row, col) => {
             const sortedCol = find(table.sorted, { id: col.id });
             const boxShadow =
               sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
             return {
               style: {
                 boxShadow,
               },
             };
           };

               const columns = [{
                 Header: () => (
                 <span>
                   <b>  </b>
                 </span>
               ),
               accessor: 'deviceId',
               minWidth: 100,
               maxWidth: 140,
               Cell: row => (
                   <div>
                   <span className="ml-2" style={{
                       color: "rgb(44,165,255)"
                     }}>
                     <IoMdPerson size={28}/>
                   </span>
                   </div>
                 )

               },{
                 Header: () => (
                 <span>
                   <b> Nom complet </b>
                 </span>
               ),
                 accessor: 'name',

               },{
                 Header: () => (
                 <span>
                   <b> Login </b>
                 </span>
               ),
                 accessor: 'login',

               }]

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Erreur : {error.message}</span>
        </div>

        <div className="container"></div>
      </div>);
    }

    if (isLoading) {
      return (<div className="d-flex justify-content-center mb-3">
        <div className="spinner-border text-dark mt-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>);
    }


    return (
      <div className="card">
      {
        this.state.tableau ?
        this.state.tableau.length > 0 ?
        <div><ReactTable

        getTheadThProps={getTheadThProps}

        getTdProps={(state, rowInfo, column, instance) => {
      return {
        onClick: (e, handleOriginal) => {
        }
      }
      }}
        showPagination={false}
        data={this.state.tableau}
        columns={columns}
        resizable={false}
        defaultPageSize={this.state.tableau.length}
              style={{
                maxHeight: "65vh",
                width: 'auto'
              }}
              className=" text-center border-0 small"
            /></div>
          : <div><ReactTable

          getTheadThProps={getTheadThProps}

          showPagination={false}
          columns={columns}
          resizable={false}
          defaultPageSize={0}
                style={{
                  maxHeight: "65vh",
                  minHeight: "15vh",
                  width: 'auto'
                }}
                className=" text-center border-0 small"
              /></div>
          : <div/>}
            </div>
    )
  }
}
GroupsUsers.propTypes = {
  intl: intlShape.isRequired
};
GroupsUsers = injectIntl(GroupsUsers,
                        {withRef: true});

export default GroupsUsers
