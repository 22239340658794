import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose,
  IoMdPerson
} from "react-icons/io";

import {varComputers} from '../variables';

const API = varComputers.api;

var contextMenu = {
  "disable": false,
  "delete": true,
  "showUsers": false
}



const iconSize = 22;

class ComputersContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }


  checkData() {

    var hit = this.props.hit;

    if(hit.usersLength > 0){
      contextMenu.showUsers = true;
    }

    if (this.props.userAdministration === "ADMINISTRATOR") {
      contextMenu.delete = true;
    } else {
      contextMenu.delete = false;
    }

  }

  checkIfEmptyData(){
    if (contextMenu.disable || contextMenu.delete || contextMenu.showUsers) {
      return true
    } else {
      return false;
    }
  }


// ------------------------------- Action handle ------------------------------- //

  showModal(type, color, id){
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenuComputers').modal('show')
  }


  actionMenu(id){
    switch (id) {
      case "disable":

            console.log("disable");

        break;

      case "delete":

            fetch(API + this.props.hit.deviceId, {
                method: 'DELETE',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
                body: ""
              })
              .then(response => {
                var x = response;
                if(response.status !== 200){
                 return response.json().then(responseError => {
                   console.log(responseError);
                   this.requeteResponse(x,responseError);
                 })
               }
             }).then(this.props.fetchData(API))


        break;

      default:
    }
  }


// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "disable":
          return (<Item onClick={this.showModal.bind(this, name, "info", "disable")}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "delete":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "delete")}>
            <span style={{
                color: "rgb(230,41,41)"
              }}>
              <IoMdClose size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;


        default:
          break;
      }
    }
  }

  shouldSeparator(state) {
    if (state === true)
      return (<Separator/>)
  }


// ------------------------------- Render -------------------------------------- //

  render() {

        {
          this.checkData()
        }

        if (this.props.hit != "empty" && this.checkIfEmptyData())
      return (<div>

        <Menu id='menu_computersAction' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
          }}>
          {this.testMenu("disable", contextMenu.disable, this.props.intl.formatMessage({id: "désactiver"}))}
          {this.testMenu("delete", contextMenu.delete, this.props.intl.formatMessage({id: "supprimer"}))}

          {this.shouldSeparator(this.props.hit.usersLength > 0)}

          {this.props.hit.users ?
              (() => {
                const options = [];
                const array = this.props.hit.users.sort((a, b) => a.login.localeCompare(b.login))

                for (let i = 0; i < array.length; i++) {
                  options.push(<Item
                    key={i}><span style={{ color: "rgb(70,156,212)"}}><IoMdPerson size={iconSize}/>
                  </span>   {array[i].login}</Item>);
                }

                return options;
              })()
              : null
              }


        </Menu>


        <div className="modal fade" id="modalConfirmActionContextMenuComputers" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`} style={{zIndex: '4000'}}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>)
      return (<div/>)
  }
}

ComputersContextMenu.propTypes = {
  intl: intlShape.isRequired
};
ComputersContextMenu = injectIntl(ComputersContextMenu, {withRef: true});

export default ComputersContextMenu
