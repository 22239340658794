import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline} from "react-icons/io";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class ParametresReferenceApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formatPJ: [
        "jpg", "xls", "jpeg"
      ],
      ExtPJ: ""
    };
    this.addFormatPJ = this.addFormatPJ.bind(this);
    this.handleExtPJ = this.handleExtPJ.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.addFormatPJ()
    }
  };

  handleExtPJ(event) {
    this.setState({ExtPJ: event.target.value});
  }

  addFormatPJ() {
    const array = this.state.formatPJ;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === (this.state.ExtPJ)) {
        return
      }
    }
    if (this.state.ExtPJ) {
      array.push(this.state.ExtPJ)
    }
    this.setState({formatPJ: array});
    this.setState({ExtPJ: ""});
  }

  removeFormatPJ(index) {
    const array = this.state.formatPJ;
    var newArray = [];

    for (var i = 0, j = 0; i < array.length; i++) {
      if (i !== index) {
        newArray[j++] = array[i]
      }
    }
    this.setState({formatPJ: newArray});
  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.RefApp.1"/></b>
      </h5>
      <hr/>

      <div className="col-9 container">

        <h6 className="text-info mb-2">
          <b><FormattedMessage id="parametres.RefApp.1"/></b>
        </h6>

        <div className="card border-0 mb-4">

          <div className="card-header border rounded-top" style={{
              background: 'rgb(251,251,251)',
              "height":"57px"
            }}>
            <div className="row">
              <div className="col">

                <FormattedMessage id="parametres.RefApp.2"/>
              </div>

              <div className="text-primary col-3 float-right">
                <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAddRefApp" title={this.props.intl.formatMessage({id: "parametres.notifications.2"})}>
                  <span style={{
                      color: "rgb(255,255,255)"
                    }}>
                    <FaPlus size={14}/>
                  </span>
                </button>

              </div>

            </div>
          </div>


          <div className="rounded-0"  style={{height : "42vh"}}>
            <PerfectScrollbar>
            <div className="list-group">
            {
              this.props.dataReferenceApplications.sort((a, b) => a.name.localeCompare(b.name)).map((ListFormat, index) => (<div key={index} className="list-group-item text-center">
                {ListFormat.name + " / " + ListFormat.version}
                <button style={{
                    outline: 'none'
                  }} className="close" data-toggle="modal" data-target="#modalConfirmSuprRefApp" onClick={this.modalConfirm.bind(this, ListFormat.name + " / " + ListFormat.version, index)}>
                  <span style={{
                      color: "rgb(110,110,110)"
                    }}>
                    <IoIosCloseCircleOutline size={28}/>
                  </span>
                </button>
              </div>))
            }
          </div>
          </PerfectScrollbar>
        </div>

        </div>

      </div>


      <div className="modal fade" id="modalAddRefApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddRefApp">
                Ajouter une application
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Name</span>
                  </div>
                  <input type="text" className="form-control" aria-label="Name" value={this.props.refAppName} onChange={e => this.props.handleChange(e, "refAppName")} aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">PackageName</span>
                  </div>
                  <input type="text" className="form-control" aria-label="Version" value={this.props.refAppPackageName} onChange={e => this.props.handleChange(e, "refAppPackageName")} aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Version</span>
                  </div>
                  <input type="text" className="form-control" aria-label="Version" value={this.props.refAppVersion} onChange={e => this.props.handleChange(e, "refAppVersion")} aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">VersionCode</span>
                  </div>
                  <input type="text" className="form-control" aria-label="Version" value={this.props.refAppVersionCode} onChange={e => this.props.handleChange(e, "refAppVersionCode")} aria-describedby="inputGroup-sizing-default"/>
                </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-info`} data-dismiss="modal" onClick={this.props.addReferenceApplication.bind(this)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprRefApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                Supprimer
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                Confirmer la suppression de "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.props.removeReferenceApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }

}

ParametresReferenceApplications.propTypes = {
  intl: intlShape.isRequired
};
ParametresReferenceApplications = injectIntl(ParametresReferenceApplications, {withRef: true});

export default ParametresReferenceApplications
