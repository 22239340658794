import React from 'react'
import {FormattedMessage} from "react-intl";

const ConfigDescriptionReseau = () => (<div className="col-6 mt-2 mb-2 container">

  <h6 className="text-info">
    <b><FormattedMessage id="ConfigurationServer.descriptionReseau"/></b>
  </h6>

  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text"><FormattedMessage id="ConfigurationServer.descriptionReseau.1"/></span>
      </div>
      <input className="form-control" placeholder="Mobile-Process"/>
    </div>
    <div className="input-group mt-2">
      <div className="input-group-prepend">
        <span className="input-group-text"><FormattedMessage id="ConfigurationServer.descriptionReseau.2"/></span>
      </div>
      <input className="form-control" placeholder="192.168.30.0"/>
    </div>
    <div className="input-group mt-2">
      <div className="input-group-prepend">
        <span className="input-group-text"><FormattedMessage id="ConfigurationServer.descriptionReseau.3"/></span>
      </div>
      <input className="form-control" placeholder="255.255.255.0"/>
    </div>
    <div className="input-group mt-2 mb-2">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1"><FormattedMessage id="ConfigurationServer.descriptionReseau.4"/></span>
      </div>
      <input type="text" className="form-control" placeholder="mobile-process.com" aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
  </div>
</div>);

export default ConfigDescriptionReseau
