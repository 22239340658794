import React from 'react'
import {FormattedMessage} from "react-intl";

import {varConfigServeur} from '../variables';

const API = varConfigServeur.apiLdap;

class ConfigServeurLDAP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      ldapChecked: false,
      configLDAP: {},
      error: null
    };

    this.handleChange = this.handleChange.bind(this);

  }


// ------------------------- Action handle ------------------------------------- //

  handleChange = (event, field) => {
    switch (field) {
      case "ldapSSL":
          this.setState({[field]: !this.props.dataLDAP.ldapSSL});
          this.props.handleChildModification(!this.props.dataLDAP.ldapSSL, "dataLDAP", field)
        break;
      case "ldapEnabled":
          this.setState({[field]: !this.props.dataLDAP.ldapEnabled});
          this.props.handleChildModification(!this.props.dataLDAP.ldapEnabled, "dataLDAP", field)
        break;

      default:
        this.setState({[field]: event.target.value});
        this.props.handleChildModification(event.target.value, "dataLDAP", field)

    }
  }


// ------------------------------- Function with visual ------------------------ //

  ldapLIST(array, i) {
    if (array){

      return (<div className="col">
        <select id="inputState" className="form-control mr-4" value={i} onChange={e => this.handleChange(e, "ldapHost")}>
          {
            (Object.keys(array).map((key, index) => {
              return (<option key={index} className="list-group-item small" value={key}>{array[key]}</option>)
            }))
          }
        </select>
      </div>)}
  }

  useLDAP(isChecked, configLDAP) {
    if (isChecked === true)
      return (<div className="">

        <h6 className="text-info">
          <b><FormattedMessage id="ConfigurationServer.serveurLDAP.2"/></b>
        </h6>

        <div className="row mb-1 mt-2">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.3"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.props.dataLDAP.ldapDomain} onChange={e => this.handleChange(e, "ldapDomain")}/>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.4"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.props.dataLDAP.ldapHost} onChange={e => this.handleChange(e, "ldapHost")}/>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.5"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.props.dataLDAP.ldapPort} onChange={e => this.handleChange(e, "ldapPort")}/>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.6"/>
          </div>
          <div className="col">
            <select id="inputState" className="form-control mr-4" value={this.props.dataLDAP.ldapServerType} onChange={e => this.handleChange(e, "ldapServerType")}>
              {
                (Object.keys(this.props.dataLDAP.availableLdapServerType).map((key, index) => {
                  return (<option key={index} className="list-group-item small" value={key}>{this.props.dataLDAP.availableLdapServerType[key]}</option>)
                }))
              }
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.7"/>
          </div>
          <div className="col">
            <select id="inputState" className="form-control mr-4" value={this.props.dataLDAP.ldapAuthType} onChange={e => this.handleChange(e, "ldapAuthType")}>
              {
                (Object.keys(this.props.dataLDAP.availableLdapAuthType).map((key, index) => {
                  return (<option key={index} className="list-group-item small" value={key}>{this.props.dataLDAP.availableLdapAuthType[key]}</option>)
                }))
              }
            </select>
          </div>
        </div>

        <h6 className="text-info">
          <b><FormattedMessage id="ConfigurationServer.serveurLDAP.8"/></b>
        </h6>
        <div className="row mb-1 mt-2">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.9"/>
          </div>
          <div className="col">
            <input className="form-control" value={this.props.dataLDAP.ldapLogin} onChange={e => this.handleChange(e, "ldapLogin")}/>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.10"/>
          </div>
          <div className="col">
            <input type="password" className="form-control border" value={this.props.dataLDAP.ldapPassword} onChange={e => this.handleChange(e, "ldapPassword")} aria-label="password" aria-describedby="basic-addon"/>
          </div>
        </div>

        <h6 className="text-info">
          <b><FormattedMessage id="ConfigurationServer.serveurLDAP.11"/></b>
        </h6>
        <div className="custom-control custom-checkbox mb-2 mt-1">
          <input className="custom-control-input" type="checkbox" value="" id="SSLcheck" onChange={e => this.handleChange(e, "ldapSSL")} checked={this.props.dataLDAP.ldapSSL}/>

          <label className="custom-control-label" htmlFor="SSLcheck">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.12"/>
          </label>
        </div>
        {/*
        <div className="row mb-1">
          <div className="col">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.13"/>
          </div>
          <div className="col">
            <select id="inputState" className="form-control mr-4" value={this.props.dataLDAP.ldapSaslType} onChange={e => this.handleChange(e, "ldapSaslType")}>
              {
                (Object.keys(this.props.dataLDAP.availableLdapSaslType).map((key, index) => {
                  return (<option key={index} className="list-group-item small" value={key}>{this.props.dataLDAP.availableLdapSaslType[key]}</option>)
                }))
              }
            </select>
          </div>
        </div>
        */}
        <hr/>
        <div className="col mb-1">
          <button type="button" className="btn btn-danger mr-1" onClick={this.props.handleLDAPTestButton}>Test</button>
          <button className="btn btn-warning" data-toggle="modal" data-target="#TestUserModal">Test utilisateur</button>
        </div>



      </div>)
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p>Erreur : {error.message}</p>
        </div>
      </div>);
    }

    if (isLoading) {
      return (<div className="text-center mt-5 mb-5">
        <div className="spinner-border text-success" role="status"/>
      </div>);
    }

    if (this.props.dataLDAP) {
      return (<div className="col-auto mt-2 mb-2">
        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="LDAPcheck" onChange={e => this.handleChange(e, "ldapEnabled")} checked={this.props.dataLDAP.ldapEnabled}/>
          <label className="custom-control-label" htmlFor="LDAPcheck">
            <FormattedMessage id="ConfigurationServer.serveurLDAP.1"/>
          </label>
        </div>
        {this.useLDAP(this.props.dataLDAP.ldapEnabled, this.props.dataLDAP)}
      </div>)
    }
  }
}
export default ConfigServeurLDAP
