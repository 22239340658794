import React, { Component } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {varGroupDetail} from '../variables';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'

const mapboxToken = varGroupDetail.mapboxToken;
mapboxgl.accessToken = mapboxToken;

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [6.2314, 49.1184]
      },
      properties: {
        title: 'Mapbox',
        description: 'Washington, D.C.'
      }
    }
  ]
};

var currMap;


  function metersToPixelsAtMaxZoom(meters, latitude){
    var result = meters / 0.075 / Math.cos(latitude * Math.PI / 180);
      return (
        result
      )
    }


class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 2,
      lat: 47,
      zoom: 5,
      map: null
    };
    this.mapContainer = React.createRef();
  }

  createMap(){

  }

  adaptGeofencingZoneForMapData(data){

    var list = [];

    data.map((x, index) => {

      var item = new Object();
      var properties = new Object();
      var geometry = new Object();


      item.type = "Feature";
      properties.description = '<strong style="color:' + x.color + '";>' + x.name + '</strong><p>' + x.description + '</p>';
      properties.color = x.color;
      properties.radius = x.radius;
      properties.metersToPixelsAtMaxZoom = metersToPixelsAtMaxZoom(x.radius, x.coordinates[1])
      geometry.type = 'Point';
      geometry.coordinates = x.coordinates;

      item.properties = properties;
      item.geometry = geometry;

      list.push(item);
    })

    return list;
  }

  initMap(){

      var defaultLng = 2;
      var defaultLat = 47;
      var defaultZoom = 5;

      if (this.props.dataGroupGeofencingZone[0] != null) {
        defaultLng = this.props.dataGroupGeofencingZone[0].coordinates[0];
        defaultLat = this.props.dataGroupGeofencingZone[0].coordinates[1];
        defaultZoom = 15 - this.props.dataGroupGeofencingZone[0].radius/200;
      }

        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
        container: this.mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [defaultLng, defaultLat],
        zoom: defaultZoom,
        interactive: true
      });

      currMap = map;

      const mapData = this.adaptGeofencingZoneForMapData(this.props.dataGroupGeofencingZone);

      var newData = {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': mapData
        }
      }

      currMap.on('load', function() {
        currMap.addSource("dataGroupGeofencingZones", newData)
        currMap.addLayer({'id': 'dataGroupGeofencingZones',
        'type': 'circle',
        'source': 'dataGroupGeofencingZones',
        'paint': {
          'circle-color': ['get', 'color'],
          "circle-radius": ["interpolate",["exponential", 2],["zoom"],0, 10,   20, ['get', 'metersToPixelsAtMaxZoom']],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff',
          'circle-opacity': 0.5
          }
        });

      });


      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      currMap.on('mouseenter', 'dataGroupGeofencingZones', (e) => {
        currMap.getCanvas().style.cursor = 'pointer';

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.description;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(currMap);
      });


      // Center the map on the coordinates of any clicked circle from the 'circle' layer.
      currMap.on('click', 'dataGroupGeofencingZones', (e) => {
        currMap.flyTo({
          center: e.features[0].geometry.coordinates
        });
      });

      currMap.on('contextmenu', (e) => {
        this.props.showModalNewGeonfencingZone(e.lngLat);
      });


      currMap.on('mouseleave', 'dataGroupGeofencingZones', () => {
        currMap.getCanvas().style.cursor = '';
        popup.remove();
      });


      currMap.on('move', () => {
        this.setState({
            lng: currMap.getCenter().lng.toFixed(4),
            lat: currMap.getCenter().lat.toFixed(4),
            zoom: currMap.getZoom().toFixed(2)
          });
        });

        currMap.dragRotate.disable();
        currMap.touchZoomRotate.disableRotation();

        this.setState({map: currMap, lng: defaultLng, lat: defaultLat, zoom: defaultZoom});

        currMap.current?.once('render',function(){
          currMap.current?.resize()
        });

  }

  componentDidMount() {
    this.initMap()
  }

  componentDidUpdate(nextProps, nextState){
    if (this.props.dataGroupGeofencingZone !== nextProps.dataGroupGeofencingZone) {
      if (currMap != null && currMap.getSource('dataGroupGeofencingZones') != undefined) {
        const mapData = this.adaptGeofencingZoneForMapData(this.props.dataGroupGeofencingZone);
        var newData = {
            'type': 'FeatureCollection',
            'features': mapData
          }
        currMap.getSource('dataGroupGeofencingZones').setData(newData);
      }
    }
    currMap.resize()
  }

  render() {
      const { lng, lat, zoom } = this.state;
      return (
        <div>
          <div className="sidebar">
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
          <div ref={this.mapContainer} className="map-container"/>

        </div>
      );
    }
  }


MapComponent.propTypes = {
  intl: intlShape.isRequired
};
MapComponent = injectIntl(MapComponent, {withRef: true});

export default MapComponent
