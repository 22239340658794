import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import classNames from "classnames";
import { MenuProvider } from 'react-contexify';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {withRouter} from 'react-router-dom'
import {MdLens} from "react-icons/md";
import {FaUser, FaUserLock, FaDatabase} from "react-icons/fa";

const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

function changeToDate(int, langue){
  var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
  return (ds)
}


class UsersTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        pageSizeForUsersTab: localStorage.getItem('pageSizeForUsersTab'),
    };

    this.rowClickHandle = this.rowClickHandle.bind(this)
  }

    componentWillUnmount(){
      if (this.state.pageSizeForUsersTab) {
        localStorage.setItem('pageSizeForUsersTab', this.state.pageSizeForUsersTab);
      }
    }


// ------------------------------- Action handle ------------------------------- //

  rowClickHandle(x){
    this.props.history.push("/users/" + x);
  }

// ------------------------------- Render -------------------------------------- //

  render()

  {


// ------------------------------- Setting of the table ------------------------ //

    const getTheadThProps = (table, row, col) => {
  const sortedCol = find(table.sorted, { id: col.id });
  const boxShadow =
    sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
  return {
    style: {
      boxShadow,
    },
  };
};

    const columns = [{

      Header: () => (
      <span>
        <b>  </b>
      </span>
    ),
      accessor: 'enable',
      minWidth: 60,
      maxWidth: 60,
      Cell: row => (
          <div>
            <span style={{
                color: row.original.enable === true ? "rgb(51,154,232)"
                  : "rgb(190,190,190)"
              }}>
              {row.original.enable === true ?
                row.original.ldap === true ?
                <FaDatabase size={24}/>
                :<FaUser size={24}/>
                :<FaUserLock size={28}/>
              }
            </span>
          </div>
        )

    },{
      Header: () => (<span><b> Login </b></span>
    ),
    accessor: 'login',
    minWidth: 210,

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.nomcomplet"})} </b>
      </span>
    ),
      accessor: 'name',
      minWidth: 160,


    },{Header: () => (
    <span>
      <b> {this.props.intl.formatMessage({id: "users.nbterminaux"})} </b>
    </span>
  ),
      accessor: 'nbDevices',
      minWidth: 160,

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.groupe"})} </b>
      </span>
    ),
      accessor: 'group',
      minWidth: 180,


    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.service"})} </b>
      </span>
    ),
      accessor: 'service',
      minWidth: 90,


    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.fonction"})} </b>
      </span>
    ),
      accessor: 'fonction',
      minWidth: 90,


    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.datecreation"})} </b>
      </span>
    ),
      accessor: 'sortDate',
      minWidth: 180,
      Cell: row => (
          <div>
            <span>
            {row.value ?
              changeToDate(row.value, this.props.langue)
            : "-"}
            </span>
          </div>
        )

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.datesession"})} </b>
      </span>
    ),
      accessor: 'sortLastSessionDate',
      minWidth: 180,
      Cell: row => (
          <div>
            <span>
            {row.value ?
              changeToDate(row.value, this.props.langue)
            : "-"}
            </span>
          </div>
        )


    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.erreurs"})} </b>
      </span>
    ),
      accessor: 'errors',
      minWidth: 60,

      Cell: row => (
          <div>
            <span style={{
                  color: row.value === true ? "rgba(224,0,3,0.92)"
                    : "rgb(36,232,45)"
                }}>
                <MdLens size={28}/>
              </span>
          </div>
        )


    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "users.sessionactive"})} </b>
      </span>
    ),
      accessor: 'active',
      minWidth: 89,

      Cell: row => (
          <div>
            <span style={{
                  color: row.value === true ? "rgb(36,232,45)"
                    : "rgba(224,0,3,0.92)"
                }}>
                <MdLens size={28}/>
              </span>
          </div>
        )

    }]

    return (
      <div>

    <MenuProvider id="menu_usersContextMenu" >
      <ReactTable

        oncontextmenu="return false;"
        getTheadThProps={getTheadThProps}

        getTdProps={(state, rowInfo, column, instance) => {
    return {

      onContextMenu: (e) => {
          if(rowInfo){
          this.props.contextMenu(rowInfo.original)}
          else {this.props.contextMenu("empty")}
      }
    }
  }}


    getTrProps={(state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        onContextMenu: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        style: {
          background: rowInfo.original.enable === false ? "rgb(241,241,241)"
            : rowInfo.index === this.props.selected ? '#e4eef4' : null,
          color: rowInfo.index === this.props.selected ? 'black' : null
        }
      }
    }else{
      return {}
    }
  }}

        showPagination={this.props.showPagination}
        data={this.props.tableau}
        noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
        rowsText={this.props.intl.formatMessage({id: "rows"})}
        previousText={this.props.intl.formatMessage({id: "previous"})}
        nextText={this.props.intl.formatMessage({id: "next"})}
        loadingText={this.props.intl.formatMessage({id: "chargement"})}
        pageText= "Page"
        ofText={this.props.intl.formatMessage({id: "sur"})}
        TbodyComponent={CustomTbodyComponent}
        columns={columns}
        resizable={true}
        pageSizeOptions= {[10, 20, 25, 50, 100]}
        defaultPageSize= {50}
        showPageSizeOptions={true}
        onPageSizeChange={(pageSize) => {
          this.setState({pageSizeForUsersTab: pageSize})
        }}
        pageSize={!this.props.showPagination ? this.props.tableau.length : this.state.pageSizeForUsersTab != null ? this.state.pageSizeForUsersTab : this.defaultPageSize}
              style={{
                minHeight: "15vh",
                maxHeight: "75vh",
                width: '95vw'
              }}
              className=" text-center border-0 small -clickable"
            />
          </MenuProvider>


            <div className="col text-center border-top small">
              <b className="text-dark"><FormattedMessage id="terminaux.nbusers"/> {this.props.tableau.length}</b>
            </div>

            </div>
    )
  }
}

UsersTab.propTypes = {
  intl: intlShape.isRequired
};
UsersTab = injectIntl(UsersTab,
                        {withRef: true});
export default withRouter (UsersTab)
