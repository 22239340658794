import React from 'react'
import $ from "jquery";
import {FormattedMessage} from "react-intl";
import {FaPlus} from "react-icons/fa";

import {varConfigServeur} from '../variables';

class ServeurFichiers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileData: {name:"",host:"",defaultPath:"",allowAutoLogin:""},
      newServeurName: "",
      newHost: "",
      newDefaultPath: "",
      newAllowAutoLogin: "",
      dataServFile: this.props.dataServFile,
      serv: ""
    };

    this.selectFile = this.selectFile.bind(this);

    this.addServeurFile = this.addServeurFile.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectedInputChange = this.handleSelectedInputChange.bind(this);
  }

// ------------------------- Action handle ------------------------------------- //


  selectFile(name, index) {
    this.setState({selectedFileData: this.props.dataServFile[index],selectedFile: name, selectedFileIndex: index});
  }

  handleInputChange(e, field){
    if (field === "newAllowAutoLogin") {
      this.setState({newAllowAutoLogin: !this.state.newAllowAutoLogin})
    }else {
      this.setState({[field]: e.target.value})
    }
  }

  handleSelectedInputChange(e, field){
    if (field === "allowAutoLogin") {
      this.setState({
        selectedFileData: { ...this.state.selectedFileData, [field]: !this.state.selectedFileData.allowAutoLogin }
      });
    } else {
      this.setState({
        selectedFileData: { ...this.state.selectedFileData, [field]: e.target.value }
      });
    }
  }


  addServeurFile() {


                  var data = {
                    "name": this.state.newServeurName,
                    "host": this.state.newHost,
                    "defaultPath": this.state.newDefaultPath,
                    "allowAutoLogin": this.state.newAllowAutoLogin,
                    };

                fetch(varConfigServeur.apiServFile, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': this.props.tokenCSRF
                  },
                    body: JSON.stringify(data)
                  })
                  .then(response => {
                    var x = response;
                    if(response.status === 200){
                      return response.json().then(responseRetour => {
                        this.addNewServeurFile(responseRetour);
                      })
                    }
                    else{
                      console.log(response.headers);
                     return response.json().then(responseError => {
                       console.log(responseError);
                       this.requeteResponse(x,responseError);
                     })
                   }
                   })

  }

  addNewServeurFile(data){
      const array = this.state.dataServFile;

    	var item = new Object();
      item.id = data.id;
      item.name = data.name;
      item.host = data.host;
      item.defaultPath = data.defaultPath;
      item.allowAutoLogin = data.allowAutoLogin;

      array.push(item);

      this.setState({dataServFile: array});
      this.setState({newServeurName: "", newHost: "", newDefaultPath: "", newAllowAutoLogin: false});
  }

  sendSelectedModif(data, index){

      fetch(varConfigServeur.apiServFile + data.id, {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(data)
        })
        .then(response => {
          var x = response;
          if(response.status !== 200){
           return response.json().then(responseError => {
             console.log(responseError);
           })
         }
       })

       const array = this.state.dataServFile;
       array[index] = data;
       this.setState({dataServFile: array});
  }

  removeServeurFile(index, id){

        fetch(varConfigServeur.apiServFile + id, {
            method: 'DELETE',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: ""
          })
          .then(response => {
            var x = response;
            if(response.status !== 200){
             return response.json().then(responseError => {
               console.log(responseError);
             })
           }
         })

         const array = this.state.dataServFile;
         var newArray = [];

         for (var i = 0, j = 0; i < array.length; i++) {
           if (i !== index) {
             newArray[j++] = array[i]
           }
         }
         this.setState({dataServFile: newArray});
  }


  confirmDelete(x, index, id) {
    this.setState({modalTxt: x, modalIndex: index, deleteId: id})
  }


// ------------------------- Render -------------------------------------------- //

  render() {

    return (<div className="col-5 mt-2 mb-3 text-center container" id="messagerie" role="tabpanel" aria-labelledby="home-tab">

      <div className="list-group text-center">
        <h5 className="text-info mt-2">
          <b><FormattedMessage id="ConfigurationServer.serveurFile"/>
          </b>
        </h5>

        {
          this.state.dataServFile.map((servName, index) => (<div key={index} className="row">
            <a href="#" className="list-group-item list-group-item-action col rounded-0" data-toggle="modal" data-target=".servFichiers-modal-lg" onClick={this.selectFile.bind(this, servName.name, index)}>
              {servName.name}
            </a>
            <button className="btn btn-secondary rounded-0" data-toggle="modal" data-target="#modalSuprServFile" onClick={this.confirmDelete.bind(this, servName.name, index, servName.id)}>
              <span>&times;</span>
            </button>
          </div>))
        }

      </div>
      <div className="col mt-2">
        <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutServFile">
          <div style={{
              color: "rgb(255,255,255)"
            }}>
            <FaPlus size={14}/>

          </div>
        </button>
      </div>

      <div className="modal fade servFichiers-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body ">

              <h6 className="text-primary text-center"><FormattedMessage id="ConfigurationServer.serveurFile.modal.1"/> {this.state.selectedFile}</h6>

              <h5 className="text-primary text-center">
                <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.2"/></b>
              </h5>

              <h6 className="text-info ">
                <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.2"/></b>
              </h6>

              <div className="col mt-2 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.3"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "name")} value={this.state.selectedFileData.name}/>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.4"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "host")} value={this.state.selectedFileData.host}/>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.5"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "defaultPath")} value={this.state.selectedFileData.defaultPath}/>
                  </div>
                </div>

                <div className="custom-control custom-checkbox mt-1 mb-3">
                  <input className="custom-control-input" type="checkbox" onChange={e => this.handleSelectedInputChange(e, "allowAutoLogin")} checked={this.state.selectedFileData.allowAutoLogin} id="enableAutoConnec"/>
                  <label className="custom-control-label" htmlFor="enableAutoConnec">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.6"/>
                  </label>
                </div>

              </div>

              <h6 className="text-info mt-2 mb-2">
                <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.7"/></b>
              </h6>
              <div className="col">

                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.8"/>
                  </div>
                  <div className="col-9">
                    <input className="form-control"/>
                  </div>
                </div>
                <div className="row mb-1 mt-2">
                  <div className="col-auto">
                    <FormattedMessage id="ConfigurationServer.serveurFile.modal.9"/>
                  </div>

                  <div className="col">
                    <button className="btn btn-secondary float-right"><FormattedMessage id="ConfigurationServer.serveurFile.modal.10"/></button>
                  </div>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.sendSelectedModif.bind(this, this.state.selectedFileData, this.state.selectedFileIndex)}><FormattedMessage id="valider"/></button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modalAjoutServFile" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="ConfigurationServer.serveurFile.modal.2.1"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">



                <h6 className="text-primary text-center"><FormattedMessage id="ConfigurationServer.serveurFile.modal.1"/> {this.state.selectedFile}</h6>

                <h5 className="text-primary text-center">
                  <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.2"/></b>
                </h5>

                <h6 className="text-info ">
                  <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.2"/></b>
                </h6>

                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurFile.modal.3"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newServeurName")} value={this.state.newServeurName}/>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurFile.modal.4"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newHost")} value={this.state.newHost}/>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurFile.modal.5"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newDefaultPath")} value={this.state.newDefaultPath}/>
                    </div>
                  </div>

                  <div className="custom-control custom-checkbox mt-1 mb-3">
                    <input className="custom-control-input" type="checkbox" onChange={e => this.handleInputChange(e, "newAllowAutoLogin")} checked={this.state.newAllowAutoLogin} id="enableNewAutoConnec"/>
                    <label className="custom-control-label" htmlFor="enableNewAutoConnec">
                      <FormattedMessage id="ConfigurationServer.serveurFile.modal.6"/>
                    </label>
                  </div>

                </div>

                <h6 className="text-info mt-2 mb-2">
                  <b><FormattedMessage id="ConfigurationServer.serveurFile.modal.7"/></b>
                </h6>
                <div className="col">

                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serveurFile.modal.8"/>
                    </div>
                    <div className="col-9">
                      <input className="form-control"/>
                    </div>
                  </div>
                </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addServeurFile} disabled={!(this.state.newServeurName && this.state.newHost)}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={"modalSuprServFile"} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="confirmDelete"/> : "<b className="text-info">{this.state.modalTxt}</b>" ?</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.removeServeurFile.bind(this, this.state.modalIndex, this.state.deleteId)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}
export default ServeurFichiers
