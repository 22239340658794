import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom'
import {GoPlus} from "react-icons/go";

class GroupsToolBar extends React.Component {


  render() {
    return (
      <div className="form-inline" style={{
          minWidth: "700px"
        }}>
          <input className="form-control mr-sm-2"
          style={{height: "35px"}}
          type="search"
          placeholder={this.props.intl.formatMessage({id: "recherche"})}
          aria-label="Search" value={this.props.researchValue}
          onChange={this.props.researchHandle}
          />

          { this.props.userAdministration === "ADMINISTRATOR" ?
          <NavLink className="btn btn-sm btn-info mr-2" title={this.props.intl.formatMessage({id: "groups.addGroups"})} to="/Groups/NewGroup">
            <span style={{
                color: "rgb(255,255,255)"
              }}>
              <GoPlus size={20}/>
            </span>
          </NavLink>

          : null
          }

          {this.props.isFetching}

          { this.props.userAdministration === "ADMINISTRATOR" ?
          <div className="col">
            <button type="button"
            className="btn btn-sm btn-info float-right"
            data-toggle="modal"
            data-target="#RestrictionModal"
            onClick={this.props.modalChange.bind(this,"restriction")}>
            Restriction
            </button>


            <button type="button"
            className="btn btn-sm btn-info float-right mr-2"
            data-toggle="modal"
            data-target="#RestrictionModal"
            onClick={this.props.modalChange.bind(this,"general")}>
            <FormattedMessage id="groupsDetails.PolitiqueSecu.btn"/>
            </button>
          </div>

          : null
        }


      </div>
    )
  }
}

GroupsToolBar.propTypes = {
  intl: intlShape.isRequired
};
GroupsToolBar = injectIntl(GroupsToolBar,
                        {withRef: true});

export default GroupsToolBar
