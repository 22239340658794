import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import TablePlus from '../Components/TablePlus';

import {IoIosCloseCircleOutline} from "react-icons/io";
import {FaPlus} from "react-icons/fa";

class ConfigServeurRelais extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        value: ""
    };

    this.addressIPChange = this.addressIPChange.bind(this);
    this.addIP = this.addIP.bind(this);
    this.removeIP = this.removeIP.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

    addressIPChange(event) {
      this.setState({value: event.target.value});
    }

    modalConfirm(name, index) {
      this.setState({modalTXT: name, modalIndex: index})
    }

    addIP() {
      const tableau = this.props.dataRelais;

      for (var i = 0; i < tableau.length; i++) {
        if (tableau[i] === (this.state.value)) {
          this.setState({value: ""});
          return
        }
      }
      if (this.state.value) {
        tableau.push(this.state.value)
      }
      this.setState({tabIP: tableau});
      this.setState({value: ""});
      this.props.handleChildModification(tableau, "dataRelais")
    }

    removeIP(index) {
      console.log(index);
      const tab = this.props.dataRelais;
      var newTab = [];

      for (var i = 0, j = 0; i < tab.length; i++) {
        if (i !== index) {
          newTab[j++] = tab[i]
        }
      }
      this.setState({tabIP: newTab});
      this.props.handleChildModification(newTab, "dataRelais")
    }


// ------------------------- Render -------------------------------------------- //

  render() {
    return (

      <div className="col-6 mt-4 container">

        <TablePlus
          title={this.props.intl.formatMessage({id: "ConfigurationServer.menu.4"})}
          data={this.props.dataRelais.sort((a, b) => a.localeCompare(b))}
          modalAddId="modalAjoutRelais"
          deleteHandler={this.modalConfirm.bind(this)}
          modalRemoveId="modalConfirmDeleteRelais"
          userAdministration={this.props.userAdministration}
          maxHeight="280px"
        />

      {/*<div className="card rounded-0 mb-5">

        <div className="card-header" style={{
            background: 'rgb(251,251,251)'
          }}>
          <div className="row">
            <div className="col">
              <h6 className="text-info"><b><FormattedMessage id="ConfigurationServer.menu.4"/> : </b></h6>
            </div>

            <div className="text-primary col-2 float-right">
              <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutRelais">
                <span style={{
                    color: "rgb(255,255,255)"
                  }}>
                  <FaPlus size={14}/>
                </span>
              </button>

            </div>

          </div>
        </div>


        <PerfectScrollbar>
        <ul className="list-group list-group-flush" style={{maxHeight: "280px"}}>
            {
              this.props.dataRelais.sort((a, b) => a.localeCompare(b)).map((ListFormat, index) => (
                <li key={index} className="list-group-item text-center rounded-0 py-2">
                  <div>
                {ListFormat}
                <button style={{
                    outline: 'none'
                  }} className="close" data-toggle="modal" data-target="#modalConfirmDeleteRelais" onClick={this.modalConfirm.bind(this, ListFormat, index)}>
                  <span style={{
                      color: "rgb(110,110,110)"
                    }}>
                    <IoIosCloseCircleOutline size={28}/>
                  </span>
                </button>
              </div>
              </li>))
            }
      </ul>
    </PerfectScrollbar>

  </div>*/}


      <div className="modal fade" id="modalAjoutRelais" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutRelaisLabel"><FormattedMessage id="ConfigurationServer.serveurRelais.modal"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="text" className="form-control text-center" value={this.state.value} onChange={this.addressIPChange}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addIP}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalConfirmDeleteRelais" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutRelaisLabel">
              <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutRelaisLabel">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn btn-danger`} data-dismiss="modal" onClick={this.removeIP.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}
ConfigServeurRelais.propTypes = {
  intl: intlShape.isRequired
};
ConfigServeurRelais = injectIntl(ConfigServeurRelais, {withRef: true});

export default ConfigServeurRelais
