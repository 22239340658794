import React from 'react'
import Select, { components } from 'react-select';
import $ from "jquery";
import UserMMI from "./UserMMI";
import UserMMF from "./UserMMF";
import UserVPN from "./UserVPN";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import { FaPlus } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { varGroupDetail, varUser, varConfigServeur } from '../variables';
const ApiGroups = varGroupDetail.api;
const ApiMail = varConfigServeur.apiServMessagerie;
const ApiGroupware = varConfigServeur.apiServGroupware;
const ApiFile = varConfigServeur.apiServFile;

function validate(login, name, email, groupId, password, confirmPassword) {
  // true means invalid, so our conditions got reversed
  return {
    login: login.length === 0,
    name: name.length === 0,
    email: email.length === 0,
    groupId: groupId.length === 0,
    password: password.length === 0,
    confirmPassword: confirmPassword.length === 0,
  };
}

const userInfo = {"availableMailServers":[{"name":"mail","id":1}]};

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

        fileServerUsed: [],
        mail: "Root",
        availableServer: [],

        touched: {
          Login: false,
          Name: false,
          Email: false,
          GroupId: false,
          Password: false,
          ConfirmPassword: false,
        },

        selectGroups: [
          { value: '0', label: '000'},
          { value: '1', label: '111' },
          { value: '2', label: '222' },
          { value: '3', label: '333' }
        ],

        userInfo: {
            "login": "",
            "name": "",
            "email" : "",
            "password" : "",
            "confirmPassword": "",
            "group": {
              "groupId": 0
            },
            "allAccess" : false,
            "externalConnection" : false,
            "ip": "",
            "mminstant" : false,
            availableGroups: [],
            availableMailServers: [],
            availableGroupwareServers: [],
            availableFileServers: [],
            fileServers: [],
          }

    };

        this.handleChange = this.handleChange.bind(this);
        this.addMail = this.addMail.bind(this);
        this.handleMail = this.handleMail.bind(this);

  }

// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {

    this.setState({isLoading: true});

    Promise.all([
            fetch(ApiGroups ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiMail ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiGroupware ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiFile ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
        ])
        .then(([resGroups, resMail, resGroupware, resFile]) => Promise.all([resGroups.json(), resMail.json(), resGroupware.json(), resFile.json()]))
        .then(([dataGroups, dataMail, dataGroupware, dataFile]) => (this.setState({
            Groups: dataGroups,
            listServerMail: dataMail,
            listServerGroupware: dataGroupware,
            listServerFile: dataFile
        }),
        this.setState({
          userInfo: { ...this.state.userInfo, ["availableGroups"]: dataGroups,
          ["availableMailServers"]: dataMail,
          ["availableGroupwareServers"]: dataGroupware,
          ["availableFileServers"]: dataFile}
        }), this.setState({isLoading: false}), this.getAvailableServerFile(this.state.userInfo))
      );

  }

  componentDidUpdate() {

    if(this.state.isFetching === true){
      $('#ModalValideModif').modal('show');
    }else{$('#ModalValideModif').modal('hide');}
  }

  componentWillUnmount (){
    clearInterval(this.timer);
  }

// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  addServerFile(id) {

    this.state.userInfo.availableFileServers.map((list,index) =>
    {if (list.id === parseInt(id)) {
      var newData = this.state.userInfo;
      newData.fileServers.push(list);
      this.setState({userInfo: newData });
      this.getAvailableServerFile(newData);
    }}
  )

  }

  getAvailableServerFile(data){
    var allServer = data.availableFileServers;
    var usedServer = data.fileServers;
    var availableServer = [];

    for (var i = 0; i < allServer.length; i++) {
      var finded=false;
      for (var y = 0; y < usedServer.length; y++) {
        if (allServer[i].id === usedServer[y].id && finded === false) {
          finded = true;
        }
      }
      if (!finded) {
        availableServer.push(allServer[i]);
      }
    }

    if(availableServer.length > 0){this.setState({selectedServerFileId: availableServer[0].id})}else{this.setState({selectedServerFileId: ""})}
    this.setState({availableServer: availableServer});
  }

  removeServerFile(indexFileServer) {

    var newArray = this.state.userInfo;
    var newData = [];
    this.state.userInfo.fileServers.map((list, index) => {
      if(index !== indexFileServer){
        newData.push(list);
      }
    })

    newArray.fileServers = newData;
    this.setState({userInfo: newArray });
    this.getAvailableServerFile(newArray);

  }

  selectedServerFile(e){
    this.setState({selectedServerFileId: e.target.value})
  }


  handleChange= (event, field) => {
    switch (field) {
      case "allAccess":
      case "externalConnection":
      case "mminstant":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: !this.state.userInfo[field] }
            });
        break;

      case "mailServer":
      case "groupwareServer":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: event.target.value }
            });
        break;

      default:
          this.setState({
            userInfo: { ...this.state.userInfo, [field]: event.target.value }
          });
      break;

    }
  }


  handleMail(event) {
    this.setState({mail: event.target.value});
  }

  handleBlur = field => evt => {
      this.setState({
        touched: { ...this.state.touched, [field]: true }
      });
    };

  modalConfirm(name, index){
    this.setState({modalTXT: name, modalIndex: index})
    $('#modalConfirmSuprPJ').modal('show')
  }

  sendData(){

    var adaptUserInfo = {};
    var adaptFileServers = [];
    this.state.userInfo.fileServers.map((list, index) => {
      adaptFileServers.push(list.id);
    })
    adaptUserInfo.fileServers = adaptFileServers;

    var grpID;

    if (this.state.userInfo.group.GroupId === undefined) {
      grpID = this.state.Groups[0].groupId;
    } else {
      grpID = this.state.userInfo.group.GroupId;
    }

    console.log(grpID);

      var data = {
        "login": this.state.userInfo.login,
        "name": this.state.userInfo.name,
        "email" : this.state.userInfo.email,
        "password" : this.state.userInfo.password,
        "group": {
          "groupId": grpID
        },
        "fonction": this.state.userInfo.fonction,
        "service": this.state.userInfo.service,
        "allAccess" : this.state.userInfo.allAccess,
        "externalConnection" : this.state.userInfo.externalConnection,
        "ip": this.state.userInfo.ip,
        "mminstant" : this.state.userInfo.mminstant,
        "mailServerId": parseInt(this.state.userInfo.mailServer),
        "groupwareServerId": parseInt(this.state.userInfo.groupwareServer),
        "fileServerIds": adaptFileServers
      };

console.log(data);
      this.setState({isFetching: true});


      fetch(varUser.api, {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(data)
        })
        .then(response => {
          var x = response;
          if(response.status === 200){
            return response.json().then(responseRetour => {
              this.props.history.push("/Users/" + responseRetour.userId);
            })
          }
          else{
            console.log(response.headers);
           return response.json().then(responseError => {
             console.log(responseError);
             this.requeteResponse(x,responseError);
           })
         }
         })

      this.setState({isFetching: false});
  }

  requeteResponse(response, responseError){
    this.setState({codeStatus: responseError.status, errorM: responseError.error, errorMsg: responseError.message});
      switch(responseError.status) {
          default:
            $('#ModalErreur').modal('show');
            break;
        }
  }


  getAvailableGroups(data) {
    if (data) {
      return (this.state.Groups.map((group, index) => (<option key={index} className="list-group-item small " value={group.groupId}>{group.name}</option>)));
    }
  }

  selectedGrp(event){
      this.setState({
        userInfo: { ...this.state.userInfo, ["GroupId"]: event.target.value }
      });
  }

  addMail() {
    const tableau = this.state.fileServerUsed;
    for (var i = 0; i < tableau.length; i++) {
      if (tableau[i] === (this.state.mail)) {
        return
      }
    }
    if (this.state.mail) {
      tableau.push(this.state.mail)
    }
    this.setState({fileServerUsed: tableau});
    this.setState({mail: ""});
  }

  removeMail(index) {
    const tab = this.state.fileServerUsed;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({fileServerUsed: newTab});
  }


// ------------------------------- DATA fetching ------------------------------- //

 fetchData (ApiGroups) {
   fetch(ApiGroups ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
   .then(response => {
     if (response.ok) {
       return response.json();
     } else {
       throw new Error('Something went wrong ...');
     }
   }).then(data => this.setState({Groups: data, isLoading: false})).catch(error => this.setState({error, isLoading: false}));
 }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;

    const errors = validate(this.state.userInfo.login,
      this.state.userInfo.name,
      this.state.userInfo.email,
      this.state.userInfo.group.groupId,
      this.state.userInfo.password,
      this.state.userInfo.confirmPassword);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    if (error) {
      return (
          <div>
              <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <span>Erreur : {error.message}</span>
              </div>

              <div className="container small">
              </div>
          </div>
      );
    }

           if (isLoading) {

             return (
               <div className="card mt-5">
               <div className="card-header text-info">
                 <b>
                   Chargement ...
                 </b>
               </div>
               <div className="card-body d-flex justify-content-center">

                 <div className="spinner-border text-info" role="status">
                     <span className="sr-only">Loading...</span>
                   </div>
                 </div>
               </div>);
           }

      return (
        <div className="col-6 small mt-3 mb-4">
            <h5 className="text-primary text-center mb-2">
              <b>
                <span className="text-success">
                  <FormattedMessage id="newUser.1"/></span>
              </b>
            </h5>
            <h6 className="text-primary text-center mt-2 mb-3">
              <FormattedMessage id="userDetails.configparam"/>
            </h6>

            <ul className="nav nav-tabs justify-content-center">
              <li className="nav-item">
                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#Informations" role="tab" aria-controls="home" aria-selected="true">Informations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="home-tab" data-toggle="tab" href="#VPN" role="tab" aria-controls="home" aria-selected="false">VPN </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="home-tab" data-toggle="tab" href="#MM-Instant" role="tab" aria-controls="home" aria-selected="false">MM-Instant </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="home-tab" data-toggle="tab" href="#MM-File" role="tab" aria-controls="home" aria-selected="false">MM-File </a>
              </li>
            </ul>

            <div className="tab-content col-auto mb-5" id="myTabContent">

                <div className="tab-pane show active" id="Informations" role="tabpanel" aria-labelledby="home-tab">

                  <div className="row justify-content-center card border-0">
                    <div className="col-8 container mt-2 mb-2">

                    <div className="col">

                    <h6 className="text-info mb-3 text-center">
                      <b>Informations</b>
                    </h6>

                      <div className="input-group mb-2">
                        <div className="input-group-prepend ">
                          <span className="input-group-text" id="basic-addon">Login :
                          </span>
                        </div>

                        <input type="text"
                          className={`form-control mr-2 ${shouldMarkError("login") ? "error" : ""}`}
                          onChange={e => this.handleChange(e, "login")}
                          onBlur={this.handleBlur("login")}/>
                      </div>

                      <div className="input-group mb-2">
                        <div className="input-group-prepend ">
                          <span className="input-group-text" id="basic-addon">Name :
                          </span>
                        </div>

                        <input type="text"
                          className={`form-control mr-2 ${shouldMarkError("name") ? "error" : ""}`}
                          onChange={e => this.handleChange(e, "name")}
                          onBlur={this.handleBlur("name")}/>
                      </div>

                      <p>
                        <label><FormattedMessage id="userDetails.info.address"/>
                          :</label>

                        <input className={`form-control ${shouldMarkError("email") ? "error" : ""}`}
                          placeholder="example@mail.com"
                          onChange={e => this.handleChange(e, "email")}
                          onBlur={this.handleBlur("email")}/>
                      </p>

                      <p>
                        <label htmlFor="inputState"><FormattedMessage id="userDetails.info.group"/>
                          :
                        </label>

                        <select id="inputState"
                          className={`form-control mr-4 ${shouldMarkError("groupId") ? "error" : ""}`}
                          onChange={this.selectedGrp.bind(this)}
                          onBlur={this.handleBlur("groupId")}>
                          {(this.getAvailableGroups(this.state.Groups))}
                        </select>

                      </p>
                      <p>
                        <label><FormattedMessage id="userDetails.info.fonction"/>
                          :</label>

                          <input placeholder={this.props.intl.formatMessage({id: "userDetails.info.fonction"})}
                            className={`form-control ${shouldMarkError("fonction") ? "error" : ""}`}
                            onChange={e => this.handleChange(e, "fonction")}
                            onBlur={this.handleBlur("fonction")}/>
                      </p>

                      <p>
                        <label><FormattedMessage id="userDetails.info.service"/>
                          :</label>

                          <input placeholder={this.props.intl.formatMessage({id: "userDetails.info.service"})}
                            className={`form-control ${shouldMarkError("service") ? "error" : ""}`}
                            onChange={e => this.handleChange(e, "service")}
                            onBlur={this.handleBlur("service")}/>
                      </p>

                      <div className="input-group mb-2 mt-4">
                        <div className="input-group-prepend ">
                          <span className="input-group-text" id="basic-addon"><FormattedMessage id="userDetails.info.MDP"/> :
                          </span>
                        </div>
                        <input type="password"
                          className={`form-control mr-2 ${shouldMarkError("password") ? "error" : ""}`}
                          aria-label="password"
                          onChange={e => this.handleChange(e, "password")}
                          onBlur={this.handleBlur("password")}/>
                      </div>

                      <div className="input-group mb-2">
                        <div className="input-group-prepend ">
                          <span className="input-group-text" id="basic-addon">Confirmation :
                          </span>
                        </div>
                        <input type="password"
                          className={`form-control mr-2 ${shouldMarkError("confirmPassword") ? "error" : ""}`}
                          aria-label="password"
                          onChange={e => this.handleChange(e, "confirmPassword")}
                          onBlur={this.handleBlur("confirmPassword")}/>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>


                <div className="tab-pane" id="VPN" role="tabpanel" aria-labelledby="home-tab">

                  <div className="row justify-content-center card border-0">
                    <div className="col-8 container mt-2 mb-2">

                        <UserVPN userInfo={this.state.userInfo}
                        handleChange={this.handleChange}/>

                    </div>
                  </div>
                </div>


                <div className="tab-pane" id="MM-Instant" role="tabpanel" aria-labelledby="home-tab">

                  <div className="row justify-content-center card border-0">
                    <div className="col-8 container mt-2 mb-2">

                        <UserMMI userInfo={this.state.userInfo}
                        handleChange={this.handleChange}/>

                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="MM-File" role="tabpanel" aria-labelledby="home-tab">

                  <div className="row justify-content-center card border-0">
                    <div className="col-8 container mt-2 mb-2">

                        <UserMMF userInfo={this.state.userInfo}
                        handleChange={this.handleChange}
                        availableServer={this.state.availableServer}
                        addServerFile={this.addServerFile.bind(this)}
                        removeServerFile={this.removeServerFile.bind(this)}
                        selectedServerFileId={this.state.selectedServerFileId}
                        selectedServerFile={this.selectedServerFile.bind(this)}
                        userAdministration={this.props.userAdministration}/>

                    </div>
                  </div>
                </div>
            </div>

            <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
              <div className="text-center mr-1">
                <button type="button" className="btn btn-secondary" onClick={NewUser.backPage}><FormattedMessage id="retour"/>
                </button>
              </div>

              <div className="text-center ml-1">
                <button type="button" className="btn btn-info" onClick={this.sendData.bind(this)} disabled={isDisabled}>
                  <FormattedMessage id="valider"/>
                </button>
              </div>
            </div>


            <div className="modal fade" id="ModalValideModif" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" style={{width: '160px'}} role="document">
                <div className="modal-content">
                  <div className="modal-header d-flex justify-content-center">
                    <h6 className="modal-title text-info" id="exampleModalLabel"><b>
                      Chargement ...
                    </b></h6>
                  </div>
                  <div className="modal-body text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="ModalErreur" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content modalAlert">
                  <div className="modal-header">
                    <h5 className="alert-heading"><b>Code error : </b>{this.state.codeStatus}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <p>{this.state.errorM}</p>
                    <hr/>
                    <p className="mb-0">{this.state.errorMsg}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modalConfirmSuprPJ" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered " role="document">
                <div className={`modal-content modalConfirm border-secondary`}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalAjoutDNSLabel"> Supprimer </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body ml-2">
                    <h6 className="modal-title" id="modalAjoutDNSLabel"> Confirmer la suppression de "<b className="text-info">{this.state.modalTXT}</b>" ? </h6>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                    </button>
                    <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.removeMail.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

      </div>
    )

  }
}
NewUser.propTypes = {
  intl: intlShape.isRequired
};
NewUser = injectIntl(NewUser,
                        {withRef: true});
export default NewUser
