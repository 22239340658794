import React from 'react'
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import {
  IoMdCheckmark,
  IoMdClose,
  IoIosInformationCircle,
  IoIosWarning,
  IoIosAlert
} from "react-icons/io";
import { FaUser, FaUsers } from "react-icons/fa";
import { GoDeviceMobile } from "react-icons/go";
import { MdGroup, MdMessage } from "react-icons/md";

import {varMmapp} from '../variables';


var contextMenu = {
  "feedbackDelete": false,
  "feedbackDetails": false,
}


const iconSize = 22;

class MMAppFeedbacksTabContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

  checkData() {

    const hit = this.props.hit;

    if (hit != "empty" && hit) {


      if (this.props.userAdministration === "ADMINISTRATOR") {
        contextMenu.feedbackDelete = true;
      } else {
        contextMenu.feedbackDelete = false;
      }
      contextMenu.feedbackDetails = true;

    } else {
        contextMenu.feedbackDelete = false;
        contextMenu.feedbackDetails = false;
    }
  }


  modalConfirm(name, index) {
    $('#modalConfirmSuprFeedback').modal('show');
  }



// ------------------------------- Action handle ------------------------------- //


  actionMenu(id){
    switch (id) {
      case "feedbackDelete":

      fetch(varMmapp.apiFeedbacks + this.props.hit.feedback.id, {
          method: 'DELETE',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: ""
        })
        .then(response => {
          if(process.env.NODE_ENV == "development")console.log(response);
          var x = response;
          if(response.status === 200){this.props.refetch()}
          else{
           return response.json().then(responseError => {
             console.log(responseError);
             this.requeteResponse(x,responseError);
           })
         }
         })

        break;

      default:
    }
  }

  showModal(type, test, data){
    this.setState({modalTXT: test, modalData: data})
    $('#modalMMAppFeedback').modal('show')
  }


// ------------------------------- Function with visual ------------------------ //


  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "feedbackDelete":
          return (<Item onClick={this.modalConfirm.bind(this)}>
            <span style={{
                color: "rgb(232,35,35)"
              }}>
              <IoMdClose size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "feedbackDetails":
          return (<Item onClick={this.showModal.bind(this, "feedbackDetails", this.props.hit.title, this.props.hit.message)}>
            <span style={{
                color: "rgb(51,154,232)"
              }}>
              <MdMessage size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        default:
          break;
      }
    }
  }



// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if (this.props.hit != "empty")
      return (<div>

        <Menu id='menu_mmAppFeedbacksTabContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("feedbackDetails", contextMenu.feedbackDetails, this.props.intl.formatMessage({id: "mmapp.feedbackTab.contextMenu.showMessageDetails"}))}
          {this.testMenu("feedbackDelete", contextMenu.feedbackDelete, this.props.intl.formatMessage({id: "mmapp.feedbackTab.contextMenu.deleteFeedback"}))}

        </Menu>


        <div className="modal fade" id="modalMMAppFeedback" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.showMessageDetails.modal.title"})}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                  <div className="row mb-3">

                    <div className="col">

                      <input type="text" className="form-control" value={this.props.hit.type === "all" ?
                       this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"}) : this.props.hit.device ? (this.props.hit.device.user.email + " / " + this.props.hit.device.model) : " "}/>

                    </div>


                  </div>


                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.title"})}</span>
                    </div>
                    <input type="text" className="form-control" maxLength="100" value={this.props.hit.feedback ? this.props.hit.feedback.title : null}/>
                  </div>

                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.message"})}</span>
                    </div>
                    <textarea className="form-control" rows={6} style={{ resize: "none" }} maxLength="10000" value={this.props.hit.feedback ? this.props.hit.feedback.message : null}></textarea>
                  </div>


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="modalConfirmSuprFeedback" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className={`modal-content modalConfirm border-secondary`}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalConfirmSuprFeedback">
                  <FormattedMessage id={"supprimer"}/>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalConfirmSuprFeedback">
                  <FormattedMessage id={"confirmDelete"}/> "<b className="text-info">{this.props.hit.feedback ? this.props.hit.feedback.title : null}</b>" ?
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.actionMenu.bind(this, "feedbackDelete")}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

MMAppFeedbacksTabContextMenu.propTypes = {
  intl: intlShape.isRequired
};
MMAppFeedbacksTabContextMenu = injectIntl(MMAppFeedbacksTabContextMenu, {withRef: true});

export default MMAppFeedbacksTabContextMenu
