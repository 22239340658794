import React from 'react'
import $ from "jquery";
import GroupsToolBar from './GroupsToolBar';
import GroupsTab from './GroupsTab';
import GroupsContextMenu from './GroupsContextMenu';

import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {TiPlus} from "react-icons/ti";
import CT from '../CT';

import { varGroups, varGroupDetail, varParametres} from '../variables';

const ApiGroups = varGroups.api;
const ApiPoliciesModif = varGroups.apiPolitiquesModification;
const ApiExtansions = varParametres.apiExtansions;

var time = 0;
var newRequest = false;
var firstFetchTab = true;

const tentativeArray = [4,5,6,7,8,9,10,11,12,13,14];

function research(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }

  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}


class GroupsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      extensions: "",
      tabModif: {},
      checked: true,
      disabl: false,
      valideMSG: false,
      selected: null,
      oldSelectedOptionExtensions: null,

      array: [],
      hit: [null],

      selectFormatList: [
        { value: 'pdf', label: '.PDF'},
        { value: 'txt', label: '.TXT' },
        { value: 'png', label: '.PNG' },
        { value: 'jpg', label: '.JPG' },
        { value: 'jpeg', label: '.JPEG' },
        { value: 'gif', label: '.GIF' },
        { value: 'zip', label: '.ZIP' }
      ],

      isLoading: false,
      policiesData: "",
      error: null,
      fileName: this.props.intl.formatMessage({id: "choixfichier"}),
      researchValue: "",
      showPagination: false,
    };


    this.tick = this.tick.bind(this)
    this.rowClickHandle = this.rowClickHandle.bind(this);
    this.detailsGroupHandle = this.detailsGroupHandle.bind(this);
    this.researchHandle = this.researchHandle.bind(this);
    this.modalChange = this.modalChange.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
    this.contextMenu = this.contextMenu.bind(this);

    this.sendPoliciesModif = this.sendPoliciesModif.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.handleChangeExtansionsAutorisation = this.handleChangeExtansionsAutorisation.bind(this);
    this.handleLimitSize = this.handleLimitSize.bind(this);
    this.handlePoliciesModif = this.handlePoliciesModif.bind(this);
    this.handleChangeEmailfilter = this.handleChangeEmailfilter.bind(this);
  }

  componentWillMount() {
    this.setState({isLoading: true});

        Promise.all([
            fetch(ApiExtansions ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
        ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data1]) => this.setState({
            dataExtansions: this.getExtensions(data1),
            isLoading: false
        }));
  }

  componentDidMount() {
    if (varGroups.enableTimer === "on")
      this.timer = setInterval(this.tick, 1000);

    if(process.env.NODE_ENV == "development")console.log("REFRESH AUTO groupes : " + varGroups.enableTimer + " , 1sec, " + varGroups.timer + "sec");
    this.setState({isLoading: true});
    this.fetchData(ApiGroups);
    this.fetchDataPolicies(varGroupDetail.apiPolitiquesRestriction, "restriction");
    this.fetchDataPolicies(varGroupDetail.apiPolitiquesDefaut, "general");
  }

  componentDidUpdate(time) {
    document.title = this.props.intl.formatMessage({id: "app.menu.5"});

    if (time === varGroups.timer) {
      this.fetchData(ApiGroups);
    }
  }

  componentWillUnmount() {
    time = 0;
    firstFetchTab = true;
    clearInterval(this.timer);
  }

  detailsGroupHandle(x) {
    this.props.history.push("/Groups/" + x);
  }

  researchHandle(event) {
    this.setState({researchValue: event.target.value});
  }

  rowClickHandle(x) {
    this.props.history.push("/Groups/" + x);
  }

  selectedHandle(x){
    this.setState({selected : x});
  }

  removeTab(e, tab){

    var x = [];

    for (var i = 0; i < tab.length; i++) {
      if(i !== e){x.push(tab[i])}
    }
    this.setState({array: x});
  }


  tick() {
    if (newRequest === true) {
      if (time === varGroups.timer) {
        time = 0;
        newRequest = false;
        this.fetchData(ApiGroups);
      }
      time = time + 1;
    }
  }

  fetchDataPolicies(api, group) {
    fetch(api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetch policies : Something went wrong ...');
      }
    })
    .then(data => this.setArray(data, group))
    .catch(error => this.setState({error, isLoading: false}));
  }

  modalChange(txt){
      if(txt === "general"){
      this.setState({policiesData : this.state.general,
        extansionsAutorisation: this.state.general.extensions === "*" ? true : false,
        selectedOptionExtensions: this.state.general.extensions === "*" ? { value: '*', label: '*'} : this.getExtensionsAdapt(this.state.general.extensions),
        limitSize: this.state.general.maxattachmentsize == "-1" ? false : true,
        currentModal : "general",
        titleModal : this.props.intl.formatMessage({id: "groupsDetails.PolitiqueSecu.btn"})});
    }
      else{
      this.setState({policiesData : this.state.restriction,
        extansionsAutorisation: this.state.restriction.extensions === "*" ? true : false,
        selectedOptionExtensions: this.state.restriction.extensions === "*" ? { value: '*', label: '*'} : this.getExtensionsAdapt(this.state.restriction.extensions),
        limitSize: this.state.restriction.maxattachmentsize == "-1" ? false : true,
        currentModal : "restriction",
        titleModal : "Restriction"});
    }
  }

  getExtensions(data){

    var newArray = [];
    const list = data;

      for (var i = 0; i < list.length; i++) {
        var item = new Object;
        item.value = list[i];
        item.label = list[i];

          newArray.push(item);
      }

      return newArray
  }

  getExtensionsAdapt(extensions){

    var newData = [];
    if (extensions.length > 2) {
    var myArray = extensions.substring(1, extensions.length - 1).split(',');

      for (var i = 0; i < myArray.length; i++) {
        var item = new Object();
        item.value = myArray[i].substring(1, myArray[i].length - 1);
        item.label = myArray[i].substring(1, myArray[i].length - 1);

        newData.push(item);
      }
    }

    return(newData)
  }


  handleChangeSelect = selectedOption => {
    this.setState({selectedOptionExtensions : selectedOption})
  }

  handleLimitSize(event){
    this.setState({limitSize: !this.state.limitSize})
  }

  handleChangeExtansionsAutorisation = state => {
    if (!this.state.extansionsAutorisation) {
      this.setState({
        policiesData: {
          ...this.state.policiesData,
          ["extensions"]: "*"
        },
        oldSelectedOptionExtensions: this.state.selectedOptionExtensions,
        selectedOptionExtensions: { value: '*', label: '*'}
      });
    } else {
      this.setState({
        policiesData: {
          ...this.state.policiesData,
          ["extensions"]: {}
        },
        selectedOptionExtensions: this.state.oldSelectedOptionExtensions
      });
    }
    this.setState({extansionsAutorisation : !this.state.extansionsAutorisation});
  }


  sendPoliciesModif(){

      var finalExtensions = null;
      if (this.state.extansionsAutorisation) {
        finalExtensions = "*";
      } else if (this.state.selectedOptionExtensions && this.state.selectedOptionExtensions.length > 0){
        var adaptExtensions = [];
        this.state.selectedOptionExtensions.map((x) => {
          adaptExtensions.push(x.label)
        })
        finalExtensions = JSON.stringify(adaptExtensions);
      }

        var adaptData = this.state.policiesData;

        var data = {
              "bluetooth": adaptData.bluetooth,
              "browser": adaptData.browser,
              "camera": adaptData.camera,
              "emailfilter": adaptData.emailfilter,
              "password": adaptData.password,
              "storagecard": adaptData.storagecard,
              "textmessaging": adaptData.textmessaging,
              "wifi": adaptData.wifi,
              "maxattachmentsize": !this.state.limitSize ? "-1" : adaptData.maxattachmentsize,
              "maxdevicepasswordfailedattempts": !this.state.policiesData.password ? "-1" : adaptData.maxdevicepasswordfailedattempts,
              "requiremanualsyncwhenroaming": adaptData.requiremanualsyncwhenroaming,
              "extensions": finalExtensions,
              "unsignedapplications": adaptData.unsignedapplications,
              "desktopsync": adaptData.desktopsync,
              "onlyheader": adaptData.onlyheader
            };

        fetch(ApiPoliciesModif + adaptData.policySecurityId, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(data)
        }).then(response => {
          console.log(response);
          var x = response;
          if (response.status === 200) {
            console.log(response);
            if (adaptData._restriction) {
              this.fetchDataPolicies(varGroupDetail.apiPolitiquesRestriction, "restriction");
            } else {
              this.fetchDataPolicies(varGroupDetail.apiPolitiquesDefaut, "general");
            }
          }
          else{
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })

  }


  closeModal(){
    this.setState({oldSelectedOptionExtensions: null})
  }


  setArray(data, group) {
    this.setState({
      [group]: data, policiesData : data,
      extansionsAutorisation: data.extensions === "*" ? true : false,
      selectedOptionExtensions: data.extensions === "*" ? { value: '*', label: '*'} : this.getExtensionsAdapt(data.extensions),
    })
  }

  changeState(name, value) {
    this.setState({
      [name]: !value
    });
  }

  handlePoliciesModif(event, field){
    this.setState({
      policiesData: {
        ...this.state.policiesData,
        [field]: event.target.value
      }
    });
  }

  handleChangeEmailfilter = selectedOption => {
    this.setState({
      policiesData: {
        ...this.state.policiesData,
        ["emailfilter"]: selectedOption.target.value
      }
    })
  }

  handleCheckboxChange(field){
    if(!this.state.policiesData[field]){this.setState({
      policiesData: { ...this.state.policiesData, [field]: false }
    });
    ;}
    this.setState({
      modalCheckboxTitle : "Confirmation de modification",
      modalCheckboxButton : !this.state.policiesData[field] ? this.props.intl.formatMessage({id: "activer"}) : this.props.intl.formatMessage({id: "désactiver"}),
      modalCheckboxMsg : this.warningMessage(field, !this.state.policiesData[field]),
      modalCheckboxField : [field]
    });
    $('#modalCheckbox').modal('show')
  }

  warningMessage(field, state){

    if(state === false){

      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.disable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.disable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.disable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.disable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.disable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.disable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.disable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.disable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.disable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.disable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.disable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.disable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.disable"}))
        default:
          return("default")
      }

    }else {
      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.enable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.enable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.enable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.enable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.enable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.enable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.enable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.enable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.enable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.enable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.enable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.enable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.enable"}))
        default:
          return("default")
      }
    }
  }

  handleCheckboxConfirm(field){

    if(String(field) === "password" && this.state.policiesData.requiredevicencryption === true){
      return
    }

      if(String(field) === "requiredevicencryption" && !this.state.policiesData[field] === true){
        var x = this.state.policiesData;
        x.password = true;
        this.setState({ policiesData: x });
      }

    this.setState({
      policiesData: { ...this.state.policiesData, [field]: !this.state.policiesData[field] }
    });
  }

  fetchData(api) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if(process.env.NODE_ENV == "development")console.log("fetch : " + hours + ":" + min + ":" + sec);
    fetch(api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetch groups : Something went wrong ...');
      }
    }).then(data => this.adaptData(data)).catch(error => this.setState({error, isLoading: false}));
  }

  adaptData(tab) {
    var newArray = [];
    tab.map((x, index) => {
      if (!(this.props.userAdministration === "REMOTE_ADMIN" && x.administration === 1)) {
        var item = new Object();
        item.name = x.name;
        item.administration = x.administration;
        item.nbusers = x.count;
        item.politiques = 1;
        item.groupId = x.groupId;
        item.iseditable = x.iseditable;
        item.isremovable = x.isremovable;
        item.mmapp_test = x.mmapp_test;

        newArray.push(item);
      }

    })

    if(newArray.length > 50 && firstFetchTab === true){
      firstFetchTab = false;
      this.setState({showPagination: true})
    }

    newRequest = true;
    this.setState({array: newArray, isLoading: false})
  }


  getPos(txt) {
    const tab = Object.values(this.state.tabFormat);
    for (var i = 0; i < tab.length; i++) {
      if (tab[i].format === txt) {
        return (i)
      }
    }
  }

  getFormat(x) {
    if (x)
      return (<div className="col">
        {
          (x.map((list, index) => {
            return (<div key={index} className="custom-control custom-checkbox">
              <input className="custom-control-input" type="checkbox" id={`${list.format}`} checked={list.etat} onChange={this.checked.bind(this, index)}/>
              <label className="custom-control-label" htmlFor={`${list.format}`}>
                {list.format}
              </label>
            </div>)
          }))
        }
      </div>)
  }

  checked(index) {

    var tab = this.state.tabFormat;
    tab[index].etat = !tab[index].etat;
    this.setState({tabFormat: tab});

  }

  formatList(list){
    var txt = "";
    list.map((x, index) => {
      if(x.etat === true){
        txt = txt + " " + x.format;
      }
    })
    this.setState({extensions: txt})
  }

  fetching() {
    if (newRequest === false) {
      return (<div className="col-auto d-flex justify-content-center ml-4">
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>)
    }
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({fileName: event.target.files[0].name});
    var file = event.target.files[0];
    console.log(file);
    this.setState({file}); /// if you want to upload latter
  }


  contextMenu(x){
    if(x === "empty"){
    this.setState({hit : "empty"});
  }else {
    this.setState({hit : x});
    }
  }


  render() {

        const {isLoading, error} = this.state;


        if (error) {
          return (<div>
            <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <span>Erreur : {error.message}</span>
            </div>

            <div className="container small"></div>
          </div>);
        }

        if (isLoading) {

          return (<div className="card mt-5 small">
            <div className="card-header text-info">
              <b>
                {this.props.intl.formatMessage({id: "chargement"})} ...
              </b>
            </div>
            <div className="card-body d-flex justify-content-center">

              <div className="spinner-border text-info" role="status">
                <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
              </div>
            </div>
          </div>);
        }

    return (<div className="card bg-light float-left mt-3 small" style={{minWidth: '50vw'}}>

      {this.state.array ?
        <div>

          <div className="card-header">

            <GroupsToolBar
            researchValue={this.state.researchValue}
            researchHandle={this.researchHandle}
            isFetching={this.fetching()}
            general={this.state.general}
            restriction={this.state.restriction}
            modalChange={this.modalChange}
            userAdministration={this.props.userAdministration}
          />

          </div>

            <GroupsTab
            array={(research(this.state.array,this.state.researchValue)).sort((a, b) => a.name.localeCompare(b.name))}
            rowClickHandle={this.rowClickHandle}
            selectedHandle={this.selectedHandle}
            selected={this.state.selected}
            contextMenu={this.contextMenu}
            showPagination={this.state.showPagination}
          />

          <GroupsContextMenu hit={this.state.hit}
          removeTab={this.removeTab.bind(this)}
          array={this.state.array}
          selected={this.state.selected}
          detailsGroupHandle={this.detailsGroupHandle}
          userAdministration={this.props.userAdministration}
          tokenCSRF={this.props.tokenCSRF}
          />

        </div>
        :
        null
      }



{ this.state.policiesData ?
        <div className="modal fade"
        id="RestrictionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"><FormattedMessage id="groupsDetails.5"/> 
                  <span className="badge badge-info">{this.state.titleModal}</span>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body row">

                <div className="col">

                  <CT texte="groupsDetails.PolitiqueSecu.3"
                  id="enableWIFI_Modal"
                  checked={this.state.policiesData.wifi}
                  onChange={e => this.handleCheckboxChange("wifi")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.4"
                  id="enableWEB_Modal"
                  checked={this.state.policiesData.browser}
                  onChange={e => this.handleCheckboxChange("browser")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.5"
                  id="enableBluetooth_Modal"
                  checked={this.state.policiesData.bluetooth}
                  onChange={e => this.handleCheckboxChange("bluetooth")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.6"
                  id="enableCAM_Modal"
                  checked={this.state.policiesData.camera}
                  onChange={e => this.handleCheckboxChange("camera")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.7"
                  id="enableSTOCKAGE_Modal"
                  checked={this.state.policiesData.storagecard}
                  onChange={e => this.handleCheckboxChange("storagecard")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.8"
                  id="enableUnsighAPP_Modal"
                  checked={this.state.policiesData.unsignedapplications}
                  onChange={e => this.handleCheckboxChange("unsignedapplications")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.9"
                  id="enablePCSync_Modal"
                  checked={this.state.policiesData.desktopsync}
                  onChange={e => this.handleCheckboxChange("desktopsync")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.10"
                  id="enableSMS_Modal"
                  checked={this.state.policiesData.textmessaging}
                  onChange={e => this.handleCheckboxChange("textmessaging")}
                  />

                  <FormattedMessage id="groupsDetails.PolitiqueSecu.11"/>
                  <select id="inputState" value={this.state.extansionsAutorisation} onChange={this.handleChangeExtansionsAutorisation}
                    className="form-control mt-1 mb-2">
                    <option className="list-group-item small" value={true}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.allowAll"})}</option>
                    <option className="list-group-item small" value={false}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.personalize"})}</option>
                  </select>

                  <div className="col mt-3">
                    <div className="row mb-3">
                      <div className="col">
                        <CT texte="groupsDetails.PolitiqueSecu.12"
                        id="limitPJ"
                        checked={this.state.limitSize}
                        onChange={e => this.handleLimitSize(e)}
                        disabl={this.state.disabl}
                        />

                      </div>
                      <div className="col-5">
                        <input className="form-control" value={this.state.policiesData.maxattachmentsize}
                        onChange={e => this.handlePoliciesModif(e, "maxattachmentsize")}
                        disabled={!this.state.limitSize}/>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col">
                <FormattedMessage id="groupsDetails.PolitiqueSecu.13"/>
                  <select id="inputState" className="form-control mr-4 text-center" value={this.state.policiesData.emailfilter} onChange={this.handleChangeEmailfilter}>
                    <option className="list-group-item small" value={0}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.0"})}</option>
                    <option className="list-group-item small" value={1}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.1"})}</option>
                    <option className="list-group-item small" value={2}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.2"})}</option>
                    <option className="list-group-item small" value={3}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.3"})}</option>
                    <option className="list-group-item small" value={4}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.4"})}</option>
                    <option className="list-group-item small" value={5}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.5"})}</option>
                </select>

                  <div className=" mt-2">

                    <FormattedMessage id="groupsDetails.PolitiqueSecu.14"/>


                    <Select className="mt-2 mb-3"
                      maxMenuHeight={190}
                      isDisabled={this.state.extansionsAutorisation}
                      isMulti={true}
                      value={this.state.selectedOptionExtensions}
                      onChange={this.handleChangeSelect}
                      options={this.state.dataExtansions}
                      closeMenuOnSelect={false}
                      removeSelected={false}
                    />

                  </div>

                  <CT texte="groupsDetails.PolitiqueSecu.15"
                  id="Synchro_Modal"
                  checked={this.state.policiesData.requiremanualsyncwhenroaming}
                  onChange={e => this.handleCheckboxChange("requiremanualsyncwhenroaming")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.16"
                  id="enteteMail_Modal"
                  checked={this.state.policiesData.onlyheader}
                  onChange={e => this.handleCheckboxChange("onlyheader")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.17"
                  id="cryptTerminal_Modal"
                  checked={this.state.policiesData.requiredevicencryption}
                  onChange={e => this.handleCheckboxChange("requiredevicencryption")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.18"
                  id="cryptMemory_Modal"
                  checked={this.state.policiesData.requirestoragecardencryption}
                  onChange={e => this.handleCheckboxChange("requirestoragecardencryption")}
                  />

                  <CT texte="groupsDetails.PolitiqueSecu.19"
                  id="verouillage_Modal"
                  checked={this.state.policiesData.password}
                  onChange={e => this.handleCheckboxChange("password")}
                  />

                  <div className="row mb-3 mt-3">
                    <div className="col">
                      <FormattedMessage id="groupsDetails.PolitiqueSecu.20"/>
                    </div>
                    <div className="col">
                      <select id="inputState_Modal"
                      className="form-control mr-4 text-center"
                      onChange={e => this.handlePoliciesModif(e, "maxdevicepasswordfailedattempts")}
                      disabled={!this.state.policiesData.password}
                      value={this.state.policiesData.maxdevicepasswordfailedattempts}>
                        <option className="list-group-item small" value={-1}>-</option>{
                          (tentativeArray.map((x, index) => {
                            return (<option key={index} className="list-group-item small" value={x}>{x}</option>)
                          }))
                        }
                      </select>
                    </div>
                  </div>
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}><FormattedMessage id="fermer"/></button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.sendPoliciesModif}><FormattedMessage id="valider"/></button>
              </div>
            </div>
          </div>
        </div>
        : <div/>
      }

        <div className="modal fade" id="modalCheckbox" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{this.state.modalCheckboxTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.modalCheckboxMsg}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.intl.formatMessage({id: "annuler"})}</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                  onClick={this.handleCheckboxConfirm.bind(this, this.state.modalCheckboxField)}>{this.state.modalCheckboxButton}</button>
              </div>
            </div>
          </div>
        </div>


    </div>)
  }
}
GroupsPage.propTypes = {
  intl: intlShape.isRequired
};
GroupsPage = injectIntl(GroupsPage, {withRef: true});

export default withRouter(GroupsPage);
