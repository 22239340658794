import React from 'react'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';
import find from 'lodash/find';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);

function changeToDate(int, lang){
  var options = {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${lang}-${lang.toUpperCase()}`, options);
  return (ds)
}


class FilesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSizeForFilesTab: localStorage.getItem('pageSizeForFilesTab'),
    };
  }

    componentWillUnmount(){
      if (this.state.pageSizeForFilesTab) {
        localStorage.setItem('pageSizeForFilesTab', this.state.pageSizeForFilesTab);
      }
    }


// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

        const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
      };
    };


    const columns = [{
      Header: () => (<span><b> Login </b></span>
    ),
    accessor: 'account',

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "suiviMMF.tab"})} </b>
      </span>
    ),
      accessor: 'file',
      minWidth: 220,

    },{Header: () => (<span><b> Date </b></span>
  ),
      accessor: 'date',
      Cell: row => (
          <div>
          {row.value ?
            <span>
              {changeToDate(row.value, this.props.intl.locale)}
            </span>
            :null
          }
          </div>
        )

    }]

    return (<div>
      <ReactTable
        id="tabTestId"

        getTheadThProps={getTheadThProps}
        showPagination={this.props.showPagination}
        data={this.props.array}
        noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
        rowsText={this.props.intl.formatMessage({id: "rows"})}
        previousText={this.props.intl.formatMessage({id: "previous"})}
        nextText={this.props.intl.formatMessage({id: "next"})}
        loadingText={this.props.intl.formatMessage({id: "chargement"})}
        pageText= "Page"
        ofText={this.props.intl.formatMessage({id: "sur"})}
        TbodyComponent={CustomTbodyComponent}
        columns={columns}
        resizable={true}
        pageSizeOptions= {[10, 20, 25, 50, 100]}
        defaultPageSize= {50}
        showPageSizeOptions={true}
        onPageSizeChange={(pageSize) => {
          this.setState({pageSizeForFilesTab: pageSize})
        }}
        pageSize={!this.props.showPagination ? this.props.array.length : this.state.pageSizeForFilesTab != null ? this.state.pageSizeForFilesTab : this.defaultPageSize}
              style={{
                minHeight: "15vh",
                maxHeight: "75vh",
                width: '78vw'
              }}
              className=" text-center border-0 small -clickable"
            />

            <div className="col text-center border-top small">
              <b className="text-dark"><FormattedMessage id="nb.ligne"/> {this.props.array.length}</b>
            </div>
    </div>)
  }
}

FilesTab.propTypes = {
  intl: intlShape.isRequired
};
FilesTab = injectIntl(FilesTab, {withRef: true});

export default FilesTab
