import packageJson from '../package.json';

var consoleInfo = new Object;
consoleInfo.name = packageJson.name;
consoleInfo.version = packageJson.version;

if(process.env.NODE_ENV === "development")console.log(process.env.NODE_ENV);

const realTime = "on";
const realTimeDelay = 60;


export var url = process.env.REACT_APP_URL;
if(url == undefined){
  const urlUsed = window.location.href;
  url = "http://" + urlUsed.substring(urlUsed.indexOf("//")+2).substring(0, urlUsed.substring(urlUsed.indexOf("//")+2).indexOf("/"));
}
if(process.env.NODE_ENV === "development")console.log("URL API : " + url);


export var urlMobile = process.env.REACT_APP_URL_MOBILE_APP;
if(urlMobile == undefined){
  const urlUsed = window.location.href;
  var urlMobile = "http://" + urlUsed.substring(urlUsed.indexOf("//")+2).substring(0, urlUsed.substring(urlUsed.indexOf("//")+2).indexOf("/"));
}
if(process.env.NODE_ENV === "development")console.log("URL MOBILE API : " + urlMobile);


export var urlRelais = process.env.REACT_APP_URL_RELAIS;
if(urlRelais == undefined){
  const urlUsed = window.location.href;
  var urlRelais = "http://" + urlUsed.substring(urlUsed.indexOf("//")+2).substring(0, urlUsed.substring(urlUsed.indexOf("//")+2).indexOf("/"));
}
if(process.env.NODE_ENV === "development")console.log("URL RELAIS : " + urlRelais);


export const varGlobal = {
  timerChargement: 500, // chargement validation
}


export const varAPropos = {
  consoleInfo: consoleInfo,
  api: url + "/api/version/",
  copyrightDate: "2020", // date copyright
  mail: "tech@mobile-process.com", // mail support
  phone: "03 87 36 74 71", // tel support
}


export const varApp = {
  apiExportDashboard: url + "/xls_export/dashboard",
  apiImportUsers: url + "/xls_import/users",
  apiExportUsers: url + "/xls_export/users",
  apiImportMobiles: url + "/xls_import/mobiles",
  apiExportMobiles: url + "/xls_export/mobiles",
  apiImportIMEI: url + "/xls_import/imei",
  apiExportMMFile: url + "/xls_export/mmfile",
  apiExportMMApp: url + "/xls_export/mmapp",
  apiImportMessages: urlMobile + "/mmapp-server/devices/upload/mmapp_password",
  apiServerApplication: urlMobile + "/mmapp-server/store/",
  apiTestStore: urlMobile + "/mmapp-server/testStore/",
  apiServerApplicationUpload: urlMobile + "/mmapp-server/upload/",
  apiTestStoreUpload: urlMobile + "/mmapp-server/upload/testStore/",
  apiTestStoreValide: urlMobile + "/mmapp-server/testStore/valide/",
  apiRelaisDownload: urlRelais + "/mmapp/download/",
};

export const varLogin = {
  api: url + "/api/auth",
};

export const varGeneral = {
  apiDashboard: url + "/api/dashboard/",
  apiDevices: url + "/api/devices/",
  apiHost : url + "/api/host/",
  apiUser: url + "/api/users/",
  apiMDMConnection: urlMobile + "/mmapp-server/graphConnectionMDM/",
  apiMMAppErrorNotification : urlMobile + "/mmapp-server/message/",
  apiMMAppErrorDashboard : urlMobile + "/mmapp-server/devices/errorDashboard/",
  apiStoreNewVersionApplications : urlMobile + "/mmapp-server/store/newversion/",
  apiDevicesMMApp: urlMobile + "/mmapp-server/devices/",
  apiDangerousPermissions: urlMobile + "/mmapp-server/permissions/dangerous/",
  apiInfoServer : url + "/actuator/metrics/",
  labelDisplaySeuil: 5, // en dessous de X appareils, afficher leurs noms en dessous du shéma
  schemaSeuil: 13, // nb d'appareils maximum affichés
  modeSeuilShow: 13, // quand seuil atteint, nb d'appareils affichés
  graphPourcentage: 50, // % taille du dognut
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};


export const varUser = {
  api: url + "/api/users/",
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};

export var varTerminaux = {
  api: url + "/api/devices/",
  apiDevicesModelList: url + "/api/devices/modelList",
  apiDeviceAdd: url + "/api/devices/add",
  apiDeviceUpdate: url + "/api/devices/update/",
  apiDeviceToLink: url + "/api/devices/devicetolink/",
  apiDeviceReset: url + "/api/activesync/",
  apiDeviceRedeployment: url + "/api/activesync/cleanAccount/",
  apiDeviceDisable: url + "/api/activesync/disable/",
  apiDevicesDetails: urlMobile + "/mmapp-server/devices/",
  apiDevicesDetailsApp: urlMobile + "/mmapp-server/devices/",
  apiMMAppServerGroups: urlMobile + "/mmapp-server/groups/",
  apiMMAppServerNotifications: urlMobile + "/mmapp-server/notifications/",
  apiMMAppServerAction: urlMobile + "/mmapp-server/action/",
  apiMMAppServerDownloadApk: urlMobile + "/mmapp/download/",
  btnSize: 28, // taille des boutons
  deviceOldDataDelay: 86400000, // Si la durée entre deux envoies de données dépasse cette valeur le terminal est en erreur 403
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};
if(process.env.NODE_ENV === "development")console.log("URL API Mobile App : " + varTerminaux.apiDevicesDetails);



export const varUserDetail = {
  api: url + "/api/users/",
  apiUserMMApp : urlMobile + "/mmapp-server/users/",
};


export const varComputers = {
  api: url + "/api/computers/",
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};


export const varGroups = {
  api: url + "/api/groups/",
  apiPolitiquesModification: url + "/api/policies/",
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};


export const varGroupDetail = {
  api: url + "/api/groups/",
  apiUsers: url + "/api/groups/",
  apiPolitiquesSecu: url + "/api/policies/group/",
  apiPolitiquesRestriction: url + "/api/policies/restriction",
  apiPolitiquesDefaut: url + "/api/policies/default",
  apiPolitiquesModification: url + "/api/policies/",
  apiGroupReferenceApplications: urlMobile + "/mmapp-server/group/",
  apiGroupUnwantedApplications: urlMobile + "/mmapp-server/group/unwantedApp/",
  apiGroupReferenceApplicationsTestStore: urlMobile + "/mmapp-server/group/testStore/",
  apiGroupUnwantedApplicationsTestStore: urlMobile + "/mmapp-server/group/testStore/unwanted/",
  apiGroupSimcard: urlMobile + "/mmapp-server/group/simcard/",
  apiGroupGeofencingZone: urlMobile + "/mmapp-server/group/geofencing/",
  apiGroupAllApplications: urlMobile + "/mmapp-server/group/simcard/applications",
  apiGroupPermissions: urlMobile + "/mmapp-server/group/permissions/",
  apiGroupWallpaper: urlMobile + "/mmapp-server/group/wallpaper/",
  apiGroupWallpaperUpload: urlMobile + "/mmapp-server/wallpaper/upload/",
  apiGroupWallpaperPreview: urlMobile + "/mmapp-server/group/wallpaper/image/",
  apiReferenceApplications: urlMobile + "/mmapp-server/devices/application/reference/",
  apiApplicationList: urlMobile + "/mmapp-server/applications/",
  mapboxToken: "pk.eyJ1IjoiY3BhdXR6IiwiYSI6ImNsbHA5OGF5YjAxMWQzZHB2Y2VtM2htazYifQ.KYVKcdarRLV1g7LO53vCtg"
};


export const varFiles = {
  api: url + "/api/logs/mmfile/",
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};

export const varMmapp = {
  apiAppli: urlMobile + "/mmapp-server/applications/stat",
  apiGroup: urlMobile + "/mmapp-server/groups/stat",
  apiTabApp: urlMobile + "/mmapp-server/applicationsList/stat",
  apiApp: urlMobile + "/mmapp-server/applications/",
  apiUndesirable: urlMobile + "/mmapp-server/applicationsList/undesirable",
  apiDeviceStat: urlMobile + "/mmapp-server/devices/stat",
  apiMessage: urlMobile + "/mmapp-server/message/",
  apiFeedbacks: urlMobile + "/mmapp-server/mmapp/feedbacks/",
  apiUser: urlMobile + "/mmapp-server/users",
  apiGraph: urlMobile + "/mmapp-server/graphDevice",
  apiBackupFile: urlMobile + "/mmapp-server/backup/list/",
  apiBackupRequest: urlMobile + "/mmapp-server/files/download/",
  apiQRCodeProfil: urlMobile + "/mmapp-server/qrcode/profil/",
  timer: realTimeDelay, // temps en "seconde" avant une requète api
  enableTimer: realTime, // activer/désactiver le refresh auto
};

export const varParametres = {
  apiMDM: url + "/api/config/mdm",
  apiSMTP: url + "/api/config/smtp",
  apiNotification: url + "/api/config/notification",
  apiExtansions: url + "/api/config/extensions",
  apiUserPattern: url + "/api/config/userPattern",
  apiAutoEnrollmentAccounts: url + "/api/AutoValidator/autoEnrolement",
  apiConfigServerMMApp: urlMobile + "/mmapp-server/config/",

};

export const varConfigServeur = {
  apiServices: url + "/api/services/",
  apiDNS: url + "/api/config/dns/",
  apiRelais: url + "/api/config/relais/",
  apiLdap: url + "/api/config/ldap/",
  apiProxy: url + "/api/config/proxy/",
  apiServMessagerie: url + "/api/servers/mail/",
  apiServGroupware: url + "/api/servers/groupware/",
  apiServFile: url + "/api/servers/file/",
};
