import React from 'react'
import Select, { components } from 'react-select';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import GroupsAvance from "./GroupsAvance";
import GroupsPolitiqueSecu from "./GroupsPolitiqueSecu";

import { varGroups } from '../variables';
import {varGroupDetail} from '../variables';

const API = varGroupDetail.api;

function validate(name, administration) {
  return {
    name: name.length === 0,
    administration: administration.length === 0,
  };
}

class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      inputData: {
        name: "",
        administration: "0",
        droits: "",
        iseditable: true,
        isremovable: true,
      },

      selectRights: [
        { value: '1111', label: '1111'},
        { value: '2222', label: '2222'},
        { value: '3333', label: '3333'}
      ],

      selectAdministration: [
        { value: "0", label: 'Utilisateur'},
        { value: "1", label: 'Administrateur'},
        { value: "2", label: 'Superviseur'}
      ],

      touched: {
      name: false,
      administration: false,
      //droits: false,
    },

      arrayPolicies: {
        bluetooth:	false,
        browser:	false,
        camera:	false,
        emailfilter:	0,
        password:	false,
        storagecard:	false,
        textmessaging:	false,
        wifi:	false,
        maxattachmentsize:	-1,
        maxdevicepasswordfailedattempts:	-1,
        requiremanualsyncwhenroaming:	false,
        extensions:	"*",
        unsignedapplications:	false,
        desktopsync:	false,
        onlyheader:	false,
        requiredevicencryption:	false,
        requirestoragecardencryption:	false,
      },

      defaultPolicies: true,
      extansionsAutorisation: true,
      selectedOptionExtensions: { value: '*', label: '*'},
      oldSelectedOptionExtensions: null,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCheckboxConfirm = this.handleCheckboxConfirm.bind(this);
    this.handleDefaultPolicies = this.handleDefaultPolicies.bind(this);
    this.handleChangeExtansionsAutorisation = this.handleChangeExtansionsAutorisation.bind(this);
    this.handlePoliciesModif = this.handlePoliciesModif.bind(this);
    this.handleLimitSize = this.handleLimitSize.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //


  componentDidUpdate() {
    if(this.state.isFetching === true){
      $('#ModalValideModif').modal('show');
    }else{$('#ModalValideModif').modal('hide');}

  }

  componentWillUnmount (){
    clearInterval(this.timer);
  }


// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  handleCheckboxChange(e, field){
    this.setState({
      modalCheckboxTitle : "Confirmation de modification",
      modalCheckboxButton : !this.state.arrayPolicies[field] ? this.props.intl.formatMessage({id: "activer"}) : this.props.intl.formatMessage({id: "désactiver"}),
      modalCheckboxMsg : this.warningMessage(field, !this.state.arrayPolicies[field]),
      modalCheckboxField : [field]
    });
    $('#modalCheckbox').modal('show')
  }

  handlePoliciesModif(event, field){
    this.setState({
      arrayPolicies: {
        ...this.state.arrayPolicies,
        [field]: event.target.value
      }
    });
  }

  handleLimitSize(event){
    this.setState({limitSize: !this.state.limitSize});
  }

  warningMessage(field, state){

    if(state === false){

      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.disable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.disable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.disable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.disable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.disable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.disable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.disable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.disable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.disable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.disable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.disable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.disable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.disable"}))
        default:
          return("default")
      }

    }else {
      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.enable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.enable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.enable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.enable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.enable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.enable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.enable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.enable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.enable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.enable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.enable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.enable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.enable"}))
        default:
          return("default")
      }
    }
  }

  handleCheckboxConfirm(field){

    if(String(field) === "password" && this.state.arrayPolicies.requiredevicencryption === true){
      return
    }

      if(String(field) === "requiredevicencryption" && !this.state.arrayPolicies[field] === true){
        var x = this.state.arrayPolicies;
        x.password = true;
        this.setState({ arrayPolicies: x });
      }

    this.setState({
      arrayPolicies: { ...this.state.arrayPolicies, [field]: !this.state.arrayPolicies[field] }
    });
  }

    handleDefaultPolicies() {
      this.setState({defaultPolicies: !this.state.defaultPolicies});
    }

  handleChange= (event, field) => {
    this.setState({
      inputData: { ...this.state.inputData, [field]: event.target.value }
    });
  }


  handleChangeSelect = selectedOption => {
    this.setState({selectedOptionExtensions : selectedOption})
  }


  handleChangeExtansionsAutorisation = state => {
    if (!this.state.extansionsAutorisation) {
      this.setState({
        arrayPolicies: {
          ...this.state.arrayPolicies,
          ["extensions"]: "*"
        },
        oldSelectedOptionExtensions: this.state.selectedOptionExtensions,
        selectedOptionExtensions: { value: '*', label: '*'}
      });
    } else {
      this.setState({
        arrayPolicies: {
          ...this.state.arrayPolicies,
          ["extensions"]: {}
        },
        selectedOptionExtensions: this.state.oldSelectedOptionExtensions
      });
    }
    this.setState({extansionsAutorisation : !this.state.extansionsAutorisation});
  }


  handleChangeSelect(event, field){
    this.setState({
      inputData: {
        ...this.state.inputData,
        [field]: event.value
      }
    });
  }

  handleBlur = field => evt => {
    this.setState({
      touched: { ...this.state.touched, [field]: true }
    });
  };


  sendData(){

    var data = {
    "name": this.state.inputData.name,
    "administration": this.state.inputData.administration,
    "iseditable": this.state.inputData.iseditable,
    "isremovable": this.state.inputData.isremovable
  };

    this.setState({isFetching: true});

    fetch( varGroups.api , {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        var x = response;
        if(response.status === 200){
          return response.json().then(responseRetour => {
            if(!this.state.defaultPolicies){
              this.sendNewGroupPolicies(responseRetour.groupId);
            } else {
              this.props.history.push("/Groups/" + responseRetour.groupId);
            }
          })
        }
        else{
          console.log(response.headers);
         return response.json().then(responseError => {
           console.log(responseError);
           this.requeteResponse(x,responseError);
         })
       }
       })

      this.setState({isFetching: false});
  }


  sendNewGroupPolicies(groupId){
      var adaptData = this.state.arrayPolicies;

      var finalExtensions = null;
      if (this.state.extansionsAutorisation) {
        finalExtensions = "*";
      } else {
        var adaptExtensions = [];
        this.state.selectedOptionExtensions.map((x) => {
          adaptExtensions.push(x.label)
        })
        finalExtensions = JSON.stringify(adaptExtensions);
      }

      console.log(finalExtensions);


      var data = {
            "bluetooth": adaptData.bluetooth,
            "browser": adaptData.browser,
            "camera": adaptData.camera,
            "emailfilter": adaptData.emailfilter,
            "password": adaptData.password,
            "storagecard": adaptData.storagecard,
            "textmessaging": adaptData.textmessaging,
            "wifi": adaptData.wifi,
            "maxattachmentsize": !this.state.limitSize ? "-1" : adaptData.maxattachmentsize,
            "maxdevicepasswordfailedattempts": !this.state.arrayPolicies.password ? "-1" : adaptData.maxdevicepasswordfailedattempts,
            "requiremanualsyncwhenroaming": adaptData.requiremanualsyncwhenroaming,
            "extensions": finalExtensions,
            "unsignedapplications": adaptData.unsignedapplications,
            "desktopsync": adaptData.desktopsync,
            "onlyheader": adaptData.onlyheader
          };

      fetch(API + groupId + "/policy", {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
          body: JSON.stringify(data)
        })
        .then(response => {
          var x = response;
          if(response.status === 200){
            console.log(response);
          this.props.history.push("/Groups/" + groupId);
        }
          else{
            console.log(response.headers);
           return response.json().then(responseError => {
             console.log(responseError);
             this.requeteResponse(x,responseError);
           })
         }
         })
  }


  requeteResponse(response, responseError){
    this.setState({codeStatus: responseError.status, errorM: responseError.error, errorMsg: responseError.message});
          switch(responseError.status) {
              default:
                $('#ModalErreur').modal('show');
                break;
            }
          }


// ------------------------------- Render -------------------------------------- //

  render() {

    const errors = validate(this.state.inputData.name, this.state.inputData.administration);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

      return (
        <div className="col-6 small mt-3 mb-2">

          <h5 className="text-primary text-center mb-2">
            <b>
              <span className="text-success">
                <FormattedMessage id="newGroup.1"/></span>
            </b>
          </h5>

          <h6 className="text-primary text-center mt-2 mb-3">
            <FormattedMessage id="userDetails.configparam"/>
          </h6>

          <ul className="nav nav-tabs mt-3 justify-content-center" id="groupsDetails" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="home-tab" data-toggle="tab" href="#Général" role="tab" aria-controls="home" aria-selected="true"><FormattedMessage id="groupsDetails.2"/></a>
            </li>

{/*
            <li className="nav-item">
              <a className="nav-link " id="profile-tab" data-toggle="tab" href="#Avancé" role="tab" aria-controls="profile" aria-selected="false"><FormattedMessage id="groupsDetails.3"/></a>
            </li>
*/}

            <li className="nav-item">
              <a className="nav-link " id="contact-tab" data-toggle="tab" href="#Politiques" role="tab" aria-controls="contact" aria-selected="false"><FormattedMessage id="groupsDetails.5"/></a>
            </li>

          </ul>


          <div className="tab-content card border-0" id="myTabContent">
            <div className="tab-pane show active mb-3" id="Général" role="tabpanel" aria-labelledby="home-tab">
            <div className="col-8 mt-3 container">
                <div>

                    <h6 className="text-info"><b><FormattedMessage id="groupsDetails.General.1"/></b></h6>
                    <div className="row mb-2 mt-3">
                        <div className="col">
                            <FormattedMessage id="groupsDetails.General.2"/>
                        </div>
                        <div className="col">
                            <input className={`form-control ${shouldMarkError("name") ? "error" : ""}`}
                              value={this.state.name}
                              onChange={e => this.handleChange(e, "name")}
                              onBlur={this.handleBlur("name")}/>
                        </div>
                    </div>

                    <h6 className="text-info"><b><FormattedMessage id="groupsDetails.General.3"/> </b></h6>
                    <div className="row mb-3">
                        <div className="col ml-2 mt-2 mr-2">

                            <select id="inputState"
                              className={`form-control mr-4 ${shouldMarkError("administration") ? "error" : ""}`}
                              onChange={e => this.handleChange(e, "administration")}
                              onBlur={this.handleBlur("administration")}
                              defaultValue={this.state.inputData.administration}>
                              <option className="list-group-item small " value="0">{this.props.intl.formatMessage({id: "group.administration.0"})}</option>
                              <option className="list-group-item small " value="2">{this.props.intl.formatMessage({id: "group.administration.2"})}</option>
                              <option className="list-group-item small " value="3">{this.props.intl.formatMessage({id: "group.administration.3"})}</option>
                              {this.props.userAdministration === "REMOTE_ADMIN" ?
                                null
                                :
                                <option className="list-group-item small " value="1">{this.props.intl.formatMessage({id: "group.administration.1"})}</option>
                              }
                            </select>

                        </div>
                    </div>


{/*
                    <h6 className="text-info"><b><FormattedMessage id="groupsDetails.General.4"/></b></h6>
                    <div className="col mb-3">
                        <div className="custom-control custom-checkbox mb-2">
                            <input className="custom-control-input" type="checkbox" value="" id="LDAPcheck"/>
                            <label className="custom-control-label" htmlFor="LDAPcheck">
                                <FormattedMessage id="groupsDetails.General.5"/>
                            </label>
                        </div>
                    </div>


                    <h6 className="text-info"><b><FormattedMessage id="groupsDetails.General.6"/></b></h6>
                    <div className="col">
                        <div className="row mb-3">
                            <div className="col">
                                <FormattedMessage id="groupsDetails.General.7"/>
                            </div>
                            <div className="col">

                                <select id="inputState"
                                  className={`form-control ${shouldMarkError("droits") ? "error " : ""}`}
                                  onChange={e => this.handleChange(e, "droits")}
                                  onBlur={this.handleBlur("droits")}>
                                  <option className="list-group-item small " value="">-</option>
                                  <option className="list-group-item small " value="111">111</option>
                                  <option className="list-group-item small " value="222">222</option>
                                  <option className="list-group-item small " value="333">333</option>
                                </select>

                            </div>
                        </div>
                    </div>
*/}

                </div>

            </div>
              <div className="row  border-0">
              </div>
            </div>

{/*
            <div className="tab-pane" id="Avancé" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row  border-0">
                <GroupsAvance/>
              </div>
            </div>
*/}

            <div className="tab-pane col" id="Politiques" role="tabpanel" aria-labelledby="profile-tab">
              <div className="row  border-0">
                  <GroupsPolitiqueSecu data={this.state.arrayPolicies}
                  handlePoliciesChange={this.handleCheckboxChange}
                  defaultPolicies={this.state.defaultPolicies}
                  handleDefaultPolicies={this.handleDefaultPolicies}
                  extansionsAutorisation={this.state.extansionsAutorisation}
                  handleChangeExtansionsAutorisation={this.handleChangeExtansionsAutorisation}
                  handleChangeSelect={this.handleChangeSelect}
                  selectedOptionExtensions={this.state.selectedOptionExtensions}
                  handlePoliciesModif={this.handlePoliciesModif}
                  handleLimitSize={this.handleLimitSize}
                  limitSize={this.state.limitSize}/>
              </div>
            </div>
          </div>

          <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
              <div className="text-center mr-1">
                  <button type="button" className="btn btn-secondary" onClick={NewGroup.backPage}><FormattedMessage id="retour"/>
                  </button>
              </div>

              <div className="text-center ml-1">
                  <button type="button" className="btn btn-info" onClick={this.sendData.bind(this)} disabled={isDisabled}>
                      <FormattedMessage id="valider"/>
                  </button>
              </div>
          </div>


          <div className="modal fade" id="ModalValideModif" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{width: '160px'}} role="document">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-center">
                  <h6 className="modal-title text-info" id="exampleModalLabel"><b>
                    Chargement ...
                  </b></h6>
                </div>
                <div className="modal-body text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalCheckbox" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">{this.state.modalCheckboxTitle}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.modalCheckboxMsg}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.intl.formatMessage({id: "annuler"})}</button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal"
                    onClick={this.handleCheckboxConfirm.bind(this, this.state.modalCheckboxField)}>{this.state.modalCheckboxButton}</button>
                </div>
              </div>
            </div>
          </div>

        </div>
    )

  }
}

NewGroup.propTypes = {
  intl: intlShape.isRequired
};
NewGroup = injectIntl(NewGroup, {withRef: true});

export default NewGroup
