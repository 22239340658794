import React, {Component} from 'react';
import ReactTable from 'react-table'
import FilesTab from './FilesTab';
import FilesToolBar from './FilesToolBar';
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {varFiles} from '../variables';


var time = 0;
var newRequete = false;
var firstFetchTab = true;


export function research(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
          if(array[i].account.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
            testArray.push(array[i]);
        }
      }
    }
  if(valeur.length > 0){
    return (testArray)
  }
  return (array)
}


class files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFile: [],
      researchValue: "",
      showPagination: false,
      isLoading: false,
      error: null,
      file: "Fichier Ouvert",
      date: "Date"
    };

    this.tick = this.tick.bind(this)
    this.rechercheHandle = this.rechercheHandle.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    if (varFiles.enableTimer === "on")this.timer = setInterval(this.tick, 1000);
    if(process.env.NODE_ENV === "development")console.log("REFRESH AUTO suivi MM-Files : " + varFiles.enableTimer + " , 1sec, " + varFiles.timer + "sec");
    document.title = "MM-File";
    this.setState({isLoading: true});
    this.fetchFileData(varFiles.api);
  }

  componentWillUnmount() {
    time = 0;
    firstFetchTab = true;
    clearInterval(this.timer);
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchFileData(API) {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if(process.env.NODE_ENV == "development")console.log("fetch : " + hours + ":" + min + ":" + sec);
    fetch(API ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    }).then(data => (
      this.fetchAdapt(data)
    )).catch(error => this.setState({error, isLoading: false}));
    newRequete = true;
  }

  fetchAdapt(data){
    if(data.length > 50 && firstFetchTab === true){
      firstFetchTab = false
      this.setState({showPagination: true})
    }
    this.setState({dataFile: data, isLoading: false});
  }


// ------------------------------- Action handle ------------------------------- //

    rechercheHandle(event) {
      this.setState({researchValue: event.target.value});
    }


    fetching() {
      if (newRequete === false) {
        return (<div className="col d-flex justify-content-center">
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>)
      }
    }


// ------------------------------- Auto refresh table -------------------------- //

      tick() {
        if (newRequete === true) {
          if (time === varFiles.timer) {
            time = 0;
            newRequete = false;
            this.fetchFileData(varFiles.api);
          }
          time = time + 1;
        }
      }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Erreur : {error.message}</span>
        </div>

        <div className="container"></div>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    return (
      <div className="card bg-light float-left mt-3 small">
          <div className="card-header">

          <FilesToolBar researchValue={this.state.researchValue}
            rechercheHandle={this.rechercheHandle}
            isFetching={this.fetching()}/>

          </div>

          <FilesTab array={(research(this.state.dataFile , this.state.researchValue)).sort((a, b) => b.date .localeCompare(a.date))}
          showPagination={this.state.showPagination}/>

      </div>);
  }
}
files.propTypes = {
  intl: intlShape.isRequired
};
files = injectIntl(files, {withRef: true});

export default files;
