import React from 'react'
import {intlShape, injectIntl} from 'react-intl';

class ComputersToolBar extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div className="form-inline">
      <input className="form-control mr-sm-2 row-auto" type="search" style={{
          height: "35px"
        }} 
        placeholder={this.props.intl.formatMessage({id: "recherche"})}
        aria-label="Search"
        value={this.props.researchValue}
        onChange={this.props.researchHandle}/> {this.props.isFetching}
    </div>)
  }
}

ComputersToolBar.propTypes = {
  intl: intlShape.isRequired
};
ComputersToolBar = injectIntl(ComputersToolBar, {withRef: true});

export default ComputersToolBar
