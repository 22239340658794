  import React from 'react'
  import $ from 'jquery'
  import ReactTable from 'react-table'
  import find from 'lodash/find';
  import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
  import ReactTooltip from 'react-tooltip'
  import {MdLens, MdReplay} from "react-icons/md";
  import { GoDeviceMobile } from "react-icons/go";

  import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
  import 'react-circular-progressbar/dist/styles.css';

  import { RiLockPasswordFill } from "react-icons/ri";
  import { BiReset } from "react-icons/bi";
  import { AiFillWarning } from "react-icons/ai";

  import {FaShieldAlt, FaDatabase} from "react-icons/fa";
  import {IoIosWifi, IoMdLock, IoMdUnlock} from "react-icons/io";
  import { MdBattery20, MdBattery30, MdBattery50, MdBattery60, MdBattery80, MdBattery90, MdBatteryFull, MdInfo, MdSimCard, MdSimCardAlert, MdNoSim } from "react-icons/md";
  import '../custom.css';

  import { MenuProvider } from 'react-contexify';
  import 'react-contexify/dist/ReactContexify.min.css';

  import classNames from "classnames";
  import PerfectScrollbar from 'react-perfect-scrollbar';
  import 'react-perfect-scrollbar/dist/css/styles.css';

  import { varTerminaux, varGroupDetail } from '../variables';

  const APIdevicesDetailsApp = varTerminaux.apiDevicesDetailsApp;

  const CustomTbodyComponent = props => (
    <div {...props} className={classNames("rt-tbody", props.className || [])}>
      <PerfectScrollbar>{props.children}</PerfectScrollbar>
    </div>
  );


  function octetAdapt(value){
    var newValue = value;
    if(value > Math.pow(10, 3)){
      newValue = Number(parseInt(value)/Math.pow(10, 3)).toFixed(2) + " Go";
    } else {
      newValue = newValue.toFixed(2) + " Mo";
    }
    return(newValue)
  }

  function changeToDate(int, langue){
      var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      var d = new Date(int);
      var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
      return (ds)
  }



  class DevicesDetailsInfos extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        forcedPassword: "",
        forcedPasswordVerification: "",
        maxPasswordTryBeforeWipe: false,
        maxPasswordTryBeforeWipeValue: 5,
      };

      this.handleChange = this.handleChange.bind(this);
    }


  // ------------------------------- Component MOUNT / UNMOUNT ------------------- //


  // ------------------------------- Action handle ------------------------------- //

  storageClearAction(){

    var data = {};

    data.deviceId = this.props.devicesDetails.android_id;
    data.email = this.props.devicesDetails.user.email;
    data.title = "storage_clear";
    data.state = "action_storage_clear";

    if(process.env.NODE_ENV == "development")
    {
      console.log(data);
    }


    fetch(varTerminaux.apiMMAppServerAction, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        this.hideStorageClearWarningModal();
       })


  }


  factoryResetAction(){

    var data = {};

    data.deviceId = this.props.devicesDetails.android_id;
    data.email = this.props.devicesDetails.user.email;
    data.title = "factory_reset";
    data.state = "action_factory_reset";

    if(process.env.NODE_ENV == "development")
    {
      console.log(data);
    }


    fetch(varTerminaux.apiMMAppServerAction, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        this.hideFactoryResetActionWarningModal();
       })


  }

  validateLocationStateOldAction(){
    if (this.props.devicesDetails.user.email !== null) {
      var dataConf = {
        "locationStateOld": null
      }

      fetch(varTerminaux.apiDevicesDetails + this.props.devicesDetails.model + "/" + this.props.devicesDetails.user.email + "/valideLocationStateOld", {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(dataConf)
        })
        .then(response => {
          if(process.env.NODE_ENV == "development")
          {
            console.log(response);
          }
          this.props.fetchDevicesDetails(APIdevicesDetailsApp + this.props.devicesDetails.model + "/" + this.props.devicesDetails.user.email);
          this.props.refresh();
          this.hideValidateLocationStateOldModal();
         })
    }
  }

  getSimCardInfos(data, secondSimCard){
    var simcardNumber = 0;
    if (secondSimCard) {
      simcardNumber = 1;
    }

  //console.log(dataTemp);

    if(data.simCardInfos && data.simCardInfos[simcardNumber]){
      return (
        <span className="col-auto">
          <ReactTooltip id={`tooltipId ${simcardNumber}`} place="right" effect="solid" className="custom-tooltip-simcard">
              <div>{ data.simCardInfos[simcardNumber].simSubscriptionID ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.simSubscriptionID"}) + " : " + data.simCardInfos[simcardNumber].simSubscriptionID : null }</div>
              <div>{ data.simCardInfos[simcardNumber].iccid ? "ICCID : " + data.simCardInfos[simcardNumber].iccid : null }</div>
              <div>{ data.simCardInfos[simcardNumber].imsi ? "IMSI : " + data.simCardInfos[simcardNumber].imsi : null }</div>
              <div>{ data.simCardInfos[simcardNumber].networkOperatorName ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.networkOperatorName"}) + " : " + data.simCardInfos[simcardNumber].networkOperatorName : null }</div>
              <div>{ data.simCardInfos[simcardNumber].networkOperator ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.networkOperator"}) + " : " + data.simCardInfos[simcardNumber].networkOperator : null }</div>
              <div>{ data.simCardInfos[simcardNumber].mobileDataUsage ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.mobileDataUsage"}) + " : " + data.simCardInfos[simcardNumber].mobileDataUsage : null }</div>
              <div>{ data.simCardInfos[simcardNumber].dataRoamingEnabled != null ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.dataRoamingEnabled"}) + " : " + data.simCardInfos[simcardNumber].dataRoamingEnabled : null }</div>
              <div>{ data.simCardInfos[simcardNumber].networkRoaming != null ? this.props.intl.formatMessage({id: "terminauxDetails.simcard.networkRoaming"}) + " : " + data.simCardInfos[simcardNumber].networkRoaming : null }</div>
          </ReactTooltip>

            <span data-tip data-for={`tooltipId ${simcardNumber}`} style={{color: "#77e733"}}>
              <MdSimCard size={24}/>
            </span>

        </span>
      );
    } else {
      return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_READY"})}
        style={{color: "#77e733"}}>
        <MdSimCard size={24}/>
      </span>
    }
  }

  simCardAdaptState(state, secondSimCard = true){
     switch (state) {
       case "SIM_STATE_UNKNOWN":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_UNKNOWN"})}
             style={{color: "#fc2828"}}>
             <MdNoSim size={24}/>
           </span>
         break;
       case "SIM_STATE_ABSENT":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_ABSENT"})}
             style={{color: "#f4bf22"}}>
             <MdSimCardAlert size={24}/>
           </span>
         break;
       case "SIM_STATE_PIN_REQUIRED":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_PIN_REQUIRED"})}
             style={{color: "#f4bf22"}}>
             <MdSimCardAlert size={24}/>
           </span>
         break;
       case "SIM_STATE_PUK_REQUIRED":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_PUK_REQUIRED"})}
             style={{color: "#f4bf22"}}>
             <MdSimCardAlert size={24}/>
           </span>
         break;
       case "SIM_STATE_NETWORK_LOCKED":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_NETWORK_LOCKED"})}
             style={{color: "#fc2828"}}>
             <MdNoSim size={24}/>
           </span>
         break;
       case "SIM_STATE_READY":
           return this.getSimCardInfos(this.props.devicesDetails, secondSimCard);
         break;
       case "SIM_STATE_NOT_READY":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_NOT_READY"})}
             style={{color: "#f4bf22"}}>
             <MdSimCardAlert size={24}/>
           </span>
         break;
       case "SIM_STATE_PERM_DISABLED":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_PERM_DISABLED"})}
             style={{color: "#fc2828"}}>
             <MdNoSim size={24}/>
           </span>
         break;
       case "SIM_STATE_CARD_IO_ERROR":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_CARD_IO_ERROR"})}
             style={{color: "#fc2828"}}>
             <MdNoSim size={24}/>
           </span>
         break;
       case "SIM_STATE_CARD_RESTRICTED":
           return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.SIM_STATE_CARD_RESTRICTED"})}
             style={{color: "#fc2828"}}>
             <MdNoSim size={24}/>
           </span>
         break;
       default:


       return <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.simcard.default"})}
         style={{color: "#fc2828"}}>
         <MdNoSim size={24}/>
       </span>
     }
   }

  forcePasswordAction(){

    var data = {};

    data.deviceId = this.props.devicesDetails.android_id;
    data.email = this.props.devicesDetails.user.email;
    data.title = "force_password";
    data.state = "action_force_password";
    data.link = this.state.forcedPassword;

    if(process.env.NODE_ENV == "development")
    {
      console.log(data);
    }


    fetch(varTerminaux.apiMMAppServerAction, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        if (!this.state.maxPasswordTryBeforeWipe) {
          this.hideForcePasswordModal();
        }
       })

       if (this.state.maxPasswordTryBeforeWipe) {
         var data2 = {};

         data2.deviceId = this.props.devicesDetails.android_id;
         data2.email = this.props.devicesDetails.user.email;
         data2.title = "password_try_before_wipe";
         data2.state = "action_password_try_before_wipe";
         data2.link = this.state.maxPasswordTryBeforeWipeValue;

         if(process.env.NODE_ENV == "development")
         {
           console.log(data2);
         }


         fetch(varTerminaux.apiMMAppServerAction, {
             method: 'POST',
             headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'X-XSRF-TOKEN': this.props.tokenCSRF
           },
             body: JSON.stringify(data2)
           })
           .then(response => {
             if(process.env.NODE_ENV == "development")
             {
               console.log(response);
             }
             this.hideForcePasswordModal();
            })
       }


  }

  storageClearWarningModal(){
    $('#modalConfirmActionStorageClear').modal('show')
  }

  hideStorageClearWarningModal(){
    $('#modalConfirmActionStorageClear').modal('hide')
  }

  factoryResetActionWarningModal(){
    $('#modalConfirmActionFactoryReset').modal('show')
  }

  hideFactoryResetActionWarningModal(){
    $('#modalConfirmActionFactoryReset').modal('hide')
  }

  validateLocationStateOldModal(){
    $('#modalValidateLocationStateOld').modal('show')
  }

  hideValidateLocationStateOldModal(){
    $('#modalValidateLocationStateOld').modal('hide')
  }

  forcePasswordModal(){
    $('#modalForcePassword').modal('show')
  }

  hideForcePasswordModal(){
      this.setState({forcedPassword: ""})
      this.setState({forcedPasswordVerification: ""})
      this.setState({maxPasswordTryBeforeWipe: false})
      this.setState({maxPasswordTryBeforeWipeValue: 5})
    $('#modalForcePassword').modal('hide')
  }

  handleChange = (event, field) => {
    switch (field) {
      case "forcedPassword":
          this.setState({forcedPassword: event.target.value})
      break;
      case "forcedPasswordVerification":
          this.setState({forcedPasswordVerification: event.target.value})
      break;
      case "maxPasswordTryBeforeWipe":
          this.setState({maxPasswordTryBeforeWipe: !this.state.maxPasswordTryBeforeWipe})
      break;
      case "maxPasswordTryBeforeWipeValue":
        if (/^\d+$/.test(event.target.value)) {
          this.setState({maxPasswordTryBeforeWipeValue: event.target.value})
        }
      break;

}


  }


  createBattery(value){
    var color = "#c4c4c4";
    var size = 26;

    if(value < 20){color = "#fc2828";}
    else if(value < 40){color = "#fc8d25";}
    else if(value < 60){color = "#f4bf22";}
    else if(value < 80){color = "#a5e44a";}
    else {color = "#77e733";}


      return(
          <span style={{
              color: color
            }}> 
            {value < 20 ?
                <MdBattery20 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 30 ?
                <MdBattery30 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 50 ?
                <MdBattery50 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 60 ?
                <MdBattery60 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 80 ?
                <MdBattery80 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 90 ?
                <MdBattery90 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : <MdBatteryFull size={size} style={{transform: `rotate(${90}deg)`}}/> 
            } 
            <b>{value} %</b>
          </span>
    )
  }


    createProgessBar(name, percentage, availableValue, maxValue){

      var color = "#c4c4c4";
      var newAvailableValue = octetAdapt(parseInt(availableValue));
      var newMaxValue = octetAdapt(parseInt(maxValue));

      if(percentage <= 75){color = "#77e733";}
      else if(percentage <= 80){color = "#ffdc29";}
      else if(percentage <= 90){color = "#fc8d25";}
      else {color = "#fc2828";}


        return(
          <div>
            <div className="row mt-3 ml-2">
            <div className="">
              <div className="" style={{ maxWidth: "6vw" }}>
              <CircularProgressbar value={percentage} text={`${percentage}%`}
              styles={buildStyles({
                    textSize: '24px',
                    pathTransitionDuration: 0.5,
                    pathColor: color,
                    textColor: color,
                    trailColor: '#d6d6d6'
                  })}/>

              </div>
            </div>
            <div className="mt-2 ml-3">

              {availableValue ? <p><b className="text-info">{this.props.intl.formatMessage({id: "disponible"})} : </b>{newAvailableValue}</p> : null}
              {maxValue ? <p><b className="text-info">Maximum : </b>{newMaxValue}</p> : null}

            </div>
          </div>

        </div>
      )
    }

  // ------------------------------- Render -------------------------------------- //

    render() {

  // ------------------------------- Setting of the table ------------------------ //

      return (
        <div className="card">
          <div style={{
            height: "51vh",
          }}>

            <div className="row ml-3 mt-3" style={{fontSize: 'calc(5px + 1vmin)'}}>

            <div className="col-5">
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.1"})} </b> : 
                {this.props.devicesDetails.user ? this.props.devicesDetails.user.email : null}
              </p>
              <p className="mt-2"><b> {this.props.intl.formatMessage({id: "terminaux.4"})} </b> : {this.props.devicesDetails.model}</p>
              {this.props.dataConfigServerMMApp.enableLocation === "true" ?
              <div>
                {this.props.devicesDetails.user && this.props.devicesDetails.user.localisation ?
                    <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.location"})} </b> : {this.props.devicesDetails.location}</p>
                    : null}
                {this.props.devicesDetails.user && this.props.devicesDetails.user.localisation && this.props.devicesDetails.coordinates ?
                    <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.coordinates"})} </b> : {this.props.devicesDetails.coordinates}</p>
                    : null}
              </div>
              : null
              }

              {this.props.devicesDetails.locationState !== null ?
                this.props.devicesDetails.locationStateOld !== null && this.props.devicesDetails.locationState !== this.props.devicesDetails.locationStateOld ?
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.valideLocationStateOld"})} </b> :
                    <span className="btn btn-sm btn-warning ml-2" style={{ color: "rgb(255,255,255)" }} onClick={this.validateLocationStateOldModal.bind(this)}>
                      <AiFillWarning size={20}/>
                    </span>
                  </p>

                  :

                  null

                : null }

              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.3"})} </b> : {this.props.devicesDetails.securityLevel}</p>
              {this.props.devicesDetails.basebandVersion ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.9"})} </b> : {this.props.devicesDetails.basebandVersion}</p>
                :
                null
              }

              {this.props.devicesDetails.phoneIMEI ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.phoneIMEI"})} </b> : {this.props.devicesDetails.phoneIMEI}</p>
                :
                null
              }

              {this.props.devicesDetails.phoneNumber ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.phoneNumber"})} </b> : {this.props.devicesDetails.phoneNumber}</p>
                :
                null
              }


            {/*
              this.props.devicesDetails.dualSimPhone != null ?
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.dualSimPhone"})} </b> :
              {this.props.devicesDetails.dualSimPhone ?
                <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.dualSimPhone.true"})}
                  style={{color: "#fc2828"}}>
                <MdSimCard size={24}/>
              </span>

              : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.dualSimPhone.false"})}
                style={{color: "#77e733"}}>
                <MdSimCard size={24}/>
              </span>}
              </p>
                :
                null
            */}



              {this.props.devicesDetails.stateSimCard1 && this.props.devicesDetails.stateSimCard1 !== "SIM_STATE_UNKNOWN" ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.simCard1"})} </b> : {
                  this.simCardAdaptState(this.props.devicesDetails.stateSimCard1, false)
                }</p>
                :
                null
              }


              {this.props.devicesDetails.dualSimPhone && this.props.devicesDetails.stateSimCard2 !== "SIM_STATE_UNKNOWN" && this.props.devicesDetails.dualSimPhone ?
                this.props.devicesDetails.stateSimCard2 ?
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.simCard2"})} </b> : {
                    this.simCardAdaptState(this.props.devicesDetails.stateSimCard2, true)
                  }</p>
                  :
                  null

                :
                null
              }

              {this.props.devicesDetails.locationState && this.props.devicesDetails.locationStateOld ?
                this.props.devicesDetails.locationStateOld ?
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.simCard2"})} </b> : {
                    this.simCardAdaptState(this.props.devicesDetails.stateSimCard2)
                  }</p>
                  :
                  null

                :
                null
              }





            </div>
            <div className="col">
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.4"})} </b> : {this.props.devicesDetails.kernelName}</p>
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.5"})} </b> : {this.props.devicesDetails.kernelVersion}</p>
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.6"})} </b> : {this.props.devicesDetails.os_Version}</p>
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.battery"})} </b> :
                { this.props.devicesDetails.battery != null ? this.createBattery(this.props.devicesDetails.battery) : null}</p>
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure"})} </b> :
              {this.props.devicesDetails.deviceSecure ?
                <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure.locked"})}
                  style={{color: "rgb(81,157,185)"}}>
                <IoMdLock size={26}/>
              </span>

              : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure.unlocked"})}
                style={{color: "rgb(244,191,34)"}}>
                <IoMdUnlock size={26}/>
              </span>}
              </p>

              {this.props.devicesDetails.ownerDevice && !this.props.devicesDetails.deviceSecure ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.forcePassword.button"})} </b> :
                  <span className="btn btn-sm btn-danger ml-2" style={{ color: "rgb(255,255,255)" }} onClick={this.forcePasswordModal.bind(this)}>
                    <RiLockPasswordFill size={20}/>
                  </span>
                </p>

                : null }

              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted"})} </b> :
              {this.props.devicesDetails.deviceRooted ?
                <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted.rooted"})}
                  style={{color: "#fc2828"}}>
                <FaShieldAlt size={24}/>
              </span>

              : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted.notRooted"})}
                style={{color: "#77e733"}}>
                <FaShieldAlt size={24}/>
              </span>}
              </p>

              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.deviceOwner"})} </b> :
              {this.props.devicesDetails.ownerDevice ?
                <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceOwner.true"})}
                  style={{color: "#5591d7"}}>
                <FaShieldAlt size={24}/>
              </span>

              : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceOwner.false"})}
                style={{color: "#fc2828"}}>
                <FaShieldAlt size={24}/>
              </span>}
              </p>


              {this.props.devicesDetails.ownerDevice ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.factoryReset.button"})} </b> :
                  <span className="btn btn-sm btn-danger ml-2" style={{ color: "rgb(255,255,255)" }} onClick={this.factoryResetActionWarningModal.bind(this)}>
                    <BiReset size={22}/>
                  </span>
                </p>

                : null }

            </div>

            <div className="col">
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.7"})} </b> : {changeToDate(this.props.devicesDetails.createdAt, this.props.intl.locale)}</p>
              <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.8"})} </b> : {changeToDate(this.props.devicesDetails.updatedAt, this.props.intl.locale)}</p>

              <b> {this.props.intl.formatMessage({id: "terminauxDetails.header.phoneStorage"})} </b> :
              <div className="mt-2">
                { this.props.devicesDetails.storage ? this.createProgessBar("", Number(((this.props.devicesDetails.storage - this.props.devicesDetails.storageAvailable)/this.props.devicesDetails.storage)*100).toFixed(2), this.props.devicesDetails.storageAvailable, this.props.devicesDetails.storage) : null}
              </div>
              <div className="mt-2">
              {this.props.devicesDetails.storageOnServeur && this.props.devicesDetails.storageOnServeur > 0 ?
                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.storageOnServeur"})} </b> : {octetAdapt(this.props.devicesDetails.storageOnServeur / (1024*1024))}</p>
                : null
              }
              </div>

                <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.storageClear.button"})} </b> :
                  <span className="btn btn-sm btn-warning ml-2" style={{ color: "rgb(255,255,255)" }} onClick={this.storageClearWarningModal.bind(this)}>
                    <FaDatabase size={22}/>
                  </span>
                </p>

            </div>
          </div>

          {this.props.devicesDetails.wifi ?
            this.props.devicesDetails.wifi.length > 0 ?
            <ReactTooltip className="relayClass" id='wifiList' type={'light'} border={true} effect={'solid'} place={'bottom'}>
              <div style={{"listStyleType": "circle"}}>
                {
                this.props.devicesDetails.wifi.map((x, index) =>
                x.length > 0 ?
                  <li key={index}>{x.name}</li>
                  : null
              )
            }
            </div>
            </ReactTooltip>

              :null
              :null

          }
          </div>


          <div className="modal fade" id="modalConfirmActionStorageClear" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className={`modal-content modalConfirm border-warning rounded-0`} style={{zIndex: '4000'}}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "terminauxDetails.storageClear.title"})}
                  </h5>
                  <button type="button" className="close" aria-label="Close" onClick={this.hideStorageClearWarningModal.bind(this)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.storageClear.message"})}</p>

                      {this.props.intl.formatMessage({id: "confirmer"})}
                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.hideStorageClearWarningModal.bind(this)}><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-warning`} onClick={this.storageClearAction.bind(this)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalConfirmActionFactoryReset" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className={`modal-content modalConfirm border-danger rounded-0`} style={{zIndex: '4000'}}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "terminauxDetails.factoryReset.title"})}
                  </h5>
                  <button type="button" className="close" aria-label="Close" onClick={this.hideFactoryResetActionWarningModal.bind(this)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.factoryReset.message"})}</p>

                      {this.props.intl.formatMessage({id: "confirmer"})}
                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.hideFactoryResetActionWarningModal.bind(this)}><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-danger`} onClick={this.factoryResetAction.bind(this)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="modalValidateLocationStateOld" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className={`modal-content modalConfirm border-warning rounded-0`} style={{zIndex: '4000'}}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.title"})}
                  </h5><button type="button" className="close" aria-label="Close" onClick={this.hideValidateLocationStateOldModal.bind(this)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.1"})}</p>

                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.2"})}
                        <b className="text-warning">{this.props.devicesDetails.locationState ? this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.2.enabled"})
                      : this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.2.disabled"})}</b></p>

                      <p>{this.props.devicesDetails.locationState ? null
                      : this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.4"})}</p>

                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.validateLocationStateOld.message.3"})}</p>

                      {this.props.intl.formatMessage({id: "confirmer"})}
                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.hideValidateLocationStateOldModal.bind(this)}><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-warning`} onClick={this.validateLocationStateOldAction.bind(this)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalForcePassword" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className={`modal-content modalConfirm border-danger rounded-0`} style={{zIndex: '4000'}}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "terminauxDetails.forcePassword.title"})}
                  </h5>
                  <button type="button" className="close" aria-label="Close" onClick={this.hideForcePasswordModal.bind(this)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                      <p>{this.props.intl.formatMessage({id: "terminauxDetails.forcePassword.message"})}</p>

                      <div className="row mb-1">
                        <div className="col-6">
                          <FormattedMessage id="terminauxDetails.forcePassword.message.password"/>
                        </div>
                        <div className="col">
                          <input type="password" className="form-control" id="qRCodeRelais" value={this.state.forcedPassword} onChange={e => this.handleChange(e, "forcedPassword")}/>
                        </div>
                      </div>

                      <div className="row mb-1">
                        <div className="col-6">
                          <FormattedMessage id="terminauxDetails.forcePassword.message.passwordVerification"/>
                        </div>
                        <div className="col">
                          <input type="password" className="form-control" id="qRCodeRelais" value={this.state.forcedPasswordVerification} onChange={e => this.handleChange(e, "forcedPasswordVerification")}/>
                        </div>
                      </div>

                      <div className="custom-control custom-checkbox mt-2 mb-2">
                        <input type="checkbox" className="custom-control-input" id="allSystemApps" checked={this.state.maxPasswordTryBeforeWipe} onChange={e => this.handleChange(e, "maxPasswordTryBeforeWipe")}/>
                        <label className="custom-control-label" htmlFor="allSystemApps"><FormattedMessage id="terminauxDetails.maxPasswordTryBeforeWipe.title"/></label>
                      </div>

                      {this.state.maxPasswordTryBeforeWipe ?

                        <div className="row mb-1">
                          <div className="col-6">
                            <FormattedMessage id="terminauxDetails.maxPasswordTryBeforeWipe.message"/>
                          </div>
                          <div className="col">
                            <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            value={this.state.maxPasswordTryBeforeWipeValue}
                               onChange={e => this.handleChange(e, "maxPasswordTryBeforeWipeValue")}/></div>
                        </div>

                        : null
                      }





                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.hideForcePasswordModal.bind(this)}><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-danger`} onClick={this.forcePasswordAction.bind(this)} disabled={this.state.forcedPassword.length === 0 || this.state.forcedPassword != this.state.forcedPasswordVerification}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>)
    }
  }

  DevicesDetailsInfos.propTypes = {
    intl: intlShape.isRequired
  };
  DevicesDetailsInfos = injectIntl(DevicesDetailsInfos, {withRef: true});

  export default DevicesDetailsInfos
