import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux, varGroupDetail } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose,
  IoMdAdd
} from "react-icons/io";

import {
  MdBlock
} from "react-icons/md";


var contextMenu = {
  "appCompliant": false,
  "appNotCompliant": false,
  "addAppToGroup": true,
  "forceAppUpdate": false,
  "forceAppUninstall": false,
  "forceAppHide": false,
}


const iconSize = 22;

class DevicesDetailsApplistContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

    checkData(){
      if (this.props.devicesDetails.ownerDevice && this.props.userAdministration === "ADMINISTRATOR") {

        if (this.props.appHit.applicationSystem != null) {

          if (this.props.appHit.compliantStatus === "1"
          && this.props.appHit.application.storeVersion
          && this.props.appHit.application.storeVersionCode
          && this.props.appHit.applicationSystem === true) {

              contextMenu.forceAppUpdate = true;
          } else {
              contextMenu.forceAppUpdate = false;
          }
          if (this.props.appHit.applicationSystem) {

            contextMenu.forceAppUninstall = false;
            contextMenu.forceAppHide = true;

          } else {

            contextMenu.forceAppUninstall = true;
            contextMenu.forceAppHide = true;

          }


        } else {

          if (this.props.appHit.compliantStatus === "1"
          && this.props.appHit.application.storeVersion
          && this.props.appHit.application.storeVersionCode) {
              contextMenu.forceAppUpdate = true;
          } else {
              contextMenu.forceAppUpdate = false;
          }
            contextMenu.forceAppUninstall = true;
            contextMenu.forceAppHide = true;
        }

      } else {
          contextMenu.forceAppUpdate = false;
          contextMenu.forceAppUninstall = false;
          contextMenu.forceAppHide = false;
      }
    }

// ------------------------------- Action handle ------------------------------- //

  showModal(type, color, id){
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenuApp').modal('show')
  }

  hideModal(){
    $('#modalConfirmActionContextMenuApp').modal('hide')
  }


  actionMenu(id){
    switch (id) {
      case "appCompliant":

          var data = {};

          data.name = this.props.appHit.name;
          data.packageName = this.props.appHit.packageName;
          data.version = this.props.appHit.version;
          data.versionCode = this.props.appHit.versionCode;

          fetch(varTerminaux.apiDevicesDetails + "/application/reference", {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: JSON.stringify(data)
            })
            .then(response => {
              if(process.env.NODE_ENV == "development")
              {
                console.log(response);
              }
              if(response.status === 200){
                this.props.fetchAllDevices();
              }
              var x = response;
             })

        break;

        case "addAppToGroup":

              const dataAddGrp = this.props.appHit.packageName;

              const groupId = this.props.hit.users[0].group.groupId

              if (groupId) {
                fetch(varGroupDetail.apiGroupReferenceApplications + groupId, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': this.props.tokenCSRF
                  },
                    body: JSON.stringify(dataAddGrp)
                  })
                  .then(response => {
                    if(process.env.NODE_ENV == "development")
                    {
                      console.log(response);
                    }
                    if(response.status === 200){
                      this.props.fetchAllDevices();
                    }
                    var x = response;
                   })
              }


          break;

          case "forceAppUpdate":

              var data = {};

              data.deviceId = this.props.devicesDetails.android_id;
              data.email = this.props.devicesDetails.user.email;
              data.title = this.props.appHit.packageName;
              data.state = "action_install_app";

              if(process.env.NODE_ENV == "development")
              {
                console.log(data);
              }

              fetch(varTerminaux.apiMMAppServerAction, {
                  method: 'POST',
                  headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-XSRF-TOKEN': this.props.tokenCSRF
                },
                  body: JSON.stringify(data)
                })
                .then(response => {
                  if(process.env.NODE_ENV == "development")
                  {
                    console.log(response);
                  }
                 })

            break;

          case "forceAppUninstall":

              var data = {};

              data.deviceId = this.props.devicesDetails.android_id;
              data.email = this.props.devicesDetails.user.email;
              data.title = this.props.appHit.packageName;
              data.state = "action_uninstall_app";

              fetch(varTerminaux.apiMMAppServerAction, {
                  method: 'POST',
                  headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-XSRF-TOKEN': this.props.tokenCSRF
                },
                  body: JSON.stringify(data)
                })
                .then(response => {
                  if(process.env.NODE_ENV == "development")
                  {
                    console.log(response);
                  }
                 })

            break;

          case "forceAppHide":

              var data = {};

              data.deviceId = this.props.devicesDetails.android_id;
              data.email = this.props.devicesDetails.user.email;
              data.title = this.props.appHit.packageName;
              data.state = "action_hide_app";

              fetch(varTerminaux.apiMMAppServerAction, {
                  method: 'POST',
                  headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-XSRF-TOKEN': this.props.tokenCSRF
                },
                  body: JSON.stringify(data)
                })
                .then(response => {
                  if(process.env.NODE_ENV == "development")
                  {
                    console.log(response);
                  }
                 })

            break;
      default:
    }
    this.hideModal();
  }


// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "appCompliant":
          return (<Item onClick={this.showModal.bind(this, name, "info", "appCompliant")}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "appNotCompliant":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "appNotCompliant")}>
            <span style={{
                color: "rgb(230,41,41)"
              }}>
              <IoMdClose size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "addAppToGroup":
          return (<Item onClick={this.showModal.bind(this, name, "success", "addAppToGroup")}>
            <span style={{
                color: "rgb(43,185,49)"
              }}>
              <IoMdAdd size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

          case "forceAppUpdate":
            return (<Item onClick={this.showModal.bind(this, name, "warning", "forceAppUpdate")}>
              <span style={{
                  color: "rgb(255,184,46)"
                }}>
                <IoMdCheckmark size={iconSize}/>
              </span>
                {name}
            </Item>)
            break;

          case "forceAppUninstall":
            return (<Item onClick={this.showModal.bind(this, name, "danger", "forceAppUninstall")}>
              <span style={{
                  color: "rgb(230,41,41)"
                }}>
                <IoMdClose size={iconSize}/>
              </span>
                {name}
            </Item>)
            break;

            case "forceAppHide":
              return (<Item onClick={this.showModal.bind(this, name, "danger", "forceAppHide")}>
                <span style={{
                    color: "rgb(230,41,41)"
                  }}>
                  <MdBlock size={iconSize}/>
                </span>
                  {name}
              </Item>)
              break;

        default:
          break;
      }
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if (this.props.appHit != "empty")
      return (<div>

        <Menu id='menu_devicesDetailsApplistContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("appCompliant", contextMenu.appCompliant, this.props.intl.formatMessage({id: "terminaux.appCompliant"}))}
          {this.testMenu("appNotCompliant", contextMenu.appNotCompliant, this.props.intl.formatMessage({id: "terminaux.appNotCompliant"}))}
          {this.testMenu("addAppToGroup", contextMenu.addAppToGroup, this.props.intl.formatMessage({id: "terminaux.addAppToGroup"}))}
          {this.testMenu("forceAppUpdate", contextMenu.forceAppUpdate, this.props.intl.formatMessage({id: "terminauxDetails.tab.1.forceAppUpdate"}))}
          {this.testMenu("forceAppUninstall", contextMenu.forceAppUninstall, this.props.intl.formatMessage({id: "terminauxDetails.tab.1.forceAppUninstall"}))}
          {this.testMenu("forceAppHide", contextMenu.forceAppHide, this.props.intl.formatMessage({id: "terminauxDetails.tab.1.forceAppHide"}))}

        </Menu>

        <div className="modal fade" id={"ModalGraph"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-graph modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title text-info" id="exampleModalLabel">
                  <b>
                    {this.props.intl.formatMessage({id: "terminaux.dropdown.9"})}
                    : {this.props.modalName}
                  </b>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center small">

                <div className="d-flex justify-content-center">
                  <iframe className="embed-responsive-item border-0" src={this.props.modalAddress} width="800" height="470"></iframe>
                </div>

              </div>
              <div className="modal-footer justify-content-center d-flex">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modalConfirmActionContextMenuApp" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`} style={{zIndex: '4000'}}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" onClick={this.hideModal.bind(this)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={this.hideModal.bind(this)}><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>)
    else {
      return (<div/>)
    }
  }
}

DevicesDetailsApplistContextMenu.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsApplistContextMenu = injectIntl(DevicesDetailsApplistContextMenu, {withRef: true});

export default DevicesDetailsApplistContextMenu
