import React from 'react'
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import {
  IoMdCheckmark,
  IoMdClose,
  IoIosInformationCircle,
  IoIosWarning,
  IoIosAlert
} from "react-icons/io";
import { FaUser, FaUsers } from "react-icons/fa";
import { GoDeviceMobile } from "react-icons/go";
import { MdGroup, MdMessage } from "react-icons/md";

import {varMmapp} from '../variables';


var contextMenu = {
  "messageDelete": false,
  "messageDetails": false,
}


const iconSize = 22;

class MMAppMessagesTabContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

  checkData() {

    const hit = this.props.hit;

    if (hit != "empty" && hit) {


      if (this.props.userAdministration === "ADMINISTRATOR") {
        contextMenu.messageDelete = true;
      } else {
        contextMenu.messageDelete = false;
      }
      contextMenu.messageDetails = true;

    } else {
        contextMenu.messageDelete = false;
        contextMenu.messageDetails = false;
    }
  }



// ------------------------------- Action handle ------------------------------- //


  actionMenu(id){
    switch (id) {
      case "messageDelete":

      fetch(varMmapp.apiMessage + this.props.hit.id, {
          method: 'DELETE',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: ""
        })
        .then(response => {
          if(process.env.NODE_ENV == "development")console.log(response);
          var x = response;
          if(response.status === 200){this.props.refetch()}
          else{
           return response.json().then(responseError => {
             console.log(responseError);
             this.requeteResponse(x,responseError);
           })
         }
         })

        break;

      default:
    }
  }

  showModal(type, test, data){
    this.setState({modalTXT: test, modalData: data})
    $('#modalMMAppMessage').modal('show')
  }


// ------------------------------- Function with visual ------------------------ //


  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "messageDelete":
          return (<Item onClick={this.actionMenu.bind(this, "messageDelete")}>
            <span style={{
                color: "rgb(232,35,35)"
              }}>
              <IoMdClose size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "messageDetails":
          return (<Item onClick={this.showModal.bind(this, "messageDetails", this.props.hit.title, this.props.hit.message)}>
            <span style={{
                color: "rgb(51,154,232)"
              }}>
              <MdMessage size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        default:
          break;
      }
    }
  }



// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if (this.props.hit != "empty")
      return (<div>

        <Menu id='menu_mmAppMessagesTabContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("messageDetails", contextMenu.messageDetails, this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.showMessageDetails"}))}
          {this.testMenu("messageDelete", contextMenu.messageDelete, this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.deleteMessage"}))}

        </Menu>


        <div className="modal fade" id="modalMMAppMessage" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{this.props.intl.formatMessage({id: "mmapp.messageTab.contextMenu.showMessageDetails.modal.title"})}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                  <div className="row mb-3">

                    <div className="col-auto">

                      {this.props.hit.type == "user" ?
                          <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.user.tooltip"})}>
                            <FaUser size={32}/>
                          </span>
                        :
                        this.props.hit.type == "group" ?
                          <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.group.tooltip"})}>
                            <FaUsers size={34}/>
                          </span>
                        :
                        this.props.hit.type == "device" ?
                          <span style={{ color: "rgb(151,183,208)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.device.tooltip"})}>
                            <GoDeviceMobile size={34}/>
                          </span>
                        :
                        this.props.hit.type == "all" ?
                          <span style={{ color: "rgb(44,165,255)" }} title={this.props.intl.formatMessage({id: "mmapp.messageTab.type.all.tooltip"})}>
                            <GoDeviceMobile size={34}/>
                          </span>
                        :
                        null
                      }

                    </div>


                    <div className="col">

                      <input type="text" className="form-control" value={this.props.hit.type === "all" ?
                       this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"}) : this.props.hit.destinataire}/>

                    </div>

                    <div className="col-auto">

                      {this.props.hit.state == "info" ?
                        <span style={{ color: "rgb(44,165,255)" }}>
                          <IoIosInformationCircle size={34}/>
                        </span>
                        :
                        this.props.hit.state == "warning" ?
                          <span style={{ color: "rgb(255,159,47)" }}>
                            <IoIosWarning size={34}/>
                          </span>
                        :
                        this.props.hit.state == "error" ?
                          <span style={{ color: "rgb(250,31,31)" }}>
                            <IoIosAlert size={34}/>
                          </span>
                        :
                        null
                      }

                    </div>


                  </div>


                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.title"})}</span>
                    </div>
                    <input type="text" className="form-control" maxLength="100" value={this.props.hit.title}/>
                  </div>

                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.message"})}</span>
                    </div>
                    <textarea className="form-control" rows={2} style={{ resize: "none" }} maxLength="250" value={this.props.hit.message}></textarea>
                  </div>

                  {this.props.hit.link ?
                    <div className="input-group input-group-sm mt-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.link"})}</span>
                      </div>
                      <input type="text" className="form-control" maxLength="100" value={this.props.hit.link}/>
                    </div>
                    :
                    null
                  }



              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

MMAppMessagesTabContextMenu.propTypes = {
  intl: intlShape.isRequired
};
MMAppMessagesTabContextMenu = injectIntl(MMAppMessagesTabContextMenu, {withRef: true});

export default MMAppMessagesTabContextMenu
