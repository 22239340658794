import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage} from 'react-intl';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {BsFillQuestionCircleFill} from "react-icons/bs";

const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);


  function changeToDate(int, langue){
    var options = { weekday: 'short', year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }

  function octetAdapt(value){
    var newValue = value;
    if(value > Math.pow(10, 3)){
      newValue = Number(parseInt(value)/Math.pow(10, 3)).toFixed(2) + " Go";
    } else {
      newValue = newValue.toFixed(2) + " Mo";
    }
    return(newValue)
  }

  function createProgessBarForMobileData(value, max){
    if (value) {
      var affichedValue = value.substring(0, value.indexOf(" "));
      var affichedIdicator = "";
      var newValue;
      var i = value.substring(value.indexOf(" ")+1);
      switch (i) {
        case "B":
              newValue = parseFloat(affichedValue.replace(',', '.')) * 1024;
              affichedIdicator = "o"
          break;
        case "MB":
              newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024);
              affichedIdicator = "Mo"
          break;
        case "GB":
              newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024);
              affichedIdicator = "Go"
          break;
        case "TB":
              newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024 * 1024);
              affichedIdicator = "To"
          break;
        default:

      }
      var color = null;

      if (!max) {
        var percentage = 0;
      } else {
        var percentage = (newValue / max)*100;
        if(percentage <= 75){color = "#69dd22";}
        else if(percentage <= 80){color = "#e6c51d";}
        else if(percentage <= 90){color = "#e97e1a";}
        else {color = "#d91c1c";}
      }

      return (
        <div className="progress position-relative mt-1" title={`${value}%`}>
          <div className="progress-bar" role="progressbar" style={{width:`${percentage}%`, backgroundColor:`${color}`}} aria-valuemin="0" aria-valuemax="100"></div>
          <b className="justify-content-center d-flex position-absolute w-100 p-2" style={{fontSize: 'calc(2px + 1vmin)', color: '#ffffff',
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>{affichedValue} {affichedIdicator}</b>
        </div>
      )
    } else {
      return null;
    }
  }

const GeneralModals = ({texte, etat, bouton, id, data, langue}) => {



// ------------------------------- Setting of the table ------------------------ //

  const getTheadThProps = (table, row, col) => {
    const sortedCol = find(table.sorted, {id: col.id});
    const boxShadow = sortedCol
      ? `inset 0px ${sortedCol.desc
        ? -2
        : 2}px 0px 0px white`
      : '';
    return {
      style: {
        boxShadow
      },
      onContextMenu: (e) => {
        this.props.contextMenu("empty");
      }
    };
  };

  switch (id) {
    case "grp":
      var columns = [
        {
          Header: () => (<span>
            <b>
              nom</b>
          </span>),
          accessor: 'model'
        }, {
          Header: () => (<span>
            <b>
              nb
            </b>
          </span>),
          accessor: 'value'
        }
      ]
      break;
    case "nbtermdesac":
    case "nbtermval":
    case "termrenouv":
    case "termconf":
    case "nbuserdesac":

      var columns = [
        {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.2.1"/></b>
          </span>),
          accessor: 'name',
          minWidth: 150
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.2.2"/>
            </b>
          </span>),
          accessor: 'imei'
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.2.3"/>
            </b>
          </span>),
          accessor: 'model',
          minWidth: 120
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.2.4"/>
            </b>
          </span>),
          accessor: 'os',
          minWidth: 70
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.2.5"/>
            </b>
          </span>),
          accessor: 'users',
          minWidth: 80
        }
      ]
      break;


    case "nbtermatt":

      var columns = [
        {
          Header: () => (<span>
            <b>
              Identifiant</b>
          </span>),
          accessor: 'name',
          minWidth: 150
        }, {
          Header: () => (<span>
            <b>
              Modèle
            </b>
          </span>),
          accessor: 'model',
          minWidth: 120
        }, {
          Header: () => (<span>
            <b>
              O.S.
            </b>
          </span>),
          accessor: 'os',
          minWidth: 70
        }, {
          Header: () => (<span>
            <b>
              Utilisateurs
            </b>
          </span>),
          accessor: 'users',
          minWidth: 80
        }
      ]
      break;

    case "deviceCloseToMobileDataLimit":
      var columns = [
        {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.9.1"/></b>
          </span>),
          accessor: 'model',
          minWidth: 70,
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.9.2"/>
            </b>
          </span>),
          accessor: 'email'
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.9.3"/>
            </b>
          </span>),
          accessor: 'percentage',
          minWidth: 70,
          Cell: row => (
              createProgessBarForMobileData(row.original.data.mobile_data_usage, row.original.data.server_simcard_mobile_data_limit)
            )
        }
      ]
      break;

      case "termconfapp":
      case "termrooted":
        var columns = [
          {
            Header: () => (<span>
              <b>
                <FormattedMessage id="general.modal.13.2"/>
              </b>
            </span>),
            accessor: 'email'
          }, {
            Header: () => (<span>
              <b>
                <FormattedMessage id="general.modal.13.3"/>
              </b>
            </span>),
            accessor: 'model'
          }
        ]
        break;
        case "deviceOutOfGeofencingZone":
          var columns = [
            {
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.18.1"/>
                </b>
              </span>),
              accessor: 'email'
            }, {
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.18.2"/>
                </b>
              </span>),
              accessor: 'model'
            }, {
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.18.3"/>
                </b>
              </span>),
              accessor: 'geofencing_zone_infos'
            }
          ]
          break;

        case "termDangerousPermissions":
          var columns = [
            {
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.1"/>
                </b>
              </span>),
              accessor: 'email'
            },{
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.2"/>
                </b>
              </span>),
              accessor: 'login'
            },{
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.3"/>
                </b>
              </span>),
              accessor: 'model'
            },{
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.4"/>
                </b>
              </span>),
              accessor: 'group_name'
            }, {
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.5"/>
                </b>
              </span>),
              accessor: 'group_permission'
            },{
              Header: () => (<span>
                <b>
                  <FormattedMessage id="general.modal.16.6"/>
                </b>
              </span>),
              minWidth: 200,
              Cell: row => (
                  <div>
                    <span style={{
                        color: "rgb(49, 147, 238)"
                      }}>
                      <BsFillQuestionCircleFill size={24} title={row.original.applist.map((x, index) => {
                        return x.names + "\n";
                      })}/>
                    </span>
                  </div>
                )
            }
          ]
          break;

          case "allDevicesStorage":
            var columns = [
              {
                Header: () => (<span>
                  <b>
                    <FormattedMessage id="general.modal.17.1"/>
                  </b>
                </span>),
                accessor: 'email'
              },{
                Header: () => (<span>
                  <b>
                    <FormattedMessage id="general.modal.17.2"/>
                  </b>
                </span>),
                accessor: 'model'
              }, {
                Header: () => (<span>
                  <b>
                    <FormattedMessage id="general.modal.17.3"/>
                  </b>
                </span>),
                accessor: 'storage',

                Cell: row => (
                    <div>
                        {Number(row.value).toFixed(2) + " %"}
                    </div>
                  )
              }
            ]
            break;


    case "imap":
    case "groupware":
    case "ldap":
      var columns = [
        {
          Header: () => (<span>
            <b><FormattedMessage id="general.modal.10.1"/></b>
          </span>),
          accessor: 'account',
          minWidth: 100,
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.10.2"/>
            </b>
          </span>),
          accessor: 'date',
          minWidth: 140,

          Cell: row => (
              <div>
                <span>
                  {changeToDate(row.value, langue)}
                </span>
              </div>
            )

        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.10.3"/>
            </b>
          </span>),
          accessor: 'topic',
          minWidth: 140,
        }, {
          Header: () => (<span>
            <b>
              <FormattedMessage id="general.modal.10.4"/>
            </b>
          </span>),
          accessor: 'occurrence',
          minWidth: 70,
        }
      ]
      break;

    default:
      return ("wrong id")
  }

  return (id === "imap" || id === "groupware" || id === "ldap" ?

  <ReactTable getTheadThProps={getTheadThProps} showPagination={false} data={data} noDataText={<FormattedMessage id = {
      "noElementsTab"
    } />} TbodyComponent={CustomTbodyComponent}
    columns={columns}
    resizable={false}
    pageSizeOptions={[10, 20, 25, 50, 100]}
    showPageSizeOptions={false}
    pageSize={data.length} style={{
      maxHeight: "61vh"
    }} className=" text-center border-0 small"/>

    :<div>

      {id === "termDangerousPermissions" || id === "deviceOutOfGeofencingZone" ?

      <ReactTable getTheadThProps={getTheadThProps} showPagination={false} data={data} noDataText={<FormattedMessage id = {
      "noElementsTab"
    } />} TbodyComponent={CustomTbodyComponent}
    columns={columns}
    resizable={true}
    pageSizeOptions={[10, 20, 25, 50, 100]}
    showPageSizeOptions={false}
    pageSize={data.length} style={{
      maxHeight: "61vh"
    }} className=" text-center border-0 small"

  />
      :
      <ReactTable getTheadThProps={getTheadThProps} showPagination={false} data={data} noDataText={<FormattedMessage id = {
      "noElementsTab"
    } />} TbodyComponent={CustomTbodyComponent}
    columns={columns}
    resizable={false}
    pageSizeOptions={[10, 20, 25, 50, 100]}
    showPageSizeOptions={false}
    pageSize={data.length} style={{
      maxHeight: "61vh"
    }} className=" text-center border-0 small"

  />
}


    </div>



  )

}
export default GeneralModals
