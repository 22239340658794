import React from 'react'
import Select, { components } from 'react-select';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import { varTerminaux, varUser } from '../variables';
import {IoIosCloseCircleOutline, IoMdCloudDownload, IoIosApps, IoMdInformationCircle} from "react-icons/io";

const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>

           {props.data.label}  
    {
      props.data.link == 1 ?
      <span
        style={{color: "rgb(28,150,210)"}}>
        <IoMdCloudDownload size={24}/>
      </span>
      : null
    }

    {
      props.data.applicationSystem === true ?
      <span style={{color: "rgb(28,150,210)"}}>
        <IoMdInformationCircle size={24}/>
      </span>
      : null
    }

    </Option>
);


class LinkDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };


  }

  componentDidMount() {

    Promise.all([fetch(varUser.api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varTerminaux.api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resAPIUsers, resAPIDevices]) => Promise.all([resAPIUsers.json(), resAPIDevices.json()]))
        .then(([dataAPIUsers, dataAPIDevices]) => (this.setState({
            dataAPIUsers: dataAPIUsers, dataAPIDevices: dataAPIDevices, isLoading: false
        })));
  }


  static backPage() {
    window.history.back();
  }

  adaptUsersList(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;

        item.value = list[i];
        item.userId = list[i].userId;
        item.label = list[i].name + " / " + list[i].login;

        if (item.label !== undefined) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  adaptDevicesList(list){

      if (list) {
        var newArray = [];

        for (var i = 0; i < list.length; i++) {

          var item = new Object;

          item.value = list[i];
          item.deviceId = list[i].deviceId;
          item.label = list[i].name + " / " + list[i].model;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }
  }

  handleChangeSelectUser = selectedOption => {
    this.setState({ selectedUser: selectedOption });
  }

  handleChangeSelectDevice = selectedOption => {
    this.setState({ selectedDevice: selectedOption });
  }

// ------------------------------- Component MOUNT / UNMOUNT ------------------- //


// ------------------------------- Action handle ------------------------------- //

getSelectFormat(){
  var list = [{name:"test"},{name:"deux"}]


  if (list) {
    var newArray = [];

    for (var i = 0; i < list.length; i++) {

      var item = new Object;

      item.value = list[i];

      item.label = list[i].name;

    }

    return newArray.sort((a, b) => a.label.localeCompare(b.label));
  }
}

sendData(){

  if (this.state.selectedDevice.deviceId && this.state.selectedUser.userId) {
    fetch( varTerminaux.api + "devicetolink" + "/" + this.state.selectedDevice.deviceId + "/" + this.state.selectedUser.userId, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: null
      })
      .then(response => {
        var x = response;
        if(response.status === 200){
          return response.json().then(responseRetour => {
              this.props.history.push("/Devices/");
          })
        }
        else{
          console.log(response.headers);
         return response.json().then(responseError => {
           console.log(responseError);
           this.requeteResponse(x,responseError);
         })
       }
       })
  }
}

// ------------------------------- DATA fetching ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  render() {
    return(

          <div className="col-6 small mt-4 mb-2">

            <h5 className="text-primary text-center mb-3">
              <b>
                <span className="text-success">
                  <FormattedMessage id="linkDevice.1"/></span>
              </b>
            </h5>

            <div className="card border">
              <div className="col-8 mt-3 container mb-2">
                  <div>

                      <h6 className="text-info"><b><FormattedMessage id="linkDevice.general.1"/></b></h6>

                      <Select className="mt-2 mb-3"
                        maxMenuHeight={235}
                        onChange={this.handleChangeSelectUser}
                        options={this.adaptUsersList(this.state.dataAPIUsers)}
                        components={{ Option: IconOption }}
                      />


                  </div>

                  <div>

                      <h6 className="text-info"><b><FormattedMessage id="linkDevice.general.2"/></b></h6>

                      <Select className="mt-2 mb-3"
                        maxMenuHeight={235}
                        onChange={this.handleChangeSelectDevice}
                        options={this.adaptDevicesList(this.state.dataAPIDevices)}
                        components={{ Option: IconOption }}
                      />

                  </div>

              </div>
            </div>


            <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
                <div className="text-center mr-1">
                    <button type="button" className="btn btn-secondary" onClick={LinkDevice.backPage}><FormattedMessage id="retour"/>
                    </button>
                </div>

                <div className="text-center ml-1">
                    <button type="button" className="btn btn-info" onClick={this.sendData.bind(this)}>
                        <FormattedMessage id="valider"/>
                    </button>
                </div>
            </div>


          </div>

        )
  }
}
LinkDevice.propTypes = {
  intl: intlShape.isRequired
};
LinkDevice = injectIntl(LinkDevice,
                        {withRef: true});
export default LinkDevice
