import React from 'react'
import $ from "jquery";
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


import {IoIosApps, IoIosCloseCircleOutline} from "react-icons/io";
import { CgArrowBottomLeftR } from "react-icons/cg";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { FcSteam } from "react-icons/fc";
import { BiUserCircle } from "react-icons/bi";
import {FiDownload} from "react-icons/fi";



function changeToDate(int, langue){
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
}

function research(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }

  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

class MobileApplications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      researchValue: "",
      selectedNoteReview: null,
    };

    this.researchHandle = this.researchHandle.bind(this);
  }

  researchHandle(event) {
    this.setState({researchValue: event.target.value});
  }


  noteApplicationStar(data, size){
    var note = data;
    var color = "rgb(219, 166, 48)";
    return (
      <div>
        { note >= 1 ?
          <span style={{ color: color }}><BsStarFill size={size}/></span>
          :
          note >= 0.5 ?
          <span style={{ color: color }}><BsStarHalf size={size}/></span>
          :
          <span style={{ color: color }}><BsStar size={size}/></span>
        }

        { note >= 2 ?
          <span style={{ color: color }}><BsStarFill size={size}/></span>
          :
          note >= 1.5 ?
          <span style={{ color: color }}><BsStarHalf size={size}/></span>
          :
          <span style={{ color: color }}><BsStar size={size}/></span>
        }

        { note >= 3 ?
          <span style={{ color: color }}><BsStarFill size={size}/></span>
          :
          note >= 2.5 ?
          <span style={{ color: color }}><BsStarHalf size={size}/></span>
          :
          <span style={{ color: color }}><BsStar size={size}/></span>
        }

        { note >= 4 ?
          <span style={{ color: color }}><BsStarFill size={size}/></span>
          :
          note >= 3.5 ?
          <span style={{ color: color }}><BsStarHalf size={size}/></span>
          :
          <span style={{ color: color }}><BsStar size={size}/></span>
        }

        { note >= 5 ?
          <span style={{ color: color }}><BsStarFill size={size}/></span>
          :
          note >= 4.5 ?
          <span style={{ color: color }}><BsStarHalf size={size}/></span>
          :
          <span style={{ color: color }}><BsStar size={size}/></span>
        }
      </div>

    )
  }


  openModalNoteReview(data){
    this.setState({selectedNoteReview: data});
    $('#modalShowNoteReview').modal('show')
  }

  hideModalShowNoteReview(){
    $('#modalShowNoteReview').modal('hide')
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div style={{maxWidth: "96vw"}}>

        <div className="row">
          <div className="col">
            <h6 className="text-secondary card rounded-0">
              <b><FormattedMessage id="app.menu.9.1"/></b>
            </h6>
          </div>

            <div className="col-4">
                <div className="custom-control custom-switch ml-4">
                  <input type="checkbox" className="custom-control-input" id="customSwitchImportTestStore" checked={this.props.showTestStore} onChange={this.props.handleShowTestStoreChange}/>
                  <label className="custom-control-label" htmlFor="customSwitchImportTestStore">
                    {this.props.showTestStore ?
                      <FormattedMessage id="importMMApp.applications.switch.testStore"/>
                      :
                      <FormattedMessage id="importMMApp.applications.switch.store"/>
                    }
                  </label>
                </div>
              </div>

        </div>



        <div className="d-flex justify-content-center">

            <div className="mb-5">

              <input className="form-control mr-sm-2 row-auto rounded-0"
              style={{height: "30px", boxShadow: 'none'}}
              type="search"
              placeholder={this.props.intl.formatMessage({id: "recherche"})}
              aria-label="Search"
              onChange={this.researchHandle}/>

                <PerfectScrollbar className="card rounded-0 border-top-0" style={{maxWidth: "45vw", minWidth: "40vw"}} tabIndex="-1">
                <ul className="list-group list-group-flush" style={{maxHeight: "37vh", minHeight: "37vh"}}>
                {

                  research(this.props.showTestStore ? this.props.dataTestStore : this.props.dataAppli, this.state.researchValue).sort((a, b) => a.name.localeCompare(b.name)).map((x, index) => {

                  return(
                      <li key={index} className="list-group-item rounded-0 py-1">
                          <div className="row">
                          <span className="ml-2">
                            <IoIosApps size={42} style={{color: "rgb(28,158,231)"}}/>
                          </span>
                            <div className="col" style={{textAlign:"left"}}>
                              <a style={{fontSize: '16px'}}>{x.name}<br/></a>
                              <a style={{fontSize: '12px'}}>{x.packageName}</a>
                            </div>

                            {this.props.showTestStore && this.props.userAdministration === "ADMINISTRATOR" && x.averageNote ?
                              <div className="col-auto" style={{textAlign:"right", verticalAlign:"bottom"}}>
                                <button style={{outline: 'none'}} className="close" onClick={this.openModalNoteReview.bind(this, x)}>
                                  {this.noteApplicationStar(x.averageNote, 22)}
                                </button>
                              </div>
                              :
                              null
                            }

                            <div className="col" style={{textAlign:"right"}}>
                              <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{x.storeVersion}</a><br/>
                              <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{x.storeVersionCode}</a>
                            </div>



                            {this.props.showTestStore && this.props.userAdministration === "ADMINISTRATOR" ?
                              <div className="col-auto" style={{textAlign:"right", verticalAlign:"bottom"}}>
                                  <button style={{
                                      outline: 'none'
                                    }} title={this.props.intl.formatMessage({id: "importMMApp.applications.button.addClassicStore.title"})} className="close" data-toggle="modal" data-target="#modalConfirmMoveAppToClassicStore" onClick={this.props.modalConfirm.bind(this, x.name, index)}>
                                    <span style={{
                                        color: "rgb(2, 138, 214)"
                                      }}>
                                      <CgArrowBottomLeftR size={28}/>
                                    </span>
                                  </button>
                              </div>
                              :
                              null
                            }
                            {this.props.userAdministration === "ADMINISTRATOR" ?
                              <div className="col-auto" style={{textAlign:"right", verticalAlign:"bottom"}}>
                                  <button style={{
                                      outline: 'none'
                                    }} title={this.props.intl.formatMessage({id: "importMMApp.applications.button.download.title"})} className="close" data-toggle="modal" data-target="#modalConfirmDownloadAppToDevice"
                                    onClick={this.props.showTestStore == true
                                      ?this.props.modalConfirm.bind(this, x.name, x.packageName+"/"+x.storeVersion+"/"+x.storeVersionCode)
                                      :this.props.modalConfirm.bind(this, x.name, x.packageName)}>
                                    <span style={{
                                        color: "rgb(2, 138, 214)"
                                      }}>
                                      <FiDownload size={28}/>
                                    </span>
                                  </button>
                              </div>
                              :
                              null
                            }
                            { this.props.userAdministration === "ADMINISTRATOR" ?
                            <div className="col-auto" style={{textAlign:"right"}}>
                              <button style={{
                                  outline: 'none'
                                }} className="close" data-toggle="modal" data-target="#modalConfirmSuprAppServ" onClick={this.props.modalConfirm.bind(this, x.name, index)}>
                                <span style={{
                                    color: "rgb(110,110,110)"
                                  }}>
                                  <IoIosCloseCircleOutline size={28}/>
                                </span>
                              </button>
                            </div>

                            :null
                            }
                          </div>
                      </li>
                  )
                })
              }
              </ul>
              </PerfectScrollbar>
            </div>

            </div>


              <div className="modal fade small" id="modalShowNoteReview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className={"modal-dialog modal-xl modal-dialog-centered"} role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-light">
                    <h5 className="modal-title text-info" id="modalAjoutDNSLabel">
                      {this.state.selectedNoteReview ? this.state.selectedNoteReview.name : null}
                    </h5>
                    <button type="button" className="close" onClick={this.hideModalShowNoteReview.bind(this)} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body ml-2">


                      <PerfectScrollbar tabIndex="-1">
                        <ul className="list-group list-group-flush" style={{maxHeight: "45vh", minHeight: "45vh"}}>

                    {

                      this.state.selectedNoteReview ? this.state.selectedNoteReview.noteApplicationTestStoreList.map((x, index) => {
                      return (
                        <li key={index} className="list-group-item">
                            <div className="row m-1 mb-2">
                              <span className="mr-2">
                                <BiUserCircle size={32} className="" style={{color: "rgb(28,158,231)"}}/>
                              </span>
                                <div className="" style={{textAlign:"left"}}>
                                  <a style={{fontSize: '16px'}}>{x.email}<br/></a>
                                  <span style={{fontSize: '12px'}}>{x.model}</span>
                                  <span className="ml-4" style={{fontSize: '12px'}}>{changeToDate(x.updated_at, this.props.intl.locale)}</span>

                                </div>

                                <div className="ml-4" style={{textAlign:"left", verticalAlign:"bottom"}}>
                                      {this.noteApplicationStar(x.note, 20)}
                                </div>

                            </div>
                            <div className="row m-1 mb-2">
                                <div>
                                  <div style={{fontSize: '12px', textAlign:"left"}}>{x.message}</div>
                                </div>
                            </div>
                        </li>

                      )

                    })
                    :
                    null

                  }

                      </ul>
                  </PerfectScrollbar>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.hideModalShowNoteReview.bind(this)}><FormattedMessage id="fermer"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>


      </div>
    )
  }
}

MobileApplications.propTypes = {
  intl: intlShape.isRequired
};
MobileApplications = injectIntl(MobileApplications,
                        {withRef: true});

export default MobileApplications
