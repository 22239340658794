import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import { Menu, Item, Separator, theme } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import {GoDeviceMobile} from "react-icons/go";
import {FaUserCheck, FaUserEdit, FaUserLock, FaUserTimes} from "react-icons/fa";
import {
  MdLaptopChromebook
 } from "react-icons/md";


import {varUser} from '../variables';

var contextMenu = {
  "details": true,
  "enable": false,
  "disable": true,
  "delete": true
}


const iconSize = 20;

class UsersContextMenu extends React.Component {constructor(props) {
  super(props);
  this.state = {
    modalBorderColor: "",
  };
}

checkData() {

  const hit = this.props.hit;

  if (this.props.userAdministration === "ADMINISTRATOR") {
    contextMenu.delete = true;
  } else {
    contextMenu.delete = false;
  }

  if (hit.enable === true) {
    contextMenu.disable = true;
    contextMenu.enable = false;
  } else {
    contextMenu.disable = false;
    contextMenu.enable = true;
  }

}


// ------------------------------- Action handle ------------------------------- //

  handleContextMenuClick(event){
    console.log(event);
  }

  showModal(type, color, id){
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenu').modal('show')
  }

  actionMenu(id){
    switch (id) {
      case "enable":
          this.disableUser(this.props.hit);
        break;
      case "disable":
          this.disableUser(this.props.hit);
        break;
      case "delete":
          this.deleteUser(this.props.hit);
        break;
      default:
    }
  }

  deleteUser(hit){

    fetch(varUser.api + hit.userId, {
        method: 'DELETE',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: ""
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")console.log(response);
        var x = response;
        if(response.status === 200){this.props.fetchUserData()}
        else{
         return response.json().then(responseError => {
           console.log(responseError);
           this.requeteResponse(x,responseError);
         })
       }
       })
  }

  disableUser(hit){


    var data = {
      "login": hit.login,
      "name": hit.name,
      "email" : hit.email,
      "group": {
        "groupId": hit.groupId
      },
      "enable": !hit.enable
    };

        fetch(varUser.api + hit.userId, {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: JSON.stringify(data)
          })
          .then(response => {
            if(process.env.NODE_ENV == "development")console.log(response);
            var x = response;
            if(response.status === 200){this.props.fetchUserData()}
            else{
             return response.json().then(responseError => {
               console.log(responseError);
               this.requeteResponse(x,responseError);
             })
           }
           })

  }


// ------------------------------- Function with visual ------------------------ //

 testMenu(id, state, name){
      if(state === true){
       switch(id) {
      case "details":
          return(<Item onClick={this.props.detailsUserHandle.bind(this, this.props.hit.userId)}>
            <span style={{ color: "rgb(70,156,212)" }}>
              <FaUserEdit size={iconSize}/>
             </span>
               {name}
          </Item>)

        break;

      case "enable":
          return(<Item onClick={this.showModal.bind(this, name, "success", "enable")}>
            <span style={{ color: "rgb(57,200,59)" }}>
              <FaUserCheck size={iconSize}/>
             </span>
               {name}
          </Item>)

        break;
      case "disable":
          return(<Item onClick={this.showModal.bind(this, name, "secondary", "disable")}>
            <span style={{ color: "rgb(133,138,140)" }}>
              <FaUserLock size={iconSize}/>
             </span>
               {name}
          </Item>)
        break;
      case "delete":
          return(<Item onClick={this.showModal.bind(this, name, "danger", "delete")}>
            <span style={{ color: "rgb(238,83,83)" }}>
              <FaUserTimes size={iconSize}/>
             </span>
               {name}
          </Item>)
        break;
      default:
        break;
    }}
 }

 shouldSeparator(state){
   if(state === true)return (<Separator/>)
 }


// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if(this.props.hit !== "empty")
    return (<div>

              <Menu id='menu_usersContextMenu' className="border" theme={theme.light}
                style={{fontSize: 'calc(4px + 1vmin)', height: 'auto', maxHeight: '50vh', 'overflowX': 'hidden'}}>

                {this.testMenu("details", contextMenu.details, this.props.intl.formatMessage({id: "details"}))}
                {this.testMenu("enable", contextMenu.enable, this.props.intl.formatMessage({id: "activer"}))}
                {this.testMenu("disable", contextMenu.disable, this.props.intl.formatMessage({id: "désactiver"}))}
                {this.testMenu("delete", contextMenu.delete, this.props.intl.formatMessage({id: "supprimer"}))}

                {this.shouldSeparator(this.props.hit.nbDevices > 0)}

                {this.props.hit.devices ?
                    (() => {
                      const options = [];
                      const array = this.props.hit.devices.sort((a, b) => a.name.localeCompare(b.name))

                      for (let i = 0; i < array.length; i++) {
                        options.push(<Item onClick={this.props.mobalGraph.bind(this, this.props.hit, array[i])}
                          key={i}><span style={{ color: "rgb(70,156,212)"}}>
                          {array[i].mobilePhone === true ?
                            <GoDeviceMobile size={iconSize}/>
                          : <MdLaptopChromebook size={iconSize}/>}
                         </span>   {array[i].model ? <div>{array[i].name} - <b>{" ( "+array[i].model+" )"}</b></div> : array[i].name}</Item>);
                      }

                      return options;
                    })()
                    : null
                    }

              </Menu>



              <div className="modal fade" id={"ModalGraph"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-graph modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title text-info" id="exampleModalLabel">
                        <b>
                          {this.props.intl.formatMessage({id: "terminaux.dropdown.9"})} : {this.props.modalName}
                        </b>
                      </div>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body text-center small">

                      <div className="d-flex justify-content-center">
                        <iframe className="embed-responsive-item border-0" src={this.props.modalAddress} width="800" height="470"></iframe>
                      </div>

                    </div>
                    <div className="modal-footer justify-content-center d-flex">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
                    </div>
                  </div>
                </div>
              </div>


      <div className="modal fade" id="modalConfirmActionContextMenu" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalSecurite border-${this.state.modalBorderColor} rounded-0`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"> {this.state.modalTXT} : "<b className="text-info">{this.props.hit.name}</b>" </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel"> {this.props.intl.formatMessage({id: "confirmer"})} </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
else {
    return(<div/>)}
  }
}

UsersContextMenu.propTypes = {
  intl: intlShape.isRequired
};
UsersContextMenu = injectIntl(UsersContextMenu, {withRef: true});

export default UsersContextMenu
