import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {FaPlus} from "react-icons/fa";

import {varConfigServeur} from '../variables';

class ServeurMessagerie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMailData: {name:"",imapHost:"",smtpHost:"",imapPort:"",smtpPort:""},
      newServeurName: "",
      newHostInAdresse: "",
      newHostOutAdresse: "",
      newMailProtocole: 143,
      newSMTPProtocole: 25,
      newServMSGewsSecuredConnection: true,
      dataServMessagerie: this.props.dataServMessagerie,
      serv: ""
    };

    this.selectMail = this.selectMail.bind(this);

    this.addServeurMessagerie = this.addServeurMessagerie.bind(this);
    this.handleMail = this.handleMail.bind(this);


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectedInputChange = this.handleSelectedInputChange.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount(){
    this.initialisation(this.props.dataServMessagerie)
  }

  initialisation(data) {
    var adaptedData = [];
    for (var i = 0, j = 0; i < data.length; i++) {

      var item = new Object;
      item.id = data[i].id;
      item.name = data[i].name;
      item.imapHost = data[i].imapHost;
      item.smtpHost = data[i].smtpHost;
      item.smtpPort = data[i].smtpPort;


      if (data[i].imapPort == 80 || data[i].imapPort == 443) {
        if (data[i].imapPort == 443) {
          item.imapPort = 80;
          item.ewsSecuredConnection = true;
        } else {
          item.imapPort = 80;
          item.ewsSecuredConnection = false;
        }
      } else {
        item.imapPort = data[i].imapPort;
        item.ewsSecuredConnection = true;
      }

      adaptedData.push(item);
    }

    this.setState({
      dataServMessagerie: adaptedData
    })
  }


// ------------------------- Action handle ------------------------------------- //

  selectMail(name, index) {
    this.setState({selectedMailData: this.state.dataServMessagerie[index],selectedMail: name, selectedMailIndex: index});
  }

  handleMail(event) {
    this.setState({serv: event.target.value});
  }

  handleSelectedInputChange(e, field){
    if (field == "ewsSecuredConnection") {
      this.setState({
        selectedMailData: { ...this.state.selectedMailData, [field]: !this.state.selectedMailData.ewsSecuredConnection }
      });
    } else {
      this.setState({
        selectedMailData: { ...this.state.selectedMailData, [field]: e.target.value }
      });
    }

  }

  handleInputChange(e, field){
    if (field == "newServMSGewsSecuredConnection") {
      this.setState({[field]: !this.state.newServMSGewsSecuredConnection})
    } else {
      this.setState({[field]: e.target.value})
    }
  }

  addServeurMessagerie() {

    var item = new Object();
    item.name = this.state.newServeurName;
    item.imapHost = this.state.newHostInAdresse;
    item.smtpHost = this.state.newHostOutAdresse;
    item.smtpPort = this.state.newSMTPProtocole;

    if (this.state.newMailProtocole == 80 && document.getElementById('enableSecuredConnectionNewServMSG').checked) {
      item.imapPort = 443;
    } else {
      item.imapPort = this.state.newMailProtocole;
    }


                fetch(varConfigServeur.apiServMessagerie, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': this.props.tokenCSRF
                  },
                    body: JSON.stringify(item)
                  })
                  .then(response => {
                    var x = response;
                    if(response.status === 200){
                      return response.json().then(responseRetour => {
                        this.addNewServeurMessagerie(responseRetour);
                      })
                    }
                    else{
                      console.log(response.headers);
                     return response.json().then(responseError => {
                       console.log(responseError);
                       this.requeteResponse(x,responseError);
                     })
                   }
                   })

  }

  addNewServeurMessagerie(data){
      const array = this.state.dataServMessagerie;

    	var item = new Object();
      item.id = data.id;
      item.name = data.name;
      item.imapHost = data.imapHost;
      item.smtpHost = data.smtpHost;
      item.imapPort = data.imapPort;
      item.smtpPort = data.smtpPort;

      if (data.imapPort == 80 || data.imapPort == 443) {
        if (data.imapPort == 443) {
          item.imapPort = 80;
          item.ewsSecuredConnection = true;
        } else {
          item.imapPort = 80;
          item.ewsSecuredConnection = false;
        }
      } else {
        item.imapPort = data.imapPort;
        item.ewsSecuredConnection = true;
      }

      array.push(item);

      this.setState({dataServMessagerie: array});
      this.setState({newServeurName: "", newHostInAdresse: "", newHostOutAdresse: "", newMailProtocole: 143, newSMTPProtocole: 25,});
  }

  sendSelectedModif(data, index){

    var item = new Object();
    item.id = data.id;
    item.name = data.name;
    item.imapHost = data.imapHost;
    item.smtpHost = data.smtpHost;
    item.smtpPort = data.smtpPort;


    if (data.imapPort == 80 && document.getElementById('enableSecuredConnection').checked) {
      item.imapPort = 443;
    } else {
      item.imapPort = data.imapPort;
    }


      fetch(varConfigServeur.apiServMessagerie + data.id, {
          method: 'PUT',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(item)
        })
        .then(response => {
          var x = response;
          if(response.status !== 200){
           return response.json().then(responseError => {
             console.log(responseError);
           })
         }
       })

       const array = this.state.dataServMessagerie;
       array[index] = data;
       this.setState({dataServMessagerie: array});
  }

  removeServeurMessagerie(index, id){

        fetch(varConfigServeur.apiServMessagerie + id, {
            method: 'DELETE',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: ""
          })
          .then(response => {
            var x = response;
            if(response.status !== 200){
             return response.json().then(responseError => {
               console.log(responseError);
             })
           }
         })

         const array = this.state.dataServMessagerie;
         var newArray = [];

         for (var i = 0, j = 0; i < array.length; i++) {
           if (i !== index) {
             newArray[j++] = array[i]
           }
         }
         this.setState({dataServMessagerie: newArray});
  }

  removeMail(index) {
    const array = this.state.dataServMessagerie;
    var newArray = [];

    for (var i = 0, j = 0; i < array.length; i++) {
      if (i !== index) {
        newArray[j++] = array[i]
      }
    }
    this.setState({dataServMessagerie: newArray});
  }

  confirmDelete(x, index, id) {
    this.setState({modalTxt: x, modalIndex: index, deleteId: id})
  }


// ------------------------- Render -------------------------------------------- //

  render() {

    return (<div className="col-5 mt-2 mb-3 text-center container" id="messagerie" role="tabpanel" aria-labelledby="home-tab">

      <div className="list-group text-center">
        <h5 className="text-info mt-2">
          <b><FormattedMessage id="ConfigurationServer.serverMSG"/>
          </b>
        </h5>


        {
          this.state.dataServMessagerie.map((servName, index) => (<div key={index} className="row">
            <a href="#" className="list-group-item list-group-item-action col rounded-0" data-toggle="modal" data-target=".servMSG-modal-lg" onClick={this.selectMail.bind(this, servName.name, index)}>
              {servName.name}
            </a>
            <button className="btn btn-secondary rounded-0" data-toggle="modal" data-target="#modalSuprServMSG" onClick={this.confirmDelete.bind(this, servName.name, index, servName.id)}>
              <span>&times;</span>
            </button>
          </div>))
        }

      </div>
      <div className="mt-2">
        <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAjoutServMSG">
          <div style={{
              color: "rgb(255,255,255)"
            }}>
            <FaPlus size={14}/>

          </div>
        </button>
      </div>

      <div className="modal fade servMSG-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body ">

              <h6 className="text-primary text-center"><FormattedMessage id="ConfigurationServer.serverMSG.modal.1"/> {this.state.selectedMail}</h6>

              <h5 className="text-primary text-center">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.2"/></b>
              </h5>
              <h6 className="text-info ">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.2"/></b>
              </h6>

              <div className="col mt-2 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serverMSG.modal.3"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "name")} value={this.state.selectedMailData.name}/>
                  </div>
                </div>
              </div>

              <h6 className="text-info mt-2 mb-2">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.4"/></b>
              </h6>
              <div className="col mt-2 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serverMSG.modal.5"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "imapHost")} value={this.state.selectedMailData.imapHost}/>
                  </div>
                </div>
              </div>

              <h6 className="text-info mt-2 mb-2">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.6"/></b>
              </h6>
              <div className="col mt-2 ">
                <div className="row mb-1">
                  <div className="col">
                    <FormattedMessage id="ConfigurationServer.serverMSG.modal.7"/>
                  </div>
                  <div className="col">
                    <input className="form-control" onChange={e => this.handleSelectedInputChange(e, "smtpHost")} value={this.state.selectedMailData.smtpHost}/>
                  </div>
                </div>
              </div>

              <h5 className="text-primary text-center">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.8"/></b>
              </h5>
              <h6 className="text-info">
                <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.9"/></b>
              </h6>

              <div className="row mb-1">
                <div className="col">
                  <FormattedMessage id="ConfigurationServer.serverMSG.modal.10"/>
                </div>

                <div className="col">
                  <select id="inputState" className="form-control mr-4" value={this.state.selectedMailData.imapPort} onChange={e => this.handleSelectedInputChange(e, "imapPort")}>
                    <option className="list-group-item small" value={143}>IMAP</option>
                    <option className="list-group-item small" value={993}>IMAP (SSL)</option>
                    <option className="list-group-item small" value={80}>Exchange Web Service</option>
                  </select>
                </div>
              </div>


                {this.state.selectedMailData.imapPort == 80 || this.state.selectedMailData.imapPort == 443 ?

                  <div className="custom-control custom-checkbox mb-2 mt-3">
                    <input className="custom-control-input" type="checkbox" checked={this.state.selectedMailData.ewsSecuredConnection} onChange={e => this.handleSelectedInputChange(e, "ewsSecuredConnection")} id="enableSecuredConnection"/>
                    <label className="custom-control-label" htmlFor="enableSecuredConnection">
                      <FormattedMessage id="ConfigurationServer.serverMSG.EWS.securedConnection"/>
                    </label>
                  </div>
                  :
                  <div>
                    <h6 className="text-info mt-2 mb-2">
                      <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.13"/></b>
                    </h6>
                    <div className="row mb-1">
                      <div className="col">
                        <FormattedMessage id="ConfigurationServer.serverMSG.modal.10"/>
                      </div>
                      <div className="col">
                        <select id="inputState" className="form-control mr-4" value={this.state.selectedMailData.smtpPort} onChange={e => this.handleSelectedInputChange(e, "smtpPort")}>
                          <option className="list-group-item small" value={25}>SMTP (25)</option>
                          <option className="list-group-item small" value={587}>SMTP (587)</option>
                          <option className="list-group-item small" value={465} >SMTP (SSL)</option>
                        </select>
                      </div>
                    </div>
                  </div>

                }



              <div className="row mb-1">
                <div className="col">
                  <h5 className="text-primary text-center mt-2">
                    <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.14"/></b>
                  </h5>
                  <h6 className="text-info mt-2 mb-2">
                    <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.15"/></b>
                  </h6>

                  <FormattedMessage id="ConfigurationServer.serverMSG.modal.16"/>

                  <button type="button" className="btn btn-danger float-right">Test</button>
                </div>
                <div className="col">

                  <h5 className="text-primary text-center mt-2">
                    <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.17"/></b>
                  </h5>
                  <div className="card text-center mt-4">Pas de problème à signaler sur ce serveur.</div>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.sendSelectedModif.bind(this, this.state.selectedMailData, this.state.selectedMailIndex)}><FormattedMessage id="valider"/></button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalAjoutServMSG" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="ConfigurationServer.serverMSG.modal.2.1"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">


                <h5 className="text-primary text-center">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.2"/></b>
                </h5>
                <h6 className="text-info ">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.2"/></b>
                </h6>

                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serverMSG.modal.3"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newServeurName")} value={this.state.newServeurName}/>
                    </div>
                  </div>
                </div>

                <h6 className="text-info mt-2 mb-2">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.4"/></b>
                </h6>
                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serverMSG.modal.5"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newHostInAdresse")} value={this.state.newHostInAdresse}/>
                    </div>
                  </div>
                </div>

                <h6 className="text-info mt-2 mb-2">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.6"/></b>
                </h6>
                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serverMSG.modal.7"/>
                    </div>
                    <div className="col">
                      <input className="form-control" onChange={e => this.handleInputChange(e, "newHostOutAdresse")} value={this.state.newHostOutAdresse}/>
                    </div>
                  </div>
                </div>

                <h5 className="text-primary text-center">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.8"/></b>
                </h5>

                <h6 className="text-info mt-2 mb-2">
                  <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.9"/></b>
                </h6>
                <div className="col mt-2 ">
                  <div className="row mb-1">
                    <div className="col">
                      <FormattedMessage id="ConfigurationServer.serverMSG.modal.10"/>
                    </div>
                    <div className="col">
                      <select id="inputState" className="form-control mr-4" value={this.state.newMailProtocole} onChange={e => this.handleInputChange(e, "newMailProtocole")}>
                        <option className="list-group-item small" value={143}>IMAP</option>
                        <option className="list-group-item small" value={993}>IMAP (SSL)</option>
                        <option className="list-group-item small" value={80}>Exchange Web Service</option>
                      </select>
                    </div>
                  </div>
                </div>

                {this.state.newMailProtocole == 80 || this.state.newMailProtocole == 443 ?

                  <div className="custom-control custom-checkbox mb-2 mt-3">
                    <input className="custom-control-input" type="checkbox" checked={this.state.newServMSGewsSecuredConnection} onChange={e => this.handleInputChange(e, "newServMSGewsSecuredConnection")} id="enableSecuredConnectionNewServMSG"/>
                    <label className="custom-control-label" htmlFor="enableSecuredConnectionNewServMSG">
                      <FormattedMessage id="ConfigurationServer.serverMSG.EWS.securedConnection"/>
                    </label>
                  </div>
                  :
                  <div>
                    <h6 className="text-info mt-2 mb-2">
                      <b><FormattedMessage id="ConfigurationServer.serverMSG.modal.13"/></b>
                    </h6>
                    <div className="col mt-2 ">
                      <div className="row mb-1">
                        <div className="col">
                          <FormattedMessage id="ConfigurationServer.serverMSG.modal.10"/>
                        </div>
                        <div className="col">
                          <select id="inputState" className="form-control mr-4" value={this.state.newSMTPProtocole} onChange={e => this.handleInputChange(e, "newSMTPProtocole")}>
                            <option className="list-group-item small" value={25}>SMTP (25)</option>
                            <option className="list-group-item small" value={587}>SMTP (587)</option>
                            <option className="list-group-item small" value={465} >SMTP (SSL)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                }


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addServeurMessagerie} disabled={!(this.state.newServeurName && this.state.newHostInAdresse && this.state.newHostOutAdresse && this.state.newMailProtocole && this.state.newSMTPProtocole)}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id={"modalSuprServMSG"} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="confirmDelete"/> : "<b className="text-info">{this.state.modalTxt}</b>" ?</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.removeServeurMessagerie.bind(this, this.state.modalIndex, this.state.deleteId)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}
ServeurMessagerie.propTypes = {
  intl: intlShape.isRequired
};
ServeurMessagerie = injectIntl(ServeurMessagerie, {withRef: true});

export default ServeurMessagerie
