import React from 'react'
import {FormattedMessage} from "react-intl";

class RestrictionLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidMount() {
    document.title = 'Restricted';
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }


// ------------------------------- Action handle ------------------------------- //

  handleKeyDown(e) {
    if (e.keyCode == 13) {
      this.props.click();
    }
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //


// ------------------------------- Action handle ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <div>
        <div className="card col mt-5">
          <h4 className="text-primary d-flex justify-content-center mt-3">
            <b>
              <i>Restricted</i>
            </b>
          </h4>

          <hr/>


          <div className="mb-4 mt-3 d-flex justify-content-center">
            <button className="btn btn-secondary" onClick={this.props.click}>
              <FormattedMessage id="deconnexion"/>
            </button>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default RestrictionLogin
