import React from 'react'

class Notfound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirection: false
    };
  }

  componentDidMount(){
    this.props.click();
  }

  componentDidUpdate(){
    if(this.state.redirection === true)this.azer()
  }

  test(){
    this.timer = setInterval(this.tick(), 2000);
  }

  tick(){
    this.setState({redirection: true});
  }

  azer(){
    this.props.history.push("/general");
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
            <div className="alert-dark border border-danger">
			<h1>Page not Found</h1>
      <button onClick={this.azer.bind(this)}>x</button>
            </div>
        </header>
      </div>
    );
  }
}

export default Notfound
