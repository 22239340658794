import React from 'react'
import $ from "jquery";
import UsersToolBar from './UsersToolBar';
import UsersTab from './UsersTab';
import UsersContextMenu from './UsersContextMenu';
import './UserTableau.css'
import {intlShape, injectIntl} from 'react-intl';
import '../custom.css';


import {varUser,url} from '../variables';

var time = 0;
var newRequete = false;
var firstFetchTab = true;


export function research(array, valeur) {


  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
      if(valeur.toLowerCase().indexOf("state:disabled") !== -1 ){
        if(array[i].enable == false){
          testArray.push(array[i]);
        }
      }

      else {
          if(array[i].login && array[i].login.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].name && array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].group && array[i].group.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
        }
    }
  }

  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}


class users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableau: [],
      modalName: "modalName",
      modalAddress: "",
      isLoading: false,
      error: null,
      fileName: this.props.intl.formatMessage({id: "choixfichier"}),
      researchValue: "",
      selected: null,
      showPagination: false,
      hit: [],
    };

    this.tick = this.tick.bind(this)
    this.tableShowPaginationHandle = this.tableShowPaginationHandle.bind(this);
    this.rechercheHandle = this.rechercheHandle.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
    this.detailsUserHandle = this.detailsUserHandle.bind(this);
    this.contextMenu = this.contextMenu.bind(this);

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    if(this.props.location.defaultResearch){this.setState({researchValue: this.props.location.defaultResearch})}
    if (varUser.enableTimer === "on")
      this.timer = setInterval(this.tick, 1000);
    if(process.env.NODE_ENV === "development")console.log("REFRESH AUTO utilisateurs : " + varUser.enableTimer + " , 1sec, " + varUser.timer + "sec");
    this.setState({isLoading: true});
    this.fetchUserData();
  }

  componentDidUpdate(time) {
    document.title = this.props.intl.formatMessage({id: "app.menu.2"});

    if (time === varUser.timer) {
      this.fetchUserData();
    }
  }

  componentWillUnmount() {
    time = 0;
    firstFetchTab = true;
    clearInterval(this.timer);
  }


// ------------------------------- Action handle ------------------------------- //

  detailsUserHandle(x) {
    this.props.history.push("/Users/" + x);
  }

  rechercheHandle(event) {
    this.setState({researchValue: event.target.value});
  }

  selectedHandle(x){
    this.setState({selected : x});
  }

  tableShowPaginationHandle(){
    this.setState({showPagination : !this.state.showPagination })
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({fileName: event.target.files[0].name});
    var file = event.target.files[0];
    console.log(file);
    this.setState({file});
  }

  removeTab(e, tab){

    var x = [];

    for (var i = 0; i < tab.length; i++) {
      if(i !== e){x.push(tab[i])}
    }

    console.log(x);
    this.setState({tableau: x});
  }

  mobalGraph(x, device){
    this.setState({modalName: device.name, modalAddress: this.getGraph(x.login, device.name)});
    $('#ModalGraph').modal('show')
  }


// ------------------------------- Auto refresh table -------------------------- //

  tick() {
    if (newRequete === true) {
      if (time === varUser.timer) {
        time = 0;
        newRequete = false;
        this.fetchUserData();
      }
      time = time + 1;
    }
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchUserData() {
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if(process.env.NODE_ENV == "development")console.log("fetch : " + hours + ":" + min + ":" + sec);
    fetch(varUser.api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    }).then(data => this.adaptData(data)).catch(error => this.setState({error, isLoading: false}));
  }

  adaptData(tab) {
    var newTableau = [];
    tab.map((x, index) => {
      if (!(this.props.userAdministration === "REMOTE_ADMIN" && x.administration === 1)) {
        var item = new Object();
        item.userType = "normal";
        item.userId = x.userId;
        item.enable = x.enable;
        item.ldap = x.ldap;
        item.login = x.login;
        item.name = x.name;
        if (x.model) {
          item.model = x.model;
        }
        item.nbDevices = x.devices.length;
        if(x.devices.length > 0){
          item.devices = x.devices;
        }

        if (x.group) {
          item.group = x.group.name;
          item.groupId = x.group.groupId;
        } else {
          item.group = "-";
        }

        item.createdDate = x.createdDate;
        item.sortDate = x.createdDate;
        item.lastSessionDate = x.lastSessionDate;
        item.sortLastSessionDate = x.lastSessionDate;
        item.errors = x.error;
        item.active = x.active;
        item.email = x.email;
        item.service = x.service;
        item.fonction = x.fonction;

        newTableau.push(item);
      }

    })
    newRequete = true;

    if(newTableau.length > 50 && firstFetchTab === true){
      firstFetchTab = false
      this.setState({showPagination: true})
    }

    this.setState({tableau: newTableau, isLoading: false})
  }

  convertDate(date) {
    var newYear,
      newMonth,
      newDay,
      newHour;

    if (date) {
      newDay = (date.substring(0, date.indexOf(" ")));
      if (date.indexOf(" ") === 1) {
        newDay = "0" + newDay
      }
      newMonth = this.monthToNum(date.substring(date.indexOf(" ") + 1, date.indexOf(" ", (date.indexOf(" ") + 1))));
      newYear = (date.substring(2)).substring((date.substring(2)).indexOf("20"), (date.substring(2)).indexOf("20") + 4);
      newHour = date.substring(date.indexOf(" ", (date.indexOf(" ") + 1)) + 1);
      newHour = newHour.substring(newHour.indexOf(" ") + 1)
      newHour = newHour.substring(0, 2) + newHour.substring(3, 5) + newHour.substring(6, 8)

      return (newYear + newMonth + newDay + newHour)
    }
    return ("-")
  }

  monthToNum(x) {
    switch (x) {
      case 'janv.':
        return "01";
      case 'févr.':
        return "02";
      case 'mars':
        return "03";
      case 'avr.':
        return "04";
      case 'mai':
        return '05';
      case 'juin':
        return '06';
      case 'juil.':
        return '07';
      case 'août':
        return '08';
      case 'sept.':
        return '09';
      case 'oct.':
        return '10';
      case 'nov.':
        return '11';
      case 'déc.':
        return '12';
      default:
        return x;
    }
  }

  getGraph(login, devices) {
    return (`${url}/Microsoft-Server-ActiveSync/?Cmd=Activity&User=${login}&Login=${login}&Device=${devices}&lang=${this.props.intl.locale}&context=null`)
  }

  contextMenu(x){
    if(x === "empty"){
    this.setState({hit : "empty"});
  }else {
    this.setState({hit : x});
    }
  }


// ------------------------------- Function with visual ------------------------ //

  fetching() {
    if (newRequete === false) {
      return (<div className="col d-flex justify-content-center">
        <div className="spinner-grow text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>)
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Erreur : {error.message}</span>
        </div>

        <div className="container small"></div>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    return (<div className="card bg-light float-left mt-3 small">
        <div className="card-header">

        <UsersToolBar researchValue={this.state.researchValue}
          rechercheHandle={this.rechercheHandle}
          isFetching={this.fetching()}
          fileName={this.state.fileName}
          onChangeFile={this.onChangeFile}
          showPagination={this.state.showPagination}
          tableShowPaginationHandle={this.tableShowPaginationHandle.bind(this)}
          userAdministration={this.props.userAdministration}
          />

      </div>

        <UsersTab tableau={(research(this.state.tableau , this.state.researchValue)).sort((a, b) => a.login.localeCompare(b.login))}
        selectedHandle={this.selectedHandle}
        selected={this.state.selected}
        contextMenu={this.contextMenu}
        showPagination={this.state.showPagination}
        langue={this.props.intl.locale}/>


        <UsersContextMenu hit={this.state.hit}
        hitDevices={this.state.hit}
        detailsUserHandle={this.detailsUserHandle}
        mobalGraph={this.mobalGraph.bind(this)}
        getGraph={this.getGraph}
        modalName={this.state.modalName}
        modalAddress={this.state.modalAddress}
        removeTab={this.removeTab.bind(this)}
        tableau={this.state.tableau}
        selected={this.state.selected}
        fetchUserData={this.fetchUserData.bind(this)}
        userAdministration={this.props.userAdministration}
        tokenCSRF={this.props.tokenCSRF}
        />

    </div>)
  }
}
users.propTypes = {
  intl: intlShape.isRequired
};
users = injectIntl(users, {withRef: true});

export default users
