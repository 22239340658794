import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import $ from "jquery";
import axios from "axios";
import {FormattedMessage} from "react-intl";
import ReactTable from 'react-table'
import find from 'lodash/find';
import Cookies from 'js-cookie';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


import './index.css';
import './custom.css';
import {IntlProvider} from 'react-intl';
import messages_fr from "./translations/fr.json";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";
import {Route, NavLink, Redirect, BrowserRouter as Router, Switch} from 'react-router-dom'
import Dashboard from './dashboard/DashboardPage';
import Users from './users/UsersPage';
import NewUser from './user/NewUser.js';
import User from './user/UserPage';
import Group from './group/GroupPage';
import Groups from './groups/GroupsPage';
import Devices from './devices/DevicesPage';
import NewDevice from './devices/NewDevice.js';
import LinkDevice from './devices/LinkDevice.js';
import NewGroup from './group/NewGroup';
import Config from './config/ConfigPage';
import Parameters from './parameters/ParametersPage';
import Computers from './computers/ComputersPage';
import Files from './files/FilesPage';
import Mmapp from './mmapp/MMAppPage';
import Login from './Login';
import RestrictionLogin from './RestrictionLogin';
import Notfound from './notfound';

import MobileApplications from "./import-export/MobileApplications";


import {FaFileExport, FaFile, FaRegFlag, FaUserAlt, FaQuestionCircle, FaNetworkWired} from "react-icons/fa";
import {IoMdSettings, IoMdExit, IoMdPerson, IoIosApps, IoIosCloseCircleOutline, IoMdInformationCircle} from "react-icons/io";
import {BsCheck} from "react-icons/bs";

import {varApp, varAPropos, varParametres} from './variables';



const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);


export const loginAdmin = "admin";
export const passwordAdmin = "admin";

const messages = {
  'fr': messages_fr,
  'en': messages_en,
  'de': messages_de
};


  const getTheadThProps = (table, row, col) => {
  const sortedCol = find(table.sorted, { id: col.id });
  const boxShadow =
  sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
  return {
  style: {
    boxShadow,
  },
  };
  };

  const columnsImportMMAppResult= [{

      Header: () => (
      <span>
        <b> Email </b>
      </span>
    ),
      accessor: 'email',
      minWidth: 150,

    },{
      Header: () => (
      <span>
        <b> Model </b>
      </span>
    ),
    accessor: 'model',

    },{
      Header: () => (
      <span>
        <b> Code </b>
      </span>
    ),
      accessor: 'code',
      minWidth: 250,

      Cell: row => (
          <div>
            {console.log(row.value)}
            <span style={{
                  color: row.value == 200 ? "rgb(36,232,45)"
                    : "rgba(224,0,3,0.92)"
                }}>
                {
                  row.value == 401 ? <FormattedMessage id="mmapp.import.password.alreadySecured"/>
                  : row.value == 402 ? <FormattedMessage id="mmapp.import.password.notDeviceOwner"/>
                  : row.value == 404 ? <FormattedMessage id="mmapp.import.password.notFound"/>
                  : row.value == 200 ? <BsCheck size={24}/>
                  : "default"

                }
              </span>
          </div>
        )


    }]

  const columnsApplicationServer= [{

      Header: () => (
      <span>
        <b> Name </b>
      </span>
    ),
      accessor: 'Name',

    },{
      Header: () => (
      <span>
        <b> Package </b>
      </span>
    ),
    accessor: 'packageName',

    },{
      Header: () => (
      <span>
        <b> Version </b>
      </span>
    ),
    accessor: 'latestKnownVersion',

    }]

  const columns = [{

      Header: () => (
      <span>
        <FormattedMessage id="result"/>
      </span>
    ),
      accessor: 'result',
      minWidth: 200,

    }]


function PrivateRoute({
  component: Component,
  Logged,
  userAdministration,
  tokenCSRF,
  click,
  data,
  ...rest
}) {
  return (<Route {...rest} render={props => Logged == 1
      ?
      userAdministration != "USER" ?
        (propRoute(click, data, userAdministration, tokenCSRF, Component, props))
      :(<Redirect to={{
          pathname: "/Restricted"
        }}/>)
      : (<Redirect to={{
          pathname: "/Login",
          state: {
            from: props.location
          }
        }}/>)}/>);
}

function propRoute(click, data, userAdministration, tokenCSRF, Component, props) {
  return (<Component {...props} click={click} data={data} userAdministration={userAdministration} tokenCSRF={tokenCSRF}/>)

}


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table: [],
      tabResult: [],
      language: navigator.language.split(/[-_]/)[0],
      userIsLogged: localStorage.getItem('userIsLogged'),
      userAdministration: localStorage.getItem('userAdministration'),
      tokenCSRF: localStorage.getItem('tokenCSRF'),
      refreshing: true,
      location: window.location.pathname,
      fileName: "...",
      dataAppliLength: 0,
      showTestStore: false,
      importMMAppResult: [],
      progress : 0,
      estimated : 0
    };

    this.languageChange = this.languageChange.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);

    this.pageRightClick = this.pageRightClick.bind(this);
  }


  setupBeforeUnloadListener = () => {
          window.addEventListener("beforeunload", (ev) => {
              localStorage.setItem('lastSession', Date.now());
          });
      };


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    if (localStorage.getItem('userIsLogged') == 1) {
      if(Date.now() - localStorage.getItem('lastSession') > 30*60*1000){
        localStorage.setItem('userIsLogged', 0);
        localStorage.setItem('userAdministration', null);
        localStorage.setItem('tokenCSRF', null);
        this.setState({userIsLogged : 0, userAdministration : null, tokenCSRF: null})
      }
    }

    this.setupBeforeUnloadListener();
    document.addEventListener('contextmenu', this.pageRightClick, false);

    var testLanguage = navigator.language.split(/[-_]/)[0];
    if (testLanguage !== 'fr' && testLanguage !== 'en' && testLanguage !== 'de') {
      this.setState({language: 'en'})
    }

        Promise.all([this.fetchApi(varParametres.apiMDM), this.fetchApi(varParametres.apiConfigServerMMApp)])
      .then(([dataMDM, dataConfigMDM]) => {
        var item = new Object;
        item.singleAssociationDeviceUser = dataMDM.singleAssociationDeviceUser;
        item.enableLocation = dataConfigMDM.enableLocation === "true" ? true : false;
        item.enableSaveDeviceData = dataConfigMDM.enableSaveDeviceData === "true" ? true : false;
        item.deleteDataOnceSaved = dataConfigMDM.deleteDataOnceSaved === "true" ? true : false;
        item.autoStorageManagement = dataConfigMDM.autoStorageManagement === "true" ? true : false;
        if(process.env.NODE_ENV === "development")console.log(item);
          this.setState({
          dataMDM: item
        });
    })

        Promise.all([this.fetchApi(varAPropos.api)])
      .then(([dataAbout]) => {
        this.setState({
        dataAbout: this.adaptDataAbout(dataAbout)
      });
    })
  }


  adaptDataAbout(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
      var item = new Object;
      item.name = data[i]["build.name"];
      item.version = data[i]["build.version"];
      newTab.push(item);
    }

    var item = new Object;
    item.name = varAPropos.consoleInfo.name;
    newTab.push(item);

    return newTab;
  }


  componentWillUnmount() {
    document.removeEventListener('contextmenu', this.pageRightClick, false);
  }


// ------------------------------- Action handle ------------------------------- //

  pageRightClick(e) {
    if (process.env.NODE_ENV !== "development")
      e.preventDefault();
    }

    handleModifMDM(singleAssociationDeviceUser, enableLocation, enableSaveDeviceData, deleteDataOnceSaved, autoStorageManagement, defaultDataSavedImages, defaultDataSavedFiles, defaultDataSavedSMS, defaultDataSavedContacts){
      if(process.env.NODE_ENV === "development")console.log("singleAssociationDeviceUser : " + singleAssociationDeviceUser + " // " + "enableLocation : " + enableLocation + " // " + "enableSaveDeviceData : " + enableSaveDeviceData);
        var item = new Object();
        item.singleAssociationDeviceUser = singleAssociationDeviceUser;
        item.enableLocation = enableLocation;
        item.enableSaveDeviceData = enableSaveDeviceData;
        item.deleteDataOnceSaved = deleteDataOnceSaved;
        item.autoStorageManagement = autoStorageManagement;
        item.defaultDataSavedImages = defaultDataSavedImages;
        item.defaultDataSavedFiles = defaultDataSavedFiles;
        item.defaultDataSavedSMS = defaultDataSavedSMS;
        item.defaultDataSavedContacts = defaultDataSavedContacts;
      this.setState({
        dataMDM: item
      });
      console.log(this.state.dataMDM);
    }


  languageChange(event) {

    this.setState({language: event.target.value});
    if (process.env.NODE_ENV == "development")
      console.log("language change : " + event.target.value);
    }

    handleShowTestStoreChange(){
      this.setState({showTestStore: !this.state.showTestStore})
    }

  importExport(x) {
    this.setState({modalId: x});
    if (x === 7) {

      Promise.all([
          fetch(varApp.apiServerApplication, {method: 'GET', headers: { 'X-XSRF-TOKEN': this.state.tokenCSRF }}),
          fetch(varApp.apiTestStore, {method: 'GET', headers: { 'X-XSRF-TOKEN': this.state.tokenCSRF }})
      ])
      .then(([resAppli, resTestStore]) => Promise.all([resAppli.json(), resTestStore.json()]))
      .then(([dataAppli, dataTestStore]) => this.setState({
            dataAppli: this.adaptDataAppli(dataAppli, false),
            dataAppliLength: dataAppli.length,
            dataTestStore: this.adaptDataAppli(dataTestStore, true),
            dataTestStoreLength: dataTestStore.length
        })
      ).then($('#ModalImpExp').modal('show'));

    } else {
      $('#ModalImpExp').modal('show');
    }
  }

  adaptDataAppli(data, isTestStoreData){
      var newTableau = [];
      data.map((x, index) => {
        var item = new Object();

        if (isTestStoreData) {
          var hit = x.application;
          item.noteApplicationTestStoreList = x.noteApplicationTestStoreList;
          item.averageNote = this.noteCalcul(x.noteApplicationTestStoreList);
        } else {
          var hit = x;
        }
        item.name = this.getApplicationName(hit);
        item.packageName = hit.packageName;
        item.latestKnownVersion = hit.latestKnownVersion;
        item.latestKnownVersionCode = hit.latestKnownVersionCode;
        item.storeVersion = hit.storeVersion;
        item.storeVersionCode = hit.storeVersionCode;
        newTableau.push(item);
      })
      return newTableau;
  }


  getApplicationName(appli){
    var name = "";
    switch (this.state.language) {
      case "fr":
          if (appli.names !== undefined && appli.names.fr !== undefined) {
            name = appli.names.fr;
          } else if (appli.names !== undefined && appli.names.en !== undefined){
            name = appli.names.en + " (en)";
          }
        break;
      case "en":
          if (appli.names !== undefined && appli.names.en !== undefined) {
            name = appli.names.en;
          } else if (appli.names !== undefined && appli.names.fr !== undefined){
            name = appli.names.fr + " (fr)";
          }
        break;
      default:
          name = appli.packageName;
        break;
    }

    return name;
  }

  noteCalcul(data){
    var values = 0;
    var result;

    if (data) {
      for (var i = 0; i < data.length; i++) {
        values = values + data[i].note;
      }
      result = values / data.length;
    }
    return result;
  }


  fetchApi(api){
    return fetch(api ,{
      method: 'GET',
      headers: { 'X-XSRF-TOKEN': this.state.tokenCSRF }
    }
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetchData : Something went wrong ...');
      }
    })
  }


  importResult(tab) {

    var newTableau = [];
    tab.map((x, index) => {
      var item = new Object();
      item.result = x;
      newTableau.push(item);
    })


    this.setState({tabResult: newTableau});

    $('#ModalImpExp').modal('hide');
    $('#ModalImportResult').modal('show');
  }

  importResultMMAppPassword(tab) {

    var newTableau = [];
    tab.map((x, index) => {
      var item = new Object();
      item = x;
      newTableau.push(item);
    })

    this.setState({importMMAppResult: newTableau});

    $('#ModalImpExp').modal('hide');
    $('#modalImportPasswordResult').modal('show');
  }

  modalImport(x) {

    switch (x) {
      case 2:
      case 3:
      case 7:
      case 11:
        return (<div className="mb-4">

          <h6 className="text-secondary card rounded-0">
            <b><FormattedMessage id="importer"/></b>
          </h6>

          <span><FormattedMessage id="importer.1"/>
          </span>
          <div className="col d-flex justify-content-center">

            <div className="input-group mb-2 mt-3" style={{maxWidth: "30vw"}}>
              <div className="input-group-prepend">
                <button className="btn btn-default btn-info btn-choose" type="file" onClick={() => {
                    this.upload.click()
                  }}><FormattedMessage id="importer"/></button>
              </div>
              <input type="text" className="form-control" ref={(ref) => this.upload = ref} readOnly="readOnly" value={this.state.fileName}></input>
              <input id="myInput" type="file" ref={(ref) => this.upload = ref} style={{
                  display: 'none'
                }} onChange={this.onChangeFile.bind(this)}/>
              <div className="input-group-append">
                <button className="btn btn-success" type="button" onClick={this.uploadFile.bind(this, x)}><FormattedMessage id="envoyer"/></button>
              </div>
            </div>
          </div>
        </div>);
        break;
        case 10:
          return(
            <div className="mb-4">
              <h6 className="text-secondary card rounded-0">
                <b><FormattedMessage id="importer"/></b>
              </h6>

              <span><FormattedMessage id="importer.1"/>
              </span>
              <div className="col d-flex justify-content-center">

                <div className="input-group mb-2 mt-3" style={{maxWidth: "30vw"}}>
                  <div className="input-group-prepend">
                    <button className="btn btn-default btn-info btn-choose" type="file" onClick={() => {
                        this.upload.click()
                      }}><FormattedMessage id="importer"/></button>
                  </div>
                  <input type="text" className="form-control" ref={(ref) => this.upload = ref} readOnly="readOnly" value={this.state.fileName}></input>
                  <input id="myInput" type="file" ref={(ref) => this.upload = ref} style={{
                      display: 'none'
                    }} onChange={this.onChangeFile.bind(this)}/>
                  <div className="input-group-append">
                    <button className="btn btn-success" type="button" onClick={this.uploadFile.bind(this, x)}><FormattedMessage id="envoyer"/></button>
                  </div>
                </div>
              </div>
            </div>
          );
      default:
        return;
    }

  }

  nRefreshGRP(x) {
    this.setState({refreshing: true});
  }

  onChangeFile(event) {
    if (event.target.files[0] && event.target.files[0].name) {
      var file = event.target.files[0];
      event.stopPropagation();
      event.preventDefault();

      if (process.env.NODE_ENV == "development"){
        console.log(file);
      }
      this.setState({fileUpload: file, fileName: file.name});
    }
  }

  /*
  * Fonction qui gère l'affichage de la barre de progression de l'upload des fichiers .APK
  * Créateur : Thomas J.
  */
  async handleProgressBar(url, data, isTestStore){
    $('#modalUploadLoading').modal('show');
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          headers: {'X-XSRF-TOKEN': this.state.tokenCSRF}
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onUploadProgress: (progressEvent) => {
          const prog = (progressEvent.loaded/progressEvent.total)*100;
          console.log(prog);
          switch (Math.round(prog/10)) {
            case 1:
              this.setState({progress:10});
              break;
            case 2:
              this.setState({progress:20});
              break;
            case 3:
              this.setState({progress:30});
              break;
            case 4:
              this.setState({progress:40});
              break;
            case 5:
              this.setState({progress:50});
              break;
            case 6:
              this.setState({progress:60});
              break;
            case 7:
              this.setState({progress:70});
              break;
            case 8:
              this.setState({progress:80});
              break;
            case 9:
              this.setState({progress:90});
              break;
            case 10:
              this.setState({progress:100});
              break;
            default:
              this.setState({progress:0});
          }
          if (progressEvent.estimated >= 0) {
            this.setState({estimated:Math.floor(progressEvent.estimated)});
          }
        }
      });
    } catch (e) {
      return (-1);
    } finally {
        if (isTestStore) {
            fetch(varApp.apiTestStore, {
                method: "GET",
                headers: {'X-XSRF-TOKEN': this.state.tokenCSRF}
              })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('fetchData : Something went wrong ...');
              }
            }).then(data => {this.setState({dataTestStore: this.adaptDataAppli(data, true), dataTestStoreLength: data.length})})
        } else {
          fetch(varApp.apiServerApplication, {
              method: "GET",
              headers: {'X-XSRF-TOKEN': this.state.tokenCSRF}
            })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('fetchData : Something went wrong ...');
            }
          }).then(data => {this.setState({dataAppli: this.adaptDataAppli(data), dataAppliLength: data.length});})
        }
        this.setState({progress:0, estimated:0});
        $('#modalUploadLoading').modal('hide');
    }
  };

  uploadFile(index){

    var data = new FormData();
    data.append("file", this.state.fileUpload);

    switch (index) {
      case 2:
          fetch(varApp.apiImportUsers, {
              method: "POST",
              headers: {'X-XSRF-TOKEN': this.state.tokenCSRF},
              body: data
            }).then(response => {
              $('#modalUploadLoading').modal('hide');
              console.log("response end");
              var x = response;
              if(response.status === 200){
                return response.json().then(responseRetour => {
                  this.importResult(responseRetour);
                })
              }
             })
        break;

      case 3:
          fetch(varApp.apiImportMobiles, {
              mode: 'no-cors',
              method: "POST",
              headers: {'X-XSRF-TOKEN': this.state.tokenCSRF},
              body: data
            }).then(function (res) {
              $('#modalUploadLoading').modal('hide');
            if (res.status == 401) {
              alert("Error 401 !");
            }
          }, function (e) {
            alert("Error submitting form!");
          });
        break;

      case 7:

      if (this.state.showTestStore) {
        this.handleProgressBar(varApp.apiTestStoreUpload, data, true);
      } else {
        this.handleProgressBar(varApp.apiServerApplicationUpload, data, false);
      }

        break;
      case 10:
        fetch(varApp.apiImportMessages, {
          method: "POST",
          headers: {'X-XSRF-TOKEN': this.state.tokenCSRF},
          body: data
        }).then(response => {
          $('#modalUploadLoading').modal('hide');
          var x = response;
          if(response.status === 200){
            return response.json().then(responseRetour => {
              this.importResultMMAppPassword(responseRetour);
            })
          }
        })
        break;

      case 11:
          fetch(varApp.apiImportIMEI, {
              mode: 'no-cors',
              method: "POST",
              headers: {'X-XSRF-TOKEN': this.state.tokenCSRF},
              body: data
            }).then(function (res) {
              $('#modalUploadLoading').modal('hide');
            if (res.status == 401) {
              alert("Error 401 !");
            }
          }, function (e) {
            alert("Error submitting form!");
          });
        break;

    }

    this.setState({fileName: "...", fileUpload: []});

  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }

  removeAppFromServer(index){

    if (this.state.showTestStore) {
      var listApp = this.state.dataTestStore.sort((a, b) => a.name.localeCompare(b.name));

      var newListApp = [];

      fetch(varApp.apiTestStore + listApp[index].packageName + "/" + listApp[index].storeVersion + "/" + listApp[index].storeVersionCode, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.state.tokenCSRF
        },
        body: ""
      }).then(response => {
        var x = response;
        if (response.status === 200) {
          for (var i = 0; i < listApp.length; i++) {
            if (listApp[i].packageName !== listApp[index].packageName || listApp[i].storeVersion !== listApp[index].storeVersion || listApp[i].storeVersionCode !== listApp[index].storeVersionCode) {
              newListApp.push(listApp[i]);
            }
          }
          this.setState({dataTestStore: newListApp})
        } else {
          return response.json().then(responseError => {
            console.log(responseError);
            this.requeteResponse(x, responseError);
          })
        }
      })

    } else {
      var listApp = this.state.dataAppli.sort((a, b) => a.name.localeCompare(b.name));

      var newListApp = [];

            console.log(varApp.apiServerApplication + listApp[index].packageName);

      fetch(varApp.apiServerApplication + listApp[index].packageName, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.state.tokenCSRF
        },
        body: ""
      }).then(response => {
        var x = response;
        if (response.status === 200) {
          for (var i = 0; i < listApp.length; i++) {
            if (listApp[i].packageName !== listApp[index].packageName) {
              newListApp.push(listApp[i]);
            }
          }
          this.setState({dataAppli: newListApp})
        } else {
          return response.json().then(responseError => {
            console.log(responseError);
            this.requeteResponse(x, responseError);
          })
        }
      })

    }
  }

  addAppToClassicStore(index){

    var data = this.state.dataTestStore[index];

    var oldStore = this.state.dataAppli;
    var oldTestStore = this.state.dataTestStore;
    var newListApp = [];
    var newListTestStoreApp = [];

    fetch(varApp.apiTestStoreValide + data.packageName + "/" + data.storeVersion + "/" + data.storeVersionCode, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.state.tokenCSRF
      },
      body: ""
    }).then(response => {
      var x = response;
      if (response.status === 200) {

            for (var i = 0; i < oldStore.length; i++) {
              if (oldStore[i].packageName !== data.packageName) {
                newListApp.push(oldStore[i]);
              }
            }
            newListApp.push(data);

            for (var i = 0; i < oldTestStore.length; i++) {
              if (oldTestStore[i].packageName !== data.packageName
                || oldTestStore[i].storeVersion !== data.storeVersion
                || oldTestStore[i].storeVersionCode !== data.storeVersionCode) {
                newListTestStoreApp.push(oldTestStore[i]);
              }
            }

        this.setState({dataAppli: newListApp, dataTestStore: newListTestStoreApp})

      } else {
        return response.json().then(responseError => {
          console.log(responseError);
          this.requeteResponse(x, responseError);
        })
      }
    })


  }


  loggingFunc(administration, tokenCSRF) {
    this.setState({userIsLogged: 1, userAdministration: administration, tokenCSRF: tokenCSRF});
    localStorage.setItem('userIsLogged', 1);
    localStorage.setItem('userAdministration', administration);
    localStorage.setItem('tokenCSRF', tokenCSRF);
    axios.defaults.headers.common['X-XSRF-TOKEN'] = tokenCSRF;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = tokenCSRF;
  }

  disconnect() {
    this.setState({userIsLogged: 0, userAdministration: null,  tokenCSRF: null});
    localStorage.setItem('userIsLogged', 0);
    localStorage.setItem('userAdministration', null);
    localStorage.setItem('tokenCSRF', null);
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    return (<IntlProvider locale={this.state.language} messages={messages[this.state.language]}>
      <Router>
        <header className="index-header">

          {
            this.state.userIsLogged == 1 && this.state.userAdministration != "USER"
              ? <nav className="navbar  bg-dark stroke navbar-expand container-fluid sticky-top" role="navigation">

                  <ul className="navbar-nav mr-auto small disable_text_highlighting" style={{
                      fontSize: 'calc(7px + 1vmin)'
                    }}>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Dashboard"><FormattedMessage id="app.menu.1"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Users"><FormattedMessage id="app.menu.2"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Devices"><FormattedMessage id="app.menu.3"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Computers"><FormattedMessage id="app.menu.4"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Groups"><FormattedMessage id="app.menu.5"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/MM-File"><FormattedMessage id="app.menu.6"/></NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/MM-App">MM-App</NavLink>
                    </li>
                    <li className="nav-item ml-2">
                      <NavLink className="nav-link" to="/Parametres"><FormattedMessage id="app.menu.7"/></NavLink>
                    </li>

                  </ul>

                  <div className="col-3 form-inline justify-content-md-end">

                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-sm btn-navbar dropdown-toggle text-white" id="dropdownMenuLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span style={{
                              color: "rgb(255,255,255)"
                            }}>
                            <FaFile size={22}/>
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLanguage">
                          <span className="dropdown-header border-bottom">Import / Export</span>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 1)}><FormattedMessage id="app.menu.1"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 2)}><FormattedMessage id="app.menu.2"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 3)}><FormattedMessage id="app.menu.3"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 11)}><FormattedMessage id="app.menu.11"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 4)}><FormattedMessage id="app.menu.6"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 7)}><FormattedMessage id="app.menu.9"/></button>
                          <button type="button" className="dropdown-item" onClick={this.importExport.bind(this, 10)}><FormattedMessage id="app.menu.10"/></button>

                        </div>
                      </div>

                    { this.state.userAdministration === "ADMINISTRATOR" ?
                      <NavLink className="btn btn-sm btn-navbar" to="/ServerConfiguration">
                        <span style={{
                            color: "rgb(255,255,255)"
                          }}>
                          <IoMdSettings size={26}/>
                        </span>
                      </NavLink>

                      : null
                    }

                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-sm btn-navbar dropdown-toggle text-white" id="dropdownMenuLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span style={{
                              color: "rgb(255,255,255)"
                            }}>
                            <FaRegFlag size={20}/>
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLanguage">
                          <span className="dropdown-header border-bottom"><FormattedMessage id="app.menu.8"/></span>
                          <button className="dropdown-item" value="fr" onClick={this.languageChange}>Français</button>
                          <button className="dropdown-item" value="en" onClick={this.languageChange}>English</button>
                          {/*<button className="dropdown-item" value="de" onClick={this.languageChange}>Deutsch</button>*/}
                        </div>
                      </div>

                      <button type="button" className="btn btn-sm btn-navbar text-white" data-toggle="modal" data-target="#modalAPropos">
                        <span style={{
                            color: "rgb(255,255,255)"
                          }}>
                          <FaQuestionCircle size={22}/>
                        </span>
                      </button>

                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-sm btn-navbar dropdown-toggle text-white" id="dropdownMenuUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span style={{
                              color: "rgb(255,255,255)"
                            }}>
                            <FaUserAlt size={20}/>
                          </span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuUser">

                          <button className="dropdown-item" onClick={this.disconnect}>
                            <FormattedMessage id="deconnexion"/>
                            <span className="ml-2">
                              <IoMdExit size={20}/>
                            </span>
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              : <div/>
          }

          <Switch>
            <PrivateRoute exact path="/Dashboard" component={Dashboard} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF} data={this.state.dataMDM}/>
            <PrivateRoute exact path="/Users" component={Users} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Users/NewUser" component={NewUser} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute path="/Users/:userId" component={User} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF} data={this.state.dataMDM}/>
            <PrivateRoute exact path="/Groups/newGroup" component={NewGroup} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute path="/Groups/:groupName" component={Group} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Groups" component={Groups} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/ServerConfiguration" component={Config} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Parametres" component={Parameters} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF} data={this.state.dataMDM} click={this.handleModifMDM.bind(this)}/>
            <PrivateRoute exact path="/Devices" component={Devices} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Devices/NewDevice" component={NewDevice} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Devices/LinkDevice" component={LinkDevice} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Computers" component={Computers} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/MM-File" component={Files} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/MM-App" component={Mmapp} Logged={this.state.userIsLogged} userAdministration={this.state.userAdministration} tokenCSRF={this.state.tokenCSRF}/>
            <PrivateRoute exact path="/Restricted" component={RestrictionLogin} Logged={this.state.userIsLogged} click={this.disconnect.bind(this)}/>
            <Route exact path='/Login' render={(props) => <Login {...props} Logged={this.state.userIsLogged} click={this.loggingFunc.bind(this)}/>}/>
            <PrivateRoute render={(props) => <Notfound {...props} click={this.nRefreshGRP.bind(this, this.state.refreshing)}/>}/>
          </Switch>
              <div className="modal fade small" id="ModalImpExp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className={this.state.modalId === 7 ? "modal-dialog modal-lg modal-dialog-centered" : "modal-dialog modal-dialog-centered"} role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-light">
                      <h5 className="modal-title text-info" id="exampleModalLabel">{
                        this.state.modalId === 1 ? <FormattedMessage id="app.menu.1"/>
                        : this.state.modalId === 2 ? <FormattedMessage id="app.menu.2"/>
                        : this.state.modalId === 3 ? <FormattedMessage id="app.menu.3"/>
                        : this.state.modalId === 4 ? <FormattedMessage id="app.menu.6"/>
                        : this.state.modalId === 7 ? <FormattedMessage id="app.menu.9"/>
                        : this.state.modalId === 10 ? <FormattedMessage id="app.menu.10"/>
                        : this.state.modalId === 11 ? <FormattedMessage id="app.menu.11"/>
                        : null
                      }</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body text-center">

                      {this.state.modalId === 7 ?

                          this.state.dataAppli && this.state.dataAppli.length > 0 ?

                          <MobileApplications dataAppli={this.state.dataAppli}
                          dataTestStore={this.state.dataTestStore}
                          dataTestStoreNote={this.state.dataTestStoreNote}
                          showTestStore={this.state.showTestStore}
                          handleShowTestStoreChange={this.handleShowTestStoreChange.bind(this)}
                          modalConfirm={this.modalConfirm.bind(this)}
                          userAdministration={this.state.userAdministration}
                          tokenCSRF={this.state.tokenCSRF}/>

                            :null

                        :null
                      }

                      {this.state.userAdministration === "ADMINISTRATOR" ? this.modalImport(this.state.modalId) : null}

                      {this.state.modalId === 7 || this.state.modalId === 11 ?
                        null
                        :
                        <div>
                        <h6 className="text-secondary card rounded-0">
                          <b><FormattedMessage id="exporter"/></b>
                        </h6>
                        <span className="mr-3"><FormattedMessage id="exporter.1"/>
                        </span>
                        </div>
                      }

                      { this.state.modalId === 1 ?
                        <a href={varApp.apiExportDashboard}>
                          <button className="btn btn-sm btn-info">
                            <span style={{
                                color: "rgb(255,255,255)"
                              }}>
                              <FaFileExport size={28}/>
                            </span>
                          </button>
                        </a>

                        : this.state.modalId === 2 ?
                          <a href={varApp.apiExportUsers}>
                            <button className="btn btn-sm btn-info">
                              <span style={{
                                  color: "rgb(255,255,255)"
                                }}>
                                <FaFileExport size={28}/>
                              </span>
                            </button>
                          </a>

                          : this.state.modalId === 3 ?
                            <a href={varApp.apiExportMobiles}>
                              <button className="btn btn-sm btn-info">
                                <span style={{
                                    color: "rgb(255,255,255)"
                                  }}>
                                  <FaFileExport size={28}/>
                                </span>
                              </button>
                            </a>

                            : this.state.modalId === 4 ?
                              <a href={varApp.apiExportMMFile}>
                                <button className="btn btn-sm btn-info">
                                  <span style={{
                                      color: "rgb(255,255,255)"
                                    }}>
                                    <FaFileExport size={28}/>
                                  </span>
                                </button>
                              </a>
                            : this.state.modalId === 10 ?
                              <a href={varApp.apiExportMMApp}>
                                <button className="btn btn-sm btn-info">
                                  <span style={{
                                      color: "rgb(255,255,255)"
                                    }}>
                                    <FaFileExport size={28}/>
                                  </span>
                                </button>
                              </a>
                            : null}

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade small" id="ModalImportResult" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Import Result</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      <ReactTable

                      oncontextmenu="return false;"
                      getTheadThProps={getTheadThProps}

                      getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          style: {
                            text: rowInfo.original
                          }
                        }
                      }else{
                        return {}
                      }
                    }}


                        showPagination={false}
                        data={this.state.tabResult}
                        TbodyComponent={CustomTbodyComponent}
                        columns={columns}
                        resizable={true}
                        pageSize={this.state.tabResult.length}
                              style={{
                                minHeight: "15vh",
                                maxHeight: "70vh"
                              }}
                              className=" text-center border-0 small -clickable"
                            />

                            <div className="col text-center border-top small card rounded-0">
                							<b className="text-dark"><FormattedMessage id={"nb.ligne"}/>{this.state.tabResult.length}</b>
                						</div>

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalConfirmSuprAppServ" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className={`modal-content modalConfirm border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel">
                        <FormattedMessage id={"supprimer"}/>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel">
                        <FormattedMessage id={"confirmDelete"}/> "<b className="text-info">{this.state.modalTXT}</b>" ?
                      </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.removeAppFromServer.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalImportPasswordResult" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                  <div className={`modal-content border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalImportPasswordResult">
                        <FormattedMessage id="result"/>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2 small">

                      <ReactTable getTheadThProps={getTheadThProps} showPagination={false} data={this.state.importMMAppResult}
                        noDataText={<FormattedMessage id = {"noElementsTab"} />} TbodyComponent={CustomTbodyComponent}
                    columns={columnsImportMMAppResult}
                    resizable={true}
                    pageSizeOptions={[10, 20, 25, 50, 100]}
                    showPageSizeOptions={false}
                    pageSize={this.state.importMMAppResult.length} style={{
                      maxHeight: "61vh"
                    }} className=" text-center border-0 small"

                  />

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalConfirmMoveAppToClassicStore" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className={`modal-content modalConfirm border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel">
                        <b className="text-info">{this.state.modalTXT}</b>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel">
                        <FormattedMessage id={"importMMApp.applications.modal.addClassicStore.msg"}/>
                      </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <button type="button" className={`btn text-white btn-info`} data-dismiss="modal" onClick={this.addAppToClassicStore.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modalConfirmDownloadAppToDevice" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className={`modal-content modalConfirm border-secondary`}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalAjoutDNSLabel">
                        <b className="text-info">{this.state.modalTXT}</b>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ml-2">
                      <h6 className="modal-title" id="modalAjoutDNSLabel">
                        <FormattedMessage id={"importMMApp.applications.modal.download.msg"}/>
                      </h6>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                      </button>
                      <a href={varApp.apiRelaisDownload + this.state.modalIndex}>
                        <button className="btn btn-info">
                            <FormattedMessage id="valider"/>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="modalAPropos" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                  <div className="modal-header bg-light">
                      <h5 className="modal-title text-info" id="modalAjoutDNSLabel">
                          <FormattedMessage id="app.menu.help.about"/>
                      </h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      <h6 className="text-info text-center card rounded-0"><b><FormattedMessage id="versionInfo"/></b></h6>

                      <div className="col mb-3">
                          {this.state.dataAbout ?
                            this.state.dataAbout.map((service, index) => {

                            return(
                              service.name && service.version ?
                                    <div key={index} className="row d-flex justify-content-center">
                                      <div>
                                        <b style={{fontSize: "calc(6px + 1vmin)", color: "rgb(50,50,50)"}}>{service.name}</b> : 
                                        <a style={{fontSize: "calc(6px + 1vmin)", color: "rgb(100,100,100)"}}>{service.version}</a>
                                      </div>
                                    </div>
                                : null
                            )
                          })
                          : null
                        }
                      </div>

                        <div className="border-top pt-2" style={{fontSize: "calc(5px + 1vmin)", color: "rgb(100,100,100)"}}>
                          <div className="row d-flex justify-content-center"><FormattedMessage id="copyright"/> {varAPropos.copyrightDate} <FormattedMessage id="allRights"/></div>
                          <div className="row d-flex justify-content-center">Mail : {varAPropos.mail}</div>
                          <div className="row d-flex justify-content-center"><FormattedMessage id="phoneNum"/> : {varAPropos.phone}</div>
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal" id="modalUploadLoading" tabIndex="-1" role="dialog" aria-hidden="true"
              data-backdrop="static" data-keyboard="false">

              <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-light">
                      <h6 className="modal-title text-info">
                        <b>
                          <FormattedMessage id="chargement"/> ...
                        </b>
                      </h6>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  <div className="modal-body">
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{width: `${Math.floor(this.state.progress)}%`}} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div className="uploadStatus">
                    <p><FormattedMessage id="estimate"/> :<br/> {`${Math.floor(this.state.estimated/60)} minutes ${Math.floor(this.state.estimated%60)} `}<FormattedMessage id="secondes"/> </p>
                  </div>
                  </div>
                </div>
              </div>
        </div>



        </header>
      </Router>
    </IntlProvider>);

  }
}
export default App;
