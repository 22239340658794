import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select, { components } from 'react-select';

import { FaPlus } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";

import TablePlus from '../Components/TablePlus';


var tempE;

class ParametresMDM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //



// ------------------------------- Action handle ------------------------------- //

_handleKeyDownForAccounts = (e) => {
  if (e.key === 'Enter') {
    this.props.addAutoEnrollmentAccounts();
    $('#modalAddAutoEnrollmentAccount').modal('hide');
  }
};

_handleKeyDownForBrands = (e) => {
  if (e.key === 'Enter') {
    this.props.addAutoEnrollmentBrands();
    $('#modalAddAutoEnrollmentBrand').modal('hide');
  }
};

deleteHandler(){

}

  brandList(usedModel){
    return <div>
      <button style={{
          outline: 'none'
        }} className="close" >
          <span style={{
              color: "rgb(110,110,110)"
            }}>
            <IoIosCloseCircleOutline size={28}/>
          </span>
      </button>
      {usedModel.id_brand + " // " + usedModel.id_model}
    </div>
  }


  getAccountsSelectFormat(){

    var newArray = [];
    const list = this.props.dataAutoEnrollment.availableAccount;

    for (var i = 0; i < list.length; i++) {

      var item = new Object;
      item.name = list[i].name;
      item.type = list[i].type;
      item.label = list[i].name + " / " + list[i].type;

      if (item.label !== undefined) {
        newArray.push(item);
      }
    }

    return newArray.sort((a, b) => a.label.localeCompare(b.label));
  }

  getBrandsSelectFormat(){

    var newArray = [];
    const list = this.props.dataAutoEnrollment.availableModels;

    for (var i = 0; i < list.length; i++) {

      var item = new Object;
      item.id_brand = list[i].id_brand;
      item.id_model = list[i].id_model;
      item.label = list[i].id_brand + " / " + list[i].id_model;

      if (item.label !== undefined) {
        newArray.push(item);
      }
    }

    return newArray.sort((a, b) => a.label.localeCompare(b.label));
  }

  adaptDataForBrands(data){
    if (data) {
      var newTab = [];
      for (var i = 0; i < data.length; i++) {
        newTab.push(data[i].id_brand + " / " + data[i].id_model);
      }
      return newTab;
    } else {
      return null;
    }
  }

  adaptDataForAccounts(data){
    if (data) {
      var newTab = [];
      for (var i = 0; i < data.length; i++) {
        newTab.push(data[i].name + " / " + data[i].type);
      }
      return newTab;
    } else {
      return null;
    }
  }

// ------------------------------- DATA fetching ------------------------------- //


// ------------------------------- Render -------------------------------------- //

  render() {

    return (<div className="col mb-3">

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.autoEnrollment.title"/></b>
      </h5>
      <hr/>

      <div className="col-9 container">

        <div className="col mt-4">
          <TablePlus
            title={<FormattedMessage id="parametres.autoEnrollment.brandTab.title"/>}
            data={this.adaptDataForBrands(this.props.dataAutoEnrollment.usedModel)}
            modalAddId="modalAddAutoEnrollmentBrand"
            deleteHandler={this.modalConfirm.bind(this)}
            modalRemoveId="modalConfirmSuprBrand"
            userAdministration={this.props.userAdministration}
            maxHeight="280px"
          />
        </div>

        <div className="col mt-4">
          <TablePlus
            title={<FormattedMessage id="parametres.autoEnrollment.accountTab.title"/>}
            data={this.adaptDataForAccounts(this.props.dataAutoEnrollment.usedAccount)}
            modalAddId="modalAddAutoEnrollmentAccount"
            deleteHandler={this.modalConfirm.bind(this)}
            modalRemoveId="modalConfirmSuprCompte"
            userAdministration={this.props.userAdministration}
            maxHeight="280px"
          />
        </div>





        <h6 className="text-info mt-2 mb-3">
          <b><FormattedMessage id="parametres.autoEnrollment.checkbox.title"/></b>
        </h6>

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" id="imei" checked={this.props.dataAutoEnrollment.numIMEI} onChange={e => this.props.handleChange(e, "numIMEI")}/>
          <label className="custom-control-label" htmlFor="imei">
            <FormattedMessage id="parametres.autoEnrollment.checkbox.imei"/>
          </label>
        </div>

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" id="undesirableMobilesApp" checked={this.props.dataAutoEnrollment.unwantedApplications} onChange={e => this.props.handleChange(e, "unwantedApplications")}/>
          <label className="custom-control-label" htmlFor="undesirableMobilesApp">
            <FormattedMessage id="parametres.autoEnrollment.checkbox.unwantedApp"/>
          </label>
        </div>

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" id="gmailClient" checked={this.props.dataAutoEnrollment.clientEmail} onChange={e => this.props.handleChange(e, "clientEmail")}/>
          <label className="custom-control-label" htmlFor="gmailClient">
            <FormattedMessage id="parametres.autoEnrollment.checkbox.samsungMail"/>
          </label>
        </div>

        <div className="custom-control custom-checkbox mb-5">
          <input className="custom-control-input" type="checkbox" id="versionOS" checked={this.props.dataAutoEnrollment.versionOS} onChange={e => this.props.handleChange(e, "versionOS")}/>
          <label className="custom-control-label" htmlFor="versionOS">
            <FormattedMessage id="parametres.autoEnrollment.checkbox.osVersion"/>
          </label>
        </div>


      </div>


      <div className="modal fade" id="modalAddAutoEnrollmentBrand" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title col"><FormattedMessage id="parametres.autoEnrollment.brandTab.modal.title"/></h5>

              {/*
                <button className="btn btn-sm btn-primary col-auto" data-toggle="modal" data-target={"modalAddBrand"}>
                  <span style={{
                      color: "rgb(255,255,255)"
                    }}>
                    <FaPlus size={14}/>
                  </span>
                </button>
              */}


              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><FormattedMessage id="parametres.autoEnrollment.brandTab.modal.firstInput"/></span>
                </div>
                <input type="text" className="form-control" placeholder={this.props.intl.formatMessage({id: "parametres.autoEnrollment.brandTab.modal.firstInput.placeholder"})} aria-label="Brand" aria-describedby="basic-addon1" id="brandNameInput" defaultValue={this.props.autoEnrollmentBrands.id_brand} onChange={e => this.props.handleAutoEnrollmentBrands(e, "id_brand")}/>
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><FormattedMessage id="parametres.autoEnrollment.brandTab.modal.secondInput"/></span>
                </div>
                <input type="text" className="form-control" placeholder={this.props.intl.formatMessage({id: "parametres.autoEnrollment.brandTab.modal.secondInput.placeholder"})} aria-label="Model" aria-describedby="basic-addon1" id="modelNameInput" defaultValue={this.props.autoEnrollmentBrands.id_model} onChange={e => this.props.handleAutoEnrollmentBrands(e, "id_model")}/>
              </div>

              {/*
                <Select className="mt-2 mb-3"
                  id="newSelectGroupForMessageGroupe"
                  maxMenuHeight={235}
                  value={this.props.autoEnrollmentBrands}
                  onChange={this.props.handleChangeSelectBrand}
                  options={this.getBrandsSelectFormat()}
                />
              */}


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" onClick={this.props.addAutoEnrollmentBrands} data-dismiss="modal"><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalAddAutoEnrollmentAccount" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title col"><FormattedMessage id="parametres.autoEnrollment.accountTab.modal.title"/></h5>
              {/*
                <button className="btn btn-sm btn-primary col-auto" data-toggle="modal" data-target={"#"}>
                  <span style={{
                      color: "rgb(255,255,255)"
                    }}>
                    <FaPlus size={14}/>
                  </span>
                </button>
              */}

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><FormattedMessage id="parametres.autoEnrollment.accountTab.modal.firstInput"/></span>
                </div>
                <input type="text" className="form-control" placeholder={this.props.intl.formatMessage({id: "parametres.autoEnrollment.accountTab.modal.firstInput.placeholder"})} aria-label="AccountName" aria-describedby="basic-addon1" id="accountNameInput" defaultValue={this.props.autoEnrollmentAccounts.name} onChange={e => this.props.handleAutoEnrollmentAccounts(e, "name")}/>
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"><FormattedMessage id="parametres.autoEnrollment.accountTab.modal.secondInput"/></span>
                </div>
                <input type="text" className="form-control" placeholder={this.props.intl.formatMessage({id: "parametres.autoEnrollment.accountTab.modal.secondInput.placeholder"})} aria-label="AccountType" aria-describedby="basic-addon1" id="accountTypeInput" defaultValue={this.props.autoEnrollmentAccounts.type} onChange={e => this.props.handleAutoEnrollmentAccounts(e, "type")}/>
              </div>

              {/*
                <Select className="mt-2 mb-3"
                  id="newSelectGroupForMessageGroupe"
                  maxMenuHeight={235}
                  value={this.props.autoEnrollmentAccounts}
                  onChange={this.props.handleChangeSelectAccount}
                  options={this.getAccountsSelectFormat()}
                />
              */}


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" onClick={this.props.addAutoEnrollmentAccounts} data-dismiss="modal"><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprCompte" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title">
                {this.props.intl.formatMessage({id: "supprimer"})}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title">
                {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.props.removeAutoEnrollmentAccounts.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalConfirmSuprBrand" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title">
                {this.props.intl.formatMessage({id: "supprimer"})}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title">
                {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.props.removeAutoEnrollmentBrands.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>




    </div>)
  }
}
ParametresMDM.propTypes = {
  intl: intlShape.isRequired
};
ParametresMDM = injectIntl(ParametresMDM, {withRef: true});

export default ParametresMDM
