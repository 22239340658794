import React from 'react'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'
import DevicesDetailsInfos from './DevicesDetailsInfos';
import DevicesDetailsApplist from './DevicesDetailsApplist';
import DevicesDetailsApplistContextMenu from './DevicesDetailsApplistContextMenu';
import DevicesDetailsMissApp from './DevicesDetailsMissApp';
import DevicesDetailsMissAppContextMenu from './DevicesDetailsMissAppContextMenu';
import DevicesDetailsUnwantedApp from './DevicesDetailsUnwantedApp';
import DevicesDetailsNotifications from './DevicesDetailsNotifications';
import DevicesDetailsAccounts from './DevicesDetailsAccounts';
import DevicesDetailsGeofencings from './DevicesDetailsGeofencings';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import {IoIosWifi, IoMdLock, IoMdUnlock} from "react-icons/io";
import { MdBattery20, MdBattery30, MdBattery50, MdBattery60, MdBattery80, MdBattery90, MdBatteryFull, MdInfo } from "react-icons/md";


var subCompliantInfo = null;

// Fonction pour convertir les octets en Mo/Go
  function octetAdapt(value){
    var newValue = value;
    if(value > Math.pow(10, 3)){
      newValue = Number(parseInt(value)/Math.pow(10, 3)).toFixed(2) + " Go";
    } else {
      newValue = newValue.toFixed(2) + " Mo";
    }
    return(newValue)
  }

  function changeToDate(int, langue){
    var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


class DevicesDetails extends React.Component {constructor(props) {
  super(props);
  this.state = {
    activeTab: "detailsTab",
    applistTab: "",
    missApplistTab: "",
    unwantedApplistTab: "",
    wrongApplistTab: "",
    notificationsTab: "",
    accountsTab: "",
    geofencingsTab: "",
    appHit: [],
    notificationHit: [],
  };

  this.contextMenu = this.contextMenu.bind(this);
}


  checkData(hit){
    if(hit){
      subCompliantInfo = hit;
    }else {
      subCompliantInfo = null;
    }
  }

  componentDidMount= () =>{
  $('#ModalDevicesDetails').on('hidden.bs.modal', function (event){
      //this.setState({activeTab: "detailsTab"})
      //this.setState({detailsTab : "show active" ,applistTab : "", missApplistTab: "", unwantedApplistTab: "", wrongApplistTab:"", notificationsTab:"", accountsTab: ""})
    }.bind(this))
  }

  onModalDeviceDetailsClose(){
    $('#ModalDevicesDetails').modal('hide')
    this.setState({activeTab: "detailsTab"})
  }

  tabClick(tab){
    //this.setState({detailsTab: "", applistTab: "", missApplistTab: "", unwantedApplistTab: "", wrongApplistTab: "", notificationsTab: "", accountsTab: ""})
    //this.setState({[tab]: "show active"})
    this.setState({activeTab: tab})
  }

  contextMenu(x){
      if(x === "empty"){
      this.setState({appHit : "empty"});
    }else {
    this.setState({appHit : x});
    }
  }

  contextMenuNotifications(x){
      if(x === "empty"){
      this.setState({notificationHit : "empty"});
    }else {
    this.setState({notificationHit : x});
    }
  }


// ------------------------------- Function with visual ------------------------ //

  createBattery(value){
    var color = "#c4c4c4";
    var size = 26;

    if(value < 20){color = "#fc2828";}
    else if(value < 40){color = "#fc8d25";}
    else if(value < 60){color = "#f4bf22";}
    else if(value < 80){color = "#a5e44a";}
    else {color = "#77e733";}


      return(
          <span style={{
              color: color
            }}> 
            {value < 20 ?
                <MdBattery20 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 30 ?
                <MdBattery30 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 50 ?
                <MdBattery50 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 60 ?
                <MdBattery60 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 80 ?
                <MdBattery80 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : value < 90 ?
                <MdBattery90 size={size} style={{transform: `rotate(${90}deg)`}}/> 
              : <MdBatteryFull size={size} style={{transform: `rotate(${90}deg)`}}/> 
            } 
            <b>{value} %</b>
          </span>
    )
  }


  createProgessBar(name, percentage, availableValue, maxValue){

    var color = "#c4c4c4";
    var newAvailableValue = octetAdapt(parseInt(availableValue));
    var newMaxValue = octetAdapt(parseInt(maxValue));

    if(percentage <= 25){color = "#77e733";}
    else if(percentage <= 50){color = "#ffdc29";}
    else if(percentage <= 75){color = "#fc8d25";}
    else {color = "#fc2828";}


      return(
        <div>
          <div className="row ml-2">
          <div className="">
            <div className="" style={{ maxWidth: "5vw" }}>
            <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
                textSize: '24px',
                pathTransitionDuration: 0.5,
                pathColor: color,
                textColor: color,
                trailColor: '#d6d6d6',
            })}/>

            </div>
          </div>
          <div className="mt-2 ml-3">

            {availableValue ? <p><b className="text-info">{this.props.intl.formatMessage({id: "disponible"})} : </b>{newAvailableValue}</p> : null}
            {maxValue ? <p><b className="text-info">Maximum : </b>{newMaxValue}</p> : null}

          </div>
        </div>

      </div>
    )
  }



// ------------------------------- Render -------------------------------------- //

  render() {

    const { activeTab } = this.state;

    this.checkData(this.props.devicesDetails.subCompliantInfo);

    if (this.props.errorModal) {
      return (
          <div>

            <div className="modal fade" id={"ModalDevicesDetailsError"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-graph modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title text-info" id="exampleModalLabel">
                      <b>
                        Error
                      </b>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body small">

                    error {this.props.errorModal.message}
                </div>
                </div>
              </div>
            </div>

              <div className="container small">
              </div>
          </div>
      );
    }

    return (<div>

      <div className="modal fade" id={"ModalDevicesDetails"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xxl modal-dialog-centered" role="document">
          <div className="modal-content" style={{maxHeight: "91vh", minWidth: "60vw"}}>
            <div className="modal-header">
              <div className="modal-title text-info" id="exampleModalLabel">
                <h6><b>
                  {this.props.intl.formatMessage({id: "terminaux.dropdown.10"})} </b> : {this.props.hit.name}
                </h6>
              </div>
              <button type="button" className="close" aria-label="Close" onClick={this.onModalDeviceDetailsClose.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body small">

              {
                /*
              <div style={{
                height: "25vh",
              }}>

                <div className="row ml-3" style={{fontSize: 'calc(5px + 1vmin)'}}>

                <div className="col-auto">
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.1"})} </b> : 
                    {this.props.devicesDetails.user ? this.props.devicesDetails.user.email : null}
                  </p>
                  <p className="mt-2"><b> {this.props.intl.formatMessage({id: "terminaux.4"})} </b> : {this.props.devicesDetails.model}</p>
                  {this.props.dataConfigServerMMApp.enableLocation === "true" ?
                   this.props.devicesDetails.user && this.props.devicesDetails.user.localisation ?
                     <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.location"})} </b> : {this.props.devicesDetails.location}</p>
                     : null
                  : null
                  }
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.3"})} </b> : {this.props.devicesDetails.securityLevel}</p>
                  {this.props.devicesDetails.basebandVersion ?
                    <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.9"})} </b> : {this.props.devicesDetails.basebandVersion}</p>
                    :
                    null
                  }


                </div>
                <div className="col-auto">
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.4"})} </b> : {this.props.devicesDetails.kernelName}</p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.5"})} </b> : {this.props.devicesDetails.kernelVersion}</p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.6"})} </b> : {this.props.devicesDetails.os_Version}</p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.battery"})} </b> :
                  { this.props.devicesDetails.battery ? this.createBattery(this.props.devicesDetails.battery) : null}</p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure"})} </b> :
                  {this.props.devicesDetails.deviceSecure ?
                    <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure.locked"})}
                      style={{color: "rgb(81,157,185)"}}>
                    <IoMdLock size={26}/>
                  </span>

                  : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceSecure.unlocked"})}
                    style={{color: "rgb(244,191,34)"}}>
                    <IoMdUnlock size={26}/>
                  </span>}
                  </p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted"})} </b> :
                  {this.props.devicesDetails.deviceRooted ?
                    <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted.rooted"})}
                      style={{color: "rgb(228,38,38)"}}>
                    <IoMdLock size={26}/>
                  </span>

                  : <span className="col-auto" title={this.props.intl.formatMessage({id: "terminauxDetails.header.deviceRooted.notRooted"})}
                    style={{color: "rgb(70,201,37)"}}>
                    <IoMdUnlock size={26}/>
                  </span>}
                  </p>

                </div>

                <div className="col">
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.7"})} </b> : {changeToDate(this.props.devicesDetails.createdAt, this.props.intl.locale)}</p>
                  <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.8"})} </b> : {changeToDate(this.props.devicesDetails.updatedAt, this.props.intl.locale)}</p>

                  <b> {this.props.intl.formatMessage({id: "terminauxDetails.header.phoneStorage"})} </b> :
                  <div className="mt-2">
                    { this.props.devicesDetails.storage ? this.createProgessBar("", Number(((this.props.devicesDetails.storage - this.props.devicesDetails.storageAvailable)/this.props.devicesDetails.storage)*100).toFixed(2), this.props.devicesDetails.storageAvailable, this.props.devicesDetails.storage) : null}
                  </div>
                  <div className="mt-2">
                  {this.props.devicesDetails.storageOnServeur && this.props.devicesDetails.storageOnServeur > 0 ?
                    <p><b> {this.props.intl.formatMessage({id: "terminauxDetails.header.storageOnServeur"})} </b> : {octetAdapt(this.props.devicesDetails.storageOnServeur / (1024*1024))}</p>
                    : null
                  }
                  </div>

                </div>
              </div>

              {this.props.devicesDetails.wifi ?
                this.props.devicesDetails.wifi.length > 0 ?
                <ReactTooltip className="relayClass" id='wifiList' type={'light'} border={true} effect={'solid'} place={'bottom'}>
                  <div style={{"listStyleType": "circle"}}>
                    {
                    this.props.devicesDetails.wifi.map((x, index) =>
                    x.length > 0 ?
                      <li key={index}>{x.name}</li>
                      : null
                  )
                }
                </div>
                </ReactTooltip>

                  :null
                  :null

              }
              </div>
              */
            }

              <div className="card border-0">

              <nav>
                <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                  <a className={`nav-item nav-link ${activeTab == "detailsTab" ? "active" : null}`} data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true" onClick={this.tabClick.bind(this, "detailsTab")} ><FormattedMessage id="terminauxDetails.tab.details"/></a>
                  <a className={`nav-item nav-link ${activeTab == "applistTab" ? "active" : null}`} data-toggle="tab" href="#nav-applist" role="tab" aria-controls="nav-applist" aria-selected="false" onClick={this.tabClick.bind(this, "applistTab")} ><FormattedMessage id="terminauxDetails.tab.1"/></a>
                  <a className={`nav-item nav-link ${activeTab == "missApplistTab" ? "active" : null}`} data-toggle="tab" href="#nav-missApp" role="tab" aria-controls="nav-missApp" aria-selected="false" onClick={this.tabClick.bind(this, "missApplistTab")}><FormattedMessage id="terminauxDetails.tab.2"/></a>
                  <a className={`nav-item nav-link ${activeTab == "unwantedApplistTab" ? "active" : null}`} data-toggle="tab" href="#nav-unwantedApp" role="tab" aria-controls="nav-unwantedApp" aria-selected="false" onClick={this.tabClick.bind(this, "unwantedApplistTab")}><FormattedMessage id="terminauxDetails.tab.unwanted"/></a>
                  <a className={`nav-item nav-link ${activeTab == "notificationsTab" ? "active" : null}`} data-toggle="tab" href="#nav-notif" role="tab" aria-controls="nav-notif" aria-selected="false" onClick={this.tabClick.bind(this, "notificationsTab")}><FormattedMessage id="terminauxDetails.tab.notif"/></a>

                  {
                    this.props.devicesDetails.accountList.length > 0 ?
                    <a className={`nav-item nav-link ${activeTab == "accountsTab" ? "active" : null}`} data-toggle="tab" href="#nav-account" role="tab" aria-controls="nav-account" aria-selected="false" onClick={this.tabClick.bind(this, "accountsTab")}><FormattedMessage id="terminauxDetails.tab.account"/></a>
                    :
                    null
                  }

                  {
                    this.props.devicesDetails.geofencingZoneInfos && this.props.devicesDetails.geofencingZoneInfos.length > 0 ?
                    <a className={`nav-item nav-link ${activeTab == "geofencingsTab" ? "active" : null}`} data-toggle="tab" href="#nav-geofencing" role="tab" aria-controls="nav-geofencing" aria-selected="false" onClick={this.tabClick.bind(this, "geofencingsTab")}><FormattedMessage id="terminauxDetails.tab.geofencing"/></a>
                    :
                    null
                  }

                </div>
              </nav>

              { this.props.devicesDetailsApplist ?

                <div className="tab-content" id="nav-tabContent" style={{height: "52vh"}}>
                  <div className={`tab-pane fade ${activeTab == "detailsTab" ? "show active" : null}`} id="nav-details" role="tabpanel">

                    <DevicesDetailsInfos devicesDetails={this.props.devicesDetails}
                    dataConfigServerMMApp={this.props.dataConfigServerMMApp}
                    refresh={this.props.refresh}
                    fetchDevicesDetails={this.props.fetchDevicesDetails}
                    tokenCSRF={this.props.tokenCSRF}/>

                  </div>
                  <div className={`tab-pane fade ${activeTab == "applistTab" ? "show active" : null}`} id="nav-applist" role="tabpanel">

                    <DevicesDetailsApplist devicesDetailsApplist={this.props.devicesDetailsApplist}
                    researchAppHandle={this.props.researchAppHandle}
                    applistFetching={this.props.applistFetching}
                    subCompliantInfo={subCompliantInfo}
                    contextMenu={this.contextMenu}
                    tokenCSRF={this.props.tokenCSRF}/>

                    <DevicesDetailsApplistContextMenu
                      userAdministration={this.props.userAdministration}
                      deviceGroupId={this.props.devicesDetails}
                      hit={this.props.hit}
                      appHit={this.state.appHit}
                      fetchAllDevices={this.props.fetchAllDevices}
                      adaptAllDevices={this.props.adaptAllDevices}
                      devicesDetails={this.props.devicesDetails}
                      tokenCSRF={this.props.tokenCSRF}/>

                  </div>
                  <div className={`tab-pane fade ${activeTab == "missApplistTab" ? "show active" : null}`} id="nav-missApp" role="tabpanel">

                    <DevicesDetailsMissApp devicesDetailsMissApplist={this.props.devicesDetailsMissApplist}
                    devicesDetailsMissReferenceApplicationlist={this.props.devicesDetailsMissReferenceApplicationlist}
                    researchAppHandle={this.props.researchMissAppHandle}
                    applistFetching={this.props.applistFetching}
                    subCompliantInfo={subCompliantInfo}
                    contextMenu={this.contextMenu}
                    tokenCSRF={this.props.tokenCSRF}/>

                    <DevicesDetailsMissAppContextMenu
                      userAdministration={this.props.userAdministration}
                      deviceGroupId={this.props.devicesDetails.group_id}
                      appHit={this.state.appHit}
                      fetchAllDevices={this.props.fetchAllDevices}
                      adaptAllDevices={this.props.adaptAllDevices}
                      devicesDetails={this.props.devicesDetails}
                      tokenCSRF={this.props.tokenCSRF}/>

                  </div>

                  <div className={`tab-pane fade ${activeTab == "unwantedApplistTab" ? "show active" : null}`}id="nav-unwantedApp" role="tabpanel">

                    <DevicesDetailsUnwantedApp devicesDetailsUnwantedApplist={this.props.devicesDetailsUnwantedApplist}
                    researchAppHandle={this.props.researchUnwantedAppHandle}
                    applistFetching={this.props.applistFetching}
                    subCompliantInfo={subCompliantInfo}
                    contextMenu={this.contextMenu}
                    tokenCSRF={this.props.tokenCSRF}/>

                  </div>

                  <div className={`tab-pane fade ${activeTab == "notificationsTab" ? "show active" : null}`}id="nav-notif" role="tabpanel">

                    {this.props.deviceNotifications ?
                      <DevicesDetailsNotifications deviceNotifications={this.props.deviceNotifications}
                      devicesDetailsMissReferenceApplicationlist={this.props.devicesDetailsMissReferenceApplicationlist}
                      researchAppHandle={this.props.researchNotificationsHandle}
                      applistFetching={this.props.applistFetching}
                      devicesDetails={this.props.devicesDetails}
                      fetchDevicesDetails={this.props.fetchDevicesDetails}
                      contextMenuNotifications={this.contextMenuNotifications.bind(this)}
                      notificationHit={this.state.notificationHit}
                      tokenCSRF={this.props.tokenCSRF}/>
                      :
                      null
                    }


                  </div>

                  <div className={`tab-pane fade ${activeTab == "accountsTab" ? "show active" : null}`} id="nav-account" role="tabpanel">
                    {
                      this.props.devicesDetails.accountList.length > 0 ?
                      <DevicesDetailsAccounts data={this.props.devicesDetails.accountList}
                      tokenCSRF={this.props.tokenCSRF}/>
                      :
                      null
                    }
                  </div>

                  <div className={`tab-pane fade ${activeTab == "geofencingsTab" ? "show active" : null}`} id="nav-geonfencing" role="tabpanel">
                    {
                      this.props.devicesDetails.geofencingZoneInfos && this.props.devicesDetails.geofencingZoneInfos.length > 0 ?
                      <DevicesDetailsGeofencings data={this.props.devicesDetails.geofencingZoneInfos}
                      tokenCSRF={this.props.tokenCSRF}/>
                      :
                      null
                    }
                  </div>

                </div>

                : null
              }

            </div>

            <div className="modal-footer border-0 justify-content-center d-flex">
              <button type="button" className="btn btn-secondary" onClick={this.onModalDeviceDetailsClose.bind(this)}><FormattedMessage id="fermer"/></button>
            </div>

          </div>
          </div>
        </div>
      </div>

    </div>)
  }
}

DevicesDetails.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetails = injectIntl(DevicesDetails, {withRef: true});

export default DevicesDetails
