import React from 'react'
import find from 'lodash/find';
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import TablePlus from '../Components/TablePlus';

class GroupsPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

// ------------------------------- Action handle ------------------------------- //

modalConfirm(name, index) {
  this.setState({modalTXT: name, modalIndex: index})
}


getSelectFormat(list){
  if (list) {
    var newArray = [];

    for (var i = 0; i < list.length; i++) {

      var item = new Object;

      item.value = list[i];
      item.label = this.permissionsTrad(list[i]);
      newArray.push(item)
    }

    return newArray;
  }
}

AdaptPermissionsLabel(groupPermissions){
  var tradPermissions = []
  for (var i = 0; i < groupPermissions.length; i++) {
    tradPermissions.push(this.permissionsTrad(groupPermissions[i].permission))
  }
  return tradPermissions
}

permissionsTrad(permissionName){
  switch (permissionName) {
    case "CAMERA":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.1"});
      break;
    case "CONTACTS":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.2"});
      break;
    case "LOCATION":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.3"});
      break;
    case "RECORD_AUDIO":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.4"});
      break;
    case "STORAGE":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.5"});
      break;
    case "PHONE":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.6"});
      break;
    case "CALENDAR":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.7"});
      break;
    case "SMS":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.8"});
      break;
    case "CALL_LOG":
        return this.props.intl.formatMessage({id: "terminauxDetails.permissions.9"});
      break;

    default:
      return permissionName;
  }
}

// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div>

        <h5 className="text-primary text-center mt-2">
          <b><FormattedMessage id="groupsDetails.7"/></b>
        </h5>
        <hr/>

        <div className="col-8 mt-4 container">

          <h6 className="text-info mb-2">
            <b><FormattedMessage id="groupsDetails.permissions.title"/></b>
          </h6>

            <TablePlus
            title={this.props.intl.formatMessage({id: "groupsDetails.permissions.subTitle"})}
            data={this.AdaptPermissionsLabel(this.props.dataGroupPermissions)}
            modalAddId="modalAjoutPermissions"
            deleteHandler={this.modalConfirm.bind(this)}
            modalRemoveId="modalConfirmSuprEmail"
            userAdministration={this.props.userAdministration}
            maxHeight="235px"
            />


          <div className="modal fade" id="modalAjoutPermissions" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="groupsDetails.permissions.addMenu"/></h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <div className="form-group">

                  <Select className="mt-2 mb-3"
                    maxMenuHeight={235}
                    value={this.props.selectedPermission}
                    onChange={e => this.props.handlePermissionSelect(e)}
                    options={this.getSelectFormat(this.props.dataAvailableGroupPermissions)}
                  />

                  {/*<select className="form-control" value={this.props.selectedReferenceApplication}  onChange={e => this.props.selectedRefApp(e)}>
                    {this.props.dataAvailableReferenceApplications.map((listApp, index) => {
                        return (<option key={index} value={listApp.id}>{listApp.name + " / " + listApp.version}</option>)
                      })
                    }
                  </select>*/}
                </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                  </button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addGroupPermissions}><FormattedMessage id="ajouter"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalConfirmSuprEmail" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered " role="document">
              <div className={`modal-content modalConfirm border-secondary`}>
                <div className="modal-header">
                  <h5 className="modal-title" id="modalAjoutDNSLabel">
                    {this.props.intl.formatMessage({id: "supprimer"})}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                    {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn btn-danger`} data-dismiss="modal" onClick={this.props.removeGroupPermissions.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

      </div>

    </div>
    )
  }
}
GroupsPermissions.propTypes = {
  intl: intlShape.isRequired
};
GroupsPermissions = injectIntl(GroupsPermissions,
                        {withRef: true});

export default GroupsPermissions
