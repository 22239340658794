import React from 'react'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';
import find from 'lodash/find';

import classNames from "classnames";
import { MenuProvider } from 'react-contexify';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {IoMdInformationCircle} from "react-icons/io";


const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);

class MMAppErrorDashboardTab extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

      const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [
      {
        Header: () => (<span>
          <b><FormattedMessage id="general.modal.15.2"/></b>
        </span>),
        accessor: 'names',

        Cell: row => (
            <div>
              {row.original.applicationSystem ?
                <span>{row.value}
                <span className="ml-2" title={this.props.intl.formatMessage({id: "groupsDetails.applications.systemApplication"})}>
                  <IoMdInformationCircle size={26} style={{color: "rgb(28,150,210)"}}/>
                </span>
                </span>
                :
                row.value
              }
            </div>
          )

      },{
        Header: () => (<span>
          <b><FormattedMessage id="general.modal.15.3"/></b>
        </span>),
        accessor: 'packageName',
        minWidth: 150,
      },{
        Header: () => (<span>
          <b><FormattedMessage id="general.modal.15.4"/></b>
        </span>),
        accessor: 'latestKnownVersion',
        maxWidth: 100,
      }, {
        Header: () => (<span>
          <b>
            <FormattedMessage id="general.modal.15.5"/>
          </b>
        </span>),
        accessor: 'latestKnownVersionCode',

      }
    ]

    return (<div key={this.props.type}>

        <ReactTable getTheadThProps={getTheadThProps}

        getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            onContextMenu: (e) => {
              this.props.selectedHandle(rowInfo.index);
            },
            style: {
                background: rowInfo.original.enable === false ? "rgb(241,241,241)"
                  : rowInfo.index === this.props.selected ? '#e4eef4' : null,
                color: rowInfo.index === this.props.selected ? 'black' : null
            }
          }
        }else{
          return {}
        }
      }}

          getTdProps={(state, rowInfo, column, instance) => {
      return {

        onContextMenu: (e) => {
            if(rowInfo){
            this.props.contextMenu(rowInfo.original)}
            else {this.props.contextMenu("empty")}
        }
      }
    }}
          showPagination={false}
          data={this.props.data}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={true}
          pageSizeOptions={[10, 20, 25, 50, 100]}
          showPageSizeOptions={false}
          pageSize={this.props.data.length} style={{
            maxHeight: "61vh"
          }} className=" text-center border-0 small -clickable"/>
    </div>)
  }
}

MMAppErrorDashboardTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppErrorDashboardTab = injectIntl(MMAppErrorDashboardTab, {withRef: true});

export default MMAppErrorDashboardTab
