import React from 'react'
import $ from "jquery";
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline, IoMdCloudDownload, IoIosApps, IoMdInformationCircle} from "react-icons/io";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>

           {props.data.label}  
    {
      props.data.link == 1 ?
      <span
        style={{color: "rgb(28,150,210)"}}>
        <IoMdCloudDownload size={24}/>
      </span>
      : null
    }

    {
      props.data.applicationSystem === true ?
      <span style={{color: "rgb(28,150,210)"}}>
        <IoMdInformationCircle size={24}/>
      </span>
      : null
    }

    </Option>
);

class GroupsApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formatPJ: [
        "jpg", "xls", "jpeg"
      ],
      ExtPJ: "",
      showUnwantedApp: true
    };
    this.addFormatPJ = this.addFormatPJ.bind(this);
    this.handleExtPJ = this.handleExtPJ.bind(this);
    this.unwantedAppHandler = this.unwantedAppHandler.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //

  handleSwitchClick(){
    $('#modalConfirmUseTestStore').modal('show')
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.addFormatPJ()
    }
  };

  handleExtPJ(event) {
    this.setState({ExtPJ: event.target.value});
  }

  unwantedAppHandler() {
    this.setState({showUnwantedApp: !this.state.showUnwantedApp});
  }

  addFormatPJ() {
    const array = this.state.formatPJ;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === (this.state.ExtPJ)) {
        return
      }
    }
    if (this.state.ExtPJ) {
      array.push(this.state.ExtPJ)
    }
    this.setState({formatPJ: array});
    this.setState({ExtPJ: ""});
  }

  removeFormatPJ(index) {
    const array = this.state.formatPJ;
    var newArray = [];

    for (var i = 0, j = 0; i < array.length; i++) {
      if (i !== index) {
        newArray[j++] = array[i]
      }
    }
    this.setState({formatPJ: newArray});
  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }

  getSelectFormat(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;
        if (list[i].link) {
          item.link = 1;
        } else {item.link = 0;}

        item.value = list[i];
        item.applicationSystem = list[i].applicationSystem;

        item.label = this.getApplicationName(list[i]);

        if (item.label !== undefined && !list[i].undesirable) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }


  getSelectFormatTestStore(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;
        if (list[i].application.link) {
          item.link = 1;
        } else {item.link = 0;}

        item.value = list[i].application.packageName;
        item.listVersionTestStore = list[i].listVersionTestStore;
        item.application = list[i].application;

        item.label = this.getApplicationName(list[i].application);

        if (item.label !== undefined && !list[i].application.undesirable) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }


  getApplicationName(appli){
    var name = "";

    if (appli.names) {
      switch (this.props.intl.locale) {
        case "fr":
            if (appli.names.fr !== undefined) {
              name = appli.names.fr;
            } else if (appli.names.en !== undefined){
              name = appli.names.en + " (en)";
            }
          break;
        case "en":
            if (appli.names.en !== undefined) {
              name = appli.names.en;
            } else if (appli.names.fr !== undefined){
              name = appli.names.fr + " (fr)";
            }
          break;
        default:
            name = appli.packageName;
          break;
      }
    }

    return name;
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.RefApp.1"/></b>
      </h5>

      <hr/>


      <div className="col-9 container">

        {this.props.useTestStore ?

          <div>
        <div className="form-inline mb-1">
        <h6 className="text-info mb-2">
          <b><FormattedMessage id="groupsDetails.application.1"/></b>
        </h6>

        <div className="custom-control custom-switch ml-4">
          <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.showUnwantedApp} onChange={this.unwantedAppHandler.bind(this)}/>
          <label className="custom-control-label" htmlFor="customSwitch1">
            {this.state.showUnwantedApp ?
              <FormattedMessage id="groupsDetails.applications.switch.referenceApp"/>
              :
              <FormattedMessage id="groupsDetails.applications.switch.unwantedApp"/>
            }
          </label>
        </div>
        </div>


        {this.state.showUnwantedApp ?
          <div className="card rounded-0 mb-3">

            <div className="card-header py-2" style={{
                background: 'rgb(251,251,251)'
              }}>
              <div className="row">
                <div className="col">
                  <FormattedMessage id="parametres.RefApp.2"/>
                </div>

                <div className="text-primary col-2 float-right">
                  <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAddRefAppGrpTestStore">
                    <span style={{
                        color: "rgb(255,255,255)"
                      }}>
                      <FaPlus size={14}/>
                    </span>
                  </button>

                </div>

              </div>
            </div>


            <PerfectScrollbar>
              <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>

                {
                  this.props.dataGroupTestStoreReferenceApplications ?
                  this.props.dataGroupTestStoreReferenceApplications.sort((a, b) => this.getApplicationName(a.application).localeCompare(this.getApplicationName(b.application))).map((appli, index) => (
                    <li key={index} className="list-group-item text-center rounded-0 py-2">
                    <div className="row">
                    <span className="ml-2">
                      <IoIosApps size={42} style={{color: "rgb(28,158,231)"}}/>
                    </span>
                      <div className="col" style={{textAlign:"left"}}>
                        <a style={{fontSize: '16px'}}>{this.getApplicationName(appli.application)}<br/></a>
                        <a style={{fontSize: '12px'}}>{appli.application.packageName}</a>
                      </div>
                      <div className="col-auto" style={{textAlign:"right"}}>
                        <select id="inputState"
                          className="form-control form-control-sm" value={appli.application.storeVersionCode} onChange={e => this.props.handleGroupTestStoreApplicationVersionSelect(e, appli.application.packageName, index, false)}>
                          {
                            appli.listVersionTestStore.map((hit, indexVersion) => (
                              <option className="list-group-item small" value={hit.versionCode}>{hit.version}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-auto" style={{textAlign:"right"}}>
                        <button style={{
                            outline: 'none'
                          }} className="close" data-toggle="modal" data-target="#modalConfirmSuprTestStoreRefApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli.application), appli)}>
                          <span style={{
                              color: "rgb(110,110,110)"
                            }}>
                            <IoIosCloseCircleOutline size={28}/>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>))
                  : null

                }

            </ul>
            </PerfectScrollbar>

          </div>
          :
          <div className="card rounded-0 mb-3">

            <div className="card-header py-2" style={{
                background: 'rgb(251,251,251)'
              }}>
              <div className="row">
                <div className="col">
                  <FormattedMessage id="groupsDetails.applications.unwantedApp"/>
                </div>

                <div className="text-danger col-2 float-right">
                  <button className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#modalAddUnwantedRefAppGrpTestStore">
                    <span style={{
                        color: "rgb(255,255,255)"
                      }}>
                      <FaPlus size={14}/>
                    </span>
                  </button>

                </div>

              </div>
            </div>


            <PerfectScrollbar>
              <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>

                {
                  this.props.dataGroupTestStoreUnwantedApplications ?
                  this.props.dataGroupTestStoreUnwantedApplications.sort((a, b) => this.getApplicationName(a.application).localeCompare(this.getApplicationName(b.application))).map((appli, index) => (
                    <li key={index} className="list-group-item text-center rounded-0 py-2">
                    <div className="row">
                    <span className="ml-2">
                      <IoIosApps size={42} style={{color: "rgb(231,28,28)"}}/>
                    </span>
                      <div className="col" style={{textAlign:"left"}}>
                        <a style={{fontSize: '16px'}}>{this.getApplicationName(appli.application)}<br/></a>
                        <a style={{fontSize: '12px'}}>{appli.application.packageName}</a>
                      </div>
                      { appli.application.applicationSystem ?
                        <span className="ml-2" title={this.props.intl.formatMessage({id: "groupsDetails.applications.systemApplication"})}>
                          <IoMdInformationCircle size={28} style={{color: "rgb(28,150,210)"}}/>
                        </span>
                        :
                        null
                      }

                      <div className="col-auto" style={{textAlign:"right"}}>
                        <select id="inputState"
                          className="form-control form-control-sm" value={appli.application.storeVersionCode} onChange={e => this.props.handleGroupTestStoreApplicationVersionSelect(e, appli.application.packageName, index, true)}>
                          {
                            appli.listVersionTestStore.map((hit, indexVersion) => (
                              <option className="list-group-item small" value={hit.versionCode}>{hit.version}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="col-auto" style={{textAlign:"right"}}>
                        <button style={{
                            outline: 'none'
                          }} className="close" data-toggle="modal" data-target="#modalConfirmSuprTestStoreUnwantedApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli.application), appli)}>
                          <span style={{
                              color: "rgb(110,110,110)"
                            }}>
                            <IoIosCloseCircleOutline size={28}/>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>))
                  : "1"

                }

            </ul>
            </PerfectScrollbar>

          </div>
        }

      </div>

          :

          <div>
        <div className="form-inline mb-1">
        <h6 className="text-info mb-2">
          <b><FormattedMessage id="groupsDetails.application.1"/></b>
        </h6>

        <div className="custom-control custom-switch ml-4">
          <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.showUnwantedApp} onChange={this.unwantedAppHandler.bind(this)}/>
          <label className="custom-control-label" htmlFor="customSwitch1">
            {this.state.showUnwantedApp ?
              <FormattedMessage id="groupsDetails.applications.switch.referenceApp"/>
              :
              <FormattedMessage id="groupsDetails.applications.switch.unwantedApp"/>
            }
          </label>
        </div>
        </div>


        {this.state.showUnwantedApp ?
          <div className="card rounded-0 mb-3">

            <div className="card-header py-2" style={{
                background: 'rgb(251,251,251)'
              }}>
              <div className="row">
                <div className="col">
                  <FormattedMessage id="parametres.RefApp.2"/>
                </div>

                <div className="text-primary col-2 float-right">
                  <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAddRefAppGrp">
                    <span style={{
                        color: "rgb(255,255,255)"
                      }}>
                      <FaPlus size={14}/>
                    </span>
                  </button>

                </div>

              </div>
            </div>


            <PerfectScrollbar>
              <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>

                {
                  this.props.dataGroupReferenceApplications ?
                  this.props.dataGroupReferenceApplications.sort((a, b) => this.getApplicationName(a).localeCompare(this.getApplicationName(b))).map((appli, index) => (
                    <li key={index} className="list-group-item text-center rounded-0 py-2">
                    <div className="row">
                    <span className="ml-2">
                      <IoIosApps size={42} style={{color: "rgb(28,158,231)"}}/>
                    </span>
                      <div className="col-auto" style={{textAlign:"left"}}>
                        <a style={{fontSize: '16px'}}>{this.getApplicationName(appli)}<br/></a>
                        <a style={{fontSize: '12px'}}>{appli.packageName}</a>
                      </div>
                      <div className="col" style={{textAlign:"right"}}>
                        <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersion!=null?appli.storeVersion:appli.latestKnownVersion}</a><br/>
                        <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersionCode!=null?appli.storeVersionCode:appli.latestKnownVersionCode}</a>
                      </div>
                      <div className="col-auto" style={{textAlign:"right"}}>
                        <button style={{
                            outline: 'none'
                          }} className="close" data-toggle="modal" data-target="#modalConfirmSuprRefApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli), appli)}>
                          <span style={{
                              color: "rgb(110,110,110)"
                            }}>
                            <IoIosCloseCircleOutline size={28}/>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>))
                  : null

                }

            </ul>
            </PerfectScrollbar>

          </div>
          :
          <div className="card rounded-0 mb-3">

            <div className="card-header py-2" style={{
                background: 'rgb(251,251,251)'
              }}>
              <div className="row">
                <div className="col">
                  <FormattedMessage id="groupsDetails.applications.unwantedApp"/>
                </div>

                <div className="text-danger col-2 float-right">
                  <button className="btn btn-sm btn-danger float-right" data-toggle="modal" data-target="#modalAddUnwantedAppGrp">
                    <span style={{
                        color: "rgb(255,255,255)"
                      }}>
                      <FaPlus size={14}/>
                    </span>
                  </button>

                </div>

              </div>
            </div>


            <PerfectScrollbar>
              <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>

                {
                  this.props.dataGroupUnwantedApplications ?
                  this.props.dataGroupUnwantedApplications.sort((a, b) => this.getApplicationName(a).localeCompare(this.getApplicationName(b))).map((appli, index) => (
                    <li key={index} className="list-group-item text-center rounded-0 py-2">
                    <div className="row">
                    <span className="ml-2">
                      <IoIosApps size={42} style={{color: "rgb(231,28,28)"}}/>
                    </span>
                      <div className="col-auto" style={{textAlign:"left"}}>
                        <a style={{fontSize: '16px'}}>{this.getApplicationName(appli)}<br/></a>
                        <a style={{fontSize: '12px'}}>{appli.packageName}</a>
                      </div>
                      { appli.applicationSystem ?
                        <span className="ml-2" title={this.props.intl.formatMessage({id: "groupsDetails.applications.systemApplication"})}>
                          <IoMdInformationCircle size={28} style={{color: "rgb(28,150,210)"}}/>
                        </span>
                        :
                        null
                      }

                      <div className="col" style={{textAlign:"right"}}>
                        <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersion!=null?appli.storeVersion:appli.latestKnownVersion}</a><br/>
                        <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersionCode!=null?appli.storeVersionCode:appli.latestKnownVersionCode}</a>
                      </div>
                      <div className="col-auto" style={{textAlign:"right"}}>
                        <button style={{
                            outline: 'none'
                          }} className="close" data-toggle="modal" data-target="#modalConfirmSuprUnwantedApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli), appli)}>
                          <span style={{
                              color: "rgb(110,110,110)"
                            }}>
                            <IoIosCloseCircleOutline size={28}/>
                          </span>
                        </button>
                      </div>
                    </div>
                  </li>))
                  : null

                }

            </ul>
            </PerfectScrollbar>

          </div>
        }

      </div>
  }



        <div className="custom-control custom-switch mb-4">
          <input type="checkbox" className="custom-control-input" id="customSwitchTestStore" checked={this.props.useTestStore} onChange={this.handleSwitchClick.bind(this)}/>
          <label className="custom-control-label" htmlFor="customSwitchTestStore">
            {this.props.useTestStore ?
              <FormattedMessage id="groupsDetails.applications.switch.testStore"/>
              :
              <FormattedMessage id="groupsDetails.applications.switch.testStore"/>
            }
          </label>
        </div>


      </div>


      <div className="modal fade" id="modalAddRefAppGrp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddRefAppGrp">
                <FormattedMessage id="groupsDetails.applications.referenceApp.add"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <div className="form-group">

              <Select className="mt-2 mb-3"
                maxMenuHeight={235}
                value={this.props.selectedOption}
                onChange={this.props.handleChangeSelectApp}
                options={this.getSelectFormat(this.props.dataAvailableReferenceApplications)}
                components={{ Option: IconOption }}
              />

              {/*<select className="form-control" value={this.props.selectedReferenceApplication}  onChange={e => this.props.selectedRefApp(e)}>
                {this.props.dataAvailableReferenceApplications.map((listApp, index) => {
                    return (<option key={index} value={listApp.id}>{listApp.name + " / " + listApp.version}</option>)
                  })
                }
              </select>*/}
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addReferenceApplication}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade" id="modalAddUnwantedAppGrp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddUnwantedAppGrp">
                <FormattedMessage id="groupsDetails.applications.unwantedApp.add"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <div className="form-group">

              <Select className="mt-2 mb-3"
                maxMenuHeight={235}
                value={this.props.selectedOption}
                onChange={this.props.handleChangeSelectApp}
                options={this.getSelectFormat(this.props.dataAvailableReferenceApplications)}
                components={{ Option: IconOption }}
              />

              {/*<select className="form-control" value={this.props.selectedReferenceApplication}  onChange={e => this.props.selectedRefApp(e)}>
                {this.props.dataAvailableReferenceApplications.map((listApp, index) => {
                    return (<option key={index} value={listApp.id}>{listApp.name + " / " + listApp.version}</option>)
                  })
                }
              </select>*/}
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addUnwantedApplication}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprRefApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeReferenceApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprUnwantedApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeUnwantedApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmUseTestStore" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title">
                <FormattedMessage id="groupsDetails.applications.switch.testStore.modal.title"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title">
                {this.props.useTestStore ?
                  <FormattedMessage id="groupsDetails.applications.switch.testStore.modal.msg.disable"/>
                  :
                  <FormattedMessage id="groupsDetails.applications.switch.testStore.modal.msg.enable"/>
                }

              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-warning`} data-dismiss="modal"  onClick={this.props.handleUseTestStoreChange}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalAddRefAppGrpTestStore" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddRefAppGrp">
                <FormattedMessage id="groupsDetails.applications.referenceApp.add"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <div className="form-group">

              <Select className="mt-2 mb-3"
                maxMenuHeight={235}
                value={this.props.selectedOption}
                onChange={this.props.handleChangeSelectApp}
                options={this.getSelectFormatTestStore(this.props.dataGroupTestStoreAvailableReferenceApplications)}
                components={{ Option: IconOption }}
              />

              {/*<select className="form-control" value={this.props.selectedReferenceApplication}  onChange={e => this.props.selectedRefApp(e)}>
                {this.props.dataAvailableReferenceApplications.map((listApp, index) => {
                    return (<option key={index} value={listApp.id}>{listApp.name + " / " + listApp.version}</option>)
                  })
                }
              </select>*/}
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addTestStoreReferenceApplication}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalAddUnwantedRefAppGrpTestStore" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAddRefAppGrp">
                <FormattedMessage id="groupsDetails.applications.referenceApp.add"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <div className="form-group">

              <Select className="mt-2 mb-3"
                maxMenuHeight={235}
                value={this.props.selectedOption}
                onChange={this.props.handleChangeSelectApp}
                options={this.getSelectFormatTestStore(this.props.dataGroupTestStoreAvailableReferenceApplications)}
                components={{ Option: IconOption }}
              />

              {/*<select className="form-control" value={this.props.selectedReferenceApplication}  onChange={e => this.props.selectedRefApp(e)}>
                {this.props.dataAvailableReferenceApplications.map((listApp, index) => {
                    return (<option key={index} value={listApp.id}>{listApp.name + " / " + listApp.version}</option>)
                  })
                }
              </select>*/}
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addUnwantedTestStoreReferenceApplication}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprTestStoreRefApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeTestStoreReferenceApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalConfirmSuprTestStoreUnwantedApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeUnwantedTestStoreReferenceApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }

}

GroupsApplications.propTypes = {
  intl: intlShape.isRequired
};
GroupsApplications = injectIntl(GroupsApplications, {withRef: true});

export default GroupsApplications
