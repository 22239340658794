import React from 'react'
import $ from 'jquery'
import ReactTable from 'react-table'
import find from 'lodash/find';
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip'
import {MdLens} from "react-icons/md";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import { GoDeviceMobile } from "react-icons/go";
import '../custom.css';

import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

function changeToDate(int, langue){
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
}

function researchApp(array, valeur, optionsPermission) {

  var testArray = [];

  if (optionsPermission) {
      array = appListFilterPermissions(array, optionsPermission)
  }


  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }
  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

function appListFilterPermissions(applicationsList, permissions){

  var returnAppList = []
  var permissionsList = []

  for (var a = 0; a < permissions.length; a++) {
    permissionsList.push(permissions[a].value)
  }

    for (var i = 0; i < applicationsList.length; i++) {
      var appPermissionsList = applicationsList[i].permissions;
      var appCanBeAdded = true;

      if (appPermissionsList) {
        for (var y = 0; y < permissionsList.length; y++) {
          if (!appPermissionsList.includes(permissionsList[y])) {
            appCanBeAdded = false;
          }
        }

        if (appCanBeAdded) {
          returnAppList.push(applicationsList[i]);
        }
      }
    }

  return(returnAppList)
}

class DevicesDetailsApplist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabExpanded: [],
      filtered: [],
      sorted: [],
      expanded: {},
      researchApp: "",
    };

    this.researchAppHandle = this.researchAppHandle.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount= () =>{
  $('#ModalDevicesDetails').on('hidden.bs.modal', function (){
      this.setState({tabExpanded: []})
    }.bind(this))
  }

  getOptionsPermissions(){
    var optionsPermissions = [
      { value: 'CAMERA', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.1"}) },
      { value: 'CONTACTS', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.2"}) },
      { value: 'LOCATION', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.3"}) },
      { value: 'RECORD_AUDIO', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.4"}) },
      { value: 'STORAGE', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.5"}) },
      { value: 'PHONE', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.6"}) },
      { value: 'CALENDAR', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.7"}) },
      { value: 'SMS', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.8"}) },
      { value: 'CALL_LOG', label: this.props.intl.formatMessage({id: "terminauxDetails.permissions.9"}) },
    ];
    return optionsPermissions
  }

  showPermissionsIndicator(permissions){
    if (permissions[0]) {
      var text = this.props.intl.formatMessage({id: "terminauxDetails.0"}) + " :\n"
      for (var i = 0; i < permissions.length; i++) {
        text = text + "- " + this.permissionsTrad(permissions[i]) + "\n"
      }
      return (
        <span style={{
            color: "rgb(49, 147, 238)"
          }}>
          <BsFillQuestionCircleFill size={24} title={text}/>
        </span>
      )
    } else {
      return null
    }

  }

  permissionsTrad(permissionName){
    switch (permissionName) {
      case "CAMERA":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.1"});
        break;
      case "CONTACTS":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.2"});
        break;
      case "LOCATION":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.3"});
        break;
      case "RECORD_AUDIO":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.4"});
        break;
      case "STORAGE":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.5"});
        break;
      case "PHONE":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.6"});
        break;
      case "CALENDAR":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.7"});
        break;
      case "SMS":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.8"});
        break;
      case "CALL_LOG":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.9"});
        break;

      default:
        return permissionName;
    }
  }

  getPermissions(tab, index){
    if (tab[index] && tab[index].permissions[0] != null) {
      var newTab = [];
      for (var i = 0; i < tab[index].permissions.length; i++) {
        var item = new Object();
        //item.name = tab[index].permissions[i];

          switch (tab[index].permissions[i]) {
            case "CAMERA":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.1"});
              break;
            case "CONTACTS":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.2"});
              break;
            case "LOCATION":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.3"});
              break;
            case "RECORD_AUDIO":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.4"});
              break;
            case "STORAGE":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.5"});
              break;
            case "PHONE":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.6"});
              break;
            case "CALENDAR":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.7"});
              break;
            case "SMS":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.8"});
              break;
            case "CALL_LOG":
                item.name = this.props.intl.formatMessage({id: "terminauxDetails.permissions.9"});
              break;

            default:
              item.name = tab[index].permissions[i];
          }

        newTab.push(item);
      }
      return (newTab)
    }else {
      return ([])
    }
  }


// ------------------------------- Action handle ------------------------------- //

    _handleSortedChange = (sorted) => {
      this.setState({ sorted , tabExpanded: []})
    }

    researchAppHandle(event) {
      this.setState({researchApp: event.target.value});
    }

    handleChangeSelect = selectedOption => {
      this.setState({selectedOptionsPermissions : selectedOption})
    }

// ------------------------------- Render -------------------------------------- //

  render() {

// ------------------------------- Setting of the table ------------------------ //

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [{
      /*
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.0"})}</b>
        </span>),
        */
        minWidth: 40,

        Cell: row => (
            <div>
              {this.showPermissionsIndicator(row.original.permissions)}
            </div>
          )
      },
      {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.1"})}</b>
        </span>),
        accessor: 'name',
        minWidth: 200,
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.2"})}
          </b>
        </span>),
        accessor: 'version',
        minWidth: 160,
      },{
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.3"})}
          </b>
        </span>),
        accessor: 'versionCode',
        minWidth: 160,
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.4"})}
          </b>
        </span>),
        accessor: 'installDate',
        minWidth: 200,

        Cell: row => (
            <div>
              <span>
                {changeToDate(row.value, this.props.intl.locale)}
              </span>
            </div>
          )
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.5"})}
          </b>
        </span>),
        accessor: 'updateDate',
        minWidth: 180,

        Cell: row => (
            <div>
              <span>
                {changeToDate(row.value, this.props.intl.locale)}
              </span>
            </div>
          )
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.6"})}
          </b>
        </span>),
        accessor: 'compliantStatus',
        minWidth: 80,

        // compliant = 2 // uncompliant = 1 // default = 3
        Cell: row => (<div>
          <span style={{
              color: row.value === "4" ? "rgb(60, 60, 60)"
                : row.value === "3" ? "rgb(233,44,33)"
                : row.value === "2" ? "rgb(36,232,45)"
                : row.value === "1" ? "rgb(255,104,8)"
                : "rgb(198,198,198)"
            }}>
            <MdLens size={28} title={
              row.value === "4" ?
                this.props.intl.formatMessage({id: "terminauxDetails.6.suspended"})
                  : row.value === "3" ?
                      this.props.intl.formatMessage({id: "terminauxDetails.6.unwanted"})
                      : row.value === "2" ?
                          this.props.intl.formatMessage({id: "terminauxDetails.6.compliant"})
                          : row.value === "1" ?
                              (row.original.complianceInfo.storeVersion && row.original.complianceInfo.storeVersion!==null?
							this.props.intl.formatMessage({id: "terminauxDetails.6.store_uncomppliant"})
                                + "\n" + this.props.intl.formatMessage({id: "terminauxDetails.2"}) + " : " + row.original.complianceInfo.storeVersion
                                + "\n" + this.props.intl.formatMessage({id: "terminauxDetails.3"}) + " : " + row.original.complianceInfo.storeVersionCode
                            :
                            this.props.intl.formatMessage({id: "terminauxDetails.6.uncompliant"})
                                + "\n" + this.props.intl.formatMessage({id: "terminauxDetails.2"}) + " : " + row.original.complianceInfo.latestKnownVersion
                                + "\n" + this.props.intl.formatMessage({id: "terminauxDetails.3"}) + " : " + row.original.complianceInfo.latestKnownVersionCode
							)
                            : null
            }/>
          </span>
        </div>)

      }
    ]


    const subColumns = [
      {
        Header: () => (<span>
          <b>
            Permissions</b>
        </span>),
        accessor: 'name',
        minWidth: 200,
      }
    ]

    return (<div>
      <div className="card bg-light">
        <div className="card-header">

          <div className="form-inline">

            <input className="form-control mr-sm-2 row-auto" style={{
                height: "36px"
              }} type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" value={this.state.researchApp} onChange={this.researchAppHandle}/>


              <Select className="col-6"
                maxMenuHeight={190}
                isMulti={true}
                value={this.state.selectedOptionsPermissions}
                onChange={this.handleChangeSelect}
                options={this.getOptionsPermissions()}
                closeMenuOnSelect={false}
                removeSelected={false}
              />

              { this.props.subCompliantInfo ?

                <div>
                <span className="col-auto" style={{
                    color: "rgb(81,157,185)"
                  }}>
                  <b className="text-info">
                     {this.props.intl.formatMessage({id: "terminauxDetails.subCompliant"})} : </b>
                  <GoDeviceMobile size={28} data-tip data-for='sadFace'/>
                </span>


                    <ReactTooltip className="relayClass" id='sadFace' type={'light'} border={true} effect={'solid'} place={'right'}>

                      <div style={{"listStyleType": "circle"}}>
                        <li>
                          <span>
                            <b>Android ID</b> : {this.props.subCompliantInfo.android_id}
                          </span>
                        </li>
                        <li>
                          <span>
                            <b>{this.props.intl.formatMessage({id: "terminauxDetails.header.1"})}</b> : {this.props.subCompliantInfo.email}
                          </span>
                        </li>
                        <li>
                          <span>
                            <b>{this.props.intl.formatMessage({id: "terminaux.4"})}</b> : {this.props.subCompliantInfo.model}
                          </span>
                        </li>
                        <li>
                          <span>
                            <b>{this.props.intl.formatMessage({id: "terminauxDetails.header.4"})}</b> : {this.props.subCompliantInfo.kernelName}
                          </span>
                        </li>
                        <li>
                          <span>
                            <b>{this.props.intl.formatMessage({id: "terminauxDetails.header.5"})}</b> : {this.props.subCompliantInfo.kernelVersion}
                          </span>
                        </li>
                        <li>
                          <span>
                            <b>{this.props.intl.formatMessage({id: "terminauxDetails.header.6"})}</b> : {this.props.subCompliantInfo.os_Version}
                          </span>
                        </li>
                      </div>
                    </ReactTooltip>

              </div>
              : null
              }

              {this.props.applistFetching ?
                <div className="col d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                : null
              }

          </div>
        </div>

        <div>

        <MenuProvider id="menu_devicesDetailsApplistContextMenu">
          <ReactTable

            getTheadThProps={getTheadThProps}

            getTdProps={(state, rowInfo, column, instance) => {
        return {

          onContextMenu: (e) => {
              if(rowInfo){
              this.props.contextMenu(rowInfo.original)}
              else {this.props.contextMenu("empty")}
          }
        }
      }}

            getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {

                style: {
                  borderWidth: this.state.tabExpanded[rowInfo.viewIndex] ? "1px" : null,
                  borderStyle: this.state.tabExpanded[rowInfo.viewIndex] ? "solid" : null,
                  borderColor: this.state.tabExpanded[rowInfo.viewIndex] ? "rgb(126,167,237)" : null,
                  borderBottom: this.state.tabExpanded[rowInfo.viewIndex] ?
                  this.getPermissions(this.props.devicesDetailsApplist, rowInfo.index).length > 0 ?
                  "none"
                  : null
                  : null,

                  border: this.state.tabExpanded[rowInfo.viewIndex] ?
                  this.getPermissions(this.props.devicesDetailsApplist, rowInfo.index).length < 0 ?
                  "none"
                  : null
                  : null
                }
              }
            }else{
              return {}
            }
          }}

            showPagination={false}
            filterable={false}
            data={researchApp(this.props.devicesDetailsApplist, this.state.researchApp, this.state.selectedOptionsPermissions).sort((a, b) => a.name.localeCompare(b.name))}
            noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
            TbodyComponent={CustomTbodyComponent}
            columns={columns}
            freezeWhenExpanded={true}
            expanded={this.state.tabExpanded}
            resizable={true}

            sorted={this.state.sorted}
            filtered={this.state.filtered}
            onSortedChange={this._handleSortedChange}
            SubComponent={this._renderSubComponent}
            ExpanderComponent={this._renderExpanderComponent}

            pageSize={this.props.devicesDetailsApplist.length}
            style={{
              height: "41vh",
              width: 'auto'
            }} className=" text-center border-0 -clickable"

            onExpandedChange={(newExpanded, index, event) => {
              var x = this.state.tabExpanded;
              x[index] = !x[index];
              this.setState({tabExpanded: x})
            }}

          />
      </MenuProvider>

          <div className="col text-center border-top">
            <b className="text-dark"><FormattedMessage id="terminauxDetails.7"/> {this.props.devicesDetailsApplist.length}</b>
          </div>
        </div>
      </div>

    </div>)
  }
}

DevicesDetailsApplist.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsApplist = injectIntl(DevicesDetailsApplist, {withRef: true});

export default DevicesDetailsApplist
