import React from 'react'
import {FormattedMessage} from "react-intl";

class UserVPN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div className="col mt-2 ml-2 mb-2">

      <h6 className="text-info mt-2 mb-2">
        <b>Configuration VPN</b>
      </h6>

      <div className="custom-control custom-checkbox mb-2 mt-2">
        <input className="custom-control-input" type="checkbox" value="" id="noRestrictionUser" checked={this.props.userInfo.allAccess} onChange={e => this.props.handleChange(e, "allAccess")}/>
        <label className="custom-control-label" htmlFor="noRestrictionUser">
          <FormattedMessage id="userDetails.info.userrestriction"/>
        </label>
      </div>

      <div className="custom-control custom-checkbox mb-2">
        <input className="custom-control-input" type="checkbox" value="" id="enableEXTReseau" checked={this.props.userInfo.externalConnection} onChange={e => this.props.handleChange(e, "externalConnection")}/>
        <label className="custom-control-label" htmlFor="enableEXTReseau">
          <FormattedMessage id="userDetails.info.autoriserAcces"/>
        </label>
      </div>

      <p>
        <label htmlFor="inputState"><FormattedMessage id="userDetails.info.adresseIP"/> :
        </label>

        <input className="form-control" placeholder="" value={this.props.userInfo.ip} onChange={e => this.props.handleChange(e, "ip")}/>
      </p>

      </div>
    )
}
}

export default UserVPN
