import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import { MenuProvider } from 'react-contexify';

import '../custom.css';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { MdWarning } from "react-icons/md";

const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

class GroupsTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        pageSizeForGroupsTab: localStorage.getItem('pageSizeForGroupsTab'),
      };

    }

    componentWillUnmount(){
      if (this.state.pageSizeForGroupsTab) {
        localStorage.setItem('pageSizeForGroupsTab', this.state.pageSizeForGroupsTab);
      }
    }


  render()
  {

    const getTheadThProps = (table, row, col) => {
  const sortedCol = find(table.sorted, { id: col.id });
  const boxShadow =
    sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
  return {
    style: {
      boxShadow,
    },
    onContextMenu: (e) => {
      this.props.contextMenu("empty");
    }
  };
};

    const columns = [{

      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "groups.NOM"})} </b>
      </span>
    ),
      accessor: 'name',
      minWidth: 200,

      Cell: row => (
          <div>
          {row.original.mmapp_test ? <span title={this.props.intl.formatMessage({id: "groups.testStore.msg"})} style={{
            color: "rgb(38, 147, 228)"
          }}>
          <MdWarning className="mr-1" size={22}/>
          {row.value}
        </span> : row.value}
          </div>
        )

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "groups.ADMINISTRATION"})} </b>
      </span>
    ),
    accessor: 'administration',
    minWidth: 140,

    Cell: row => (
        <div>
        {row.value === 1 ? this.props.intl.formatMessage({id: "group.administration.1"})
        : row.value === 2 ? this.props.intl.formatMessage({id: "group.administration.2"})
        : row.value === 3 ? this.props.intl.formatMessage({id: "group.administration.3"})
        : this.props.intl.formatMessage({id: "group.administration.0"})
        }
        </div>
      )

    },{
      Header: () => (
      <span>
        <b> {this.props.intl.formatMessage({id: "groups.NBUSERS"})} </b>
      </span>
    ),
      accessor: 'nbusers',
      minWidth: 140,


    },{Header: () => (
    <span>
      <b> {this.props.intl.formatMessage({id: "groups.POLITIQUES"})} </b>
    </span>
  ),
      accessor: 'politiques',
      minWidth: 140,

    }]

    return (
      <div>
      <MenuProvider id="menu_groupsContextMenu" >
      <ReactTable

      oncontextmenu="return false;"
      getTheadThProps={getTheadThProps}

          getTdProps={(state, rowInfo, column, instance) => {
      return {

        onContextMenu: (e) => {
            if(rowInfo){
            this.props.contextMenu(rowInfo.original)}
            else {this.props.contextMenu("empty")}
        }
      }
    }}

    getTrProps={(state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        onContextMenu: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        style: {
          background: rowInfo.index === this.props.selected ? '#e4eef4' : null,
          color: rowInfo.index === this.props.selected ? 'black' : null
        }
      }
    }else{
      return {}
    }
    }}

        showPagination={this.props.showPagination}
        data={this.props.array}
        noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
        rowsText={this.props.intl.formatMessage({id: "rows"})}
        previousText={this.props.intl.formatMessage({id: "previous"})}
        nextText={this.props.intl.formatMessage({id: "next"})}
        loadingText={this.props.intl.formatMessage({id: "chargement"})}
        pageText= "Page"
        ofText={this.props.intl.formatMessage({id: "sur"})}
        TbodyComponent={CustomTbodyComponent}
        columns={columns}
        resizable={true}
        pageSizeOptions= {[10, 20, 25, 50, 100]}
        defaultPageSize= {50}
        onPageSizeChange={(pageSize) => {
          this.setState({pageSizeForGroupsTab: pageSize})
        }}
        pageSize={!this.props.showPagination ? this.props.array.length : this.state.pageSizeForGroupsTab != null ? this.state.pageSizeForGroupsTab : this.defaultPageSize}
              style={{
                minHeight: "15vh",
                maxHeight: "70vh"
              }}
              className=" text-center border-0 small -clickable"
            />

            </MenuProvider>

            <div className="col text-center border-top small">
              <b className="text-dark"><FormattedMessage id="groups.nbgroups"/> {this.props.array.length}</b>
            </div>

          </div>
    )
  }
}

GroupsTab.propTypes = {
  intl: intlShape.isRequired
};
GroupsTab = injectIntl(GroupsTab, {withRef: true});

export default GroupsTab
