import React from 'react'
import {FormattedMessage} from 'react-intl';

const CT = ({texte, id, checked, onChange, disabl}) => {

  if (!checked) {
    checked = false
  }

  return (<div className="custom-control custom-checkbox mb-2">
    <input className="custom-control-input" type="checkbox" checked={checked} id={id} onChange={onChange} disabled={disabl}/>
    <label className="custom-control-label" htmlFor={id}>
      <FormattedMessage id={texte} defaultMessage={texte}/>
    </label>
  </div>)
};

export default CT
