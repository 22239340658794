import React from 'react'
import {FormattedMessage} from "react-intl";
import { varLogin } from './variables';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      pass: "",
      redirection: false,
      sendingLogin: false
    };
    this.handlePass = this.handlePass.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.submit = this.submit.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidMount() {

    if (this.props.Logged == 1)
      this.props.history.push("/Dashboard");

    document.title = 'MM-Server Login';
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }


// ------------------------------- Action handle ------------------------------- //

  handleKeyDown(e) {
    if (e.ctrlKey && e.key === 'p') {
      if(process.env.NODE_ENV === "development"){
            this.setState({sendingLogin: false});
            this.props.click();
            this.props.history.push("/Dashboard");
      }
  }
    if (e.keyCode == 13) {
      this.submit();
    }
  }

  handlePass(event) {
    this.setState({pass: event.target.value});
  }

  handleLogin(event) {
    this.setState({login: event.target.value});
  }

  componentDidUpdate() {
    if (this.state.redirection === true)
      this.azer()
  }
  loggedRedirect() {
    if (this.state.validUser === true) {
      this.setState({UserLogged: true});
      this.props.history.push("/Dashboard");
    }
  }

  hideAlert() {
    this.setState({showWrong: "hide"});
  }

  submit() {


    const login = this.state.login;
    const password = this.state.pass;

    if (login.length === 0) {
      this.setState({logError: "Login empty", errorBorderLog: "rounded border border-danger"});
    } else {
      this.setState({logError: "", errorBorderLog: ""});
    }

    if (password.length === 0) {
      this.setState({passError: "Password empty", errorBorderPass: "rounded border border-danger"});
    } else {
      this.setState({passError: "", errorBorderPass: ""});
    }

    if(password.length > 0 && login.length > 0){

          this.setState({sendingLogin: true});

          var data = {
            "login": login,
            "password": password
          };

          fetch(varLogin.api, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
              body: JSON.stringify(data)
            })
            .then(response => {
              var x = response;
              if(response.status === 200){
                return response.json().then(responseRetour => {
                  if(responseRetour.status === "OK"){
                    this.setState({sendingLogin: false});
                    this.props.click(responseRetour.administration, response.headers.get('X-XSRF-TOKEN'));
                    if (responseRetour.administration === "USER") {
                      this.props.history.push("/Restricted");
                    } else {
                      this.props.history.push("/Dashboard");
                    }
                  }else {
                    this.setState({showWrong: "show", sendingLogin: false});
                    console.log(responseRetour.status);
                  }
                })
              }
              else{
                console.log(response.headers);
               return response.json().then(responseError => {
                 console.log(responseError);
                 //this.requeteResponse(x,responseError);
               })
             }
             })

    }

  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <div>
        <div className="card col mt-5">
          <h4 className="text-primary d-flex justify-content-center mt-3">
            <b>
              <i><FormattedMessage id="connexion"/></i>
            </b>
          </h4>

          <hr/>

          <div className={`input-group ${this.state.errorBorderLog}`}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon">Login :
              </span>
            </div>

            <input type="text" className="form-control" aria-label="login" aria-describedby="basic-addon" value={this.state.login} onChange={this.handleLogin}/>
          </div>
          <span className="text-danger small ml-2">{this.state.logError}</span>

          <div className={`input-group mt-2 ${this.state.errorBorderPass}`}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon">Password :
              </span>
            </div>

            <input type="password" className="form-control border" aria-label="password" aria-describedby="basic-addon" value={this.state.pass} onChange={this.handlePass}/>

          </div>

          <span className="text-danger small ml-2">{this.state.passError}</span>
          <div className="mb-4 mt-3 d-flex justify-content-center">

            {this.state.sendingLogin ?
            <button className="btn btn-secondary" onClick={this.submit} style={{width: "5vw"}}>
              <div className="spinner-border text-light spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
            :
            <button className="btn btn-secondary" onClick={this.submit}>
              <FormattedMessage id="valider"/>
            </button>
            }


          </div>
        </div>
      </div>

      <div className={` mt-2 alert alert-danger alert-dismissible fade small ${this.state.showWrong}`} role="alert">
        Wrong
        <b>
           Login/Password</b>
        <button type="button" className="close" onClick={this.hideAlert.bind(this)}>
          <span>&times;</span>
        </button>
      </div>
    </div>);
  }
}

export default Login
