import React from 'react'
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import LineGeneral from '../Components/LineGeneral';
import { url, varGroupDetail } from '../variables';

const tentativeArray = [4,5,6,7,8,9,10,11,12,13,14];

class UserPolitiquesSecu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adressesMails: [
        "Root", "Test", "Shared"
      ],
      mail: "",
      selectData: [],
      selectedReferenceApplication:"",
      selectedOptionExtensions: null,
    };
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    this.setState({ isLoading: true });
    if(this.props.userInfo.group){
      this.fetchData(varGroupDetail.apiPolitiquesSecu + this.props.userInfo.group.groupId);
    } else {
      this.fetchData(varGroupDetail.apiPolitiquesDefaut);
    }
  }


// ------------------------------- Action handle ------------------------------- //

  onChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;

    this.setState({
      clients: e
    });
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchData(api){
    fetch(api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.initTableau(data))
      .catch(error => this.setState({ error, isLoading: false }));
  }


  initTableau(data){
    this.setState({
      tableau: data,
      isLoading: false,

      extensions: data.extensions,
      wifi: this.getBool(data.wifi),
      browser: this.getBool(data.browser),
      bluetooth: this.getBool(data.bluetooth),
      camera: this.getBool(data.camera),
      storagecard: this.getBool(data.storagecard),
      unsignedapplications: this.getBool(data.unsignedapplications),
      desktopsync: this.getBool(data.desktopsync),
      textmessaging: this.getBool(data.textmessaging),
      password: this.getBool(data.password),
      requiremanualsyncwhenroaming: this.getBool(data.requiremanualsyncwhenroaming),
      requiredevicencryption: this.getBool(data.requiredevicencryption),
      requirestoragecardencryption: this.getBool(data.requirestoragecardencryption),
      maxattachmentsize: data.maxattachmentsize == "-1" ? false : true,
      maxdevicepasswordfailedattempts: data.maxdevicepasswordfailedattempts,
      emailfilter: data.emailfilter,

      extansionsAutorisation: data.extensions === "*" ? true : false

     })
  }



  getBool(bool){
    if(bool === true){return(bool)}
    return(false)
  }

  getGroupName (grp) {
    if(grp){return(grp.name)}
  }

  getExtensions(extensions){

        var newData = [];

        if (extensions && extensions.length > 2) {
          var myArray = extensions.substring(1, extensions.length - 1).split(',');

            for (var i = 0; i < myArray.length; i++) {
              var item = new Object();
              item.value = myArray[i].substring(1, myArray[i].length - 1);
              item.label = myArray[i].substring(1, myArray[i].length - 1);

              newData.push(item);
            }
        }


        return(newData)
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    if(this.state.tableau){
    return (
<div className="tab-pane" id="PolitiqueDeSecu" role="tabpanel" aria-labelledby="home-tab">

  <h6 className="text-info text-center mt-2 card rounded-0" style={{

      fontSize: 'calc(9px + 1vmin)',
    }}>
    <b className="mt-1 mb-1"><FormattedMessage id="userDetails.MMI.politiquesSecu"/> : {this.props.userInfo.group ? this.getGroupName(this.props.userInfo.group) : this.props.intl.formatMessage({id: "groupsDetails.PolitiqueSecu.btn"})}</b>
  </h6>

  <div className="row ">

    <div className="col-auto mr-1">
      <div className="mt-2 mb-2">

      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.1"})} etat={this.state.wifi}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.2"})} etat={this.state.browser}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.3"})} etat={this.state.bluetooth}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.4"})} etat={this.state.camera}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.5"})} etat={this.state.storagecard}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.6"})} etat={this.state.unsignedapplications}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.7"})} etat={this.state.desktopsync}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.8"})} etat={this.state.textmessaging}/>

      <div className="mt-2"/>
      <span><FormattedMessage id="userDetails.MMI.politiquesSecu.9"/></span>
      <div className="mb-2 mt-2">
        <select id="inputState" value={this.state.extansionsAutorisation} readOnly
          className="form-control mt-1 mb-2" >
          <option className="list-group-item small" value={true}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.allowAll"})}</option>
          <option className="list-group-item small" value={false}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.personalize"})}</option>
        </select>
      </div>

      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.10"})} etat={this.state.maxattachmentsize}/>

      </div>
    </div>
    <div className="col-auto ml-1">
      <div className="mt-2 mb-2">

        <div className="mb-2">
            <label htmlFor="inputState"><FormattedMessage id="userDetails.MMI.politiquesSecu.11"/></label>

            <select id="inputState" className="form-control mr-4" value={this.state.emailfilter} readOnly>
              <option className="list-group-item small" value={0}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.0"})}</option>
              <option className="list-group-item small" value={1}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.1"})}</option>
              <option className="list-group-item small" value={2}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.2"})}</option>
              <option className="list-group-item small" value={3}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.3"})}</option>
              <option className="list-group-item small" value={4}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.4"})}</option>
              <option className="list-group-item small" value={5}>{this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.mailSync.5"})}</option>
            </select>
        </div>


        <label htmlFor="inputState"><FormattedMessage id="userDetails.MMI.politiquesSecu.12"/></label>
        <Select className="mt-1 mb-2"
        value={this.getExtensions(this.state.extensions)}

          onChange={this.onChange}
        isMulti
        isSearchable={false}
        menuIsOpen={false}
        />


      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.13"})} etat={this.state.requiremanualsyncwhenroaming}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.14"})} etat={this.state.requiredevicencryption}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.15"})} etat={this.state.requiredevicencryption}/>
      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.16"})} etat={this.state.requirestoragecardencryption}/>

      <LineGeneral texte={this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.17"})} etat={this.state.password}/>

      <div className="mb-2">
        <label htmlFor="inputState"><FormattedMessage id="userDetails.MMI.politiquesSecu.18"/></label>

        <select id="inputState_Modal"
        className="form-control mr-4 text-center"
        value={this.state.maxdevicepasswordfailedattempts} readOnly>
          <option className="list-group-item small" value={-1}>-</option>{
            (tentativeArray.map((x, index) => {
              return (<option key={index} className="list-group-item small" value={x}>{x}</option>)
            }))
          }
        </select>
      </div>

      </div>
    </div>

  </div>

</div>
)}

return (
<div>
<div className="spinner-border text-dark mt-5" role="status">
  <span className="sr-only">Loading...</span>
</div>
</div>
);

}
}
UserPolitiquesSecu.propTypes = {
  intl: intlShape.isRequired
};
UserPolitiquesSecu = injectIntl(UserPolitiquesSecu,
                        {withRef: true});

export default UserPolitiquesSecu
