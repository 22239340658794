import React, {Component} from 'react';
import ReactTable from 'react-table'
import find from 'lodash/find';
import MMAppFeedbacksTabContextMenu from './MMAppFeedbacksTabContextMenu';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import { MenuProvider } from 'react-contexify';
import Select, { components } from 'react-select';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { IoIosInformationCircle, IoIosWarning, IoIosAlert, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FaUser, FaUsers } from "react-icons/fa";
import { MdGroup, MdMessage } from "react-icons/md";
import { GoDeviceMobile } from "react-icons/go";

import {varMmapp, varGroups} from '../variables';

  const { Option } = components;
  const IconOption = (props) => (
      <Option {...props}>

             {props.data.label}  
      {
        props.data.value == "info" ?
        <span
          style={{color: "rgb(44,165,255)"}}>
          <IoIosInformationCircle size={24}/>
        </span>
        :
        props.data.value == "warning" ?
        <span
          style={{color: "rgb(255,159,47)"}}>
          <IoIosWarning size={24}/>
        </span>
        :
        props.data.value == "error" ?
        <span
          style={{color: "rgb(250,31,31)"}}>
          <IoIosAlert size={24}/>
        </span>
        :
        null
      }

      </Option>
  );

  const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>);

  function changeToDate(int, langue){
    var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


 function researchFeedback(array, valeur, nameAllDevices) {

    var testArray = [];

    if (valeur) {
      for (var i = 0; i < array.length; i++) {
        if(array[i].device.user.email.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].device.model.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].feedback.title.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
      }
    }

    if(valeur.length > 0){
      return (testArray)
    }

    return (array)
  }



  class MMAppFeedbacksTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedType: {value: 0, label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})},
        selectedState: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
        selectedExpire: {value: 'null', label: this.props.intl.formatMessage({id: "mmapp.modal.addMessage.aucun"})},
        researchFeedbacksValue: "",
        selected: null,
        pageSizeForMMAppFeedbacksTab: localStorage.getItem('pageSizeForMMAppFeedbacksTab'),
      };
      this.selectedHandle = this.selectedHandle.bind(this);
    }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

    componentWillUnmount(){
      if (this.state.pageSizeForMMAppFeedbacksTab) {
        localStorage.setItem('pageSizeForMMAppFeedbacksTab', this.state.pageSizeForMMAppFeedbacksTab);
      }
    }


// ------------------------------- Action handle ------------------------------- //

    selectedHandle(x){
      this.setState({selected : x});
    }

    handleChangeSelectType = selectedType => {
      this.setState({ selectedType, selectedGroup: null, selectedUser: null, selectedDevice: null });
    }

    handleChangeSelectGroup = selectedGroup => {
      this.setState({ selectedGroup });
    }

    handleChangeSelectUser = selectedUser => {
      this.setState({ selectedUser, selectedDevice: null});
    }

    handleChangeSelectDevice = selectedDevice => {
      this.setState({ selectedDevice });
    }

    handleChangeSelectState = selectedState => {
      this.setState({ selectedState });
    }

    handleChangeCheckboxLink = useLink => {
      this.setState({ useLink: !this.state.useLink });
    }

    handleChangeSelectExpire = selectedExpire => {
      this.setState({ selectedExpire });
    }

    rechercheFeedbackHandle(event) {
      this.setState({researchFeedbacksValue: event.target.value});
    }



// ------------------------------- Function with visual ------------------------ //

    createTabColumn(id){

      switch (id) {
        case "TabApp":
        var columnsApp = [
          {
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.feedbackTab.email"})}
              </b>
            </span>),
            accessor: 'device.user.email',
            minWidth: 50
          },{
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.feedbackTab.model"})}
            </b>
          </span>),
          accessor: 'device.model',
          minWidth: 40
        },{
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.feedbackTab.title"})}
            </b>
          </span>),
          accessor: 'feedback.title',
          minWidth: 100
        },{
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.feedbackTab.createdAt"})}
            </b>
          </span>),
          accessor: 'feedback.createdAt',
          minWidth: 50,
          Cell: row => (
              <div>
                <span>
                {row.value ?
                  changeToDate(row.value, this.props.intl.locale)
                : "-"}
                </span>
              </div>
            )
        }
        ];
          break;

        default:

      }

      return columnsApp;
    }

    getTheadThProps = (table, row, col) => {
        const sortedCol = find(table.sorted, {id: col.id});
        const boxShadow = sortedCol
          ? `inset 0px ${sortedCol.desc
            ? -2
            : 2}px 0px 0px white`
          : '';
        return {
          style: {
            boxShadow
          },
          onContextMenu: (e) => {
            this.props.contextMenuFeedbackTab("empty");
          }
        };
      }


      getGroupSelectFormat(){

        var newArray = [];
        const list = this.props.dataGroup;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;
          if (list[i].link) {
            item.link = 1;
          } else {item.link = 0;}

          item.value = list[i].groupId;

          item.label = list[i].name;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }

      getUserSelectFormat(){

        var newArray = [];
        const list = this.props.dataUser;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;

          item.value = list[i].email;

          item.label = list[i].email;

          item.devices = list[i].devices;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }

      getDeviceSelectFormat(){

        var newArray = [];
        const list = this.state.selectedUser.devices;

        for (var i = 0; i < list.length; i++) {

          var item = new Object;

          item.value = list[i].android_id;

          item.label = list[i].model;

          if (item.label !== undefined) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }



// ------------------------------- Render -------------------------------------- //

    render() {

        return (<div className="card border-0">
          <div className="card-header">
            <div className="form-inline">

              <input className="form-control mr-sm-2" type="search" value={this.state.researchFeedbacksValue} onChange={this.rechercheFeedbackHandle.bind(this)}
                placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" style={{height: "35px"}}/>

            </div>
          </div>

          <div style={{maxHeight: "83vh"}}>

            <MenuProvider id="menu_mmAppFeedbacksTabContextMenu">

            <ReactTable
              getTheadThProps={this.getTheadThProps}
              getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e) => {
                    this.selectedHandle(rowInfo.index);
                  },
                  onContextMenu: (e) => {
                    this.selectedHandle(rowInfo.index);
                  },
                  style: {
                    background: rowInfo.index === this.state.selected ? '#e4eef4' : null,
                    color: rowInfo.index === this.state.selected ? 'black' : null
                  }
                }
              }else{
                return {}
              }
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {

                  onContextMenu: (e) => {
                      if(rowInfo){
                      this.props.contextMenuFeedbackTab(rowInfo.original)}
                      else {this.props.contextMenuFeedbackTab("empty")}
                  }
                }
              }}
              data={researchFeedback(this.props.dataFeedbacks , this.state.researchFeedbacksValue, this.props.intl.formatMessage({id: "mmapp.modal.addMessage.all"})).sort((a, b) => b.feedback.createdAt.localeCompare(a.feedback.createdAt))}
              showPagination={true}
              noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
              rowsText={this.props.intl.formatMessage({id: "rows"})}
              previousText={this.props.intl.formatMessage({id: "previous"})}
              nextText={this.props.intl.formatMessage({id: "next"})}
              loadingText={this.props.intl.formatMessage({id: "chargement"})}
              pageText= "Page"
              ofText={this.props.intl.formatMessage({id: "sur"})}
              TbodyComponent={CustomTbodyComponent}
              columns={this.createTabColumn("TabApp")}
              resizable={true}
              pageSizeOptions={[10, 20, 25, 50, 100, 200]}
              defaultPageSize= {50}
              showPageSizeOptions={true}
              onPageSizeChange={(pageSize) => {
                this.setState({pageSizeForMMAppFeedbacksTab: pageSize})
              }}
              pageSize={this.state.pageSizeForMMAppFeedbacksTab != null ? this.state.pageSizeForMMAppFeedbacksTab : this.defaultPageSize}
               style={{
                maxHeight: "71vh",
                minHeight: "15vh"
              }} className=" text-center border-0 small -clickable"/>

            </MenuProvider>

            <MMAppFeedbacksTabContextMenu
              userAdministration={this.props.userAdministration}
              hit={this.props.feedbackHit}
              refetch={this.props.fetchAll}
              tokenCSRF={this.props.tokenCSRF}/>

              <div className="col text-center border-top small">
                <b className="text-dark">
                  <FormattedMessage id="mmapp.messageTab.nbMessages"/>
                   {this.props.dataFeedbacks.length}</b>
              </div>

          </div>

        </div>)
    }
  }

MMAppFeedbacksTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppFeedbacksTab = injectIntl(MMAppFeedbacksTab, {withRef: true});

export default MMAppFeedbacksTab
