import React from 'react';
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import ReactTooltip from 'react-tooltip'
import MMAppApplicationsTabContextMenu from './MMAppApplicationsTabContextMenu';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {IoMdCloudDownload, IoIosApps, IoMdCheckmark, IoMdInformationCircle} from "react-icons/io";
import { MenuProvider } from 'react-contexify';
import { varGeneral, varMmapp } from '../variables';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const APImmappErrorDashboard = varGeneral.apiMMAppErrorDashboard;
var filterRestore = [];

  const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>);

  function changeToDate(int, langue){
    var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


  function researchApp(array, valeur) {

    var testArray = [];

    if (valeur) {
      for (var i = 0; i < array.length; i++) {
          if(array[i].names.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
            || array[i].packageName.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
      }
    }

    if(valeur.length > 0){
      return (testArray)
    }

    return (array)
  }




  class MMAppApplicationsTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        researchValue: "",
        selected: null,
        dataFiltreCheckbox: {
          checkboxFilterAppStore: false,
          checkboxFilterUndesirable: false,
          checkboxFilterAutoUninstall: false,
          checkboxFilterNewApp: false,
          checkboxFilterNBTerm: false,
          checkboxFilterSystemApp: false,
        },

        filteredAppList: this.props.data,
        pageSizeForMMAppApplicationsTab: localStorage.getItem('pageSizeForMMAppApplicationsTab'),
      };
      this.selectedHandle = this.selectedHandle.bind(this);
    }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

    componentWillUnmount(){
      if (this.state.pageSizeForMMAppApplicationsTab) {
        localStorage.setItem('pageSizeForMMAppApplicationsTab', this.state.pageSizeForMMAppApplicationsTab);
      }
    }


// ------------------------------- Action handle ------------------------------- //

    selectedHandle(x){
      this.setState({selected : x});
    }


    handleCheckbox = (field) => {
      this.setState({
        dataFiltreCheckbox: { ...this.state.dataFiltreCheckbox, [field]: !this.state.dataFiltreCheckbox[field] }
      });
    }


    appliFilter(){
      var item = new Object;
      item.checkboxFilterAppStore = document.getElementById('checkboxFilterAppStore').checked;
      item.checkboxFilterUndesirable = document.getElementById('checkboxFilterUndesirable').checked;
      item.checkboxFilterAutoUninstall = document.getElementById('checkboxFilterAutoUninstall').checked;
      item.checkboxFilterNewApp = document.getElementById('checkboxFilterNewApp').checked;
      item.checkboxFilterNBTerm = document.getElementById('checkboxFilterNBTerm').checked;
      item.checkboxFilterSystemApp = document.getElementById('checkboxFilterSystemApp').checked;

      this.dataFilter(item);
    }

    saveFilterForRestore(){
      var item = new Object;
      item.checkboxFilterAppStore = document.getElementById('checkboxFilterAppStore').checked;
      item.checkboxFilterUndesirable = document.getElementById('checkboxFilterUndesirable').checked;
      item.checkboxFilterAutoUninstall = document.getElementById('checkboxFilterAutoUninstall').checked;
      item.checkboxFilterNewApp = document.getElementById('checkboxFilterNewApp').checked;
      item.checkboxFilterNBTerm = document.getElementById('checkboxFilterNBTerm').checked;
      item.checkboxFilterSystemApp = document.getElementById('checkboxFilterSystemApp').checked;

      filterRestore = item;
    }

    filterModalRestore(){
      document.getElementById('checkboxFilterAppStore').checked = filterRestore.checkboxFilterAppStore;
      document.getElementById('checkboxFilterUndesirable').checked = filterRestore.checkboxFilterUndesirable;
      document.getElementById('checkboxFilterAutoUninstall').checked = filterRestore.checkboxFilterAutoUninstall;
      document.getElementById('checkboxFilterNewApp').checked = filterRestore.checkboxFilterNewApp;
      document.getElementById('checkboxFilterNBTerm').checked = filterRestore.checkboxFilterNBTerm;
      document.getElementById('checkboxFilterSystemApp').checked = filterRestore.checkboxFilterSystemApp;
    }

    dataFilter(dataFiltreCheckbox){
      var newList = [];
      var fullList = this.props.data;

      if (!dataFiltreCheckbox.checkboxFilterAppStore
        && !dataFiltreCheckbox.checkboxFilterUndesirable
        && !dataFiltreCheckbox.checkboxFilterAutoUninstall
        && !dataFiltreCheckbox.checkboxFilterNewApp
        && !dataFiltreCheckbox.checkboxFilterNBTerm
        && !dataFiltreCheckbox.checkboxFilterSystemApp) {
        newList = fullList;
      } else {
        newList = fullList;
        if (dataFiltreCheckbox.checkboxFilterAppStore) {
          newList = this.filterList(newList, "checkboxFilterAppStore");
        }
        if (dataFiltreCheckbox.checkboxFilterUndesirable) {
          newList = this.filterList(newList, "checkboxFilterUndesirable");
        }
        if (dataFiltreCheckbox.checkboxFilterAutoUninstall) {
          newList = this.filterList(newList, "checkboxFilterAutoUninstall");
        }
        if (dataFiltreCheckbox.checkboxFilterNewApp) {
          newList = this.filterList(newList, "checkboxFilterNewApp");
        }
        if (dataFiltreCheckbox.checkboxFilterNBTerm) {
          newList = this.filterList(newList, "checkboxFilterNBTerm");
        }
        if (dataFiltreCheckbox.checkboxFilterSystemApp) {
          newList = this.filterList(newList, "checkboxFilterSystemApp");
        }

      }

      this.setState({filteredAppList: newList})
    }


      filterList(tab, idName){
        var newList = [];
        switch (idName) {
          case "checkboxFilterAppStore":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].store_version != null) {
                  newList.push(tab[x]);
                }
              }
            break;
          case "checkboxFilterUndesirable":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].undesirable) {
                  newList.push(tab[x]);
                }
              }
            break;
          case "checkboxFilterAutoUninstall":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].auto_uninstall) {
                  newList.push(tab[x]);
                }
              }
            break;
          case "checkboxFilterNewApp":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].newApplication_for_dashboard_error) {
                  newList.push(tab[x]);
                }
              }
            break;
          case "checkboxFilterNBTerm":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].device_with_app > 0) {
                  newList.push(tab[x]);
                }
              }
            break;
          case "checkboxFilterSystemApp":
              for (var x = 0; x < tab.length; x++) {
                if (tab[x].applicationSystem) {
                  newList.push(tab[x]);
                }
              }
            break;
          }
          return newList;
      }


      rechercheHandle(event) {
      this.setState({researchValue: event.target.value});
    }

    notifUndesirableApp(data, appName){

      var message = this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.msg.1"})
      + appName
      + this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.msg.2"});

      if (document.getElementById('inputForCustomMessageText').value != null) {
        message = document.getElementById('inputForCustomMessageText').value;
      }

      for (var i = 0; i < data.length; i++) {

        var responseNB = 0;

        var dataMSG = {
          "email" : data[i].email,
          "deviceId" : data[i].android_id,
          "message": message,
          "state": "warning",
          "title": this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.title"}) + appName,
              }


          fetch(varMmapp.apiMessage, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: JSON.stringify(dataMSG)
            })
            .then(response => {
              responseNB += 1;
              if(process.env.NODE_ENV == "development")
              {
                console.log(response);
              }
              if (responseNB == data.length) {
                this.props.fetchMessage();
              }
             })

      }
      document.getElementById('inputForCustomMessageText').value = "";
    }


      notifUndesirableAppCustomMsg(data, appName){
        this.setState({dataCustomMsg: data, appNameCustomMsg: appName})

        document.getElementById('inputForCustomMessageText').value = this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.msg.1"})
        + appName
        + this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.msg.2"});

        $('#modalNotifUndesirableAppCustomMsg').modal('show')
      }


          actionMenu(){

            fetch(APImmappErrorDashboard + "/valide/", {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
                body: JSON.stringify(this.state.mmappErrorDashboard.applicationList)
              })
              .then(response => {
                if(process.env.NODE_ENV == "development")console.log(response);
                this.props.fetchAll();
               })
          }

// ------------------------------- Function with visual ------------------------ //

    createTabColumn(id){

      switch (id) {
        case "TabApp":
        var columnsApp = [
          {
          Header: () => (<span>
            <b></b>
          </span>),
          accessor: 'icons',
          minWidth: 45,
          maxWidth: 45,
          Cell: row => (
              <div>
                <span style={{color: row.original.undesirable ? row.original.auto_uninstall ? "rgb(235,39,28)" : "rgb(255,104,8)" : "rgb(51,154,232)"}}
                  data-tip data-for={row.original.undesirable ? row.original.packageName : null}>
                  {
                    row.original.store_version ? <IoMdCloudDownload size={27}/> : <IoIosApps size={27}/>
                  }
                </span>
                {row.original.undesirable ?
                  row.original.auto_uninstall ?
                  <ReactTooltip className="relayClass" id={row.original.packageName} type={'light'} border={true} effect={'solid'} place={'bottom'}>
                    {this.props.intl.formatMessage({id: "mmapp.tab.tooltip.undesirable.autouninstall"})}
                  </ReactTooltip>
                  :
                  <ReactTooltip className="relayClass" id={row.original.packageName} type={'light'} border={true} effect={'solid'} place={'bottom'}>
                    {this.props.intl.formatMessage({id: "mmapp.tab.tooltip.undesirable"})}
                  </ReactTooltip>
                  :
                  null
                }
              </div>
            )
        }, {
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.tab.newApplication"})}
            </b>
          </span>),
          accessor: 'newApplication_for_dashboard_error',
          minWidth: 120,
          maxWidth: 200,
          Cell: row => (
              <div>
                {row.value ?
                  <span style={{color: "rgb(35,203,72)"}}>
                    <IoMdCheckmark size={25}/>
                  </span>
                  :
                  null
                }
              </div>
            )
        }, {
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.tab.names"})}
            </b>
          </span>),
          accessor: 'names',
          minWidth: 90,
          Cell: row => (
              <div>
                {row.original.applicationSystem ?
                  <span>{row.value}
                  <span className="ml-2" title={this.props.intl.formatMessage({id: "groupsDetails.applications.systemApplication"})}>
                    <IoMdInformationCircle size={26} style={{color: "rgb(28,150,210)"}}/>
                  </span>
                  </span>
                  :
                  row.value
                }
              </div>
            )
        },{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.tab.packageName"})}
              </b>
            </span>),
            accessor: 'packageName',
            minWidth: 140
          }
          /*
          ,
          {
              Header: () => (<span>
                <b>
                  {this.props.intl.formatMessage({id: "mmapp.tab.createdAt"})}
                </b>
              </span>),
              accessor: 'createdAt',
              minWidth: 90,
              Cell: row => (
                  <div>
                    <span>
                    {row.value ?
                      changeToDate(row.value, this.props.intl.locale)
                    : "-"}
                    </span>
                  </div>
                )
            }*/
            ,{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.tab.nbTerminaux"})}
              </b>
            </span>),
            accessor: 'device_with_app',
            minWidth: 80,
          }
        ];
          break;

        default:

      }

      return columnsApp;
    }

    getTheadThProps = (table, row, col) => {
        const sortedCol = find(table.sorted, {id: col.id});
        const boxShadow = sortedCol
          ? `inset 0px ${sortedCol.desc
            ? -2
            : 2}px 0px 0px white`
          : '';
        return {
          style: {
            boxShadow
          },
          onContextMenu: (e) => {
            this.props.contextMenu("empty")
          }
        };
      }


// ------------------------------- Render -------------------------------------- //

    render() {

        return (<div className="card border-0">
          <div className="card-header">
            <div className="form-inline">
            <input className="form-control mr-sm-2" type="search" value={this.state.researchValue} onChange={this.rechercheHandle.bind(this)}
              placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" style={{height: "35px"}}/>

            {/*
              <div className="custom-control custom-checkbox ml-3">
                  <input type="checkbox" className="custom-control-input" id="checkboxOnlyUndesirable" onChange={this.props.selectChangeHandle.bind(this)}/>
                  <label className="custom-control-label" htmlFor="checkboxOnlyUndesirable" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.checkbox.undesirable"/></label>
              </div>
            */}

            <button type="button" className="btn btn-primary btn-sm ml-2" data-toggle="modal" data-target="#modalFilter" onClick={this.saveFilterForRestore.bind(this)}>
              <FormattedMessage id="mmapp.app.filter.checkbox.button"/>
            </button>

            {
              /*
              this.props.userAdministration === "ADMINISTRATOR" ?
              <div className="col">
                <button type="button" className="btn btn-primary btn-sm float-right" data-toggle="modal" data-target="#modalValidAllApps">
                    {this.props.intl.formatMessage({id: "mmapp.modal.validAllApps"})}
                </button>
              </div>
              :
              null
              */
            }

          </div>
          </div>

          <div style={{maxHeight: "83vh"}}>

              <div>

              <MenuProvider id="menu_mmAppApplicationsTabContextMenu">

              <ReactTable
                    getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.selectedHandle(rowInfo.index);
                        },
                        onContextMenu: (e) => {
                          this.selectedHandle(rowInfo.index);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#e4eef4' : null,
                          color: rowInfo.index === this.state.selected ? 'black' : null
                        }
                      }
                    }else{
                      return {}
                    }
                    }}

                  getTdProps={(state, rowInfo, column, instance) => {
                  return {

                    onContextMenu: (e) => {
                        if(rowInfo){
                        this.props.contextMenu(rowInfo.original)}
                        else {this.props.contextMenu("empty")}
                    }
                  }
                }}
                getTheadThProps={this.getTheadThProps}
                data={
                  (researchApp(this.state.filteredAppList , this.state.researchValue)).sort((a, b) => a.names.localeCompare(b.names))
                }
                showPagination={true}
                noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
                rowsText={this.props.intl.formatMessage({id: "rows"})}
                previousText={this.props.intl.formatMessage({id: "previous"})}
                nextText={this.props.intl.formatMessage({id: "next"})}
                loadingText={this.props.intl.formatMessage({id: "chargement"})}
                pageText= "Page"
                ofText={this.props.intl.formatMessage({id: "sur"})}
                TbodyComponent={CustomTbodyComponent}
                columns={this.createTabColumn("TabApp")}
                resizable={true}
                pageSizeOptions={[10, 20, 25, 50, 100, 200]}
                defaultPageSize= {50}
                showPageSizeOptions={true}
                onPageSizeChange={(pageSize) => {
                  this.setState({pageSizeForMMAppApplicationsTab: pageSize})
                }}
                pageSize={this.state.pageSizeForMMAppApplicationsTab != null ? this.state.pageSizeForMMAppApplicationsTab : this.defaultPageSize}
                 style={{
                  maxHeight: "71vh",
                  minHeight: "15vh"
                }} className=" text-center border-0 small -clickable"/>

              </MenuProvider>

              <MMAppApplicationsTabContextMenu
                userAdministration={this.props.userAdministration}
                hit={this.props.appHit}
                refetch={this.props.fetchAll}
                AppHitUpdate={this.props.AppHitUpdate}
                notifUndesirableApp={this.notifUndesirableApp.bind(this)}
                notifUndesirableAppCustomMsg={this.notifUndesirableAppCustomMsg.bind(this)}
                tokenCSRF={this.props.tokenCSRF}/>

              <div className="col text-center border-top small">
                <b className="text-dark">
                  <FormattedMessage id="mmapp.nbapp"/>
                   {this.state.filteredAppList.length}</b>
              </div>
            </div>
          </div>


          <div className="modal fade" id="modalValidAllApps" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`} style={{zIndex: '4000'}}>
                <div className="modal-header">
                  <h5 className="modal-title">
                    {this.props.intl.formatMessage({id: "mmapp.modal.validAllApps"})}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">
                    {this.props.intl.formatMessage({id: "confirmer"})}
                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-primary`} data-dismiss="modal" onClick={this.actionMenu.bind(this)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalFilter" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel"><FormattedMessage id="mmapp.app.filter.checkbox.modal.title"/></h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterAppStore"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterAppStore" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterAppStore"/></label>
                  </div>

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterUndesirable"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterUndesirable" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterUndesirable"/></label>
                  </div>

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterAutoUninstall"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterAutoUninstall" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterAutoUninstall"/></label>
                  </div>

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterNewApp"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterNewApp" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterNewApp"/></label>
                  </div>

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterNBTerm"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterNBTerm" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterNBTerm"/></label>
                  </div>

                  <div className="custom-control custom-checkbox ml-3">
                      <input type="checkbox" className="custom-control-input" id="checkboxFilterSystemApp"/>
                      <label className="custom-control-label" htmlFor="checkboxFilterSystemApp" style={{cursor: "pointer"}}><FormattedMessage id="mmapp.app.filter.checkbox.checkboxFilterSystemApp"/></label>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.filterModalRestore.bind(this)}><FormattedMessage id="fermer"/></button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.appliFilter.bind(this)}><FormattedMessage id="save"/></button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modalNotifUndesirableAppCustomMsg" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.btn.customMsg"})} : {this.state.appNameCustomMsg}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ml-2">
                  <h6 className="modal-title" id="modalAjoutDNSLabel">

                    <div className="input-group input-group-sm">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{this.props.intl.formatMessage({id: "mmapp.modal.addMessage.message"})}</span>
                      </div>
                      <textarea className="form-control" id="inputForCustomMessageText" rows={2} style={{ resize: "none" }} maxLength="250"></textarea>
                    </div>

                  </h6>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                  </button>
                  <button type="button" className={`btn text-white btn-primary`} data-dismiss="modal"
                    onClick={this.notifUndesirableApp.bind(this, this.state.dataCustomMsg, this.state.appNameCustomMsg)}><FormattedMessage id="valider"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>)
    }
  }


MMAppApplicationsTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppApplicationsTab = injectIntl(MMAppApplicationsTab, {withRef: true});

export default MMAppApplicationsTab
