import React from 'react'
import {FormattedMessage} from "react-intl";

import {varConfigServeur} from '../variables';

const API = varConfigServeur.apiProxy;


class ConfigServeurProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProxy: [],
      proxyEnabled: false
    };
    this.handleChange = this.handleChange.bind(this);
  }


// ------------------------- Action handle ------------------------------------- //

  handleChange = (event, field) => {
      this.props.handleChildModification(event.target.value, "dataProxy", field)
  }


// ------------------------- Render -------------------------------------------- //

  render() {

    if(this.props.dataProxy.proxyEnabled){
      return (<div className="col-auto mt-2 mb-2">

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="PROXYcheck" onChange={e => this.handleChange(e, "proxyEnabled")} checked={this.props.dataProxy.proxyEnabled}/>
          <label className="custom-control-label" htmlFor="PROXYcheck">
            <FormattedMessage id="ConfigurationServer.serverProxy.1"/>
          </label>
        </div>

        <div>

          <h6 className="text-info mt-3 mb-3">
            <b><FormattedMessage id="ConfigurationServer.serverProxy.2"/></b>
          </h6>
          <div className="row mb-1">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.3"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataProxy.proxyHost} onChange={e => this.handleChange(e, "proxyHost")}/>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.4"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataProxy.proxyPort} onChange={e => this.handleChange(e, "proxyPort")}/>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.5"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataProxy.proxyLogin} onChange={e => this.handleChange(e, "proxyLogin")}/>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.6"/>
            </div>
            <div className="col">
              <input type="password" className="form-control border" aria-label="password" aria-describedby="basic-addon" value={this.props.dataProxy.proxyPassword} onChange={e => this.handleChange(e, "proxyPassword")}/>
            </div>
          </div>

          <div className="row mb-1">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.7"/>
            </div>
            <div className="col">
              <input type="password" className="form-control border" aria-label="password" aria-describedby="basic-addon" value={this.props.dataProxy.proxyPassword} onChange={e => this.handleChange(e, "proxyPassword")}/>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormattedMessage id="ConfigurationServer.serverProxy.8"/>
            </div>
            <div className="col">
              <input type="text" className="form-control border" value={this.props.dataProxy.proxySchemeOrder} onChange={e => this.handleChange(e, "proxySchemeOrder")}/>
            </div>
          </div>
          <hr/>
          <div className="col mb-1">
            <button className="btn btn-danger mr-1">Test</button>
          </div>

        </div>

      </div>)
    }else {
      return (<div className="col-auto mt-2 mb-2">

        <div className="custom-control custom-checkbox mb-2">
          <input className="custom-control-input" type="checkbox" value="" id="PROXYcheck" onChange={e => this.handleChange(e, "proxyEnabled")} checked={this.props.dataProxy.proxyEnabled}/>
          <label className="custom-control-label" htmlFor="PROXYcheck">
            <FormattedMessage id="ConfigurationServer.serverProxy.1"/>
          </label>
        </div>

      </div>)
    }
  }
}

export default ConfigServeurProxy
