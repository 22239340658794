import React, {Component} from 'react';
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import ReactTooltip from 'react-tooltip'
import MMAppGraphDeviceTab from './MMAppGraphDeviceTab';
import MMAppApplicationsTab from './MMAppApplicationsTab';
import MMAppMessagesTab from './MMAppMessagesTab';
import MMAppFeedbacksTab from './MMAppFeedbacksTab';
import MMAppDeviceBackupManagement from './MMAppDeviceBackupManagement';
import MMAppQRCodeTab from './MMAppQRCodeTab';
import { Doughnut, Line } from 'react-chartjs-2';
import {NavLink} from 'react-router-dom'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import Select, { components } from 'react-select';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import LineGeneral from '../Components/LineGeneral';
import CustomProgressBar from '../Components/CustomProgressBar';
import {varMmapp, varGroups} from '../variables';


var time = 0;
var newRequete = false;

var itemActiveGraph = "active";
var tabActiveGraph = "show active";

var itemActiveSaveDevice = null;
var tabActiveSaveDevice = null;

// ------------------------------- Setting of the table ------------------------ //

  const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>);

  function octetAdapt(value){
    var newValue = value;
    if(value > Math.pow(10, 12)){
      newValue = Number(parseInt(value)/(1024 * 1024 * 1024 * 1024)).toFixed(2) + " To";
    } else if(value > Math.pow(10, 9)){
      newValue = Number(parseInt(value)/(1024 * 1024 * 1024)).toFixed(2) + " Go";
    } else if(value > Math.pow(10, 6)){
      newValue = Number(parseInt(value)/(1024 * 1024)).toFixed(2) + " Mo";
    } else if(value > Math.pow(10, 3)){
      newValue = Number(parseInt(value)/1024).toFixed(2) + " Ko";
    } else {
      newValue = newValue.toFixed(2) + " octets";
    }
    return(newValue)
  }

  function changeToDate(int, langue){
    var options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


class MMAppPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderTab: true,
      isLoading: false,
      error: null,
      appHit: [],
      messageHit: [],
      feedbackHit: [],
      dataModal: [],
      modalData: [],
      modalTitleDetailDevices: "",
      modalDataName: "columnsDevices",
    };

    this.tick = this.tick.bind(this)

    this.contextMenu = this.contextMenu.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {
    if (varMmapp.enableTimer === "on")this.timer = setInterval(this.tick, 1000);
    if(process.env.NODE_ENV === "development")console.log("REFRESH AUTO suivi MM-Files : " + varMmapp.enableTimer + " , 1sec, " + varMmapp.timer + "sec");
    document.title = "MM-App";
    this.setState({isLoading: true});

    this.fetchAll();

  }

  componentWillUnmount() {
    time = 0;
    clearInterval(this.timer);

    itemActiveGraph = "active";
    tabActiveGraph = "show active";

    itemActiveSaveDevice = null;
    tabActiveSaveDevice = null;
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchAll(){
    Promise.all([fetch(varMmapp.apiAppli ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiGroup ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiTabApp ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiDeviceStat ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiMessage ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiFeedbacks ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiUser ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiGraph ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(varMmapp.apiQRCodeProfil ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
      ]).then(([resAppli, resGroup, resTabApp, resDeviceStat, resMessage, resFeedbacks, resUser, resGraph, resQRCodeProfil]) => Promise.all([resAppli.json(), resGroup.json(), resTabApp.json(), resDeviceStat.json(), resMessage.json(), resFeedbacks.json(), resUser.json(), resGraph.json(), resQRCodeProfil.json()]))
        .then(([dataAppli, dataMMAppGroup, dataMMAppAppTabStat, dataDeviceStat, dataMessage, dataFeedbacks, dataUser, dataGraph, dataQRCodeProfil]) => (this.setState({
              dataAppli: this.adaptData(dataAppli),
              dataMMAppGroup,
              dataMMAppAppTabStat: this.adaptDataMMAppTab(dataMMAppAppTabStat),
              dataDeviceStat,
              dataMessage,
              dataFeedbacks,
              dataUser,
              dataGraph,
              dataQRCodeProfil,
              isLoading: false
        }),
      newRequete = true))
      .then(this.fetchBackupFile())
      .catch(error => this.setState({error}));
  }

  fetchBackupFile(){
    Promise.all([
        fetch(varMmapp.apiBackupFile ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resBackupFile]) => Promise.all([resBackupFile.json()]))
        .then(([dataBackupFile]) => (this.setState({
              dataBackupFile
        })))
  }

  fetchTabApp(){
    Promise.all([
        fetch(varMmapp.apiTabApp ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resTabApp]) => Promise.all([resTabApp.json()]))
        .then(([dataMMAppAppTabStat]) => (this.setState({
              dataMMAppAppTabStat: this.adaptDataMMAppTab(dataMMAppAppTabStat)
        })))
      .catch(error => this.setState({error}));
  }

  fetchQRCodeProfil(){
    Promise.all([
        fetch(varMmapp.apiQRCodeProfil ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resQRCodeProfil]) => Promise.all([resQRCodeProfil.json()]))
        .then(([dataQRCodeProfil]) => (this.setState({
              dataQRCodeProfil
        }),
      newRequete = true))
      .catch(error => this.setState({error}));
  }

  adaptData(dataMMApp){
    fetch(varGroups.api ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('fetch groups : Something went wrong ...');
      }
    }).then(data => (
      this.fuseData(dataMMApp, data)
    )).catch(error => this.setState({error}));

  }

    fetchMessage(){
      fetch(varMmapp.apiMessage ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('fetch message : Something went wrong ...');
        }
      }).then(data => (
        this.setState({dataMessage: data})
      )).catch(error => this.setState({error}));

    }

  adaptDataMMAppTab(data){

    var newList = [];

    for (var i = 0; i < data.length; i++) {
      data[i].names = this.getApplicationName(data[i]);
      if (data[i].store_version) {
        if (data[i].auto_uninstall) {
          data[i].icons = 32;
        }else if (data[i].undesirable) {
          data[i].icons = 22;
        } else {
          data[i].icons = 12;
        }
      } else {
        if (data[i].auto_uninstall) {
          data[i].icons = 31;
        }else if (data[i].undesirable) {
          data[i].icons = 21;
        } else {
          data[i].icons = 11;
        }
      }

      if (data[i].newApplication && data[i].device_with_app > 0) {
        data[i].newApplication = true;
      } else {
        data[i].newApplication = false;
      }

      if (data[i].newApplication_for_dashboard_error === true || data[i].newApplication_for_dashboard_error === null) {
        data[i].newApplication_for_dashboard_error = true;
      } else {
        data[i].newApplication_for_dashboard_error = false;
      }

    }

    this.setState({dataMMAppAppTabStatUndesirable: this.getOnlyUndesirable(data)})

    return data
  }

  getOnlyUndesirable(data){
    var newList = [];
    if (data) {
      data.map((hit,index) =>{
          if (hit.undesirable) {
            newList.push(hit);
          }
        })
        return newList;
    }
  }

  fuseData(data, dataGrp){
    var newData = [];
    for (var i = 0; i < data.length; i++) {

      var item = new Object;

      var newGroups = [];

      for (var y = 0; y < data[i].groups.length; y++) {
        var subitem = new Object;
        subitem = data[i].groups[y];

        for (var x = 0; x < dataGrp.length; x++) {
          if (dataGrp[x].groupId === data[i].groups[y].id) {
            subitem.name = dataGrp[x].name;
          }
        }
      }
    }

    this.setState({dataMMApp: data.sort((a, b) => this.getApplicationName(a).localeCompare(this.getApplicationName(b))), dataGroup: dataGrp, isLoading: false})
  }


// ------------------------------- Action handle ------------------------------- //


    selectChangeHandle(){
      this.setState({refresh: null})
    }

    AppHitUpdate(hit, field, value){
      this.setState({
        appHit: { ...this.state.appHit, [field]: value }
      });
    }


      contextMenu(x){
          if(x === "empty"){
            this.setState({appHit : "empty"});
        }else {
          this.setState({appHit : x});
        }
      }

      contextMenuMessageTab(x){
          if(x === "empty"){
            this.setState({messageHit : "empty"});
        }else {
          this.setState({messageHit : x});
        }
      }

      contextMenuFeedbackTab(x){
          if(x === "empty"){
            this.setState({feedbackHit : "empty"});
        }else {
          this.setState({feedbackHit : x});
        }
      }

      inversState(name, value){
        this.setState({[name]: !value})
      }


// ------------------------------- Auto refresh table -------------------------- //

      tick() {
        if (newRequete === true) {
          if (time === varMmapp.timer) {
            time = 0;
            newRequete = false;
            this.fetchAll();
          }
          time = time + 1;
        }
      }

// ------------------------------- Function with visual ------------------------ //


    fetching() {
      if (newRequete === false) {
        return (<div className="col d-flex justify-content-center">
          <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>)
      }
    }


  groupInfo(data){
      return(
        <div className=" row mb-1 p-2">
          <div className="col">
            <select className="form-control form-control-sm mb-2" id={"selectGroupStat"} onChange={this.selectChangeHandle.bind(this)}>
              {data && data.length > 0 ?

                data.map((hit,index) =>{
                    return(
                      <option key={index} value={index}>{hit.name}</option>
                    )
                  })
                  :
                  null
              }
            </select>
            <div>
              {this.createDoughnuts(document.getElementById('selectGroupStat') ? data[document.getElementById('selectGroupStat').value] : data[0])}
            </div>
          </div>
        </div>
      )

  }

  appStat(data){
    return(
      <div className=" row mb-1 p-2">
        <div className="col">
          <select className="form-control form-control-sm mb-2" id={"selectAppStat"} onChange={this.selectChangeHandle.bind(this)}>
            {
              data.map((hit,index) =>{
                  return(
                    <option key={index} value={index}>{this.getApplicationName(hit)}</option>
                  )
                })
            }
          </select>
          <div>
            {this.createDoughnutsApp(document.getElementById('selectAppStat') ? data[document.getElementById('selectAppStat').value] : data[0])}
          </div>
        </div>
      </div>
    )
  }


  createDoughnuts(data){

    var compliant = this.props.intl.formatMessage({id: "mmapp.graph.compliant"});
    var notCompliant = this.props.intl.formatMessage({id: "mmapp.graph.notCompliant"});
    var mmappNotInstalled = this.props.intl.formatMessage({id: "mmapp.graph.mmappNotInstalled"});

    var adaptLabels = [];
    var adaptData = [];
    var adaptDataColor = [];

    if (data && data.listCompliant && data.listCompliant.length > 0) {
          adaptLabels.push(compliant);
          adaptData.push(data.listCompliant.length);
            adaptDataColor.push("rgb(46,207,54)");
    }
    if (data && data.listNotCompliant && data.listNotCompliant.length > 0) {
          adaptLabels.push(notCompliant);
          adaptData.push(data.listNotCompliant.length);
          adaptDataColor.push("rgb(250,177,34)");
    }
    if (data && data.listMMAppNotInstalled && data.listMMAppNotInstalled.length > 0) {
          adaptLabels.push(mmappNotInstalled);
          adaptData.push(data.listMMAppNotInstalled.length);
          adaptDataColor.push("rgb(224,47,37)");
    }

    if (adaptLabels.length == 0) {
      adaptLabels.push(this.props.intl.formatMessage({id: "mmapp.graph.noValue"}));
      adaptData.push(1);
      adaptDataColor.push("rgb(226,226,226)");
    }


    var newData = {
      maintainAspectRatio: true,
      responsive: true,
      labels: adaptLabels,
      datasets: [
        {
          data: adaptData,
          backgroundColor: adaptDataColor
        }
      ]
    }

    var options = {

        onHover: (evt, chartElement) => {
          evt.target.style.cursor = 'default';
          if (chartElement[0]) {
            evt.target.style.cursor = 'pointer';
          }

        },
          onClick: (evt, item) => {
            if (item[0]) {
              switch (item[0]._model.label) {
                case compliant:
                    this.setState({modalData: data.listCompliant, modalTitleDetailDevices: compliant, modalDataName: "columnsDevices"})
                    $('#modalDetailDevices').modal('show')
                  break;
                case notCompliant:
                    this.setState({modalData: data.listNotCompliant, modalTitleDetailDevices: notCompliant, modalDataName: "columnsDevices"})
                    $('#modalDetailDevices').modal('show')
                  break;
                case mmappNotInstalled:
                    this.setState({modalData: data.listMMAppNotInstalled, modalTitleDetailDevices: mmappNotInstalled, modalDataName: "columnsDevices"})
                    $('#modalDetailDevices').modal('show')
                  break;
                default:

              }
            }
        },
        legend: {
          position: 'right',
          display: true,
          labels: {
            boxWidth: 20
          }
      }
    }


    return(
      <div>
        <Doughnut data={newData} options={options}/>
        <div className="col text-center border mt-2">
          {data && data.nbDevices ?
            this.props.intl.formatMessage({id: "mmapp.graph.nbDevices"}) + data.nbDevices
            :
            this.props.intl.formatMessage({id: "mmapp.graph.noValue"})
          }
        </div>
      </div>
    )
  }

    createColumns(name){
      var columns : [];

      switch (name) {
        case "columnsDevices":
              columns = [
                {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.email"}/>
                    </b>
                  </span>),
                  accessor: 'email',
                  minWidth: 150
                }, {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.login"}/>
                    </b>
                  </span>),
                  accessor: 'login'
                }, {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.model"}/>
                    </b>
                  </span>),
                  accessor: 'model',
                  minWidth: 120
                }
              ];

          break;
        case "columnsApplications":
              columns = [
                {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.email"}/>
                    </b>
                  </span>),
                  accessor: 'email',
                  minWidth: 150
                }, {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.model"}/>
                    </b>
                  </span>),
                  accessor: 'model'
                }, {
                  Header: () => (<span>
                    <b>
                      <FormattedMessage id={"mmapp.applicationTab.modal.androidId"}/>
                    </b>
                  </span>),
                  accessor: 'android_id',
                  minWidth: 120
                }
              ];

          break;
        default:

      }

      return columns
    }


    createDoughnutsApp(data){

      var upToDate = this.props.intl.formatMessage({id: "mmapp.graph.upToDate"});
      var outDated = this.props.intl.formatMessage({id: "mmapp.graph.outDated"});
      var notInstalled = this.props.intl.formatMessage({id: "mmapp.graph.notInstalled"});

        var adaptLabels = [];
        var adaptData = [];
        var adaptDataColor = [];

        if (data && data.listUpToDate && data.listUpToDate.length > 0) {
              adaptLabels.push(upToDate);
              adaptData.push(data.listUpToDate.length);
                adaptDataColor.push("rgb(46,207,54)");
        }
        if (data && data.listOutDated && data.listOutDated.length > 0) {
              adaptLabels.push(outDated);
              adaptData.push(data.listOutDated.length);
              adaptDataColor.push("rgb(250,177,34)");
        }
        if (data && data.listNotInstalled && data.listNotInstalled.length > 0) {
              adaptLabels.push(notInstalled);
              adaptData.push(data.listNotInstalled.length);
              adaptDataColor.push("rgb(224,47,37)");
        }

        if (adaptLabels.length == 0) {
          adaptLabels.push(this.props.intl.formatMessage({id: "mmapp.graph.noValue"}));
          adaptData.push(1);
          adaptDataColor.push("rgb(226,226,226)");
        }


        var newData = {
          maintainAspectRatio: true,
          responsive: true,
          labels: adaptLabels,
          datasets: [
            {
              data: adaptData,
              backgroundColor: adaptDataColor
            }
          ]
        }

        var options = {
              onHover: (evt, chartElement) => {
                evt.target.style.cursor = 'default';
                if (chartElement[0]) {
                  evt.target.style.cursor = 'pointer';
                }

              },
                onClick: (evt, item) => {
                  if (item[0]) {
                    switch (item[0]._model.label) {
                      case upToDate:
                          this.setState({modalData: data.listUpToDate, modalTitleDetailDevices: upToDate, modalDataName: "columnsApplications"})
                          $('#modalDetailDevices').modal('show')
                        break;
                      case outDated:
                          this.setState({modalData: data.listOutDated, modalTitleDetailDevices: outDated, modalDataName: "columnsApplications"})
                          $('#modalDetailDevices').modal('show')
                        break;
                      case notInstalled:
                          this.setState({modalData: data.listNotInstalled, modalTitleDetailDevices: notInstalled, modalDataName: "columnsApplications"})
                          $('#modalDetailDevices').modal('show')
                        break;
                      default:

                    }
                  }

              },
            legend: {
              position: 'right',
              display: true,
              labels: {
                boxWidth: 20
              }
          }
        }

        return(
          <div>
            <Doughnut data={newData} options={options}/>
            <div className="col text-center border mt-2">
              {data && data.total ?
                this.props.intl.formatMessage({id: "mmapp.graph.nbDevicesApp"}) + data.total
                :
                this.props.intl.formatMessage({id: "mmapp.graph.noValue"})
              }
            </div>
          </div>
        )
    }



  getApplicationName(appli){
    var name = "";

    switch (this.props.intl.locale) {
      case "fr":
          if (appli.names.fr !== undefined) {
            name = appli.names.fr;
          } else if (appli.names.en !== undefined){
            name = appli.names.en + " (en)";
          }
        break;
      case "en":
          if (appli.names.en !== undefined) {
            name = appli.names.en;
          } else if (appli.names.fr !== undefined){
            name = appli.names.fr + " (fr)";
          }
        break;
      default:
          name = appli.packageName;
        break;
    }

    return name;
  }



  getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, {id: col.id});
      const boxShadow = sortedCol
        ? `inset 0px ${sortedCol.desc
          ? -2
          : 2}px 0px 0px white`
        : '';
      return {
        style: {
          boxShadow
        },
        onContextMenu: (e) => {
          this.contextMenu("empty");
        }
      };
    }

    testTabActive(index) {
      if (index === 4) {

        itemActiveGraph = null;
        tabActiveGraph = null;

        itemActiveSaveDevice = "active";
        tabActiveSaveDevice = "show active";
      }
    }


// ------------------------------- Render -------------------------------------- //

  render() {

    this.testTabActive(this.props.location.tabActive);

    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Erreur : {error.message}</span>
        </div>

        <div className="container"></div>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    return (
      <div className="container-fluid small mt-3">
        <div className="row">
          <div className="col" style={{maxWidth: "30vw"}}>
            <div className="card rounded-0 bg-white mb-2">
              <div className="card-header border-info text-info bg-light p-2">
                <span className="">
                  <b>
                    <FormattedMessage id="mmapp.card.1.title"/>
                  </b>
                </span>
              </div>
              <div>
                {this.state.dataMMAppGroup ?
                    this.groupInfo(this.state.dataMMAppGroup)
                  :
                  null
                }
              </div>
             </div>
             <div className="card rounded-0 bg-white">
               <div className="card-header border-info text-info bg-light p-2">
                 <span className="">
                   <b>
                     <FormattedMessage id="mmapp.card.2.title"/>
                   </b>
                 </span>
               </div>
               <div>
                 {this.state.dataMMApp ?
                     this.appStat(this.state.dataMMApp)
                   :
                   null
                 }
               </div>
              </div>
           </div>

          <div className="col">
            <div className="card rounded-0 bg-white" style={{maxWidth:"67vw"}}>


              <ul className="nav nav-pills border-bottom border-right border-left nav-fill" style={{fontSize: 'calc(4px + 1vmin)'}}>
                <li className="nav-item">
                  <a className={`nav-link ${itemActiveGraph} rounded-0`} data-toggle="tab" href="#graph"><FormattedMessage id="mmapp.menuTab.graph"/></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link rounded-0" data-toggle="tab" href="#applications"><FormattedMessage id="mmapp.menuTab.application"/></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link rounded-0" data-toggle="tab" href="#messages"><FormattedMessage id="mmapp.menuTab.message"/></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link rounded-0" data-toggle="tab" href="#feedbacks"><FormattedMessage id="mmapp.menuTab.feedbacks"/></a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${itemActiveSaveDevice} rounded-0`} data-toggle="tab" href="#statistiques"><FormattedMessage id="mmapp.menuTab.save"/></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link rounded-0" data-toggle="tab" href="#qr_code"><FormattedMessage id="mmapp.menuTab.qr_code"/></a>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">

                <div className={`tab-pane ${tabActiveGraph} p-3`} id="graph" role="tabpanel">
                    {this.state.dataGraph ?
                      <MMAppGraphDeviceTab dataGraph={this.state.dataGraph}
                      tokenCSRF={this.props.tokenCSRF}/>
                      :
                      null
                    }

                </div>

                <div className="tab-pane fade" id="applications" role="tabpanel">

                  {this.state.dataMMAppAppTabStat && this.state.dataMMAppAppTabStatUndesirable ?

                  <MMAppApplicationsTab
                    data={this.state.dataMMAppAppTabStat}
                    userAdministration={this.props.userAdministration}
                    selectChangeHandle={this.selectChangeHandle.bind(this)}
                    contextMenu={this.contextMenu.bind(this)}
                    fetchMessage={this.fetchMessage.bind(this)}
                    dataMMAppAppTabStatUndesirable={this.state.dataMMAppAppTabStatUndesirable}
                    appHit={this.state.appHit}
                    AppHitUpdate={this.AppHitUpdate.bind(this)}
                    fetchAll={this.fetchTabApp.bind(this)}
                    tokenCSRF={this.props.tokenCSRF}
                  />

                  :
                    null
                  }

                </div>


                <div className="tab-pane fade" id="messages" role="tabpanel">

                  {this.state.dataMessage ?
                    <MMAppMessagesTab
                      userAdministration={this.props.userAdministration}
                      dataMessage={this.state.dataMessage}
                      dataGroup={this.state.dataGroup}
                      dataUser={this.state.dataUser}
                      fetchMessage={this.fetchMessage.bind(this)}
                      contextMenuMessageTab={this.contextMenuMessageTab.bind(this)}
                      messageHit={this.state.messageHit}
                      fetchAll={this.fetchAll.bind(this)}
                      tokenCSRF={this.props.tokenCSRF}/>
                    :
                    null
                  }

                </div>


                <div className="tab-pane fade" id="feedbacks" role="tabpanel">

                  {this.state.dataFeedbacks ?
                    <MMAppFeedbacksTab
                      userAdministration={this.props.userAdministration}
                      dataFeedbacks={this.state.dataFeedbacks}
                      dataGroup={this.state.dataGroup}
                      dataUser={this.state.dataUser}
                      fetchMessage={this.fetchMessage.bind(this)}
                      contextMenuFeedbackTab={this.contextMenuFeedbackTab.bind(this)}
                      feedbackHit={this.state.feedbackHit}
                      fetchAll={this.fetchAll.bind(this)}
                      tokenCSRF={this.props.tokenCSRF}/>
                    :
                    null
                  }

                </div>


                <div className={`tab-pane ${tabActiveSaveDevice} fade col`} id="statistiques" role="tabpanel">

                {this.state.dataDeviceStat && this.state.dataDeviceStat.devicesWithSavedDataList ?
                  <MMAppDeviceBackupManagement
                    dataDeviceStat = {this.state.dataDeviceStat}
                    dataBackupFile = {this.state.dataBackupFile}
                    dataUser = {this.state.dataUser}
                    createColumns = {this.createColumns.bind(this)}
                    CustomTbodyComponent = {this.CustomTbodyComponent}
                    tokenCSRF={this.props.tokenCSRF}
                  />
                  :
                  null
                }

              </div>


              <div className="tab-pane fade" id="qr_code" role="tabpanel">
                <MMAppQRCodeTab
                dataQRCodeProfil={this.state.dataQRCodeProfil}
                fetchQRCodeProfil={this.fetchQRCodeProfil.bind(this)}
                tokenCSRF={this.props.tokenCSRF}/>
              </div>




              <div className="modal fade" id="modalDetailDevices" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">{this.state.modalTitleDetailDevices}</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <ReactTable
                        getTheadThProps={this.getTheadThProps}
                        showPagination={false}
                        data={this.state.modalData}
                        TbodyComponent={CustomTbodyComponent}
                        columns={this.createColumns(this.state.modalDataName)}
                        resizable={false}
                        showPageSizeOptions={false}
                        pageSize={this.state.modalData.length}
                        style={{
                          maxHeight: "61vh",
                          minHeight: "15vh"
                        }} className=" text-center border-0 small"/>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

             </div>
           </div>

         </div>

      </div>);
  }
}
MMAppPage.propTypes = {
  intl: intlShape.isRequired
};
MMAppPage = injectIntl(MMAppPage, {withRef: true});

export default MMAppPage;
