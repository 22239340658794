import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline} from "react-icons/io";

import TablePlus from '../Components/TablePlus';

class ParametresNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailAddress: [
        "test@oui.fr", "num2@test.com"
      ],
      mail: ""
    };

    this.addMail = this.addMail.bind(this);
    this.handleMail = this.handleMail.bind(this);
  }


// ------------------------------- Action handle ------------------------------- //


  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.addMail()
    }
  };

  handleMail(event) {
    this.setState({mail: event.target.value});
  }

  addMail() {
    const tableau = this.state.mailAddress;
    for (var i = 0; i < tableau.length; i++) {
      if (tableau[i] === (this.state.mail)) {
        return
      }
    }
    if (this.state.mail) {
      tableau.push(this.state.mail)
    }
    this.setState({mailAddress: tableau});
    this.setState({mail: ""});
  }

  removeMail(index) {
    const tab = this.state.mailAddress;
    var newTab = [];

    for (var i = 0, j = 0; i < tab.length; i++) {
      if (i !== index) {
        newTab[j++] = tab[i]
      }
    }
    this.setState({mailAddress: newTab});
  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }


// ------------------------------- Render -------------------------------------- //

  render() {
    return (<div>

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.notifications.1"/></b>
      </h5>
      <hr/>

      <div className="row mt-4">
        <div className="col-6">

          <h6 className="text-info mb-2">
            <b><FormattedMessage id="parametres.notifications.paramMSG"/></b>
          </h6>

          <div className="row mb-1">
            <div className="col-6">
              <FormattedMessage id="parametres.notifications.serverSMTP"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataSMTP.host} onChange={e => this.props.handleChange(e, "host")}/>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-6">
              <FormattedMessage id="parametres.notifications.userSMTP"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataSMTP.login} onChange={e => this.props.handleChange(e, "login")}/>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-6">
              <FormattedMessage id="parametres.notifications.passwordSMTP"/>
            </div>
            <div className="col">
              <input type="password" className="form-control" value={this.props.dataSMTP.password} onChange={e => this.props.handleChange(e, "password")}/>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <FormattedMessage id="parametres.notifications.domainSMTP"/>
            </div>
            <div className="col">
              <input className="form-control" value={this.props.dataSMTP.domain} onChange={e => this.props.handleChange(e, "domain")}/>
            </div>
          </div>


          <h6 className="text-info mt-3 mb-2">
            <b><FormattedMessage id="parametres.notifications.3"/></b>
          </h6>

          <div className="custom-control custom-checkbox mb-2 mt-1">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataNotifications.newDevice} onChange={e => this.props.handleCheckboxNotifications(e, "newDevice")} id="NotifNewTerm"/>
            <label className="custom-control-label" htmlFor="NotifNewTerm">
              <FormattedMessage id="parametres.notifications.4"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-5">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataNotifications.newUser} onChange={e => this.props.handleCheckboxNotifications(e, "newUser")} id="NotifNewUser"/>
            <label className="custom-control-label" htmlFor="NotifNewUser">
              <FormattedMessage id="parametres.notifications.newUser"/>
            </label>
          </div>

        </div>


        <div className="col">

            <div className="mt-2">

                <TablePlus
                  title={this.props.intl.formatMessage({id: "parametres.notifications.2"})}
                  data={this.props.dataNotifications.emails.sort((a, b) => a.localeCompare(b))}
                  modalAddId="modalAjoutEmail"
                  deleteHandler={this.modalConfirm.bind(this)}
                  modalRemoveId="modalConfirmSuprEmail"
                  userAdministration={this.props.userAdministration}
                  maxHeight="235px"
                />

            </div>

        </div>

      </div>



      <div className="modal fade" id="modalAjoutEmail" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="parametres.notifications.6"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <input type="text" className="form-control" placeholder="example@email.com" value={this.props.mailNotifications} onChange={e => this.props.handleMailNotifications(e)} onKeyDown={this._handleKeyDown}/>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addMailNotifications}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalConfirmSuprEmail" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">
                {this.props.intl.formatMessage({id: "supprimer"})}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">
                {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn btn-danger`} data-dismiss="modal" onClick={this.props.removeMailNotifications.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }

}

ParametresNotifications.propTypes = {
  intl: intlShape.isRequired
};
ParametresNotifications = injectIntl(ParametresNotifications, {withRef: true});

export default ParametresNotifications
