  import React from 'react'
  import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

  function getDefaultGRP(x) {
    if (x.group) {
      return (x.group.groupId)
    } else {
      return ("-")
    }
  }

  function test(){
    var e = "test"
    return e
  }

  function changeToDate(int, langue){
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
  }


  class UserInformations extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
        };
      }

  render(){
  return (
    <div className="col mb-3 mt-2">

    <h6 className="text-info mb-2 text-center">
      <b>Informations</b>
    </h6>

    <div className="input-group mb-2">
      <div className="input-group-prepend ">
        <span className="input-group-text" id="basic-addon">Login :
        </span>
      </div>

      <input type="text" className="form-control mr-2" value={this.props.userInfo.login} aria-label="Username" aria-describedby="basic-addon" onChange={e => this.props.handleChange(e, "login")}/>
    </div>

    <div className="input-group mb-2">
      <div className="input-group-prepend ">
        <span className="input-group-text" id="basic-addon">Name :
        </span>
      </div>

      <input type="text" className="form-control mr-2" value={this.props.userInfo.name} aria-label="Username" aria-describedby="basic-addon" onChange={e => this.props.handleChange(e, "name")}/>
    </div>

    <p><FormattedMessage id="userDetails.info.createdDate"/>
      : {changeToDate(this.props.userInfo.createdDate, this.props.langue)}</p>

    <p>
      <label><FormattedMessage id="userDetails.info.address"/>
        :</label>

      <input className="form-control" placeholder="example@mail.com" value={this.props.userInfo.email} onChange={e => this.props.handleChange(e, "email")}/>
    </p>

    <p>
      <label htmlFor="inputState"><FormattedMessage id="userDetails.info.group"/>
        :
      </label>

      {
        getDefaultGRP(this.props.userInfo) === "-"
          ? <select id="inputState" className="form-control mr-4" defaultValue={getDefaultGRP(this.props.userInfo)} placeholder="-" onChange={e => this.props.handleGroupChange(e, "groupId")}>
              <option className="list-group-item small ">-</option>
              {(this.props.userInfo.availableGroups.map((group, index) => (<option key={index} className="list-group-item small " value={group.groupId}>{group.name}</option>)))}
            </select>

          : <select id="inputState" className="form-control mr-4" defaultValue={getDefaultGRP(this.props.userInfo)} placeholder="-" onChange={e => this.props.handleGroupChange(e, "groupId")}>
              {(this.props.userInfo.availableGroups.map((group, index) => (<option key={index} className="list-group-item small " value={group.groupId}>{group.name}</option>)))}
            </select>
      }

    </p>


    <p>
      <label><FormattedMessage id="userDetails.info.fonction"/>
        :</label>

      <input className="form-control" placeholder={this.props.intl.formatMessage({id: "userDetails.info.fonction"})} value={this.props.userInfo.fonction} onChange={e => this.props.handleChange(e, "fonction")}/>
    </p>

    <p>
      <label><FormattedMessage id="userDetails.info.service"/>
        :</label>

      <input className="form-control" placeholder={this.props.intl.formatMessage({id: "userDetails.info.service"})} value={this.props.userInfo.service} onChange={e => this.props.handleChange(e, "service")}/>
    </p>

    <div className="input-group mb-2 mt-4">
      <div className="input-group-prepend ">
        <span className="input-group-text" id="basic-addon"><FormattedMessage id="userDetails.info.MDP"/>
          :
        </span>
      </div>
      <input type="password" className="form-control mr-2" aria-label="password" defaultValue={this.props.userInfo.password} onChange={e => this.props.handleChange(e, "password")} disabled={this.props.userInfo.ldap}/>
    </div>

    <div className="input-group mb-2">
      <div className="input-group-prepend ">
        <span className="input-group-text" id="basic-addon"><FormattedMessage id="userDetails.info.confirmation"/>
        </span>
      </div>
      <input type="password" className="form-control mr-2" aria-label="password" defaultValue={this.props.userInfo.passwordConfirm} onChange={e => this.props.handleChange(e, "passwordConfirm")} disabled={this.props.userInfo.ldap}/>
    </div>

  </div>)
    }
  }


  UserInformations.propTypes = {
    intl: intlShape.isRequired
  };
  UserInformations = injectIntl(UserInformations, {withRef: true});

  export default UserInformations
