import React from 'react'
import { NavLink } from 'react-router-dom'

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// CustomProgressBar : 10 paramètres

const CustomProgressBar = ({
    bigTitle,
    percentage,
    firstTitle,
    valueFirstTitle,
    secondTitle,
    valueSecondTitle,
    thirdTitle,
    valueThirdTitle,
    invertColor,
    small
    }) => {

// définition de la couleur en fonction du pourcentage
      var color = "#c4c4c4";

      if(percentage <= 25){color = "#77e733";}
      else if(percentage <= 50){color = "#ffdc29";}
      else if(percentage <= 75){color = "#fc8d25";}
      else {color = "#fc2828";}

      if (invertColor) {
        if(percentage <= 25){color = "#fc2828";}
        else if(percentage <= 50){color = "#fc8d25";}
        else if(percentage <= 75){color = "#ffdc29";}
        else {color = "#77e733";}
      }

// Interface résultat de la fonction
      return (
        <div className="row mb-2">
          <div className="col">
              <div>
                {bigTitle ?
                  <div className="card rounded-0 mb-2">
                    <b className="text-info text-center">{bigTitle}</b>
                  </div>
                  :
                  null
                }
                <div className="row mt-2">
                  <div className="col card border-0 rounded-0 mb-2" style={small ? { maxHeight: "10vh" } : { maxHeight: "13vh" }}>
                    <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
                        textSize: percentage > 100 ? '20px' : '24px',
                        pathTransitionDuration: 0.5,
                        pathColor: color,
                        textColor: color,
                        trailColor: '#d6d6d6',
                    })}/>
                  </div>
                  {valueFirstTitle || valueSecondTitle || valueThirdTitle ?
                    <div className="col">
                      {valueFirstTitle ? <p><b className="text-info">{firstTitle} : </b>{valueFirstTitle}</p> : null}
                      {valueSecondTitle ? <p><b className="text-info">{secondTitle} : </b>{valueSecondTitle}</p> : null}
                      {valueThirdTitle ? <p><b className="text-info">{thirdTitle} : </b>{valueThirdTitle}</p> : null}
                    </div>
                    :
                    null
                  }

                </div>
              </div>


          </div>
        </div>
    )
};


export default CustomProgressBar
