import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom'
import {FaUserPlus} from "react-icons/fa";


class FilesToolBar extends React.Component {


// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div className="form-inline">

          <input className="form-control mr-sm-2" type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search"
          value={this.props.researchValue}
          onChange={this.props.rechercheHandle}
          style={{height: "35px"}}/>

          {this.props.isFetching}

      </div>
    )
  }
}

FilesToolBar.propTypes = {
  intl: intlShape.isRequired
};
FilesToolBar = injectIntl(FilesToolBar,
                        {withRef: true});

export default FilesToolBar
