import React from 'react'
import ReactTable from 'react-table'

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {GoDeviceMobile} from "react-icons/go";
import {MdLaptopChromebook} from "react-icons/md";

import { MenuProvider } from 'react-contexify';

const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

  class UserTerminaux extends React.Component {
     constructor(props) {
       super(props);

       this.state = {
         mobilePhone: " ",
         deviceId: "ID",
         name: "Appareils",
         selected: null,
         hit: [],
         configMDMDevices: "off"
       };

     }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

     componentDidMount() {
         var newTableau = [];
         this.props.devices.map((x, index) => {
           var item = new Object();

           item.mobilePhone = x.mobilePhone;
           item.deviceId = x.deviceId;
           item.name = x.name;
           if (x.model) {
             item.model = x.model;
           }


           newTableau.push(item);
         }, this.setState({tableau: newTableau}),)
     }


// ------------------------------- Render -------------------------------------- //

     render(){


// ------------------------------- Setting of the table ------------------------ //

         const getTheadThProps = (table, row, col) => {
       const sortedCol = find(table.sorted, { id: col.id });
       const boxShadow =
         sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
       return {
         style: {
           boxShadow,
         },
       };
     };

         const columns = [{

           Header: () => (
           <span>
             <b>  </b>
           </span>
         ),
           accessor: 'mobilePhone',
           minWidth: 80,
           maxWidth: 120,
           Cell: row => (
               <div>
                 {row.value === true ?
                   <span style={{
                       color: "rgb(44,165,255)"
                     }}>
                     <GoDeviceMobile size={28}/>
                   </span>
                 : <span style={{
                     color: "rgb(44,165,255)"
                   }}>
                   <MdLaptopChromebook size={28}/>
                 </span>}

               </div>
             )

         },{
           Header: () => (
           <span>
             <b> Name </b>
           </span>
         ),
           accessor: 'name',
           Cell: row => (
               <div style={{fontSize:"12px"}}>
                 {row.original.model ?
                   <div>{row.value} - <b style={{color: "rgb(92,92,92)"}}>{" ( "+row.original.model+" )"}</b></div>
                   :
                   row.value
                 }

               </div>
             )

         }]

  return (<div className="col mt-3 ml-2 mb-2">
    <div className="card bg-light mt-3 mb-2">
      <div className="card-header rounded-top text-center bg-light">
        <h6 className="text-primary">
          <b><FormattedMessage id="userDetails.terminals.listDevices"/>
          </b>
        </h6>
      </div>

      {this.state.tableau ?
        <MenuProvider id="menu_userDevicesContextMenu" >
        <ReactTable

          getTheadThProps={getTheadThProps}


      getTrProps={(state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
        return {
          onClick: (e) => {
            this.setState({selected: rowInfo.index});
            this.props.contextMenu(rowInfo.original);
          },
          onContextMenu: (e) => {
            this.setState({selected: rowInfo.index});
            this.props.contextMenu(rowInfo.original);
          },
          style: {
            background: rowInfo.index === this.state.selected ? '#e4eef4' : null,
            color: rowInfo.index === this.state.selected ? 'black' : null
          }
        }
      }else{
        return {}
      }
    }}

          showPagination={false}
          data={this.state.tableau}
          noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={false}
          pageSize={this.state.tableau.length}
              style={{
                maxHeight: "30vh",
                minHeight: "15vh",
                width: 'auto'
              }}
                className=" text-center border-0 small -clickable"
              />
            </MenuProvider>

      : <div/>}

    </div>

    <div>
      <h6 className="text-info mt-3 mb-2">
        <b><FormattedMessage id="userDetails.terminals.ContextMenu.multiTerminals"/></b>
      </h6>
      <div className="custom-control custom-checkbox ml-1">
          <input type="checkbox" className="custom-control-input" id="checkboxUserMultiDevices" checked={this.props.userInfo.moreThanOneDevice} onChange={e => this.props.handleChange(e, "moreThanOneDevice")}/>
          <label className="custom-control-label" htmlFor="checkboxUserMultiDevices"><FormattedMessage id="userDetails.terminals.ContextMenu.multiTerminalsAllow"/></label>
      </div>
    </div>

    {
      this.props.data.enableLocation === true ?

    <div>
      <h6 className="text-info mt-3 mb-2">
        <b><FormattedMessage id="userDetails.terminals.ContextMenu.locationTitle"/></b>
      </h6>
      <div className="custom-control custom-checkbox ml-1">
          <input type="checkbox" className="custom-control-input" id="checkboxUserDisableLocation"
          checked={this.props.userInfo.localisation === false ? true : false} onChange={e => this.props.handleChange(e, "localisation")}/>
          <label className="custom-control-label" htmlFor="checkboxUserDisableLocation">
            <FormattedMessage id="userDetails.terminals.ContextMenu.locationDisable"/>
          </label>
      </div>
    </div>

    :

    <div>
      <h6 className="text-info mt-3 mb-2">
        <b><FormattedMessage id="userDetails.terminals.ContextMenu.locationTitle"/></b>
      </h6>
      <div className="custom-control custom-checkbox ml-1">
          <input type="checkbox" className="custom-control-input" id="checkboxUserEnableLocation"
          checked={this.props.userInfo.localisation === true ? true : false} onChange={e => this.props.handleChange(e, "localisation")}/>
          <label className="custom-control-label" htmlFor="checkboxUserEnableLocation">
            <FormattedMessage id="userDetails.terminals.ContextMenu.locationEnable"/>
          </label>
      </div>
    </div>

    }

    <div>
      <h6 className="text-info mt-3 mb-2">
        <b><FormattedMessage id="userDetails.terminals.ContextMenu.useServerConfigTitle"/></b>
      </h6>
      <div className="custom-control custom-checkbox ml-1">
          <input type="checkbox" className="custom-control-input" id="useServerConfig"
          checked={this.props.userInfo.useServerConfig === true ? true : false} onChange={e => this.props.handleChange(e, "useServerConfig")}/>
          <label className="custom-control-label" htmlFor="useServerConfig">
            <FormattedMessage id="userDetails.terminals.ContextMenu.useServerConfig"/>
          </label>
      </div>
    </div>

    {this.props.userInfo.useServerConfig === true ?
      null
      :

      <div>
        <div>
          <h6 className="text-info mt-3 mb-2">
            <b><FormattedMessage id="userDetails.terminals.ContextMenu.saveDeviceDataTitle"/></b>
          </h6>
          <div className="custom-control custom-checkbox ml-1">
              <input type="checkbox" className="custom-control-input" id="checkboxUserEnableSaveDeviceData"
              checked={this.props.userInfo.saveDeviceData === true ? true : false} onChange={e => this.props.handleChange(e, "saveDeviceData")}/>
              <label className="custom-control-label" htmlFor="checkboxUserEnableSaveDeviceData">
                <FormattedMessage id="userDetails.terminals.ContextMenu.saveDeviceDataEnable"/>
              </label>
          </div>
        </div>

        <div className="mt-2">
          <FormattedMessage id="userDetails.terminals.DataChoice"/>
        </div>
        <div className="col mt-1">
          <div className="row">

            <div className="custom-control custom-checkbox ml-2">
                <input type="checkbox" className="custom-control-input" id="checkboxSaveDeviceImage"
                  checked={this.props.userInfo.saveDeviceImage === true ? true:false} onChange={e => this.props.handleChange(e, "saveDeviceImage")}
                   disabled={!this.props.userInfo.saveDeviceData}/>
                <label className="custom-control-label" htmlFor="checkboxSaveDeviceImage">
                  <FormattedMessage id="userDetails.terminals.DataChoice.picture"/>
                </label>
            </div>
            <div className="custom-control custom-checkbox ml-2">
                <input type="checkbox" className="custom-control-input" id="checkboxSaveDeviceFile"
                  checked={this.props.userInfo.saveDeviceFile === true ? true:false} onChange={e => this.props.handleChange(e, "saveDeviceFile")}
                disabled={!this.props.userInfo.saveDeviceData}/>
                <label className="custom-control-label" htmlFor="checkboxSaveDeviceFile">
                  <FormattedMessage id="userDetails.terminals.DataChoice.file"/>
                </label>
            </div>
            <div className="custom-control custom-checkbox ml-2">
                <input type="checkbox" className="custom-control-input" id="checkboxSaveDeviceSMS"
                  checked={this.props.userInfo.saveDeviceSMS === true ? true:false} onChange={e => this.props.handleChange(e, "saveDeviceSMS")}
                disabled={!this.props.userInfo.saveDeviceData}/>
                <label className="custom-control-label" htmlFor="checkboxSaveDeviceSMS">
                  <FormattedMessage id="userDetails.terminals.DataChoice.sms"/>
                </label>
            </div>
            <div className="custom-control custom-checkbox ml-2">
                <input type="checkbox" className="custom-control-input" id="checkboxSaveDeviceContacts"
                  checked={this.props.userInfo.saveDeviceContacts === true ? true:false} onChange={e => this.props.handleChange(e, "saveDeviceContacts")}
                disabled={!this.props.userInfo.saveDeviceData}/>
                <label className="custom-control-label" htmlFor="checkboxSaveDeviceContacts">
                  <FormattedMessage id="userDetails.terminals.DataChoice.contacts"/>
                </label>
            </div>
          </div>
        </div>

        <div>
          <h6 className="text-info mt-3 mb-2">
            <b><FormattedMessage id="userDetails.terminals.ContextMenu.deleteDataOnceSavedTitle"/></b>
          </h6>
          <div className="custom-control custom-checkbox ml-1">
              <input type="checkbox" className="custom-control-input" id="checkboxUserEnableDeleteData"
              checked={this.props.userInfo.deleteDataOnceSaved === true ? true : false} onChange={e => this.props.handleChange(e, "deleteDataOnceSaved")}/>
              <label className="custom-control-label" htmlFor="checkboxUserEnableDeleteData">
                <FormattedMessage id="userDetails.terminals.ContextMenu.deleteDeviceDataEnable"/>
              </label>
          </div>
        </div>

        <div>
          <div className="custom-control custom-checkbox ml-1">
              <input type="checkbox" className="custom-control-input" id="checkboxUserEnableAutoStorageManagement"
              checked={this.props.userInfo.autoStorageManagement === true ? true : false} onChange={e => this.props.handleChange(e, "autoStorageManagement")}/>
              <label className="custom-control-label" htmlFor="checkboxUserEnableAutoStorageManagement">
                <FormattedMessage id="userDetails.terminals.ContextMenu.autoStorageManagement"/>
              </label>
          </div>
        </div>
    </div>


    }


    <div className="modal fade" id={"ModalGraph"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-graph modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title text-info" id="exampleModalLabel">
              <b>
                <FormattedMessage id="userDetails.terminals.graph"/> : {this.props.modalName}
              </b>
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center small">

            <div className="d-flex justify-content-center">
              <iframe className="embed-responsive-item border-0" src={this.props.modalAddress} width="800" height="470"></iframe>
            </div>

          </div>
          <div className="modal-footer justify-content-center d-flex">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
          </div>
        </div>
      </div>
    </div>

  </div>)
}
};

UserTerminaux.propTypes = {
  intl: intlShape.isRequired
};
UserTerminaux = injectIntl(UserTerminaux, {withRef: true});

export default UserTerminaux
