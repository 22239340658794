import React from 'react'
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux, varGeneral } from '../variables';

import {
  MdPhonelinkLock,
  MdPhonelinkErase,
  MdWebAsset,
  MdPhonelink,
  MdSettingsApplications,
  MdPhonelinkRing,
  MdReplay,
  MdInsertDriveFile,
  MdPhonelinkSetup
} from "react-icons/md";

import {
  IoMdLock,
  IoMdUnlock,
  IoMdCheckmark,
  IoMdRefresh,
  IoIosMail,
  IoMdSettings
} from "react-icons/io";

import {GoPlus} from "react-icons/go";

var contextMenu = {
  "ignoreOS": false,
  "controlOS": false,
  "terminalConf": false,
  "terminalExceptionApp": false,
  "terminalNotExceptionApp": false,
  "newTerm": false,
  "redeployment": false,
  "allow": false,
  "enable": false,
  "disable": false,
  "resetTerm": false,
  "cancelResetTerm": false,
  "stopResetTerm": false,
  "resetMail": false,
  "delete": true,
  "graphe": false,
  "detailsApp": false,
  "mmAppliRequired": false,
  "errorDoubleResult": false,
  "cleanDoubleResultError": false,
  "modifyDevice": false,
  "createDevice": false,

  "mmFile": false
}

const iconSize = 22;

function validate(name, model) {
  // true means invalid, so our conditions got reversed
  return {
    name: name.length === 0,
    model: model.length === 0
  };
}


class DevicesContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: {
        name: false,
        model: false
      },
      modalBorderColor: "",
      "name": "",
      "model": ""
    };
  }


  checkData() {

    const hit = this.props.hit;

      if (hit.uptodate === 0 && hit.enable === true) {
        contextMenu.ignoreOS = true;
      } else {
        contextMenu.ignoreOS = false;
      }
      if (hit.uptodate === 2 && hit.enable === true) {
        contextMenu.controlOS = true;
      } else {
        contextMenu.controlOS = false;
      }
      if (hit.enable === false) {
        contextMenu.enable = true;
      } else {
        contextMenu.enable = false;
      }

      if(hit.post !== undefined){
        if(hit.post.exception === true){

          contextMenu.terminalExceptionApp = false;
          contextMenu.terminalNotExceptionApp = true;

        } else {

          contextMenu.terminalExceptionApp = true;
          contextMenu.terminalNotExceptionApp = false;

        }
      } else {
        contextMenu.terminalExceptionApp = false;
        contextMenu.terminalNotExceptionApp = false;
      }


      if (hit.enable === true) {
        if(hit.compliant === false){contextMenu.terminalConf = true;}
        else { contextMenu.terminalConf = false;}
        contextMenu.redeployment = true;
        contextMenu.disable = true;

        switch (hit.etat) {
          case "default":

            contextMenu.resetTerm = true;
            contextMenu.cancelResetTerm = false;
            contextMenu.stopResetTerm = false;

            break;

          case "needReset":

            contextMenu.resetTerm = false;
            contextMenu.cancelResetTerm = true;
            contextMenu.stopResetTerm = false;

            break;

          case "asBeenReset":

            contextMenu.resetTerm = false;
            contextMenu.cancelResetTerm = false;
            contextMenu.stopResetTerm = true;

            break;

          case "disabled":

            contextMenu.resetTerm = false;
            contextMenu.cancelResetTerm = false;
            contextMenu.stopResetTerm = false;

            break;
          default:

        }

        contextMenu.resetMail = true;
      } else {
        contextMenu.terminalConf = false;
        contextMenu.redeployment = false;
        contextMenu.disable = false;
        contextMenu.resetTerm = false;
        contextMenu.cancelResetTerm = false;
        contextMenu.stopResetTerm = false;
        contextMenu.resetMail = false;
      }

      if (hit.state === "NEW") {
        contextMenu.newTerm = true;
      } else {
        contextMenu.newTerm = false;
      }


    if (hit.userslength > 0 && hit.users) {
      contextMenu.graphe = true;
    } else {
      contextMenu.graphe = false;
    }

    if (this.props.userAdministration === "ADMINISTRATOR"){
      contextMenu.delete = true;
      if (hit.name && hit.presentInMpro) {
        contextMenu.modifyDevice = true;
      } else {
        contextMenu.modifyDevice = false;
      }

      if (!hit.presentInMpro && hit.presentInMMapp) {
        contextMenu.createDevice = true;
      } else {
        contextMenu.createDevice = false;
      }

    } else {
      contextMenu.delete = false;
      contextMenu.resetTerm = false;
      contextMenu.modifyDevice = false;
      contextMenu.createDevice = false;
    }

    if (this.props.errorDoubleResult) {
      contextMenu.detailsApp = false;
      contextMenu.mmFile = false;
      contextMenu.mmAppliRequired = false;
      contextMenu.errorDoubleResult = true;
      contextMenu.cleanDoubleResultError = true;

    }else if (this.props.showDET && this.props.getDetailsContextMenu(hit)) {
      contextMenu.detailsApp = true;
      contextMenu.mmFile = false;
      contextMenu.mmAppliRequired = false;
      contextMenu.errorDoubleResult = false;
      contextMenu.cleanDoubleResultError = false;

    } else if (hit.appUptodate === 2){
      contextMenu.detailsApp = false;
      contextMenu.mmFile = false;
      contextMenu.mmAppliRequired = true;
      contextMenu.errorDoubleResult = false;
      contextMenu.cleanDoubleResultError = false;

    } else {
      contextMenu.detailsApp = false;
      contextMenu.mmFile = false;
      contextMenu.mmAppliRequired = false;
      contextMenu.errorDoubleResult = false;
      contextMenu.cleanDoubleResultError = false;

    }

  }


// ------------------------------- Action handle ------------------------------- //

  handleChange= (event, field) => {
    this.setState({ [field]: event.target.value });
  }

  handleBlur = field => evt => {
      this.setState({
        touched: { ...this.state.touched, [field]: true }
      });
    };

  showModal(type, color, id){
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenu').modal('show')
  }

  modifyDeviceModal(x){
    document.getElementById('ModalModifyDevice.name').value = this.props.hit.name;
    document.getElementById('ModalModifyDevice.os').value = this.props.hit.os;
    document.getElementById('ModalModifyDevice.imei').value = this.props.hit.imei;
    document.getElementById('ModalModifyDevice.model').value = this.props.hit.model;
    document.getElementById('ModalModifyDevice.operator').value = this.props.hit.operator;
    this.setState({ name: this.props.hit.name, model: this.props.hit.model});
    $('#ModalModifyDevice').modal('show');
  }

  redirectToNewDevice(){
    window.location.href = '/Computers';
  }

  sendModifDevice(data){
    fetch(varTerminaux.api + this.props.hit.deviceId, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        var x = response;
        this.props.refresh()
       })
  }

  sendModifiedDevice(data){
    console.log(data);
    fetch(varTerminaux.apiDeviceUpdate + this.props.hit.deviceId, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        var x = response;
        this.props.refresh()
       })
  }


  sendDeviceRequest(data){

    fetch(varTerminaux.apiDeviceReset + data + "/" + this.props.hit.deviceId, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: ""
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        var x = response;
        this.props.refresh()
      })
  }

  sendDeviceRedepoyement(data){

    fetch(varTerminaux.apiDeviceRedeployment + this.props.hit.deviceId + "/" + this.props.hit.users[0].login, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: ""
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        var x = response;
        this.props.refresh()
      })
  }


  sendDeviceDisable(id){

    var bool = false;
    if (id === "disable") {
      bool = true;
    }
    console.log("test");

    fetch(varTerminaux.apiDeviceDisable + this.props.hit.deviceId + "/" + bool, {
        method: 'PUT',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: ""
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")
        {
          console.log(response);
        }
        var x = response;
        this.props.refresh()
      })
  }


  actionMenu(id, deviceId){
  const post = this.props.hit.post;

    switch (id) {
      case "ignoreOS":
      case "controlOS":

        var data = {
            "noRestriction": !this.props.hit.noRestriction
          };

            this.sendModifDevice(data)

        break;

      case "enable":
      case "disable":

      var data = {
          "enable": !this.props.hit.enable
        };

          //this.sendModifDevice(data)
          this.sendDeviceDisable(id)


        break;

      case "terminalConf":

      var data = {
          "compliant": true
        };

          this.sendModifDevice(data)

        break;

      case "newTerm":

      var data = {
          "state": "AUTHORIZED"
        };

          this.sendModifDevice(data)

        break;

      case "terminalExceptionApp":
                var dataConf = {
                  "exception": true
                }

                fetch(varTerminaux.apiDevicesDetails + post.model + "/" + post.email, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': this.props.tokenCSRF
                  },
                    body: JSON.stringify(dataConf)
                  })
                  .then(response => {
                    if(process.env.NODE_ENV == "development")
                    {
                      console.log(response);
                    }
                    var x = response;
                    this.props.refresh()
                   })


        break;

      case "terminalNotExceptionApp":
                  var dataNotConf = {
                    "exception": false
                  }

                  fetch(varTerminaux.apiDevicesDetails + post.model + "/" + post.email, {
                      method: 'POST',
                      headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'X-XSRF-TOKEN': this.props.tokenCSRF
                    },
                      body: JSON.stringify(dataNotConf)
                    })
                    .then(response => {
                      if(process.env.NODE_ENV == "development")
                      {
                        console.log(response);
                      }
                      var x = response;
                      this.props.refresh()
                     })

          break;


        case "cleanAccount":
        var data = "cleanAccount"

            //this.sendDeviceRequest(data)
            this.sendDeviceRedepoyement();

          break;

        case "resetTerm":
        var data = "reset"

            this.sendDeviceRequest(data)

          break;

        case "cancelResetTerm":
        var data = "cancelReset"

            this.sendDeviceRequest(data)

          break;

        case "stopResetTerm":
        var data = "stopReset"

            this.sendDeviceRequest(data)

          break;

        case "cleanInbox":
        var data = "cleanInbox"

            this.sendDeviceRequest(data)

          break;

        case "cleanDoubleResultError":
            var data = this.props.hit;

            fetch(varTerminaux.apiDevicesDetails + this.props.hit.model + "/" + this.props.hit.email ,{
                method: 'DELETE',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
                body: ""
              })
              .then(response => {
                var x = response;
                this.props.refresh()
                if(response.status !== 200){
                 return response.json().then(responseError => {
                   console.log(responseError);
                 })
               }
             })

          break;

        case "modifyDevice":

          var data = {
            "name": document.getElementById('ModalModifyDevice.name').value,
            "imei": document.getElementById('ModalModifyDevice.imei').value,
            "os": document.getElementById('ModalModifyDevice.os').value,
            "model": document.getElementById('ModalModifyDevice.model').value,
            "operator": document.getElementById('ModalModifyDevice.operator').value,
          };

          this.sendModifiedDevice(data)

        break;

        case "delete":
        var data = "delete"


          fetch(varTerminaux.api + this.props.hit.deviceId, {
              method: 'DELETE',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: ""
            })
            .then(response => {
              var x = response;
              this.props.refresh()
              if(response.status !== 200){
               return response.json().then(responseError => {
                 console.log(responseError);
               })
             }
           })


        if (post) {
          fetch(varTerminaux.apiDevicesDetails + post.model + "/" + post.email, {
              method: 'DELETE',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: ""
            })
            .then(response => {
              var x = response;
              if(response.status !== 200){
               return response.json().then(responseError => {
                 console.log(responseError);
               })
             }
           })
        }
          break;

      default:
    }
  }


// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "ignoreOS":
          return (<Item onClick={this.showModal.bind(this, name, "warning", "ignoreOS")}>
            <span style={{
                color: "rgb(244,191,34)"
              }}>
              <IoMdLock size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "controlOS":
          return (<Item onClick={this.showModal.bind(this, name, "info", "controlOS")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <IoMdUnlock size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "terminalConf":
          return (<Item onClick={this.showModal.bind(this, name, "secondary", "terminalConf")}>
            <span style={{
                color: "rgb(133,138,140)"
              }}>
              <IoMdSettings size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "terminalExceptionApp":
          return (<Item onClick={this.showModal.bind(this, name, "info", "terminalExceptionApp")}>
            <span style={{
                color: "rgb(244,191,34)"
              }}>
              <MdSettingsApplications size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

          case "terminalNotExceptionApp":
            return (<Item onClick={this.showModal.bind(this, name, "warning", "terminalNotExceptionApp")}>
              <span style={{
                  color: "rgb(70,156,212)"
                }}>
                <MdSettingsApplications size={iconSize}/>
              </span>
                {name}
            </Item>)
            break;

        case "redeployment":
          return (<Item onClick={this.showModal.bind(this, name, "info", "cleanAccount")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelinkRing size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "newTerm":
          return (<Item onClick={this.showModal.bind(this, name, "info", "newTerm")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "allow":
          return (<Item onClick={this.showModal.bind(this, name, "success", "allow")}>
              {name}</Item>)
            break;
        case "enable":
          return (<Item onClick={this.showModal.bind(this, name, "success", "enable")}>
            <span style={{
                color: "rgb(57,200,59)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "disable":
          return (<Item onClick={this.showModal.bind(this, name, "secondary", "disable")}>
            <span style={{
                color: "rgb(133,138,140)"
              }}>
              <MdPhonelinkLock size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "resetTerm":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "resetTerm")}>
            <span style={{
                color: "rgb(238,83,83)"
              }}>
              <MdReplay size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "cancelResetTerm":
          return (<Item onClick={this.showModal.bind(this, name, "warning", "cancelResetTerm")}>
            <span style={{
                color: "rgb(244,191,34)"
              }}>
              <MdReplay size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "stopResetTerm":
          return (<Item onClick={this.showModal.bind(this, name, "warning", "stopResetTerm")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdReplay size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "resetMail":
          return (<Item onClick={this.showModal.bind(this, name, "warning", "cleanInbox")}>
            <span style={{
                color: "rgb(244,191,34)"
              }}>
              <IoIosMail size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "delete":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "delete")}>
            <span style={{
                color: "rgb(238,83,83)"
              }}>
              <MdPhonelinkErase size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "graphe":
          return (<Item onClick={this.props.mobalGraph.bind(this, this.props.hit)}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdWebAsset size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "detailsApp":
          return (<Item onClick={this.props.devicesDetailsModal.bind(this, this.props.hit, this.props.devicesDetails)}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelink size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "modifyDevice":
          return (<Item onClick={this.modifyDeviceModal.bind(this, this.props.hit)}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelinkSetup size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "createDevice":
          return (
            <NavLink to={{
                pathname: "/Devices/NewDevice",
                data: this.props.hit
              }}
              style={{ textDecoration: 'none' }}>
            <Item>
            <div title={this.props.intl.formatMessage({id: "terminaux.dropdown.10.tooltip"})}>
              <span style={{
                  color: "rgb(70,156,212)"
                }}>
                <MdPhonelink size={iconSize}/>
              </span>
                {name}
              </div>
              </Item>
            </NavLink>)
          break;

        case "mmAppliRequired":
          return (
            <Item disabled >
              <div title={this.props.intl.formatMessage({id: "terminaux.dropdown.10.tooltip"})}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelink size={iconSize}/>
            </span>
              {name}
            </div>
          </Item>)
          break;

        case "errorDoubleResult":
          return (
            <Item disabled >
              <div title={this.props.intl.formatMessage({id: "terminaux.dropdown.10.error.tooltip"})}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdPhonelink size={iconSize}/>
            </span>
              {name}
            </div>
          </Item>)
          break;

        case "cleanDoubleResultError":
          return (<Item onClick={this.showModal.bind(this, name, "warning", "cleanDoubleResultError")}>
            <div title={this.props.intl.formatMessage({id: "terminaux.dropdown.cleanDoubleResultError.tooltip"})}>
            <span style={{
                color: "rgb(0,0,0)"
              }}>
              <MdPhonelink size={iconSize}/>
            </span>
              {name}
            </div>
          </Item>)
          break;

        case "mmFile":
          return (<Item onClick={this.showModal.bind(this, name, "info", "mmFile")}>
            <span style={{
                color: "rgb(70,156,212)"
              }}>
              <MdInsertDriveFile size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        default:
          break;
      }
    }
  }

  shouldSeparator(state) {
    if (state === true)
      return (<Separator/>)
  }

  sendMMAppNotification(data){
    fetch(varGeneral.apiMMAppErrorNotification, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")console.log(response);
       })
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    const errors = validate(
      this.state.name,
      this.state.model);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    const shouldMarkError = field => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    {
      this.checkData()
    }

    if (this.props.hit != "empty")
      return (<div>

        <Menu id='menu_devicesContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)'
          }}>

          {this.testMenu("detailsApp", contextMenu.detailsApp, this.props.intl.formatMessage({id: "terminaux.dropdown.10"}))}
          {this.testMenu("mmAppliRequired", contextMenu.mmAppliRequired, this.props.intl.formatMessage({id: "terminaux.dropdown.10"}))}
          {this.testMenu("errorDoubleResult", contextMenu.errorDoubleResult, this.props.intl.formatMessage({id: "terminaux.dropdown.10.error"}))}
          {this.testMenu("graphe", contextMenu.graphe, this.props.intl.formatMessage({id: "terminaux.dropdown.9"}))}
          {this.testMenu("modifyDevice", contextMenu.modifyDevice, this.props.intl.formatMessage({id: "terminaux.dropdown.modifyDevice"}))}
          {this.testMenu("createDevice", contextMenu.createDevice, this.props.intl.formatMessage({id: "terminaux.dropdown.createDevice"}))}

          {this.shouldSeparator(contextMenu.graphe === true || contextMenu.detailsApp === true || contextMenu.mmAppliRequired === true || contextMenu.errorDoubleResult === true || contextMenu.modifyDevice === true || contextMenu.createDevice === true)}

          {this.testMenu("redeployment", contextMenu.redeployment, this.props.intl.formatMessage({id: "userDetails.terminals.ContextMenu.2"}))}
          {this.testMenu("disable", contextMenu.disable, this.props.intl.formatMessage({id: "terminaux.dropdown.2"}))}
          {this.testMenu("newTerm", contextMenu.newTerm, this.props.intl.formatMessage({id: "terminaux.dropdown.3"}))}
          {this.testMenu("resetTerm", contextMenu.resetTerm, this.props.intl.formatMessage({id: "terminaux.dropdown.resetTerm"}))}
          {this.testMenu("cancelResetTerm", contextMenu.cancelResetTerm, this.props.intl.formatMessage({id: "terminaux.dropdown.cancelResetTerm"}))}
          {this.testMenu("stopResetTerm", contextMenu.stopResetTerm, this.props.intl.formatMessage({id: "terminaux.dropdown.stopResetTerm"}))}
          {this.testMenu("resetMail", contextMenu.resetMail, this.props.intl.formatMessage({id: "terminaux.dropdown.5"}))}
          {this.testMenu("enable", contextMenu.enable, this.props.intl.formatMessage({id: "terminaux.dropdown.6"}))}
          {this.testMenu("delete", contextMenu.delete, this.props.intl.formatMessage({id: "terminaux.dropdown.7"}))}
          {this.testMenu("mmFile", contextMenu.mmFile, this.props.intl.formatMessage({id: "terminaux.dropdown.mmFile"}))}

          {this.shouldSeparator(contextMenu.redeployment === true || contextMenu.disable === true || contextMenu.newTerm === true ||
          contextMenu.resetTerm === true || contextMenu.cancelResetTerm === true || contextMenu.stopResetTerm === true || contextMenu.resetMail === true ||
        contextMenu.enable === true || contextMenu.delete === true || contextMenu.mmFile === true )}

          {this.props.userAdministration === "ADMINISTRATOR" ?
          <Submenu label={"  " + this.props.intl.formatMessage({id: "terminaux.dropdown.8"})}>
            <Item onClick={this.props.contextIncidentHandle.bind(this, this.props.hit, "NONE")}>
              <span className="col-1" style={{
                  color: "rgb(51,201,38)"
                }}>
                {
                  this.props.hit.incident === "NONE"
                    ? <IoMdCheckmark size={iconSize}/>
                    : <div/>
                }
              </span>
              <a className="col" style={{
                  minHeight: "24px"
                }}>{this.props.intl.formatMessage({id: "terminaux.incident.default"})}</a>
            </Item>
            <Item onClick={this.props.contextIncidentHandle.bind(this, this.props.hit, "STOLEN")}>
              <span className="col-1" style={{
                  color: "rgb(51,201,38)"
                }}>
                {
                  this.props.hit.incident === "STOLEN"
                    ? <IoMdCheckmark size={iconSize}/>
                    : <div/>
                }
              </span>
              <a className="col" style={{
                  minHeight: "24px"
                }}>{this.props.intl.formatMessage({id: "terminaux.incident.stolen"})}</a>
            </Item>
            <Item onClick={this.props.contextIncidentHandle.bind(this, this.props.hit, "LOST")}>
              <span className="col-1" style={{
                  color: "rgb(51,201,38)"
                }}>
                {
                  this.props.hit.incident === "LOST"
                    ? <IoMdCheckmark size={iconSize}/>
                    : <div/>
                }
              </span>
              <a className="col" style={{
                  minHeight: "24px"
                }}>{this.props.intl.formatMessage({id: "terminaux.incident.lost"})}</a>
            </Item>
            <Item onClick={this.props.contextIncidentHandle.bind(this, this.props.hit, "BROKEN")}>
              <span className="col-1" style={{
                  color: "rgb(51,201,38)"
                }}>
                {
                  this.props.hit.incident === "BROKEN"
                    ? <IoMdCheckmark size={iconSize}/>
                    : <div/>
                }
              </span>
              <a className="col" style={{
                  minHeight: "24px"
                }}>{this.props.intl.formatMessage({id: "terminaux.incident.broken"})}</a>
            </Item>
            <Item onClick={this.props.contextIncidentHandle.bind(this, this.props.hit, "AFTER_SALES_SERVICE")}>
              <span className="col-1" style={{
                  color: "rgb(51,201,38)"
                }}>
                {
                  this.props.hit.incident === "AFTER_SALES_SERVICE"
                    ? <IoMdCheckmark size={iconSize}/>
                    : <div/>
                }
              </span>
              <a className="col" style={{
                  minHeight: "24px"
                }}>{this.props.intl.formatMessage({id: "terminaux.incident.sav"})}</a>
            </Item>
          </Submenu>

          : null
          }

          {this.testMenu("cleanDoubleResultError", contextMenu.cleanDoubleResultError, this.props.intl.formatMessage({id: "terminaux.dropdown.cleanDoubleResultError"}))}
          {this.testMenu("ignoreOS", contextMenu.ignoreOS, this.props.intl.formatMessage({id: "terminaux.dropdown.11"}))}
          {this.testMenu("controlOS", contextMenu.controlOS, this.props.intl.formatMessage({id: "terminaux.dropdown.12"}))}
          {this.testMenu("terminalConf", contextMenu.terminalConf, this.props.intl.formatMessage({id: "terminaux.dropdown.1"}))}
          {this.testMenu("terminalNotExceptionApp", contextMenu.terminalNotExceptionApp, this.props.intl.formatMessage({id: "terminaux.dropdown.13"}))}
          {this.testMenu("terminalExceptionApp", contextMenu.terminalExceptionApp, this.props.intl.formatMessage({id: "terminaux.dropdown.14"}))}


        </Menu>

        <div className="modal fade" id={"ModalGraph"} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-graph modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title text-info" id="exampleModalLabel">
                  <b>
                    {this.props.intl.formatMessage({id: "terminaux.dropdown.9"})}
                    : {this.props.modalName}
                  </b>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center small">

                <div className="d-flex justify-content-center">
                  <iframe className="embed-responsive-item border-0" src={this.props.modalAddress} width="800" height="470"></iframe>
                </div>

              </div>
              <div className="modal-footer justify-content-center d-flex">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modalConfirmActionContextMenu" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.id, this.props.devicesDetails.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="ModalModifyDevice" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-warning rounded-0`}>
              <div className="modal-header">
                <h5 className="modal-title">
                  Modify Device
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <div className="mt-3 container mb-2">
                        <div className="row mb-2 mt-3">
                            <div className="col">
                                <FormattedMessage id="newDevice.name"/>
                            </div>
                            <div className="col">
                                <input
                                  className={`form-control ${shouldMarkError("name") ? "error" : ""}`}
                                  id="ModalModifyDevice.name"
                                  onChange={e => this.handleChange(e, "name")}
                                  onBlur={this.handleBlur("name")}/>
                            </div>
                        </div>
                        <div className="row mb-2 mt-3">
                            <div className="col">
                                <FormattedMessage id="newDevice.os"/>
                            </div>
                            <div className="col">
                                <input
                                  className={`form-control`}
                                  id="ModalModifyDevice.os"/>
                            </div>
                        </div>
                        <div className="row mb-2 mt-3">
                            <div className="col">
                                <FormattedMessage id="newDevice.imei"/>
                            </div>
                            <div className="col">
                                <input
                                  className={`form-control`}
                                  id="ModalModifyDevice.imei"/>
                            </div>
                        </div>
                        <div className="row mb-2 mt-3">
                            <div className="col">
                                <FormattedMessage id="newDevice.model"/>
                            </div>
                            <div className="col">
                                <input
                                  className={`form-control ${shouldMarkError("model") ? "error" : ""}`}
                                  id="ModalModifyDevice.model"
                                  onChange={e => this.handleChange(e, "model")}
                                  onBlur={this.handleBlur("model")}/>
                            </div>
                        </div>
                        <div className="row mb-2 mt-3">
                            <div className="col">
                                <FormattedMessage id="newDevice.mobileOperator"/>
                            </div>
                            <div className="col">
                                <input
                                  className={`form-control`}
                                  id="ModalModifyDevice.operator"/>
                            </div>
                        </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-warning`} data-dismiss="modal"
                  disabled={isDisabled}
                  onClick={this.actionMenu.bind(this, "modifyDevice", this.props.hit)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

DevicesContextMenu.propTypes = {
  intl: intlShape.isRequired
};
DevicesContextMenu = injectIntl(DevicesContextMenu, {withRef: true});

export default DevicesContextMenu
