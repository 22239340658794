import React from 'react'
import ReactTable from 'react-table'
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import classNames from "classnames";
import { MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {MdLens, MdError, MdCheckCircle, MdCancel, MdLocationOn, MdSimCard, MdSimCardAlert, MdNoSim} from "react-icons/md";
import '../custom.css';

import { GoDeviceMobile } from "react-icons/go";
import { IoMdCheckmark, IoMdClock } from "react-icons/io";


const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);


function createProgessBar(value){
  if (value && !isNaN(value)) {

    var color = null;

    if(value <= 75){color = "#69dd22";}
    else if(value <= 80){color = "#e6c51d";}
    else if(value <= 90){color = "#e97e1a";}
    else {color = "#d91c1c";}

    return (
      <div className="progress position-relative mt-1" title={`${value}%`}>
        <div className="progress-bar" role="progressbar" style={{width:`${value}%`, backgroundColor:`${color}`}} aria-valuemin="0" aria-valuemax="100"></div>
        <b className="justify-content-center d-flex position-absolute w-100 p-2" style={{fontSize: 'calc(2px + 1vmin)', color: '#ffffff',
          textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>{value}%</b>
      </div>
    )
  } else {
    return null;
  }
}

function createProgessBarForMobileData(value, max){
  if (value) {
    var affichedValue = value.substring(0, value.indexOf(" "));
    var affichedIdicator = "";
    var newValue;
    var i = value.substring(value.indexOf(" ")+1);
    switch (i) {
      case "B":
            newValue = parseFloat(affichedValue.replace(',', '.')) * 1024;
            affichedIdicator = "o"
        break;
      case "MB":
            newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024);
            affichedIdicator = "Mo"
        break;
      case "GB":
            newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024);
            affichedIdicator = "Go"
        break;
      case "TB":
            newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024 * 1024);
            affichedIdicator = "To"
        break;
      default:

    }
    var color = null;

    if (!max) {
      var percentage = 0;
    } else {
      var percentage = (newValue / max)*100;
      if(percentage <= 75){color = "#69dd22";}
      else if(percentage <= 80){color = "#e6c51d";}
      else if(percentage <= 90){color = "#e97e1a";}
      else {color = "#d91c1c";}
    }

    return (
      <div className="progress position-relative mt-1" title={`${value}%`}>
        <div className="progress-bar" role="progressbar" style={{width:`${percentage}%`, backgroundColor:`${color}`}} aria-valuemin="0" aria-valuemax="100"></div>
        <b className="justify-content-center d-flex position-absolute w-100 p-2" style={{fontSize: 'calc(2px + 1vmin)', color: '#ffffff',
          textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>{affichedValue} {affichedIdicator}</b>
      </div>
    )
  } else {
    return null;
  }
}


function changeToDate(int, lang){
  var options = {year: 'numeric', month: 'long', day: '2-digit'};
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${lang}-${lang.toUpperCase()}`, options);
  return (ds)
}

function changeToDateWithHour(int, lang){
  var options = {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
  var d = new Date(int);
  var ds = d.toLocaleDateString(`${lang}-${lang.toUpperCase()}`, options);
  return (ds)
}

export function research(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
      if(valeur.toLowerCase().indexOf("state:new") !== -1 ){
        if(array[i].state.toLowerCase() === "new"){
          testArray.push(array[i]);
        }
      }
      else if(valeur.toLowerCase().indexOf("state:disabled") !== -1 ){
        if(array[i].etat.toLowerCase() === "disabled"){
          testArray.push(array[i]);
        }
      }

      else if(array[i].listusers){
        if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
        || array[i].imei && array[i].imei.indexOf(valeur) !== -1
        || array[i].model.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
        || array[i].listusers.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

          testArray.push(array[i]);

        }}else{
          if(array[i].name.toLowerCase().indexOf(valeur.toLowerCase()) !== -1
          || array[i].imei && array[i].imei.indexOf(valeur) !== -1
          || array[i].model.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){

            testArray.push(array[i]);

          }
        }
    }
  }

  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

class DevicesTab extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showContextMenu: false,
        hit: "",
        selected: 1,
        pageSizeForDevicesTab: localStorage.getItem('pageSizeForDevicesTab'),
      };

    }

    componentWillUnmount(){
      if (this.state.pageSizeForDevicesTab) {
        localStorage.setItem('pageSizeForDevicesTab', this.state.pageSizeForDevicesTab);
      }
    }


// ------------------------------- Action handle ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  mmAppTestCheck(title, mmapp_test){
    var newTitle = title + "\n" + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.testStore"});
    if (mmapp_test) {
      return newTitle
    } else {
      return title
    }
  }

  setMMAppSimcardTitle(rowValue, rowOriginal){
    var returnElement;
    var title;

    switch (rowValue) {
      case -1:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.simcard.notCompliant"}), rowOriginal.mmapp_test);
        returnElement =
        <span style={{color: "rgb(233,44,33)"}}><MdSimCardAlert title={title} size={28}/></span>

        break;
      case 0:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.simcard.notInstalled"}), rowOriginal.mmapp_test);
        returnElement =
        <span style={{color: "rgb(255,184,46)"}}><MdSimCardAlert
        title={title}
        size={28}/></span>

        break;
      case 1:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.simcard.compliant"}), rowOriginal.mmapp_test);
        returnElement =
        <span style={{color: "rgb(36,232,45)"}}><MdSimCard title={title} size={28}/></span>

        break;
      case 5:
        returnElement =
        <span style={{color: "rgb(233,233,233)"}}><MdNoSim size={28}/></span>

        break;

      default:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.0"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: "rgb(233,44,33)"}}><MdCancel title={title} size={28}/></span>

    }
    return returnElement;
  }

  setMMAppTitle(rowValue, rowOriginal){
    var returnElement;
    var title;


    switch (rowValue) {
      case 2:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.402"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: "rgb(233,44,33)"}}><MdError title={title} size={28}/></span>

        break;
      case 3:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.1"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(36,232,45)"}}><MdCheckCircle
        title={title}
        size={28}/></span>

        break;
      case 4:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.102"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,184,46)"}}><MdCheckCircle title={title} size={28}/></span>

        break;
      case 5:
        returnElement =
        <span style={{color: "rgb(233,233,233)"}}><MdLens size={28}/></span>

        break;
      case 6:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.601"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,104,8)"}}><MdError title={title} size={28}/></span>

        break;
      case 7:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.locationStateOld"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,184,46)"}}><MdError title={title} size={28}/></span>

        break;
      case 100:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.state.403"}) + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.lastUpdate"}) + " : " + changeToDateWithHour(rowOriginal.updatedAt, this.props.intl.locale), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(233,44,33)"}}><IoMdClock title={title} size={28}/></span>

        break;
      case 103:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.state.403"}) + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.lastUpdate"}) + " : " + changeToDateWithHour(rowOriginal.updatedAt, this.props.intl.locale), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(36,232,45)"}}><IoMdClock title={title} size={28}/></span>

        break;
      case 104:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.state.403"}) + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.lastUpdate"}) + " : " + changeToDateWithHour(rowOriginal.updatedAt, this.props.intl.locale), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,184,46)"}}><IoMdClock title={title} size={28}/></span>

        break;
      case 106:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.state.403"}) + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.lastUpdate"}) + " : " + changeToDateWithHour(rowOriginal.updatedAt, this.props.intl.locale), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,104,8)"}}><IoMdClock title={title} size={28}/></span>

        break;
      case 107:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.state.403"}) + "\n"
        + this.props.intl.formatMessage({id: "terminaux.mmApp.lastUpdate"}) + " : " + changeToDateWithHour(rowOriginal.updatedAt, this.props.intl.locale), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(255,184,46)"}}><IoMdClock title={title} size={28}/></span>

        break;
      default:
        title = this.mmAppTestCheck(this.props.intl.formatMessage({id: "terminaux.mmApp.group"}) + " : " + rowOriginal.groupName + "\n" + this.props.intl.formatMessage({id: "terminaux.mmApp.state.0"}), rowOriginal.mmapp_test);

        returnElement =
        <span style={{color: rowOriginal.mmapp_test ?  "rgb(36, 114, 232)" : "rgb(233,44,33)"}}><MdCancel title={title} size={28}/></span>

    }


    return returnElement;
  }


  render()


// ------------------------------- Setting of the table ------------------------ //

    {

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, { id: col.id });
      const boxShadow =
        sortedCol ? `inset 0px ${sortedCol.desc ? -2 : 2}px 0px 0px white` : '';
      return {
        style: {
          boxShadow,
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    };

    const columns = [{

      Header: () => (
      <span>
        <b>  </b>
      </span>
    ),
      accessor: 'etat',
      minWidth: 32,
      Cell: row => (
          <div>
            <span style={{
                color: row.value === "asBeenReset" ? "rgb(58,223,49)"
                  : row.value === "needReset" ? "rgb(233,44,33)"
                  : row.value === "default" ? "rgb(44,165,255)"
                  : row.value === "disabled" ? "rgb(190,190,190)"
                  : "rgb(0,0,0)"
              }} title={
                  row.value === "asBeenReset" ? this.props.intl.formatMessage({id: "terminaux.state.title.asBeenReset"})
                  : row.value === "needReset" ? this.props.intl.formatMessage({id: "terminaux.state.title.needReset"})
                  : row.value === "disabled" ? this.props.intl.formatMessage({id: "terminaux.state.title.disabled"})
                  : null
              }>
              <GoDeviceMobile size={28}/>
            </span>
          </div>
        )

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.1"})} </b>
      </span>
    ),
    accessor: 'state',
    minWidth: 60,
    Cell: row => (
        <div>
          {row.value === "NEW" ? <span style={{
            color: "rgb(44,165,255)"
          }}>
          <IoMdCheckmark size={28}/>
        </span> : null}
        </div>
      )

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.2"})} </b>
      </span>
    ),
      accessor: 'name',
      minWidth: 205,

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.3"})} </b>
      </span>
    ),
      accessor: 'listusers',
      minWidth: 90,

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> IMEI </b>
      </span>
    ),
      accessor: 'imei',
      minWidth: 110,

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> O.S. </b>
      </span>
    ),
      accessor: 'os',
      minWidth: 115,

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.4"})} </b>
      </span>
    ),
      accessor: 'model',
      minWidth: 125,

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.5"})} </b>
      </span>
    ),
      accessor: 'operator',
      minWidth: 85,

    },
    {
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.7"})} </b>
      </span>
    ),
      accessor: 'incident',
      minWidth: 60,
      Cell: row => (
          <div>
          { row.value === "STOLEN" ? <span>{this.props.intl.formatMessage({id: "terminaux.incident.stolen"})}</span>
          : row.value === "AFTER_SALES_SERVICE" ? <span>{this.props.intl.formatMessage({id: "terminaux.incident.sav"})}</span>
          : row.value === "LOST" ? <span>{this.props.intl.formatMessage({id: "terminaux.incident.lost"})}</span>
          : row.value === "BROKEN" ? <span>{this.props.intl.formatMessage({id: "terminaux.incident.broken"})}</span>
          : <span>{this.props.intl.formatMessage({id: "terminaux.incident.default"})}</span>
          }
          </div>
        )

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.8"})} </b>
      </span>
    ),
      accessor: 'phoneNumber',
      minWidth: 80,

    },

    {
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.simcardData"})} </b>
      </span>
    ),
      accessor: 'mobile_data_usage_for_filter',
      minWidth: 90,
      Cell: row => (
          createProgessBarForMobileData(row.original.mobile_data_usage, row.original.server_simcard_mobile_data_limit)
        )
    },

    {
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.storageUsed"})} </b>
      </span>
    ),
      accessor: 'percentage_used_for_filter',
      minWidth: 120,
      Cell: row => (
          createProgessBar(row.original.percentage_used)
        )
    },
    {
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.os"})} </b>
      </span>
    ),
      accessor: 'uptodate',
      minWidth: 40,
      Cell: row => (
          <div>
            <span style={{
                color: row.value === 1 ? "rgb(36,232,45)"
                : row.value === 2 ? "rgb(255,184,46)"
                : row.value === -1 ? "rgb(233,233,233)"
                : "rgb(233,44,33)"
              }}
              title ={
                row.value === 1 ? this.props.intl.formatMessage({id: "terminaux.os.state.1"})
                : row.value === 2 ? this.props.intl.formatMessage({id: "terminaux.os.state.2"})
                : row.value === -1 ? null
                : this.props.intl.formatMessage({id: "terminaux.os.state.0"})
              }>
              <MdLens size={28}/>
            </span>
          </div>
        )

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.simcard"})} </b>
      </span>
    ),
      accessor: 'simcard_compliant',
      minWidth: 40,
      Cell: row => (
          <div>
          {
            this.setMMAppSimcardTitle(row.value, row.original)
          }
          </div>
        )

    },{
      Header: () => (
      <span style={{fontSize: 'calc(3px + 1vmin)'}}>
        <b> {this.props.intl.formatMessage({id: "terminaux.app"})} </b>
      </span>
    ),
      accessor: 'appUptodate',
      minWidth: 40,
      Cell: row => (
          <div>
          {
            this.setMMAppTitle(row.value, row.original, row.original.mmapp_test)
          }
          </div>
        )

    }]


    return (
      <div>

      <MenuProvider id="menu_devicesContextMenu" >

      <ReactTable

        getTheadThProps={getTheadThProps}

        getTdProps={(state, rowInfo, column, instance) => {
    return {

      onContextMenu: (e) => {
          if(rowInfo){
          this.props.contextMenu(rowInfo.original)}
          else {this.props.contextMenu("empty")}
      }
    }
  }}

    getTrProps={(state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        onContextMenu: (e) => {
          this.props.selectedHandle(rowInfo.index);
        },
        style: {
            border: rowInfo.original.presentInMpro === false ? '1px solid rgb(255, 56, 56)' : null,
            background: rowInfo.original.enable === false ? "rgb(241,241,241)"
              : rowInfo.index === this.props.selected ? '#e4eef4' : null,
            color: rowInfo.index === this.props.selected ? 'black' : null
        }
      }
    }else{
      return {}
    }
  }}

        showPagination={this.props.showPagination}
        data={(research(this.props.array, this.props.researchValue)).sort((a, b) => a.name.localeCompare(b.name))}
        noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
        rowsText={this.props.intl.formatMessage({id: "rows"})}
        previousText={this.props.intl.formatMessage({id: "previous"})}
        nextText={this.props.intl.formatMessage({id: "next"})}
        loadingText={this.props.intl.formatMessage({id: "chargement"})}
        pageText= {this.props.intl.formatMessage({id: "page"})}
        ofText={this.props.intl.formatMessage({id: "sur"})}
        TbodyComponent={CustomTbodyComponent}
        columns={columns}
        resizable={true}
        pageSizeOptions= {[10, 20, 25, 50, 100]}
        defaultPageSize= {50}
        showPageSizeOptions={true}
        onPageSizeChange={(pageSize) => {
          this.setState({pageSizeForDevicesTab: pageSize})
        }}
        pageSize={!this.props.showPagination ? this.props.array.length : this.state.pageSizeForDevicesTab != null ? this.state.pageSizeForDevicesTab : this.defaultPageSize}
              style={{
                minHeight: "15vh",
                maxHeight: "75vh",
                width: '99vw'
              }}
              className=" text-center border-0 small -clickable"
            />

        </MenuProvider>

            <div className="col text-center border-top small">
              <b className="text-dark"><FormattedMessage id="terminaux.nbterminaux"/> {research(this.props.array, this.props.researchValue).length}</b>
            </div>

        </div>
    )
  }
}

DevicesTab.propTypes = {
  intl: intlShape.isRequired
};
DevicesTab = injectIntl(DevicesTab,
                        {withRef: true});

export default DevicesTab
