import React from 'react'
import $ from "jquery";
import UserInformations from "./UserInformations";
import UserTerminaux from "./UserTerminaux";
import UserMMI from "./UserMMI";
import UserMMF from "./UserMMF";
import UserVPN from "./UserVPN";
import UserPolitiquesSecu from "./UserPolitiquesSecu"
import JournalEvenements from "./JournalEvenements";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import UserDevicesContextMenu from './UserDevicesContextMenu';

import {FaUserLock, FaUserTimes, FaUserCheck} from "react-icons/fa";

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import { varUserDetail, url } from '../variables';

const ApiUserMMApp = varUserDetail.apiUserMMApp;


class user extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      isLoading: false,
      error: null,
      value: "",
      login: "",
      name: "",
      email: "",
      groupId: "",
      service: "",
      fonction: "",
      password: "",
      passwordConfirm: "",
      validButton: "info",
      userId: props.match.params.userId,
      hit: [],
      availableServer: [],

      errorAlert: "hide"
    };

    user.backPage = user.backPage.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);

    this.modifUser = this.modifUser.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

componentDidUpdate() {

  if(this.state.isFetching === true){
    $('#ModalValideModif').modal('show');
  }else{$('#ModalValideModif').modal('hide');}
}

componentDidMount() {
  this.setState({isLoading: true});
  this.fetchData(varUserDetail.api);
}

componentWillUnmount (){
  clearInterval(this.timer);
}


// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    this.props.history.push("/users");
  }

  alertClose(){
    this.setState({errorAlert: "hide"});
  }

  contextMenu(x){

        if(x === "empty"){
        this.setState({hit : "empty"});
      }else {
      this.setState({hit : x});
    }
  }

  modalGraph(x){
    this.setState({ modalAddress: this.getGraph(x.name)});
    $('#ModalGraph').modal('show')
  }

  handleChangeTest= (event, field) => {
    this.setState({ [field] : event.target.value });
    console.log([field] + " : " + event.target.value);
  }

  handleGroupChange(event, field){
    var data = this.state.userInfo.availableGroups;
    var mmapp_test = false;

    for (var i = 0; i < data.length; i++) {
      if (data[i].groupId === parseInt(event.target.value)) {
        mmapp_test = data[i].mmapp_test;
      }
    }

    this.setState({
      userInfo: { ...this.state.userInfo, [field]: event.target.value, "mmapp_test": mmapp_test }
    });

  }


  handleChange= (event, field) => {
    switch (field) {
      case "allAccess":
      case "externalConnection":
      case "mminstant":
      case "moreThanOneDevice":
      case "useServerConfig":
      case "localisation":
      case "saveDeviceData":
      case "saveDeviceImage":
      case "saveDeviceFile":
      case "saveDeviceSMS":
      case "saveDeviceContacts":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: !this.state.userInfo[field] }
            });
        break;
      case "deleteDataOnceSaved":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: !this.state.userInfo[field], ["autoStorageManagement"]: false }
            });
        break;
      case "autoStorageManagement":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: !this.state.userInfo[field], ["deleteDataOnceSaved"]: false }
            });
        break;

      case "mailServer":
      case "groupwareServer":
            this.setState({
              userInfo: { ...this.state.userInfo, [field]: event.target.value }
            });
        break;

      default:
          this.setState({
            userInfo: { ...this.state.userInfo, [field]: event.target.value }
          });
      break;

    }
  }

  addServerFile(id) {

    this.state.userInfo.availableFileServers.map((list,index) =>
    {if (list.serverId === parseInt(id)) {
      var newData = this.state.userInfo;
      newData.fileServers.push(list);
      this.setState({userInfo: newData });
      this.getAvailableServerFile(newData);
    }}
  )

  }

  removeServerFile(indexFileServer) {
    var newArray = this.state.userInfo;
    var newData = [];
    this.state.userInfo.fileServers.map((list, index) => {
      if(index !== indexFileServer){
        newData.push(list);
      }
    })

    newArray.fileServers = newData;
    this.setState({userInfo: newArray });
    this.getAvailableServerFile(newArray);

  }

  selectedServerFile(e){
    this.setState({selectedServerFileId: e.target.value})
  }

  getAvailableServerFile(data){
    var allServer = data.availableFileServers;
    var usedServer = data.fileServers;
    var availableServer = [];

    for (var i = 0; i < allServer.length; i++) {
      var finded=false;
      for (var y = 0; y < usedServer.length; y++) {
        if (allServer[i].serverId === usedServer[y].serverId && finded === false) {
          finded = true;
        }
      }
      if (!finded) {
        availableServer.push(allServer[i]);
      }
    }

    if(availableServer.length > 0){this.setState({selectedServerFileId: availableServer[0].serverId})}else{this.setState({selectedServerFileId: ""})}
    this.setState({availableServer: availableServer});
  }

  getGraph(x) {
    return (`${url}/Microsoft-Server-ActiveSync/?Cmd=Activity&User=${this.state.login}&Login=${this.state.login}&Device=${x}&lang=fr&context=null`)
  }


  getDevices(tab) {

    var txt = "";

    if (tab.users.length > 0) {
      tab.users.map(x => {
        txt = x.login;
      });

      return (txt)
    }
  }


  deleteUser(){

    this.setState({isFetching: true});

    fetch(varUserDetail.api + this.state.userId, {
        method: 'DELETE',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: ""
      })
      .then(response => {
        console.log(response);
        var x = response;
        if(response.status === 200){this.props.history.push("/users/");}
        else{
         return response.json().then(responseError => {
           console.log(responseError);
           this.requeteResponse(x,responseError);
         })
       }
       })
        this.setState({isFetching: false});
  }

  disableUser(hit){

    this.setState({enable: !this.state.enable});

    fetch(varUserDetail.api + this.state.userId ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => this.disableSendRequest(data))
    .catch(error => this.setState({error, isLoading: false}));

  }

  disableSendRequest(hit){

    var data = {
      "login": hit.login,
      "name": hit.name,
      "email" : hit.email,
      "group": {
        "groupId": hit.groupId
      },
      "enable": !hit.enable
    };

        fetch(varUserDetail.api + hit.userId, {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
            body: JSON.stringify(data)
          })
          .then(response => {
            if(process.env.NODE_ENV == "development")console.log(response);
            var x = response;
            if(response.status === 200){console.log("ok");;}
            else{
             return response.json().then(responseError => {
               console.log(responseError);
               this.requeteResponse(x,responseError);
             })
           }
           })

  }


  modifUser(){

    this.setState({sendingModif: true, validButton: "info"})

    var id = null;

    if(this.state.userInfo.password.length === 0 || this.state.userInfo.password === this.state.userInfo.passwordConfirm){

    if(this.state.userInfo.groupId === undefined){
      this.state.groupId == "" ? id = null : id = {"groupId": this.state.groupId}
    } else {
      id = {"groupId": this.state.userInfo.groupId}
    }

    var adaptUserInfo = {};
    var adaptFileServers = [];
    this.state.userInfo.fileServers.map((list, index) => {
      adaptFileServers.push(list.serverId);
    })
    adaptUserInfo.fileServers = adaptFileServers;


        var data = {
            "login": this.state.userInfo.login,
            "name": this.state.userInfo.name,
            "email" : this.state.userInfo.email,
            "group": id,
            "service": this.state.userInfo.service,
            "fonction": this.state.userInfo.fonction,
            "allAccess" : this.state.userInfo.allAccess,
            "externalConnection" : this.state.userInfo.externalConnection,
            "moreThanOneDevice": this.state.userInfo.moreThanOneDevice,
            "ip": this.state.userInfo.ip,
            "mminstant" : this.state.userInfo.mminstant,
            "mailServerId": parseInt(this.state.userInfo.mailServer),
            "groupwareServerId": parseInt(this.state.userInfo.groupwareServer),
            "fileServerIds": adaptFileServers
          };

          if(this.state.userInfo.password){
            data.password = this.state.userInfo.password;
          }


            fetch(varUserDetail.api + this.state.userId, {
                method: 'PUT',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
                body: JSON.stringify(data)
              })
              .then(response => {
                if(process.env.NODE_ENV == "development")console.log(response);
                var x = response;
                if(response.status === 200){
                  this.setState({sendingModif: false, validButton: "success"})
                }
                else{
                  this.setState({sendingModif: false, validButton: "danger"})
                 return response.json().then(responseError => {
                   console.log(responseError);
                   this.requeteResponse(x,responseError);
                 })
               }
               })


               var data2 = {
                 "login": this.state.userInfo.login,
                 "groupId": id.groupId ? id.groupId : null,
                 "service": this.state.userInfo.service,
                 "fonction": this.state.userInfo.fonction,
                 "email": this.state.userInfo.email,
                 "useServerConfig": this.state.userInfo.useServerConfig,
                 "localisation": this.state.userInfo.localisation,
                 "saveDeviceData": this.state.userInfo.saveDeviceData,
                 "saveDeviceImage": this.state.userInfo.saveDeviceImage,
                 "saveDeviceFile": this.state.userInfo.saveDeviceFile,
                 "saveDeviceSMS": this.state.userInfo.saveDeviceSMS,
                 "saveDeviceContacts": this.state.userInfo.saveDeviceContacts,
                 "deleteDataOnceSaved": this.state.userInfo.deleteDataOnceSaved,
                 "autoStorageManagement": this.state.userInfo.autoStorageManagement,
                 "mmapp_test": this.state.userInfo.mmapp_test
               }


               fetch(ApiUserMMApp + this.state.userInfo.email, {
                   method: 'POST',
                   headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'X-XSRF-TOKEN': this.props.tokenCSRF
                 },
                   body: JSON.stringify(data2)
                 })
                 .then(response => {
                   if(process.env.NODE_ENV == "development")console.log(response);
                   var x = response;
                   if(response.status === 200){
                     this.setState({sendingModif: false, validButton: "success"})
                   }
                   else{
                     this.setState({sendingModif: false, validButton: "danger"})
                    return response.json().then(responseError => {
                      console.log(responseError);
                      this.requeteResponse(x,responseError);
                    })
                  }
                  })




            this.setState({isFetching: false});

      } else {
        console.log("Password error");
      }
    }

    requeteResponse(response, responseError){
      this.setState({codeStatus: responseError.status, errorM: responseError.error, errorMsg: responseError.message});
            switch(responseError.status) {
                default:
                  $('#ModalErreur').modal('show');
                  break;
              }
    }

    passwordCheck(password, passwordConfirm){
      if (password.length === 0) {
        return false;
      } else if (password === passwordConfirm) {
        return false;
      } else {
        return true;
      }
    }



// ------------------------------- Auto refresh table -------------------------- //

  tick(){
      console.log("return");
      $('#ModalValideModif').modal('hide');
      window.history.back();
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchData(api){
    fetch(api + this.state.userId  ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => this.adaptData(data))
    .catch(error => this.setState({error, isLoading: false}));
  }


    adaptData(data){
      var adaptData = data;
      if(!data.mailServer){adaptData.mailServer = ""}else{adaptData.mailServer = data.mailServer.serverId}
      if(!data.groupwareServer){adaptData.groupwareServer = ""}else{adaptData.groupwareServer = data.groupwareServer.serverId}

      this.getAvailableServerFile(data);

      adaptData.password="";
      adaptData.mmapp_test = data.group.mmapp_test;

      var newTableau = [];
      data.availableGroups.map((x, index) => {
        if (!(this.props.userAdministration === "REMOTE_ADMIN" && x.administration === 1)) {
          newTableau.push(x);
        }
      })

      adaptData.availableGroups = newTableau;

      this.setState({
        userInfo: adaptData,
        isLoading: false,
        actuName: data.name,
        name: data.name,
        login: data.login,
        enable: data.enable,
        email: data.email,
        oldEmail: data.email,
        password: data.password,
        data: []})

        if(data.group){
          this.setState({groupId: data.group.groupId})
        }

        Promise.all([
            fetch(ApiUserMMApp + data.email ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
          ])
          .then(([resApiUserMMApp]) => Promise.all([resApiUserMMApp.json()]))
          .then(([dataApiUserMMApp]) => (this.adaptDataMMApp(dataApiUserMMApp, data, adaptData)));

    }

    adaptDataMMApp(dataApiUserMMApp, data, adaptData){

        var outputLocalisation, outputSaveDeviceData;

        if (dataApiUserMMApp) {
          if (dataApiUserMMApp.localisation) {
            adaptData.localisation = true;
          } else {adaptData.localisation = false}

          if (dataApiUserMMApp.saveDeviceData) {
            adaptData.saveDeviceData = true;
          } else {adaptData.saveDeviceData = false}
        } else {
          adaptData.localisation = false;
          adaptData.saveDeviceData = false;
         }

        if (dataApiUserMMApp) {
          if (dataApiUserMMApp.useServerConfig) {
            adaptData.useServerConfig = dataApiUserMMApp.useServerConfig;
          } else {
            adaptData.useServerConfig = false;
          }

          if (dataApiUserMMApp.saveDeviceImage) {
            adaptData.saveDeviceImage = dataApiUserMMApp.saveDeviceImage;
          } else {
            adaptData.saveDeviceImage = false;
          }

          if (dataApiUserMMApp.saveDeviceFile) {
            adaptData.saveDeviceFile = dataApiUserMMApp.saveDeviceFile;
          } else {
            adaptData.saveDeviceFile = false;
          }

          if (dataApiUserMMApp.saveDeviceSMS) {
            adaptData.saveDeviceSMS = dataApiUserMMApp.saveDeviceSMS;
          } else {
            adaptData.saveDeviceSMS = false;
          }

          if (dataApiUserMMApp.saveDeviceContacts) {
            adaptData.saveDeviceContacts = dataApiUserMMApp.saveDeviceContacts;
          } else {
            adaptData.saveDeviceContacts = false;
          }

          if (dataApiUserMMApp.deleteDataOnceSaved) {
            adaptData.deleteDataOnceSaved = dataApiUserMMApp.deleteDataOnceSaved;
          } else {
            adaptData.deleteDataOnceSaved = false;
          }

          if (dataApiUserMMApp.autoStorageManagement) {
            adaptData.autoStorageManagement = dataApiUserMMApp.autoStorageManagement;
          } else {
            adaptData.autoStorageManagement = false;
          }
        }

         this.setState({
           userInfo: adaptData,
           isLoading: false,
           actuName: data.name,
           name: data.name,
           login: data.login,
           enable: data.enable,
           email: data.email,
           oldEmail: data.email,
           password: data.password,
           data: this.props.data ? this.props.data : []})

    }


// ------------------------------- Render -------------------------------------- //

  render() {
    const {userInfo, isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p>Erreur : {error.message}</p>
        </div>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    if (userInfo && userInfo.availableGroups) {
      return (<div className=" small mt-4 mb-5 card border-0" style={{width: "52vw"}}>

             <div className="card-header border" style={{background: 'rgb(251,251,251)'}}>
             <div className="row">
               <div className="col ml-3">
                 <h5>
                   <b className="text-info">
                     <FormattedMessage id="userDetails.user"/>
                     <span className="text-success">{this.state.name}</span>
                   </b>
                 </h5>
                 <div className="text-primary mt-1">
                   <FormattedMessage id="userDetails.configparam"/>
                 </div>
               </div>



            <div className="text-primary col-3 float-right">

                        { this.props.userAdministration === "ADMINISTRATOR" ?
                        <button className="btn btn-sm btn-danger float-right" data-toggle="modal"
                        data-target="#ConfirmationSupression"
                        title={this.props.intl.formatMessage({id: "userDetails.button.1"})}>
                        <span style={{ color: "rgb(255,255,255)" }}>
                          <FaUserTimes size={26}/>
                        </span></button>

                        : null
                        }

                        { this.state.enable ?
                          <button className="btn btn-sm btn-secondary float-right mr-3" data-toggle="modal"
                          data-target="#ConfirmationDesactivation"
                          title={this.props.intl.formatMessage({id: "userDetails.button.2"})}>
                          <span style={{color: "rgb(255,255,255)"}}>
                            <FaUserLock size={26}/>
                          </span></button>
                          :<button className="btn btn-sm btn-success float-right mr-3" data-toggle="modal"
                          data-target="#ConfirmationActivation"
                          title={this.props.intl.formatMessage({id: "userDetails.button.3"})}>
                          <span style={{color: "rgb(255,255,255)"}}>
                            <FaUserCheck size={26}/>
                          </span></button>
                        }


            </div>

          </div>
          </div>

          { this.props.userAdministration === "REMOTE_ADMIN" ?

            <ul className="nav nav-pills border-bottom border-right border-left nav-fill">
              <li className="nav-item">
                <a className="nav-link active rounded-0" data-toggle="tab" href="#Informations"><FormattedMessage id="userDetails.menu.1"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#Terminaux"><FormattedMessage id="userDetails.menu.2"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#MM-Instant">MM-Instant</a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#PolitiquesSecu"><FormattedMessage id="userDetails.menu.3"/></a>
              </li>
            </ul>

          :
            <ul className="nav nav-pills border-bottom border-right border-left nav-fill">
              <li className="nav-item">
                <a className="nav-link active rounded-0" data-toggle="tab" href="#Informations"><FormattedMessage id="userDetails.menu.1"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#VPN">VPN</a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#Terminaux"><FormattedMessage id="userDetails.menu.2"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#MM-Instant">MM-Instant</a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#MM-File">MM-File</a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#PolitiquesSecu"><FormattedMessage id="userDetails.menu.3"/></a>
              </li>
              <li className="nav-item">
                <a className="nav-link rounded-0" data-toggle="tab" href="#JournalEvenements"><FormattedMessage id="userDetails.menu.4"/></a>
              </li>
            </ul>

          }




        <div className="tab-content col-auto mb-3" id="myTabContent">

          <div className="tab-pane show active" id="Informations" role="tabpanel">

            <div className="row mt-2 justify-content-center">
              <div className="col-8 container mt-2 mb-2">
                <UserInformations userInfo={userInfo}
                  name={this.state.name}
                  handleChange={this.handleChange}
                  handleGroupChange={this.handleGroupChange}
                  langue={this.props.intl.locale}
                  />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="VPN" role="tabpanel">

            <div className="col-8 container mt-3">
              <UserVPN userInfo={this.state.userInfo}
              handleChange={this.handleChange}/>
            </div>
          </div>

          <div className="tab-pane" id="Terminaux" role="tabpanel">
            <PerfectScrollbar>
            <div className="col-9 container border-0" style={{maxHeight: "60vh"}}>

              {this.state.userInfo && this.state.data ?
              <UserTerminaux devices={userInfo.devices}
                userInfo={userInfo}
                handleChange={this.handleChange}
                size={userInfo.devices.length}
                modalName={this.state.login}
                getGraph={this.getGraph}
                modalAddress={this.state.modalAddress}
                contextMenu={this.contextMenu.bind(this)}
                data={this.state.data}/>
                : null
                }
            </div>
          </PerfectScrollbar>
          </div>

          <UserDevicesContextMenu
          modalGraph={this.modalGraph.bind(this)}
          hit={this.state.hit}
          userId={this.state.userId}
          userInfo={this.state.userInfo}
          contextMenu={this.contextMenu}
          refreshData={this.fetchData.bind(this)}
          tokenCSRF={this.props.tokenCSRF}
          />


          <div className="tab-pane" id="MM-Instant" role="tabpanel">

            <div className="col-8 container border-0 mt-3">
              <UserMMI userInfo={userInfo}
              handleChange={this.handleChange}/>
            </div>
          </div>

          <div className="tab-pane" id="MM-File" role="tabpanel">

            <div className="col-9 container border-0 mt-3">
              <UserMMF userInfo={userInfo}
              handleChange={this.handleChange}
              availableServer={this.state.availableServer}
              addServerFile={this.addServerFile.bind(this)}
              removeServerFile={this.removeServerFile.bind(this)}
              selectedServerFileId={this.state.selectedServerFileId}
              selectedServerFile={this.selectedServerFile.bind(this)}
              userAdministration={this.props.userAdministration}
              />
            </div>
          </div>

          <div className="tab-pane" id="PolitiquesSecu" role="tabpanel">

            <div className="col border-0 d-flex justify-content-center">
              <UserPolitiquesSecu userInfo={userInfo}
              tokenCSRF={this.props.tokenCSRF}/>
            </div>
          </div>

          <div className="tab-pane" id="JournalEvenements" role="tabpanel">

            <div className="col border-0">
              <JournalEvenements userInfo={userInfo}/>
            </div>
          </div>
        </div>



        <div className="modal fade" id="ModalValideModif" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" style={{width: '160px'}} role="document">
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-center">
                <h6 className="modal-title text-info" id="exampleModalLabel"><b>
                  {this.props.intl.formatMessage({id: "chargement"})} ...
                </b></h6>
              </div>
              <div className="modal-body text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="ConfirmationSupression" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modalSecurite rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "supprimer"})} : "<b className="text-info">{this.state.name}</b>"</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteUser.bind(this)}><FormattedMessage id="supprimer"/>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="ConfirmationDesactivation" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "désactiver"})} : "<b className="text-info">{this.state.name}</b>"</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.disableUser.bind(this)}><FormattedMessage id="désactiver"/>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="ConfirmationActivation" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-success rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "activer"})} : "<b className="text-info">{this.state.name}</b>"</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.disableUser.bind(this)}><FormattedMessage id="activer"/>
                </button>
              </div>
            </div>
          </div>
        </div>



        <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
          <div className="text-center mr-1">
            <button type="button" className="btn btn-secondary" onClick={user.backPage}><FormattedMessage id="retour"/>
            </button>
          </div>

          {this.props.userAdministration === "ADMINISTRATOR" || this.props.userAdministration === "REMOTE_ADMIN" ?

          this.state.sendingModif ?
          <div className="text-center ml-1">
            <button type="button" className={`btn btn-${this.state.validButton}`} style={{width: "5vw"}}>
              <div className="spinner-border text-light spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          </div>

          : <div className="text-center ml-1">
            <button type="button" className={`btn btn-${this.state.validButton}`} onClick={this.modifUser} disabled={this.passwordCheck(this.state.userInfo.password, this.state.userInfo.passwordConfirm)}>
              <FormattedMessage id="valider"/>
            </button>
          </div>


          : <div className="text-center ml-1">
            <button type="button" className={`btn btn-${"info"}`} disabled title={this.props.intl.formatMessage({id: "supervisor.restriction.msg"})}>
              <FormattedMessage id="valider"/>
            </button>
          </div>
          }


        </div>


        <div className="modal fade" id="ModalErreur" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modalAlert">
              <div className="modal-header">
                <h5 className="alert-heading"><b>Code error : </b>{this.state.codeStatus}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                <p>{this.state.errorM}</p>
                <hr/>
                <p className="mb-0">{this.state.errorMsg}</p>
              </div>
            </div>
          </div>
        </div>

      </div>)
    }
    return (<p>userInfo unavaible</p>)
  }
}


user.propTypes = {
  intl: intlShape.isRequired
};
user = injectIntl(user,
                        {withRef: true});

export default user
