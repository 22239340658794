import React, {Component} from 'react';
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import ReactTooltip from 'react-tooltip'
import { Doughnut, Line } from 'react-chartjs-2';
import {NavLink} from 'react-router-dom'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import Select, { components } from 'react-select';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import LineGeneral from '../Components/LineGeneral';
import CustomProgressBar from '../Components/CustomProgressBar';
import {varMmapp, varGroups} from '../variables';



function octetAdapt(value){
  var newValue = value;
  if(value > Math.pow(10, 12)){
    newValue = Number(parseInt(value)/(1024 * 1024 * 1024 * 1024)).toFixed(2) + " To";
  } else if(value > Math.pow(10, 9)){
    newValue = Number(parseInt(value)/(1024 * 1024 * 1024)).toFixed(2) + " Go";
  } else if(value > Math.pow(10, 6)){
    newValue = Number(parseInt(value)/(1024 * 1024)).toFixed(2) + " Mo";
  } else if(value > Math.pow(10, 3)){
    newValue = Number(parseInt(value)/1024).toFixed(2) + " Ko";
  } else {
    newValue = newValue.toFixed(2) + " octets";
  }
  return(newValue)
}


  class MMAppDeviceBackupManagement extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dataModal: [],
      };

    }

// ------------------------------- Action handle ------------------------------- //


    showModal(data, title){
      this.setState({dataModal: data, modalTitle: title})
      $('#modalTabDevices').modal('show')
    }

    handleChangeSelectLogin = selectedLogin => {
      this.setState({ selectedLogin, selectedModel: null });
    }

    handleChangeSelectModel = selectedModel => {
      this.setState({ selectedModel });
    }

    handleChangeSelectUser = selectedUser => {
      this.setState({ selectedUser, selectedUserModel: null });
    }

    handleChangeSelectUserModel = selectedUserModel => {
      this.setState({ selectedUserModel });
    }


        getLoginSelectFormat(){
          var newArray = [];
          const list = this.props.dataBackupFile;

          for (var i = 0; i < list.length; i++) {

            var item = new Object;
            item.value = i;
            item.label = list[i].login;

            if (item.label !== undefined) {
              newArray.push(item);
            }
          }
          return newArray.sort((a, b) => a.label.localeCompare(b.label));
        }

        getModelSelectFormat(){
          if (this.state.selectedLogin) {

            var newArray = [];
            const list = this.props.dataBackupFile[this.state.selectedLogin.value].files;

            for (var i = 0; i < list.length; i++) {

              var item = new Object;
              item.value = list[i].name;
              item.label = list[i].name;
              item.size = list[i].size;

              if (item.label !== undefined) {
                newArray.push(item);
              }
            }
            return newArray.sort((a, b) => a.label.localeCompare(b.label));

          }

        }

        getUserSelectFormat(){
            var newArray = [];
            const list = this.props.dataUser

            for (var i = 0; i < list.length; i++) {

              var item = new Object;
              item.value = i;
              item.label = list[i].email;

              if (item.label !== undefined) {
                newArray.push(item);
              }
            }
            return newArray.sort((a, b) => a.label.localeCompare(b.label));

        }

        getUserModelSelectFormat(){
          if (this.state.selectedUser) {

            var newArray = [];
            const list = this.props.dataUser[this.state.selectedUser.value].devices;

            for (var i = 0; i < list.length; i++) {

              var item = new Object;
              item.value = list[i].model;
              item.label = list[i].model;

              if (item.label !== undefined) {
                newArray.push(item);
              }
            }
            return newArray.sort((a, b) => a.label.localeCompare(b.label));

          }

        }

        generateBackupFile(login, model, user , userModel){

          fetch(varMmapp.apiBackupRequest + login.label + "/" + model.value + "/" + user.label + "/" + userModel.value
          ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
          .then(response => {
            if (!response.ok) {
              throw new Error('fetch message : Something went wrong ...');
            }
          }).catch(error => this.setState({error}));

        }

        showModalBackup(login, model, user , userModel){

          var backupFile = login.label + "/" + model.value
          var backupFileSize =  octetAdapt(model.size);
          var receiver = user.label + "/" + userModel.value;

          this.setState({modalBorderColor : "info", modalBackupFile: backupFile, modalBackupReceiver: receiver, modalBackupFileSize: backupFileSize})
          $('#modalConfirmBackup').modal('show')
        }

// ------------------------------- Render -------------------------------------- //

    render() {
      return (
        <div>
        <div className="row">
          <div className="col mt-2 mb-2">
            {this.props.dataDeviceStat ?
              <div>

                <div className="mt-1 mb-2 border rounded-0">
                  <b className="text-info ml-2">{this.props.intl.formatMessage({id: "mmapp.saveData.device.title"})}</b>
                </div>

                  {
                    this.props.dataDeviceStat.devicesWithSavedDataList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSavedData.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithSavedDataList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSavedData"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSavedData"})}
                            valeur={this.props.dataDeviceStat.devicesWithSavedDataList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSavedData"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithServerConfigList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithServerConfig.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithServerConfigList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithServerConfig"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithServerConfig"})}
                            valeur={this.props.dataDeviceStat.devicesWithServerConfigList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithServerConfig"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithSavedImageList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveImage.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithSavedImageList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveImage"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveImage"})}
                            valeur={this.props.dataDeviceStat.devicesWithSavedImageList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveImage"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithSavedFileList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveFile.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithSavedFileList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveFile"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveFile"})}
                            valeur={this.props.dataDeviceStat.devicesWithSavedFileList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveFile"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithSavedSMSList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveSMS.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithSavedSMSList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveSMS"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveSMS"})}
                            valeur={this.props.dataDeviceStat.devicesWithSavedSMSList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveSMS"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithSavedContactsList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveContacts.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithSavedContactsList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveContacts"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveContacts"})}
                            valeur={this.props.dataDeviceStat.devicesWithSavedContactsList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithSaveContacts"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithDeleteDataOnceSavedList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithDeleteDataOnceSaved.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithDeleteDataOnceSavedList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithDeleteDataOnceSaved"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithDeleteDataOnceSaved"})}
                            valeur={this.props.dataDeviceStat.devicesWithDeleteDataOnceSavedList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithDeleteDataOnceSaved"})} valeur="0"/>
                  }
                  {
                    this.props.dataDeviceStat.devicesWithAutoStorageManagementList.length ?
                    <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#modalTabDevices"
                          title={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithStorageAutoManagement.tooltip"})}
                          onClick={this.showModal.bind(this, this.props.dataDeviceStat.devicesWithAutoStorageManagementList,
                             this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithStorageAutoManagement"}))}>

                          <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithStorageAutoManagement"})}
                            valeur={this.props.dataDeviceStat.devicesWithAutoStorageManagementList.length}/>

                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "mmapp.saveData.deviceWithStorageAutoManagement"})} valeur="0"/>
                  }

              </div>
              :
              null
            }
          </div>
          <div className="col mt-2 mb-2 border-left">


            {this.props.dataDeviceStat ?
              <div className="mt-1">

                  {this.props.dataDeviceStat.limitStorageOnServer != "0" ?
                    <CustomProgressBar
                      bigTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.storage.title"})}
                      percentage={Number((this.props.dataDeviceStat.storageOnServer/this.props.dataDeviceStat.limitStorageOnServer)*100).toFixed(2)}
                      firstTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.storage.used"})}
                      valueFirstTitle={octetAdapt(parseInt(this.props.dataDeviceStat.storageOnServer))}
                      secondTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.storage.available"})}
                      valueSecondTitle={Number((this.props.dataDeviceStat.storageOnServer/this.props.dataDeviceStat.limitStorageOnServer)*100) > 100 ?
                         '!!'
                         :
                         octetAdapt(parseInt(this.props.dataDeviceStat.limitStorageOnServer - this.props.dataDeviceStat.storageOnServer))}
                      thirdTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.storage.maximum"})}
                      valueThirdTitle={octetAdapt(parseInt(this.props.dataDeviceStat.limitStorageOnServer))}
                    />
                    :
                    null
                  }

                  {this.props.dataDeviceStat.devicesWithSavedDataList ?
                    <CustomProgressBar
                      bigTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.savedData.title"})}
                      percentage={Number(((this.props.dataDeviceStat.devicesWithSavedDataList.length / this.props.dataDeviceStat.totalDevices) * 100).toFixed(2))}
                      firstTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.savedData.enable"})}
                      valueFirstTitle={this.props.dataDeviceStat.devicesWithSavedDataList.length}
                      secondTitle={this.props.intl.formatMessage({id: "mmapp.progressBar.savedData.flotte"})}
                      valueSecondTitle={this.props.dataDeviceStat.totalDevices}
                      invertColor={true}
                    />
                    :
                    null
                  }
              </div>
              :
              null
            }

          </div>
        </div>

        {this.props.dataBackupFile && this.props.dataBackupFile.length > 0 ?
          <div className="row mt-3">
            <div className="col">
              <div className="mt-1 mb-3 border rounded-0">
                <b className="text-info ml-2">{this.props.intl.formatMessage({id: "mmapp.saveData.backup.title"})}</b>
              </div>

                <b className="text-info">{this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupFile"})}</b>


              <div className="row ml-1 mt-1 mb-3">
                <div className="col-4">
                  {this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupLogin"})}
                  <Select
                    id="selectGroupForBackupLogin"
                    maxMenuHeight={235}
                    menuPlacement="auto"
                    value={this.state.selectedLogin}
                    onChange={this.handleChangeSelectLogin}
                    options={this.getLoginSelectFormat()}
                    isClearable={true}
                  />
                </div>

                <div className="col-4">
                  {this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupModel"})}
                  <Select
                    id="selectGroupForBackupModel"
                    maxMenuHeight={235}
                    menuPlacement="auto"
                    value={this.state.selectedModel}
                    onChange={this.handleChangeSelectModel}
                    options={this.getModelSelectFormat()}
                    isClearable={true}
                    isDisabled={this.state.selectedLogin == null}
                  />
                </div>
              </div>


              <b className="text-info">{this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupReceiver"})}</b>

              <div className="row ml-1 mt-1">
                <div className="col-4">
                  {this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupUser"})}
                  <Select
                    id="selectGroupForBackupUser"
                    maxMenuHeight={235}
                    menuPlacement="auto"
                    value={this.state.selectedUser}
                    onChange={this.handleChangeSelectUser}
                    options={this.getUserSelectFormat()}
                    isClearable={true}
                  />
                </div>
                <div className="col-4">
                  {this.props.intl.formatMessage({id: "mmapp.saveData.backup.backupUserModel"})}
                  <Select
                    id="selectGroupForBackupUserModel"
                    maxMenuHeight={235}
                    menuPlacement="auto"
                    value={this.state.selectedUserModel}
                    onChange={this.handleChangeSelectUserModel}
                    options={this.getUserModelSelectFormat()}
                    isClearable={true}
                    isDisabled={this.state.selectedUser == null}
                  />
                </div>
              </div>

              <div className=" d-flex justify-content-center mt-4 mb-3 col-8">
                <button className="btn btn-primary btn-sm"
                  disabled={this.state.selectedModel == null || this.state.selectedUserModel == null}
                onClick={this.showModalBackup.bind(this,
                  this.state.selectedLogin,
                  this.state.selectedModel,
                  this.state.selectedUser,
                  this.state.selectedUserModel)}>
                    {this.props.intl.formatMessage({id: "valider"})}
                  </button>
              </div>


            </div>
          </div>

          :
          null
        }

        <div className="modal fade" id="modalConfirmBackup" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "mmapp.saveData.backup.modal.title"})}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  <div>{this.props.intl.formatMessage({id: "mmapp.saveData.backup.modal.backupFile"})} : <b className="text-info">{this.state.modalBackupFile}</b></div>
                  <div>{this.props.intl.formatMessage({id: "mmapp.saveData.backup.modal.backupFileSize"})} : <b className="text-info">{this.state.modalBackupFileSize}</b></div>
                  <div>{this.props.intl.formatMessage({id: "mmapp.saveData.backup.modal.backupReceiver"})} : <b className="text-info">{this.state.modalBackupReceiver}</b></div>

                  <div className="mt-4">{this.props.intl.formatMessage({id: "mmapp.saveData.backup.modal.wifiMSG"})}</div>

                  <div className="mt-4">{this.props.intl.formatMessage({id: "confirmer"})}</div>
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-info`} data-dismiss="modal" onClick={this.generateBackupFile.bind(this,
                  this.state.selectedLogin,
                  this.state.selectedModel,
                  this.state.selectedUser,
                  this.state.selectedUserModel)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="modalTabDevices" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{this.state.modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ReactTable
                  getTheadThProps={this.getTheadThProps}
                  showPagination={false}
                  data={this.state.dataModal}
                  noDataText={<FormattedMessage id = { "noElementsTab" } />}
                  TbodyComponent={this.props.CustomTbodyComponent}
                  columns={this.props.createColumns("columnsApplications")}
                  resizable={false}
                  pageSizeOptions={[10, 20, 25, 50, 100]}
                  showPageSizeOptions={false}
                  pageSize={this.state.dataModal.length} style={{
                    maxHeight: "61vh",
                    minHeight: "15vh"
                  }} className=" text-center border-0 small"/>
                  <div className="col text-center border-top small card rounded-0">
      							<b className="text-dark"><FormattedMessage id={"nb.ligne"}/>{this.state.dataModal.length}</b>
      						</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
              </div>
            </div>
          </div>
        </div>

      </div>
      )
    }
  }

MMAppDeviceBackupManagement.propTypes = {
  intl: intlShape.isRequired
};
MMAppDeviceBackupManagement = injectIntl(MMAppDeviceBackupManagement, {withRef: true});

export default MMAppDeviceBackupManagement
