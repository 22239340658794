import React from 'react'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline} from "react-icons/io";

import TablePlus from '../Components/TablePlus';

import { varConfigServeur, varGroups } from '../variables';

const ApiServMessagerie = varConfigServeur.apiServMessagerie;
const ApiServGroupware = varConfigServeur.apiServGroupware;

const ApiListGroups = varGroups.api;

class ParametresCompteAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataServMessagerie: [],
      dataServGroupware: [],
      dataListGroups: [],
    };

  }

  componentDidMount(){

        Promise.all([
            fetch(ApiServMessagerie ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiServGroupware ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiListGroups ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        ])
        .then(([resServMessagerie, resServGroupware, resListGroups]) => Promise.all([resServMessagerie.json(), resServGroupware.json(), resListGroups.json()]))
        .then(([dataServMessagerie, dataServGroupware, dataListGroups]) => this.setState({
            dataServMessagerie: dataServMessagerie,
            dataServGroupware: dataServGroupware,
            dataListGroups: dataListGroups,
        }));
  }


  modalConfirm(name, index){
    console.log(index);
    this.setState({modalTXT: name, modalIndex: index})
  }

  adaptUserPaternListUsed (data){
    var newArray = [];
    for (var i = 0; i < data.length; i++) {
      var item = new Object;
      item.name = data[i].name;
      item.id = data[i].id;
      newArray.push(item)
    }
    return newArray
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    return (<div className="mb-5">

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="parametres.4"/></b>
      </h5>
      <hr/>

      <div className="custom-control custom-checkbox mb-2">
        <input className="custom-control-input" type="checkbox" checked={this.props.dataUserPattern.enable} onChange={e => this.props.handleChange(e, "enable")} id="ConfigActive"/>
        <label className="custom-control-label" htmlFor="ConfigActive">
          <FormattedMessage id="parametres.compteAuto.general.3"/>
        </label>
      </div>

      {this.props.dataUserPattern.enable ?

        <div className="row">
          <div className="col">


                      <h6 className="text-info mt-2 mb-1">
                        <b><FormattedMessage id="parametres.compteAuto.parametres.2"/></b>
                      </h6>

                      <div className=" mt-1 mb-4">
                        <div className="custom-control custom-checkbox mb-1">
                          <input className="custom-control-input" type="checkbox" checked={this.props.dataUserPattern.allAccess} onChange={e => this.props.handleChange(e, "allAccess")} id="noRestric"/>
                          <label className="custom-control-label" htmlFor="noRestric">
                            <FormattedMessage id="parametres.compteAuto.parametres.3"/>
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-1">
                          <input className="custom-control-input" type="checkbox" checked={this.props.dataUserPattern.externalConnection} onChange={e => this.props.handleChange(e, "externalConnection")} id="enableAccesResExt"/>
                          <label className="custom-control-label" htmlFor="enableAccesResExt">
                            <FormattedMessage id="parametres.compteAuto.parametres.4"/>
                          </label>
                        </div>
                      </div>

                      <h6 className="text-info mt-3 mb-1">
                        <b><FormattedMessage id="parametres.compteAuto.parametres.5"/></b>
                      </h6>

                      <div className="mt-1 ">
                        <div className="row mb-1">
                          <div className="col">
                            <FormattedMessage id="parametres.compteAuto.parametres.6"/>
                          </div>

                          <div className="col-7">
                            <select id="inputState" className="form-control mr-4" value={this.props.dataUserPattern.groupId} onChange={e => this.props.handleChange(e, "groupId")}>
                              <option className="list-group-item small" value="">-</option>
                              {this.state.dataListGroups && this.state.dataListGroups.length > 0 ?
                                this.state.dataListGroups.map((list, index) => {
                                return <option key={index} className="list-group-item small" value={list.groupId}>{list.name}</option>
                              })
                              :
                              null
                              }
                            </select>
                          </div>
                        </div>
                      </div>


                                    <h6 className="text-info mb-2 mt-3">
                                      <b><FormattedMessage id="parametres.compteAuto.MMI.1"/></b>
                                    </h6>

                                    <div className=" mt-2 ">

                                      <div className="row mb-1">
                                        <div className="col">
                                          <FormattedMessage id="parametres.compteAuto.MMI.3"/>
                                        </div>

                                        <div className="col">
                                          <select id="inputState" className="form-control mr-4" value={this.props.dataUserPattern.emailServerId} onChange={e => this.props.handleChange(e, "emailServerId")}>
                                            <option className="list-group-item small" value="">-</option>
                                            {this.state.dataServMessagerie.map((list, index) => {
                                              return <option key={index} className="list-group-item small" value={list.id}>{list.name}</option>
                                            })
                                            }
                                          </select>
                                        </div>

                                      </div>

                                      <div className="row mb-1">
                                        <div className="col">
                                          <FormattedMessage id="parametres.compteAuto.MMI.4"/>
                                        </div>

                                        <div className="col mb-3">
                                          <select id="inputState" className="form-control mr-4" value={this.props.dataUserPattern.groupwareServerId} onChange={e => this.props.handleChange(e, "groupwareServerId")}>
                                            <option className="list-group-item small" value="">-</option>
                                            {this.state.dataServGroupware.map((list, index) => {
                                              return <option key={index} className="list-group-item small" value={list.id}>{list.name}</option>
                                            })
                                            }
                                          </select>
                                        </div>
                                      </div>
                                    </div>

          </div>


          <div className="col">

                          <div className="mt-2">

                              <TablePlus
                                title={this.props.intl.formatMessage({id: "parametres.compteAuto.MMI.5"})}
                                data={this.adaptUserPaternListUsed(this.props.userPaternListUsed).sort((a, b) => a.name.localeCompare(b.name))}
                                modalAddId="modalAjoutServeurFichier"
                                deleteHandler={this.modalConfirm.bind(this)}
                                deleteIndex="id"
                                modalRemoveId="modalConfirmSuprMMF"
                                userAdministration={this.props.userAdministration}
                                maxHeight="235px"
                              />

                          </div>

          </div>

        </div>


        : null
      }


    <div className="modal fade" id="modalAjoutServeurFichier" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="userDetails.MMI.config.addServ"/></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ml-2">
            <select id="inputState" className="form-control mr-4" value={this.props.selectedServerFileId}  onChange={e => this.props.selectedServerFile(e)}>
            {
              this.props.userPaternListPossible.map((ListServerDispo, index) => (
                <option key={index} className="list-group-item small" value={ListServerDispo.id}>{ListServerDispo.name}</option>))

            }
            </select>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
            </button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addServerFile}><FormattedMessage id="ajouter"/>
            </button>
          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="modalConfirmSuprMMF" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered " role="document">
        <div className={`modal-content modalConfirm border-secondary`}>
          <div className="modal-header">
            <h5 className="modal-title" id="modalAjoutDNSLabel">
              {this.props.intl.formatMessage({id: "supprimer"})}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ml-2">
            <h6 className="modal-title" id="modalAjoutDNSLabel"> {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ? </h6>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
            </button>
            <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal" onClick={this.props.removeServerFile.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
            </button>
          </div>
        </div>
      </div>
    </div>



    </div>)
  }
}
ParametresCompteAuto.propTypes = {
  intl: intlShape.isRequired
};
ParametresCompteAuto = injectIntl(ParametresCompteAuto, {withRef: true});

export default ParametresCompteAuto
