import React from 'react'
import $ from "jquery";
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {IoIosCloseCircleOutline, IoMdCloudDownload, IoIosApps, IoMdInformationCircle} from "react-icons/io";
import {FaPlus} from "react-icons/fa";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>

           {props.data.label}  
    {
      props.data.link == 1 ?
      <span
        style={{color: "rgb(28,150,210)"}}>
        <IoMdCloudDownload size={24}/>
      </span>
      : null
    }

    {
      props.data.applicationSystem === true ?
      <span style={{color: "rgb(28,150,210)"}}>
        <IoMdInformationCircle size={24}/>
      </span>
      : null
    }

    </Option>
);


class GroupsSimcards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  getSelectFormat(list){
    if (list) {
      var newArray = [];

      for (var i = 0; i < list.length; i++) {

        var item = new Object;
        if (list[i].link) {
          item.link = 1;
        } else {item.link = 0;}

        item.value = list[i];
        item.applicationSystem = list[i].applicationSystem;

        item.label = this.getApplicationName(list[i]);

        if (item.label !== undefined && !list[i].undesirable) {
          newArray.push(item);
        }
      }

      return newArray.sort((a, b) => a.label.localeCompare(b.label));
    }
  }


  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }

  getApplicationName(appli){
    var name = "";

    if (appli.names) {
      switch (this.props.intl.locale) {
        case "fr":
            if (appli.names.fr !== undefined) {
              name = appli.names.fr;
            } else if (appli.names.en !== undefined){
              name = appli.names.en + " (en)";
            }
          break;
        case "en":
            if (appli.names.en !== undefined) {
              name = appli.names.en;
            } else if (appli.names.fr !== undefined){
              name = appli.names.fr + " (fr)";
            }
          break;
        default:
            name = appli.packageName;
          break;
      }
    }

    return name;
  }

  getAvailableApplications(){
    var liste1 = this.props.dataGroupSimcardAllApplications;
    if (this.props.selectedSimcardInfo && this.props.selectedSimcardInfo.applications) {
      const nouvelleListe = liste1.filter((objet1) => !this.props.selectedSimcardInfo.applications.some((objet2) => objet2.packageName === objet1.packageName));
      return nouvelleListe;
    } else {
      return liste1;
    }
  }


// ------------------------------- Action handle ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  render() {

    return (
      <div>

        <h5 className="text-primary text-center mt-2">
          <b><FormattedMessage id="groupsDetails.simcards"/></b>
        </h5>
        <hr/>

        <div className="col-8 mt-3 mb-3 container">
          <div className="form-group row">
            <label className="col" htmlFor="simCardName"><FormattedMessage id="groupsDetails.simcards.operator"/> :</label>
            <select id="selectSimcard"
              className="form-control col"
              value={this.props.selectedSimcardInfo ? this.props.selectedSimcardInfo.simcardName : ""}
              onChange={e => this.props.handleSimcardInfoSelect(e)}>
              {
                this.props.dataGroupSimcard.map((x, index) => {
                  return <option key={x.simcardName} className="list-group-item small " value={x.simcardName}>{x.simcardName}</option>
                })
              }
            </select>
            <div className="col-auto">
              <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalAddNewSimcard" title={this.props.intl.formatMessage({id: "groupsDetails.simcards.addSimcard.tooltip"})}>
                <span style={{
                    color: "rgb(255,255,255)"
                  }}>
                  <FaPlus size={14}/>
                </span>
              </button>
            </div>
          </div>


          <div className="form-group row">
            <label className="col" htmlFor="simCardName"><FormattedMessage id="groupsDetails.simcards.operatorCode"/> :</label>
            <div className="input-group col">
              <input type="text" className="form-control" aria-label="SimcardName" aria-describedby="basic-addon1" value={this.props.selectedSimcardInfo ? this.props.selectedSimcardInfo.operatorCode : ""} readOnly/>
            </div>
          </div>


          <div className="custom-control custom-checkbox mb-3">
            <input className="custom-control-input" id="roamingEnabled" type="checkbox" checked={this.props.selectedSimcardInfo ? this.props.selectedSimcardInfo.autoriseRoaming : false} onChange={this.props.handleAurotiseRoamingChange.bind(this)}/>
            <label className="custom-control-label" htmlFor="roamingEnabled">
              <FormattedMessage id="groupsDetails.simcards.autoriseRoaming"/>
            </label>
          </div>


      <h6 className="text-info mb-2">
        <b><FormattedMessage id="groupsDetails.simcard.appList"/></b>
      </h6>


        <div className="card rounded-0 mb-4">
          <div className="card-header py-2" style={{
              background: 'rgb(251,251,251)'
            }}>
            <div className="row">
              <div className="col">
                <FormattedMessage id="groupsDetails.simcard.appList"/>
              </div>

              <div className="text-primary col-2 float-right">
                <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAddAppToSimcard">
                  <span style={{
                      color: "rgb(255,255,255)"
                    }}>
                    <FaPlus size={14} title={this.props.intl.formatMessage({id: "groupsDetails.simcard.appList.tooltip"})}/>
                  </span>
                </button>
              </div>

            </div>
          </div>

        <PerfectScrollbar>
          <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>


            {
              this.props.selectedSimcardInfo && this.props.selectedSimcardInfo.applications ?
              this.props.selectedSimcardInfo.applications.sort((a, b) => this.getApplicationName(a).localeCompare(this.getApplicationName(b))).map((appli, index) => (
                <li key={index} className="list-group-item text-center rounded-0 py-2">
                <div className="row">
                <span className="ml-2">
                  <IoIosApps size={42} style={{color: "rgb(28,158,231)"}}/>
                </span>
                  <div className="col-auto" style={{textAlign:"left"}}>
                    <a style={{fontSize: '16px'}}>{this.getApplicationName(appli)}<br/></a>
                    <a style={{fontSize: '12px'}}>{appli.packageName}</a>
                  </div>
                  <div className="col-auto ml-2" style={{textAlign:"left"}}>

                    <div className="col">
                      <div className="row" style={{maxHeight:"2vh"}}>
                        <label className=" mr-2" htmlFor={`switchForDisable ${appli.packageName} ${this.props.selectedSimcardInfo.simcardName}`}>
                          <FormattedMessage id="groupsDetails.simcard.appList.enable"/>
                        </label>
                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id={`switchForDisable ${appli.packageName} ${this.props.selectedSimcardInfo.simcardName}`} checked={appli.disabled} onChange={this.props.handleSimcardApplicationDisabled.bind(this, appli)}/>
                          <label className="custom-control-label" htmlFor={`switchForDisable ${appli.packageName} ${this.props.selectedSimcardInfo.simcardName}`}>
                            <FormattedMessage id="groupsDetails.simcard.appList.disable"/>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col" style={{textAlign:"right"}}>
                    <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersion!=null?appli.storeVersion:appli.latestKnownVersion}</a><br/>
                    <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersionCode!=null?appli.storeVersionCode:appli.latestKnownVersionCode}</a>
                  </div>
                  <div className="col-auto" style={{textAlign:"right"}}>
                    <button style={{
                        outline: 'none'
                      }} className="close" data-toggle="modal" data-target="#modalConfirmSuprSimcardApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli), appli)}>
                      <span style={{
                          color: "rgb(110,110,110)"
                        }}>
                        <IoIosCloseCircleOutline size={28}/>
                      </span>
                    </button>
                  </div>
                </div>
              </li>))
              : null

            }

        </ul>
        </PerfectScrollbar>
      </div>
    </div>



    <div className="modal fade" id="modalAddAppToSimcard" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={`modal-content modalConfirm border-secondary`}>
          <div className="modal-header">
            <h5 className="modal-title" id="modalAddAppToSimcard">
              <FormattedMessage id="groupsDetails.simcard.appList.add"/>
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ml-2">
            <div className="form-group">

            <Select className="mt-2 mb-3"
              maxMenuHeight={235}
              value={this.props.selectedOptionSimcardApp}
              onChange={this.props.handleChangeSelectAppSimcard}
              options={this.getSelectFormat(this.getAvailableApplications())}
              components={{ Option: IconOption }}
            />

          </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/></button>
            <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addReferenceApplicationSimcard.bind(this)}><FormattedMessage id="valider"/>
            </button>
          </div>
        </div>
      </div>
    </div>


    {
      //<!-- Modal add sim card -->
    }
      <div className="modal fade" id="modalAddNewSimcard" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><FormattedMessage id="groupsDetails.simcards.addSimcard.title"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <input type="text" className="form-control" id="SimcardNameInput" placeholder={this.props.intl.formatMessage({id: "groupsDetails.simcards.addSimcard.simcardName"})} aria-label="SimcardName" aria-describedby="basic-addon1"/>
              </div>
              <div className="form-group row">
                <div className="input-group col">
                  <input type="text"
                    className="form-control"
                    id="SimcardOperatorCodeInput"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                    placeholder={this.props.intl.formatMessage({id: "groupsDetails.simcards.addSimcard.operatorCode"})}
                    aria-label="OperatorCode"
                    aria-describedby="basic-addon1"/>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/></button>
              <button type="button" className="btn btn-primary" onClick={this.props.addNewSimcard.bind(this)}><FormattedMessage id="ajouter"/></button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="modalConfirmSuprSimcardApp" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title">
                <FormattedMessage id="supprimer"/>
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title">
                <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeSimcardApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>)
  }
}

GroupsSimcards.propTypes = {
  intl: intlShape.isRequired
};
GroupsSimcards = injectIntl(GroupsSimcards, {withRef: true});

export default GroupsSimcards
