import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose,
  IoMdPerson
} from "react-icons/io";

import {varComputers, varGeneral} from '../variables';

const API = varComputers.api;

const APIMMAppErrorNotification = varGeneral.apiMMAppErrorNotification;

var contextMenu = {
  "alertUser": true
}



const iconSize = 22;

class ComputersContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

  checkData() {

    var hit = this.props.hit;

    if(hit.email && hit.email.length > 0){
      contextMenu.alertUser = true;
    } else {
      contextMenu.alertUser = false;
    }

  }


// ------------------------------- Action handle ------------------------------- //

  showModal(type, color, id){
    console.log();
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenuServerAccess').modal('show')
  }


  actionMenu(id){
    if (this.props.hit.email && this.props.hit.email.length > 0) {
      switch (id) {
        case "alertUserGRP":

              var data = {
                  "email": this.props.hit.email,
                  "title": this.props.hit.topic,
                  "message" : "body du message d'erreur GROUPWARE",
                  "state" : "warning"
                };

                this.sendErrorMMApp(data);

          break;
        case "alertUserImap":

            var data = {
                "email": this.props.hit.email,
                "title": this.props.hit.topic,
                "message" : this.props.intl.formatMessage({id: "general.modal.error.imap.notif.msg"}),
                "state" : "warning"
              };

              this.sendErrorMMApp(data);

          break;
        case "alertUserImapForPasswordUpdate":

            var data = {
                "email": this.props.hit.email,
                "title": this.props.hit.topic,
                "message" : this.props.intl.formatMessage({id: "general.modal.error.imap.passwordUpdate.msg"}),
                "state" : "warning"
              };

              this.sendErrorMMApp(data);

          break;
        case "alertUserLdap":

            var data = {
                "email": this.props.hit.email,
                "title": this.props.hit.topic,
                "message" : "body du message d'erreur LDAP",
                "state" : "warning"
              };

              this.sendErrorMMApp(data);

          break;
        case "alertUserLdapForPasswordUpdate":

            var data = {
                "email": this.props.hit.email,
                "title": this.props.hit.topic,
                "message" : this.props.intl.formatMessage({id: "general.modal.error.ldap.passwordUpdate.msg"}),
                "state" : "warning"
              };

              this.sendErrorMMApp(data);

          break;

        default:
      }
    } else {
      if(process.env.NODE_ENV == "development")console.log("email empty");
    }
  }

  sendErrorMMApp(data){
    fetch(APIMMAppErrorNotification, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
        body: JSON.stringify(data)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")console.log(response);
       })
  }


// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "alertUserGRP":
          return (<Item onClick={this.actionMenu.bind(this, id)}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "alertUserImap":
          return (<Item onClick={this.actionMenu.bind(this, id)}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "alertUserImapForPasswordUpdate":
          return (<Item onClick={this.actionMenu.bind(this, id)}>
            <span style={{
                color: "rgb(72,219,127)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "alertUserLdap":
          return (<Item onClick={this.actionMenu.bind(this, id)}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;
        case "alertUserLdapForPasswordUpdate":
          return (<Item onClick={this.actionMenu.bind(this, id)}>
            <span style={{
                color: "rgb(72,219,127)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;


        default:
          break;
      }
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {


        if (this.props.hit != "empty" && contextMenu.alertUser)
      return (<div>

        {
          this.props.type === "errorGroupware" ?
        <Menu id='menu_serverAccessActionGRP' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("alertUserGRP", contextMenu.alertUser, this.props.intl.formatMessage({id: "general.modal.error.groupware.notif"}))}

        </Menu>
        :
        this.props.type === "errorImap" ?
        <Menu id='menu_serverAccessActionImap' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("alertUserImap", contextMenu.alertUser, this.props.intl.formatMessage({id: "general.modal.error.imap.notif"}))}
          {this.testMenu("alertUserImapForPasswordUpdate", contextMenu.alertUser, this.props.intl.formatMessage({id: "general.modal.error.imap.passwordUpdate"}))}

        </Menu>
        :

        this.props.type === "errorLdap" ?
        <Menu id='menu_serverAccessActionLdap' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("alertUserLdap", contextMenu.alertUser, this.props.intl.formatMessage({id: "general.modal.error.ldap.notif"}))}
          {this.testMenu("alertUserLdapForPasswordUpdate", contextMenu.alertUser, this.props.intl.formatMessage({id: "general.modal.error.ldap.passwordUpdate"}))}

        </Menu>
        :
         null
        }



        <div className="modal fade" id="modalConfirmActionContextMenuServerAccess" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`} style={{zIndex: '4000'}}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>)
      return (<div/>)
  }
}

ComputersContextMenu.propTypes = {
  intl: intlShape.isRequired
};
ComputersContextMenu = injectIntl(ComputersContextMenu, {withRef: true});

export default ComputersContextMenu
