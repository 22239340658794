import React from 'react'
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import classNames from "classnames";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {Menu, Item, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varMmapp } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline
} from "react-icons/io";

import { GoDeviceMobile } from "react-icons/go";

var contextMenu = {
  "appUndesirable": false,
  "appUndesirableNAutoUninstall": false,
  "appAutoUninstallEnable": false,
  "appAutoUninstallDisable": false,
  "appDesirable": false,
  "valideApp": false,
  "showDeviceList": false,
}


const iconSize = 22;

const CustomTbodyComponent = props => (<div {...props} className={classNames("rt-tbody", props.className || [])}>
  <PerfectScrollbar>{props.children}</PerfectScrollbar>
</div>);


class MMAppApplicationsTabContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: "",
      appUndesirableUninstallSwitch: false,
      modalConfirmActionTitle: "",
      modalConfirmActionID: "",
      modalConfirmActionColor: "primary",
    };
  }

  checkData() {
    const hit = this.props.hit;

    if (hit != "empty" && hit) {
      if (this.props.userAdministration === "ADMINISTRATOR") {


        if (hit.devices) {
          contextMenu.showDeviceList = true;
        } else {
          contextMenu.showDeviceList = false;
        }

        if (hit.store_version) {
          if (hit.undesirable){
            contextMenu.appDesirable = true;
          } else {
            contextMenu.appDesirable = false;
          }
          contextMenu.appUndesirable = false;
        } else {
          if (hit.undesirable) {
            contextMenu.appUndesirable = false;
            contextMenu.appDesirable = true;
          } else {
            contextMenu.appUndesirable = true;
            contextMenu.appDesirable = false;
          }
        }

        if ((hit.newApplication_for_dashboard_error === true || hit.newApplication_for_dashboard_error === null) && this.props.userAdministration === "ADMINISTRATOR") {
          contextMenu.valideApp = true;
        } else {
          contextMenu.valideApp = false;
        }

      } else {
        contextMenu.appUndesirable = false;
        contextMenu.appDesirable = false;
        contextMenu.valideApp = false;

        if (hit.devices) {
          contextMenu.showDeviceList = true;
        } else {
          contextMenu.showDeviceList = false;
        }

      }

      if (hit.undesirable) {
        if (hit.auto_uninstall) {
          contextMenu.appAutoUninstallEnable = false;
          contextMenu.appAutoUninstallDisable = true;
        } else {
          contextMenu.appAutoUninstallEnable = true;
          contextMenu.appAutoUninstallDisable = false;
        }
        contextMenu.appUndesirableNAutoUninstall = false;
      } else if (!hit.undesirable && !hit.auto_uninstall) {
        if (hit.store_version) {
            contextMenu.appUndesirableNAutoUninstall = false;
        } else {
            contextMenu.appUndesirableNAutoUninstall = true;
        }
          contextMenu.appAutoUninstallEnable = false;
          contextMenu.appAutoUninstallDisable = false;
      } else {
          contextMenu.appUndesirableNAutoUninstall = false;
          contextMenu.appAutoUninstallEnable = false;
          contextMenu.appAutoUninstallDisable = false;
      }

    } else {
        contextMenu.appUndesirable = false;
        contextMenu.appDesirable = false;
        contextMenu.valideApp = false;
        contextMenu.showDeviceList = false;
        contextMenu.appUndesirableNAutoUninstall = false;
        contextMenu.appAutoUninstallEnable = false;
        contextMenu.appAutoUninstallDisable = false;
    }

  }

  getApplicationName(appli){
    var name = "";

    switch (this.props.intl.locale) {
      case "fr":
          if (appli.names.fr !== undefined) {
            name = appli.names.fr;
          } else if (appli.names.en !== undefined){
            name = appli.names.en + " (en)";
          }
        break;
      case "en":
          if (appli.names.en !== undefined) {
            name = appli.names.en;
          } else if (appli.names.fr !== undefined){
            name = appli.names.fr + " (fr)";
          }
        break;
      default:
          name = appli.packageName;
        break;
    }

    return name;
  }

// ------------------------------- Action handle ------------------------------- //


  showModal(type, title, data){
    this.setState({modalTXT: title, modalData: data})
    $('#modalMMAppUser').modal('show')
  }

  actionMenu(id){
    var dataApp;

    switch (id) {

        case "appUndesirableNAutoUninstall":

            dataApp = {
              "applicationId": this.props.hit.packageName,
              "undesirable": true,
              "auto_uninstall": true
                  }

          break;

          case "appAutoUninstallEnable":

              dataApp = {
                "applicationId": this.props.hit.packageName,
                "auto_uninstall": true
                    }

            break;

        case "appAutoUninstallDisable":

            dataApp = {
              "applicationId": this.props.hit.packageName,
              "auto_uninstall": false
                  }

            break;


        case "appUndesirable":

          dataApp = {
            "applicationId": this.props.hit.packageName,
            "undesirable": true
                }

            break;

        case "appDesirable":

          dataApp = {
            "applicationId": this.props.hit.packageName,
            "undesirable": false,
            "auto_uninstall": false
                }

            break;

        case "valideApp":

          dataApp = {
            "applicationId": this.props.hit.packageName,
            "newApplicationForDashboardError": false
                }

            break;

      default:
    }

    fetch(varMmapp.apiApp + this.props.hit.packageName, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(dataApp)
      })
      .then(response => {
          this.props.refetch();
       })

  }




// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {



      case "appUndesirableNAutoUninstall":
        return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "appUndesirableNAutoUninstall", "danger")}>
          <span style={{
              color: "rgb(232,35,35)"
            }}>
            <IoMdCloseCircleOutline size={iconSize}/>
          </span>
            {name}
        </Item>)
        break;


        case "appAutoUninstallEnable":
          return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "appAutoUninstallEnable", "danger")}>
            <span style={{
                color: "rgb(232,35,35)"
              }}>
              <IoMdCloseCircleOutline size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;


          case "appAutoUninstallDisable":
            return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "appAutoUninstallDisable", "warning")}>
              <span style={{
                  color: "rgb(235,126,26)"
                }}>
                <IoMdCheckmarkCircleOutline size={iconSize}/>
              </span>
                {name}
            </Item>)
            break;

        case "appUndesirable":
          return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "appUndesirable", "warning")}>
            <span style={{
                color: "rgb(235,126,26)"
              }}>
              <IoMdClose size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "appDesirable":
          return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "appDesirable", "primary")}>
            <span style={{
                color: "rgb(51,154,232)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "valideApp":
          return (<Item onClick={this.undesirableApplicationSwitch.bind(this, name, "valideApp", "success")}>
            <span style={{
                color: "rgb(40,198,55)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        case "showDeviceList":
          return (<Item onClick={this.showModal.bind(this, "showDeviceList", this.props.hit.names, this.props.hit.devices)}>
            <span style={{
                color: "rgb(51,154,232)"
              }}>
              <GoDeviceMobile size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

        default:
          break;
      }
    }
  }


  getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, {id: col.id});
      const boxShadow = sortedCol
        ? `inset 0px ${sortedCol.desc
          ? -2
          : 2}px 0px 0px white`
        : '';
      return {
        style: {
          boxShadow
        },
        onContextMenu: (e) => {
          this.props.contextMenu("empty");
        }
      };
    }

    createTabColumn(id){

      switch (id) {
        case "TabUserList":
        var columnsApp = [
          {
          Header: () => (<span>
            <b>
              {this.props.intl.formatMessage({id: "mmapp.applicationTab.modal.email"})}
            </b>
          </span>),
          accessor: 'email',
          minWidth: 150
        }, {
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.applicationTab.modal.login"})}
              </b>
            </span>),
            accessor: 'login',
            minWidth: 100
          },{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.applicationTab.modal.model"})}
              </b>
            </span>),
            accessor: 'model',
            minWidth: 100
          },{
            Header: () => (<span>
              <b>
                {this.props.intl.formatMessage({id: "mmapp.applicationTab.modal.androidId"})}
              </b>
            </span>),
            accessor: 'android_id',
            minWidth: 100,
          }
        ];
          break;
        default:

      }

      return columnsApp;
    }


    undesirableApplicationSwitch(title, id, color){
      this.setState({modalConfirmActionTitle: title, modalConfirmActionID: id, modalConfirmActionColor: color})
      $('#modalConfirmAction').modal('show')
    }

    validAutoUninstall(){

      var dataApp = {
        "applicationId": this.props.hit.packageName,
        "auto_uninstall": !this.props.hit.auto_uninstall
            }

      fetch(varMmapp.apiApp + this.props.hit.packageName, {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
          body: JSON.stringify(dataApp)
        })
        .then(response => {
            this.props.refetch();
            this.props.AppHitUpdate(this.props.hit, "auto_uninstall", !this.props.hit.auto_uninstall);
         })

    }


// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if (this.props.hit != "empty")
      return (<div>

        <Menu id='menu_mmAppApplicationsTabContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("showDeviceList", contextMenu.showDeviceList, this.props.intl.formatMessage({id: "mmapp.contextMenu.showDeviceList"}))}
          {this.testMenu("appUndesirableNAutoUninstall", contextMenu.appUndesirableNAutoUninstall, this.props.intl.formatMessage({id: "mmapp.contextMenu.appUndesirableNAutoUninstall"}))}
          {this.testMenu("appAutoUninstallEnable", contextMenu.appAutoUninstallEnable, this.props.intl.formatMessage({id: "mmapp.contextMenu.appAutoUninstallEnable"}))}
          {this.testMenu("appAutoUninstallDisable", contextMenu.appAutoUninstallDisable, this.props.intl.formatMessage({id: "mmapp.contextMenu.appAutoUninstallDisable"}))}
          {this.testMenu("appUndesirable", contextMenu.appUndesirable, this.props.intl.formatMessage({id: "mmapp.contextMenu.appUndesirable"}))}
          {this.testMenu("appDesirable", contextMenu.appDesirable, this.props.intl.formatMessage({id: "mmapp.contextMenu.appDesirable"}))}
          {this.testMenu("valideApp", contextMenu.valideApp, this.props.intl.formatMessage({id: "mmapp.contextMenu.valideApp"}))}

        </Menu>

        <div className="modal fade" id="modalMMAppUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="row">
                  <div className="col-auto">
                    <h5 className="modal-title" id="exampleModalLabel">{this.state.modalTXT}</h5>
                  </div>
                  <div className="col">
                    {this.props.hit.undesirable ?

                      <div className="row">
                        <button type="button" className="btn btn-sm btn-warning" data-dismiss="modal"
                          onClick={this.props.notifUndesirableAppCustomMsg.bind(this, this.state.modalData, this.props.hit.names)}>
                          {this.props.intl.formatMessage({id: "mmapp.notif.undesirableApp.message.btn"})}
                        </button>

                        {/*
                        <div class="custom-control custom-switch ml-3">
                          <input type="checkbox" class="custom-control-input" id="customSwitch1" checked={this.props.hit.auto_uninstall} onChange={this.undesirableApplicationSwitch.bind(this)}/>
                          <label class="custom-control-label" for="customSwitch1">Désinstallation automatique</label>
                        </div>
                        */}

                      </div>

                    :
                      null
                    }
                  </div>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.modalData ?
                  <div>
                    <ReactTable
                      getTheadThProps={this.getTheadThProps}
                      showPagination={false}
                      data={this.state.modalData}
                      noDataText={<FormattedMessage id = {
                        "noElementsTab"
                      } />} TbodyComponent={CustomTbodyComponent}
                      columns={this.createTabColumn("TabUserList")}
                      resizable={false}
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      showPageSizeOptions={false}
                      pageSize={this.state.modalData.length} style={{
                        maxHeight: "62vh",
                        minHeight: "15vh"
                      }} className=" text-center border-0 small"/>
                      <div className="col text-center border small">
                        <b className="text-dark"><FormattedMessage id="nb.ligne"/> {this.state.modalData.length}</b>
                      </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id={"fermer"}/></button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modalConfirmAction" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalConfirmActionColor} rounded-0`} style={{zIndex: '4000'}}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.state.modalConfirmActionTitle}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalConfirmActionColor}`} data-dismiss="modal" onClick={this.actionMenu.bind(this, this.state.modalConfirmActionID)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

MMAppApplicationsTabContextMenu.propTypes = {
  intl: intlShape.isRequired
};
MMAppApplicationsTabContextMenu = injectIntl(MMAppApplicationsTabContextMenu, {withRef: true});

export default MMAppApplicationsTabContextMenu
