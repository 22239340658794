import React from 'react'
import $ from "jquery";
import ReactDOM from 'react-dom';
import Select, { components } from 'react-select';
import { HuePicker } from 'react-color'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline, IoMdCloudDownload, IoIosApps, IoMdInformationCircle} from "react-icons/io";

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import MapComponent from "./MapComponent";

var mapIsAlreadyRendered = false;

const { Option } = components;
const IconOption = (props) => (
    <Option {...props}>

           {props.data.label}  
    {
      props.data.link == 1 ?
      <span
        style={{color: "rgb(28,150,210)"}}>
        <IoMdCloudDownload size={24}/>
      </span>
      : null
    }

    {
      props.data.applicationSystem === true ?
      <span style={{color: "rgb(28,150,210)"}}>
        <IoMdInformationCircle size={24}/>
      </span>
      : null
    }

    </Option>
);

class GroupsGeofencing extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      selectedZone: '', // Zone de geofencing sélectionnée
      };

  }


  componentDidMount(){
    if (this.props.dataGroupGeofencingZone.length != 0) {
      this.setState({selectedZone: this.props.dataGroupGeofencingZone[0]})
    }
  }



// ------------------------------- Action handle ------------------------------- //











    isButtonDisabled = () => {
      const { newGeofencingZoneName, newGeofencingZoneLatitude, newGeofencingZoneLatitudeDecimal,
        newGeofencingZoneLongitude, newGeofencingZoneLongitudeDecimal, newGeofencingZoneRadius, newGeofencingZoneColor } = this.props;
      return !newGeofencingZoneName || !newGeofencingZoneLatitude || !newGeofencingZoneLatitudeDecimal || !newGeofencingZoneLongitude
      || !newGeofencingZoneLongitudeDecimal || !newGeofencingZoneRadius || !newGeofencingZoneColor;
    }


    getApplicationName(appli){
      var name = "";

      if (appli.names) {
        switch (this.props.intl.locale) {
          case "fr":
              if (appli.names.fr !== undefined) {
                name = appli.names.fr;
              } else if (appli.names.en !== undefined){
                name = appli.names.en + " (en)";
              }
            break;
          case "en":
              if (appli.names.en !== undefined) {
                name = appli.names.en;
              } else if (appli.names.fr !== undefined){
                name = appli.names.fr + " (fr)";
              }
            break;
          default:
              name = appli.packageName;
            break;
        }
      }

      return name;
    }

    getSelectFormat(list){
      if (list) {
        var newArray = [];

        for (var i = 0; i < list.length; i++) {

          var item = new Object;
          if (list[i].link) {
            item.link = 1;
          } else {item.link = 0;}

          item.value = list[i];
          item.applicationSystem = list[i].applicationSystem;

          item.label = this.getApplicationName(list[i]);

          if (item.label !== undefined && !list[i].undesirable) {
            newArray.push(item);
          }
        }

        return newArray.sort((a, b) => a.label.localeCompare(b.label));
      }
    }

    modalConfirm(name, index) {
      this.setState({modalTXT: name, modalIndex: index})
    }

    getAvailableApplications(){
      var liste1 = this.props.dataGroupGeofencingAllApplications;
      if (this.props.selectedGeofencingZone && this.props.selectedGeofencingZone.applications) {
        const nouvelleListe = liste1.filter((objet1) => !this.props.selectedGeofencingZone.applications.some((objet2) => objet2.packageName === objet1.packageName));
        return nouvelleListe;
      } else {
        return liste1;
      }
    }





// ------------------------------- Render -------------------------------------- //

  render() {

    return (
      <div>

        <h5 className="text-primary text-center mt-2">
          <b><FormattedMessage id="groupsDetails.geofencing"/></b>
        </h5>
        <hr/>

        <ul className="nav nav-tabs mt-3 justify-content-center" id="geofencingTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link active"
            data-toggle="tab"
            href="#GeofencingMap"
            role="tab">
              <FormattedMessage id="groupsDetails.geofencingMap" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link"
            data-toggle="tab"
            href="#GeofencingApplications"
            role="tab">
              <FormattedMessage id="groupsDetails.geofencingApplications" />
            </a>
          </li>
        </ul>


        <div className="tab-content border-top-0 rounded-0 card mb-2" id="myTabContent">
          <div className="tab-pane show active " id="GeofencingMap" role="tabpanel" aria-labelledby="home-tab">
            <div className="row">
              <div className="col-8">
                { this.props.dataGroupGeofencingZone && (this.props.activeTab == "Geofencing" || mapIsAlreadyRendered ) ?
                  <div className="card rounded-0">
                    {mapIsAlreadyRendered = true}
                    <MapComponent
                      dataGroupGeofencingZone={this.props.dataGroupGeofencingZone}
                      showModalNewGeonfencingZone={this.props.showModalNewGeonfencingZone.bind(this)}/>
                    </div>
                  :
                  null
                }
              </div>

              <div className="col">
                <div className="container d-flex justify-content-center row">
                  { this.props.dataGroupGeofencingZone ?
                      <div className="m-1">
                        <div className="card rounded-0 m-1 text-primary text-center" style={{minWidth: '15vw'}}>
                          <FormattedMessage id="groupsDetails.geofencing.zonelist"/>
                        </div>
                        <div>
                          { this.props.dataGroupGeofencingZone.map((x, index) => {
                            return (
                              <div key={index} className="m-1 border" style={{fontSize: "calc(6px + 1vmin)"}}>
                                <div className="m-1 row">
                                  <div className="col">
                                    <div className="" style={{color: `${x.color}`}}><b>{x.name}</b></div>
                                    <div>Radius : {x.radius} m</div>
                                    <div>Coordinates : {x.coordinates[0]} - {x.coordinates[1]}</div>
                                  </div>
                                  <div className="col-2">

                                    <button onClick={this.props.removeGeofencingZone.bind(this, x.name)} style={{
                                        outline: 'none'
                                      }} className="close">
                                      <span style={{
                                          color: "rgb(110,110,110)"
                                        }}>
                                        <IoIosCloseCircleOutline size={28}/>
                                      </span>
                                    </button>

                                  </div>

                                </div>
                            </div>
                          )
                          })
                          }
                        </div>
                      </div>

                      :
                      null
                  }
                </div>
              </div>

            </div>
          </div>

          <div className="tab-pane show" id="GeofencingApplications" role="tabpanel" aria-labelledby="home-tab">
            <div className="col mt-3">

              <div className="form-group row">
                <label className="col" htmlFor="simCardName"><FormattedMessage id="groupsDetails.geofencing.name"/> :</label>
                <select id="selectGeofencingZone"
                  className="form-control col"
                  value={this.props.selectedGeofencingZone ? this.props.selectedGeofencingZone.name : ""}
                  onChange={e => this.props.handleGeofencingZoneSelect(e)}>
                  {this.props.dataGroupGeofencingZone ?
                    this.props.dataGroupGeofencingZone.map((dataGroupGeofencingZone, index) => {
                      return <option key={dataGroupGeofencingZone.name} className="list-group-item small " value={dataGroupGeofencingZone.name}>{dataGroupGeofencingZone.name}</option>
                    })
                    :
                    null

                    }
                </select>
              </div>



              <div className="col">

                <h6 className="text-info mb-2">
                  <b><FormattedMessage id="groupsDetails.simcard.appList"/></b>
                </h6>


                  <div className="card rounded-0 mb-4">
                    <div className="card-header py-2" style={{
                        background: 'rgb(251,251,251)'
                      }}>
                      <div className="row">
                        <div className="col">
                          <FormattedMessage id="groupsDetails.simcard.appList"/>
                        </div>

                        <div className="text-primary col-2 float-right">
                          <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target="#modalAddAppToGeofencingZone">
                            <span style={{
                                color: "rgb(255,255,255)"
                              }}>
                              <FaPlus size={14} title={this.props.intl.formatMessage({id: "groupsDetails.geofencing.appList.tooltip"})}/>
                            </span>
                          </button>
                        </div>

                      </div>
                    </div>

                  <PerfectScrollbar>
                    <ul className="list-group list-group-flush" style={{"maxHeight" : "40vh"}}>



                          {

                            this.props.selectedGeofencingZone && this.props.selectedGeofencingZone.applications ?
                            this.props.selectedGeofencingZone.applications.sort((a, b) => this.getApplicationName(a).localeCompare(this.getApplicationName(b))).map((appli, index) => (
                              <li key={index} className="list-group-item text-center rounded-0 py-2">
                              <div className="row">
                              <span className="ml-2">
                                <IoIosApps size={42} style={{color: "rgb(28,158,231)"}}/>
                              </span>
                                <div className="col-auto" style={{textAlign:"left"}}>
                                  <a style={{fontSize: '16px'}}>{this.getApplicationName(appli)}<br/></a>
                                  <a style={{fontSize: '12px'}}>{appli.packageName}</a>
                                </div>
                                <div className="col-auto ml-2" style={{textAlign:"left"}}>

                                  <div className="col">
                                    <div className="row" style={{maxHeight:"2vh"}}>
                                      <label className=" mr-2" htmlFor={`switchForDisableGeofencingApp ${appli.packageName} ${this.props.selectedGeofencingZone.name}`}>
                                        <FormattedMessage id="groupsDetails.simcard.appList.enable"/>
                                      </label>
                                      <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id={`switchForDisableGeofencingApp ${appli.packageName} ${this.props.selectedGeofencingZone.name}`} checked={appli.disabled} onChange={this.props.handleGeofencingZoneApplicationDisabled.bind(this, appli)}/>
                                        <label className="custom-control-label" htmlFor={`switchForDisableGeofencingApp ${appli.packageName} ${this.props.selectedGeofencingZone.name}`}>
                                          <FormattedMessage id="groupsDetails.simcard.appList.disable"/>
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="col" style={{textAlign:"right"}}>
                                  <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersion!=null?appli.storeVersion:appli.latestKnownVersion}</a><br/>
                                  <a style={{fontSize: '12px', color: "rgb(125,125,125)"}}>{appli.storeVersionCode!=null?appli.storeVersionCode:appli.latestKnownVersionCode}</a>
                                </div>
                                <div className="col-auto" style={{textAlign:"right"}}>
                                  <button style={{
                                      outline: 'none'
                                    }} className="close" data-toggle="modal" data-target="#modalConfirmSuprGeofencingZoneApp" onClick={this.modalConfirm.bind(this, this.getApplicationName(appli), appli)}>
                                    <span style={{
                                        color: "rgb(110,110,110)"
                                      }}>
                                      <IoIosCloseCircleOutline size={28}/>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </li>))
                            : null

                          }

                          </ul>
                          </PerfectScrollbar>
                        </div>

              </div>

            </div>
          </div>
        </div>


        <div className="modal fade" id="modalAddGeofencingZone" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <FormattedMessage id="groupsDetails.geofencing.addzone"/>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <label className="col" htmlFor="newGeofencingZoneName"><FormattedMessage id="groupsDetails.geofencing.modal.name"/> :</label>
                  <div className="input-group col">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneName" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneName} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneName")}/>
                  </div>
                </div>

                  <div className="form-group row">
                    <label className="col" htmlFor="newGeofencingZoneDescription"><FormattedMessage id="groupsDetails.geofencing.modal.description"/> :</label>
                    <div className="input-group col">
                      <input type="text" className="form-control" aria-label="newGeofencingZoneDescription" aria-describedby="basic-addon1"
                        value={this.props.newGeofencingZoneDescription} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneDescription")}/>
                    </div>
                  </div>


                <div className="form-group row">
                  <label className="col" htmlFor="newGeofencingZoneLongitude"><FormattedMessage id="groupsDetails.geofencing.modal.longitude"/> :</label>
                  <div className="input-group col-3">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneLongitude" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneLongitude} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneLongitude")}/>
                  </div>
                  .
                  <div className="input-group col-4">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneLongitudeDecimal" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneLongitudeDecimal} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneLongitudeDecimal")}/>
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col" htmlFor="newGeofencingZoneLatitude"><FormattedMessage id="groupsDetails.geofencing.modal.latitude"/> :</label>
                  <div className="input-group col-3">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneLatitude" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneLatitude} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneLatitude")}/>
                  </div>
                  .
                  <div className="input-group col-4">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneLatitudeDecimal" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneLatitudeDecimal} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneLatitudeDecimal")}/>
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col" htmlFor="newGeofencingZoneRadius"><FormattedMessage id="groupsDetails.geofencing.modal.radius"/> :</label>
                  <div className="input-group col">
                    <input type="text" className="form-control" aria-label="newGeofencingZoneRadius" aria-describedby="basic-addon1"
                      value={this.props.newGeofencingZoneRadius} onChange={(e) => this.props.handleNewGeofencingZoneChange(e.target.value, "newGeofencingZoneRadius")}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col" htmlFor="dataGroupGeofencingZoneColor"><FormattedMessage id="groupsDetails.geofencing.modal.color"/> :</label>
                  <div className="mt-2   input-group col">
                    <HuePicker
                      color={ this.props.newGeofencingZoneColor }
                      onChange={ this.props.handleNewGeofencingZoneColorChange }
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.intl.formatMessage({id: "annuler"})}</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addNewGeofencingZone.bind(this)} disabled={this.isButtonDisabled()}>
                  <FormattedMessage id="ajouter"/>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="modalAddAppToGeofencingZone" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-secondary`}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAddAppToGeofencingZone">
                  <FormattedMessage id="groupsDetails.simcard.appList.add"/>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <div className="form-group">

                <Select className="mt-2 mb-3"
                  maxMenuHeight={235}
                  value={this.props.selectedOptionGeofencingZoneApp}
                  onChange={this.props.handleChangeSelectAppGeofencingZone}
                  options={this.getSelectFormat(this.getAvailableApplications())}
                  components={{ Option: IconOption }}
                />

              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/></button>
                <button type="button" className={`btn text-white btn-info`} data-dismiss="modal"  onClick={this.props.addReferenceApplicationGeofencingZone.bind(this)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="modalConfirmSuprGeofencingZoneApp" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-secondary`}>
              <div className="modal-header">
                <h5 className="modal-title">
                  <FormattedMessage id="supprimer"/>
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title">
                  <FormattedMessage id="confirmDelete"/> "<b className="text-info">{this.state.modalTXT}</b>" ?
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-danger`} data-dismiss="modal"  onClick={this.props.removeGeofencingZoneApplication.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    )
  }
}

GroupsGeofencing.propTypes = {
  intl: intlShape.isRequired
};
GroupsGeofencing = injectIntl(GroupsGeofencing, {withRef: true});

export default GroupsGeofencing
