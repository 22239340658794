import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import '../custom.css';

import {Menu, Item, Separator, Submenu, theme} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import { varTerminaux, varGroupDetail } from '../variables';

import {
  IoMdCheckmark,
  IoMdClose
} from "react-icons/io";

var contextMenu = {
  "forceAppInstall": false,
  "forceAppInstallDisabledDeviceOwner": false,
  "forceAppInstallDisabledNotCloud": false,
}


const iconSize = 22;

class DevicesDetailsMissAppContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBorderColor: ""
    };
  }

  checkData(){

    const hit = this.props.appHit;

    if (this.props.devicesDetails.ownerDevice) {
      if (hit.storeVersion && hit.storeVersionCode) {
        contextMenu.forceAppInstall = true;
        contextMenu.forceAppInstallDisabledDeviceOwner = false;
        contextMenu.forceAppInstallDisabledNotCloud = false;
      } else {
        contextMenu.forceAppInstall = false;
        contextMenu.forceAppInstallDisabledDeviceOwner = false;
        contextMenu.forceAppInstallDisabledNotCloud = true;
      }
    } else {
      contextMenu.forceAppInstall = false;
      contextMenu.forceAppInstallDisabledDeviceOwner = true;
      contextMenu.forceAppInstallDisabledNotCloud = false;
    }

  }

// ------------------------------- Action handle ------------------------------- //

  showModal(type, color, id){
    this.setState({modalBorderColor : color, modalTXT: type, id: id})
    $('#modalConfirmActionContextMenuMissApp').modal('show')
  }

  hideModal(){
    $('#modalConfirmActionContextMenuMissApp').modal('hide')
  }


  actionMenu(id){
    switch (id) {
      case "forceAppInstall":

          var data = {};

          data.deviceId = this.props.devicesDetails.android_id;
          data.email = this.props.devicesDetails.user.email;
          data.title = this.props.appHit.packageName;
          data.state = "action_install_app";

          if(process.env.NODE_ENV == "development")
          {
            console.log(data);
          }

          fetch(varTerminaux.apiMMAppServerAction, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
              body: JSON.stringify(data)
            })
            .then(response => {
              if(process.env.NODE_ENV == "development")
              {
                console.log(response);
              }
             })

        break;

      default:
    }
    this.hideModal();
  }


// ------------------------------- Function with visual ------------------------ //

  testMenu(id, state, name) {
    if (state === true) {
      switch (id) {
        case "forceAppInstall":
          return (<Item onClick={this.showModal.bind(this, name, "danger", "forceAppInstall")}>
            <span style={{
                color: "rgb(108,148,217)"
              }}>
              <IoMdCheckmark size={iconSize}/>
            </span>
              {name}
          </Item>)
          break;

          case "forceAppInstallDisabledDeviceOwner":
            return (<Item onClick={this.showModal.bind(this, name, "danger", "forceAppInstallDisabledDeviceOwner")} disabled={true}>
            <div  title={this.props.intl.formatMessage({id: "terminauxDetails.tab.2.forceInstall.error.owner"})}>
              <span style={{
                  color: "rgb(108,148,217)"
                }}>
                <IoMdCheckmark size={iconSize}/>
              </span>
                {name}
              </div>
            </Item>)
            break;

            case "forceAppInstallDisabledNotCloud":
              return (<Item onClick={this.showModal.bind(this, name, "danger", "forceAppInstallDisabledNotCloud")} disabled={true}>
              <div  title={this.props.intl.formatMessage({id: "terminauxDetails.tab.2.forceInstall.error.cloud"})}>
                <span style={{
                    color: "rgb(108,148,217)"
                  }}>
                  <IoMdCheckmark size={iconSize}/>
                </span>
                  {name}
                </div>
              </Item>)
              break;

        default:
          break;
      }
    }
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    {
      this.checkData()
    }

    if (this.props.appHit != "empty" && this.props.userAdministration === "ADMINISTRATOR")
      return (<div>

        <Menu id='menu_devicesDetailsMissAppContextMenu' className="border" theme={theme.light} style={{
            fontSize: 'calc(4px + 1vmin)',
            zIndex: '2000'
          }}>
          {this.testMenu("forceAppInstall", contextMenu.forceAppInstall, this.props.intl.formatMessage({id: "terminauxDetails.tab.2.forceInstall"}))}
          {this.testMenu("forceAppInstallDisabledDeviceOwner", contextMenu.forceAppInstallDisabledDeviceOwner, this.props.intl.formatMessage({id: "terminauxDetails.tab.2.forceInstall"}))}
          {this.testMenu("forceAppInstallDisabledNotCloud", contextMenu.forceAppInstallDisabledNotCloud, this.props.intl.formatMessage({id: "terminauxDetails.tab.2.forceInstall"}))}

        </Menu>

        <div className="modal fade" id="modalConfirmActionContextMenuMissApp" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className={`modal-content modalConfirm border-${this.state.modalBorderColor} rounded-0`} style={{zIndex: '4000'}}>
              <div className="modal-header">
                <h5 className="modal-title" id="modalAjoutDNSLabel">
                  {this.state.modalTXT}
                </h5>
                <button type="button" className="close" aria-label="Close" onClick={this.hideModal.bind(this)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ml-2">
                <h6 className="modal-title" id="modalAjoutDNSLabel">
                  {this.props.intl.formatMessage({id: "confirmer"})}
                </h6>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={this.hideModal.bind(this)}><FormattedMessage id="annuler"/>
                </button>
                <button type="button" className={`btn text-white btn-${this.state.modalBorderColor}`} onClick={this.actionMenu.bind(this, this.state.id)}><FormattedMessage id="valider"/>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>)
    else {
      return (<div/>)
    }
  }
}

DevicesDetailsMissAppContextMenu.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsMissAppContextMenu = injectIntl(DevicesDetailsMissAppContextMenu, {withRef: true});

export default DevicesDetailsMissAppContextMenu
