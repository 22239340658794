import React, {Component} from 'react';
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import { Line } from 'react-chartjs-2';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

var maxDays = 0;

  class MMAppGraphDeviceTab extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        smoothed: false,
        filled: false,
        stepped: false,
        datePalier: 7,
      };
    }


// ------------------------------- Action handle ------------------------------- //


    datePalierHandler(event) {
      this.setState({datePalier: event.target.value});
    }

// ------------------------------- Function with visual ------------------------ //


    createGraphData(){

        var newData = [];
        var msDiff;

        if (this.props.dataGraph[this.props.dataGraph.length - 1]) {
          msDiff = new Date(this.props.dataGraph[this.props.dataGraph.length - 1].createdAt).getTime() - new Date(this.props.dataGraph[0].createdAt).getTime();
        } else {
          msDiff = 0;
        }

        //var msDiff = new Date(this.props.dataGraph[this.props.dataGraph.length - 1].createdAt).getTime() - new Date(this.props.dataGraph[0].createdAt).getTime();
        var newMaxDays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
        maxDays = newMaxDays;


        var datePalier = new Date(Date.now() - this.state.datePalier * 24 * 60 * 60 * 1000);

        if (this.props.dataGraph) {
          var item = new Object;

          this.props.dataGraph.map((hit,index) => {
            var createdAt = new Date(hit.createdAt);
            createdAt.setHours(0,0,0,0);
                if (createdAt > datePalier) {
                  newData.push(hit);
                }
            })
        }


        var compliantList = [];
        var uncompliantList = [];
        var withoutMMAppList = [];

        var highestValue = 0;

        newData.map((hit,index) =>{
              var itemCompliant = new Object();
              var itemUncompliant = new Object();
              var itemWithoutMMApp = new Object();

              itemCompliant.x = new Date(hit.createdAt);
              itemCompliant.y = hit.devicesCompliant;
              itemUncompliant.x = new Date(hit.createdAt);
              itemUncompliant.y = hit.devicesUncompliant;
              itemWithoutMMApp.x = new Date(hit.createdAt);
              itemWithoutMMApp.y = hit.devicesWithoutMMApp;

              compliantList.push(itemCompliant);
              uncompliantList.push(itemUncompliant);
              withoutMMAppList.push(itemWithoutMMApp);
          })


      var dataGraph = {
        responsive: true,
        datasets: [
          {
            label: this.props.intl.formatMessage({id: "mmapp.graph.label.compliant"}),
            fill: this.state.filled,
            steppedLine: this.state.stepped,
            lineTension: this.state.smoothed ? 0.3 : false,
            backgroundColor: 'rgba(46,207,54,0.3)',
            borderWidth: 3,
            borderColor: 'rgba(46,207,54,1)',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: 'rgba(46,207,54,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(46,207,54,1)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            type: "line",
            data: compliantList,
          },
          {
            label: this.props.intl.formatMessage({id: "mmapp.graph.label.uncompliant"}),
            fill: this.state.filled,
            steppedLine: this.state.stepped,
            lineTension: this.state.smoothed ? 0.3 : false,
            backgroundColor: 'rgba(250,177,34,0.3)',
            borderWidth: 3,
            borderColor: 'rgba(250,177,34,1)',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: 'rgba(250,177,34,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(250,177,34,1)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            type: "line",
            data: uncompliantList,
          },
          {
            label: this.props.intl.formatMessage({id: "mmapp.graph.label.withoutMMApp"}),
            fill: this.state.filled,
            steppedLine: this.state.stepped,
            lineTension: this.state.smoothed ? 0.3 : false,
            backgroundColor: 'rgba(224,47,37,0.3)',
            borderWidth: 3,
            borderColor: 'rgba(224,47,37,1)',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'round',
            pointBorderColor: 'rgba(224,47,37,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(224,47,37,1)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            type: "line",
            data: withoutMMAppList,
          }
        ]
      };

      return dataGraph;
    }



    createGraphOptions(){
      var optionsGraph = {

        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0
        ,
        legend: {
            display: true,
            position: 'top'
        },
        plugins: {
            filler: {
                propagate: true
            }
        },
        title: {text: "This is a test"},
        scales: {
          xAxes: [{
            type: 'time',
            time: {
                  displayFormats: {
                    hour: this.props.intl.locale == "en" ? 'hh:mm a MM/DD' : 'hh a DD/MM'
                },
                parser: "MM/DD/YYYY"
            },
        }],
          yAxes: [{
            ticks: {
              min: 0,
            }
          }]
        }
      }

        return optionsGraph;
    }


// ------------------------------- Render -------------------------------------- //

    render() {

        return (
          <div>
          <p className="text-center text-info border"><b><FormattedMessage id="mmapp.menuTab.graph"/></b></p>
          <div className="mr-4">
            <Line data={this.createGraphData()} options={this.createGraphOptions()}/>
          </div>
            <div className="">
              <div className="form-group mt-2 col">
                <div className="text-center">
                  <label className="card rounded-0" htmlFor="formControlRange">
                    {this.props.intl.formatMessage({id: "mmapp.menuTab.graph.size.1"})}{this.state.datePalier}{this.props.intl.formatMessage({id: "mmapp.menuTab.graph.size.2"})}
                  </label>
                </div>
                <div className="row d-flex justify-content-center">
                  <span className="ml-3 mr-3">7</span>
                  <input type="range" className="form-control-range" min="7" max={maxDays} value={this.state.datePalier}
                    onChange={this.datePalierHandler.bind(this)} id="formControlRange" style={{width: "40vw"}}/>
                  <span className="ml-3">{maxDays}</span>
                </div>
              </div>
            </div>
          </div>
      )
    }
  }

MMAppGraphDeviceTab.propTypes = {
  intl: intlShape.isRequired
};
MMAppGraphDeviceTab = injectIntl(MMAppGraphDeviceTab, {withRef: true});

export default MMAppGraphDeviceTab
