import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {FaPlus} from "react-icons/fa";
import {IoIosCloseCircleOutline} from "react-icons/io";

import { varParametres } from '../variables';

import TablePlus from '../Components/TablePlus';

const API = varParametres.apiMDM;

var tempE;

class ParametresMDM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexModel: 0,
      possibleCombinaisonList: this.props.unUsedCombinaison,
      osList: []
    };

    this.handleChange = this.handleChange.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //



// ------------------------------- Action handle ------------------------------- //

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
    if(field === "model"){
      this.state.possibleCombinaisonList.map((x, index) => {
        if(x.model === event.target.value){
          this.setState({os: x.os[0], indexModel: index})
        }
      })
    }
  }

  adaptUsedCombinaison (data){
    var newArray = [];
    for (var i = 0; i < data.length; i++) {
      newArray.push(data[i].model + " , " + data[i].os)
    }
    return newArray
  }

  showDefaultSaveDataModal(e, state){
    tempE = e;
    if (state) {
      this.props.handleChangeMDM(e, "enableSaveDeviceData");
    } else {
      $('#DefaultDataChoice').modal('show');
    }
  }

  showDeleteDataConfirmModal(e, state, id){
    tempE = e;
    this.setState({tempId: id})
    if (state) {
      switch (id) {
        case "deleteDataOnceSaved":
            this.props.handleChangeMDM(e, "deleteDataOnceSaved");
          break;
        case "autoStorageManagement":
            this.props.handleChangeMDM(e, "autoStorageManagement");
          break;
        default:

      }
    } else {
      $('#confirmDeleteData').modal('show');
    }
  }


// ------------------------------- DATA fetching ------------------------------- //


// ------------------------------- Render -------------------------------------- //

  render() {

    return (<div className="col mb-3">

      <h5 className="text-primary text-center mt-2">
        <b>MDM</b>
      </h5>
      <hr/>

      <div className="row">
        <div className="col-auto">
        <h6 className="text-info mt-2 mb-2">
          <b><FormattedMessage id="parametres.securite.12"/></b>
        </h6>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataMDM.singleAssociationDeviceUser} onChange={e => this.props.handleChangeMDM(e, "singleAssociationDeviceUser")} id="TermUser"/>
            <label className="custom-control-label" htmlFor="TermUser">
              <FormattedMessage id="parametres.securite.13"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataMDM.requiredNewDeviceValidation} onChange={e => this.props.handleChangeMDM(e, "requiredNewDeviceValidation")} id="ValNTerm"/>
            <label className="custom-control-label" htmlFor="ValNTerm">
              <FormattedMessage id="parametres.securite.14"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.enableLocation === "true"} onChange={e => this.props.handleChangeMDM(e, "enableLocation")} id="enableLocation"/>
            <label className="custom-control-label" htmlFor="enableLocation">
              <FormattedMessage id="parametres.securite.15"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.autoUninstallNewApp === "true"} onChange={e => this.props.handleChangeMDM(e, "autoUninstallNewApp")} id="autoUninstallNewApp"/>
            <label className="custom-control-label" htmlFor="autoUninstallNewApp">
              <FormattedMessage id="parametres.securite.autoUninstallNewApp"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.enableSaveDeviceData === "true"} onChange={e => this.showDefaultSaveDataModal(e, this.props.dataConfigServerMMApp.enableSaveDeviceData === "true")} id="enableSaveDeviceData"/>
            <label className="custom-control-label" htmlFor="enableSaveDeviceData">
              <FormattedMessage id="parametres.securite.16"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-2">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.deleteDataOnceSaved === "true"} onChange={e => this.showDeleteDataConfirmModal(e, this.props.dataConfigServerMMApp.deleteDataOnceSaved === "true", "deleteDataOnceSaved")} id="deleteDataOnceSaved"/>
            <label className="custom-control-label" htmlFor="deleteDataOnceSaved">
              <FormattedMessage id="parametres.securite.17"/>
            </label>
          </div>

          <div className="custom-control custom-checkbox mb-3">
            <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.autoStorageManagement === "true"} onChange={e => this.showDeleteDataConfirmModal(e, this.props.dataConfigServerMMApp.autoStorageManagement === "true", "autoStorageManagement")} id="autoStorageManagement"/>
            <label className="custom-control-label" htmlFor="autoStorageManagement">
              <FormattedMessage id="parametres.securite.18"/>
            </label>
          </div>


          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm"><FormattedMessage id="parametres.securite.storageLimit"/></span>
            </div>
            <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
            value={this.props.dataConfigServerMMApp ? this.props.dataConfigServerMMApp.serverStorageLimit : 0}
               onChange={e => this.props.handleChangeMDM(e, "serverStorageLimit")}/>
            <select className="custom-select auto" id="inputGroupSelectStorageLimit" value={this.props.selectValue} onChange={e => this.props.handleStorageSelectChange(e)}>
              <option value="1">Mo</option>
              <option value="2">Go</option>
              <option value="3">To</option>
            </select>
          </div>

          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm"><FormattedMessage id="parametres.securite.simcardMobileDataLimit"/></span>
            </div>
            <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
            value={this.props.dataConfigServerMMApp ? this.props.dataConfigServerMMApp.serverSimcardMobileDataLimit : 0}
               onChange={e => this.props.handleChangeMDM(e, "serverSimcardMobileDataLimit")}/>
            <select className="custom-select auto" id="inputGroupSelectSimcardMobileDataLimit" value={this.props.selectSimcardMobileDataLimitValue} onChange={e => this.props.handleSimcardMobileDataLimitSelectChange(e)}>
              <option value="1">Mo</option>
              <option value="2">Go</option>
              <option value="3">To</option>
            </select>
          </div>


          <h6 className="text-info mb-1 mt-4">
            <b><FormattedMessage id="parametres.compteAuto.MDM.4"/></b>
          </h6>
          <div className="row mb-3 mt-3">
            <div className="col">
              <FormattedMessage id="parametres.compteAuto.MDM.5"/>
            </div>

            <div className="col">
              <select id="inputState" className="form-control mr-4" value={this.props.dataMDM.compliancyPeriod} onChange={e => this.props.handleChangeMDM(e, "compliancyPeriod")}>
                <option className="list-group-item small" value={3}>3 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={6}>6 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={9}>9 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={12}>12 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={15}>15 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={18}>18 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={21}>21 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={24}>24 {this.props.intl.formatMessage({id: "mois"})}</option>
                <option className="list-group-item small" value={27}>27 {this.props.intl.formatMessage({id: "mois"})}</option>
              </select>
            </div>

          </div>

        </div>


        <div className="col">

          <TablePlus
            title={this.props.intl.formatMessage({id: "parametres.compteAuto.MDM.3"})}
            data={this.adaptUsedCombinaison(this.props.usedCombinaison).sort((a, b) => a.localeCompare(b))}
            dataElementToShow={["model","os"]}
            modalAddId="modalAjoutTerm"
            deleteHandler={this.props.modalConfirm.bind(this)}
            modalRemoveId="modalConfirmSuprTermConf"
            userAdministration={this.props.userAdministration}
            maxHeight="235px"
            maxWidth="30vw"
          />

        </div>

      </div>


      <h6 className="text-info mb-2">
        <b><FormattedMessage id="parametres.compteAuto.MDM.6"/></b>
      </h6>

      <div className="custom-control custom-checkbox mb-1">
        <input className="custom-control-input" type="checkbox" value="" id="userNotifMAJ" checked={this.props.dataMDM.notificationAndRestriction} onChange={e => this.props.handleChangeMDM(e, "notificationAndRestriction")}/>
        <label className="custom-control-label" htmlFor="userNotifMAJ">
          <FormattedMessage id="parametres.compteAuto.MDM.7"/> et <FormattedMessage id="parametres.compteAuto.MDM.9"/>
        </label>
      </div>

      <FormattedMessage id="parametres.compteAuto.MDM.8"/>
      <div className="col mt-1">
        <label className="radio-inline"><input className="mr-2" type="radio" name="optradio" checked={this.props.dataConfigServerMMApp.notifApp === "true"} onChange={e => this.props.handleChangeMDM(e, "notifAppTrue")}/><FormattedMessage id="parametres.compteAuto.MDM.8.mail"/></label>
        <label className="radio-inline ml-4"><input className="mr-2" type="radio" name="optradio" checked={this.props.dataConfigServerMMApp.notifApp === "false"} onChange={e => this.props.handleChangeMDM(e, "notifAppFalse")}/><FormattedMessage id="parametres.compteAuto.MDM.8.notif"/></label>
      </div>


      <div className="modal fade" id="modalAjoutTerm" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel"><FormattedMessage id="parametres.compteAuto.MDM.modal.1"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">


              <div className="row mb-1">
                <div className="col">
                  <FormattedMessage id="parametres.compteAuto.MDM.modal.2"/>
                </div>

                <div className="col-8">
                  {this.props.unUsedCombinaison.length > 0 ?
                  <select id="inputState" className="form-control mr-4" value={this.props.model} onChange={e => this.props.handleSelectMDM(e, "model")}>
                    {this.props.unUsedCombinaison.map((x, index) => {
                      if (x.os.length > 0) {
                        return(<option className="list-group-item small" key={index} value={x.model}>{x.model}</option>)
                      }
                    })}
                  </select>
                  :null
                }
                </div>
              </div>

              <div className="row mb-1">
                <div className="col">
                  O.S.
                </div>

                <div className="col-8">
                  {this.props.unUsedCombinaison.length > 0 ?
                    <select id="inputState" className="form-control mr-4" value={this.props.os} onChange={e => this.props.handleSelectMDM(e, "os")}>
                      {this.props.unUsedCombinaison[this.props.indexModel].os.map((x, index) => {

                          return(<option className="list-group-item small" key={index} value={x}>{x}</option>)

                      })}
                    </select>
                  :null
                }

                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.addCompliant}><FormattedMessage id="ajouter"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="DefaultDataChoice" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><FormattedMessage id="parametres.MDM.DefaultDataChoice.title"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col mt-1">
                <div className="row">

                  <div className="custom-control custom-checkbox ml-2">
                    <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.defaultDataSavedImages === "true"} onChange={e => this.props.handleChangeMDM(e, "defaultDataSavedImages")} id="defaultDataSavedImages"/>
                    <label className="custom-control-label" htmlFor="defaultDataSavedImages">
                      <FormattedMessage id="userDetails.terminals.DataChoice.picture"/>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox ml-2">
                    <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.defaultDataSavedFiles === "true"} onChange={e => this.props.handleChangeMDM(e, "defaultDataSavedFiles")} id="defaultDataSavedFiles"/>
                    <label className="custom-control-label" htmlFor="defaultDataSavedFiles">
                      <FormattedMessage id="userDetails.terminals.DataChoice.file"/>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox ml-2">
                    <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.defaultDataSavedSMS === "true"} onChange={e => this.props.handleChangeMDM(e, "defaultDataSavedSMS")} id="defaultDataSavedSMS"/>
                    <label className="custom-control-label" htmlFor="defaultDataSavedSMS">
                      <FormattedMessage id="userDetails.terminals.DataChoice.sms"/>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox ml-2">
                    <input className="custom-control-input" type="checkbox" checked={this.props.dataConfigServerMMApp.defaultDataSavedContacts === "true"} onChange={e => this.props.handleChangeMDM(e, "defaultDataSavedContacts")} id="defaultDataSavedContacts"/>
                    <label className="custom-control-label" htmlFor="defaultDataSavedContacts">
                      <FormattedMessage id="userDetails.terminals.DataChoice.contacts"/>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn text-white btn-primary" data-dismiss="modal" onClick={this.props.handleChangeMDM.bind(this, tempE, "enableSaveDeviceData")}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="confirmDeleteData" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"><FormattedMessage id="parametres.MDM.DeleteDataWarning.title"/></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col mt-1">
              {this.state.tempId === "deleteDataOnceSaved" ?
                <FormattedMessage id="parametres.MDM.DeleteDataWarning.message"/>
                :
                <FormattedMessage id="parametres.MDM.AutoStorageManagementWarning.message"/>
              }

            </div>
          </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
              </button>
              <button type="button" className="btn text-white btn-danger" data-dismiss="modal" onClick={this.props.handleChangeMDM.bind(this, tempE, this.state.tempId)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>



    </div>)
  }
}
ParametresMDM.propTypes = {
  intl: intlShape.isRequired
};
ParametresMDM = injectIntl(ParametresMDM, {withRef: true});

export default ParametresMDM
