import React from 'react'
import { FaPlus } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";

import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const TablePlus = ({title, smallTitle, data, dataElementToShow, addHandler, modalAddId, deleteHandler, deleteIndex, modalRemoveId, userAdministration, maxHeight, maxWidth}) => {
  return (
    <div className="card rounded-0 mb-4" style={{ maxWidth : maxWidth ? maxWidth : null}}>
      <div className="card-header" style={{background: 'rgb(251,251,251)'}}>
        <div className="row">
          <div className="col ml-3">
            {title ?
              <h6 className="text-info mt-1 mb-2">
                <b className="text-info">
                  {title}
                </b>
              </h6>
              :
              <div>{smallTitle}</div>
            }

          </div>

          { userAdministration === "ADMINISTRATOR" ?
            <div className="text-primary col-auto float-right">
              <button className="btn btn-sm btn-primary float-right" data-toggle="modal" data-target={"#" + modalAddId}>
                <span style={{
                    color: "rgb(255,255,255)"
                  }}>
                  <FaPlus size={14}/>
                </span>
              </button>
            </div>
            :null
          }
        </div>
      </div>

        <PerfectScrollbar>
          <ul className="list-group list-group-flush" style={{maxHeight: maxHeight ? maxHeight : null}}>
            { data ?
              data.map((ListFormat, index) => (
                <li key={index} className="list-group-item text-center rounded-0 py-2">
                    <div>
                  {ListFormat.name ? ListFormat.name : ListFormat}

                  { userAdministration === "ADMINISTRATOR" ?
                  <button style={{
                      outline: 'none'
                    }} className="close" data-toggle="modal" data-target={"#" + modalRemoveId} onClick={deleteHandler.bind(this, ListFormat.name ? ListFormat.name : ListFormat, ListFormat.id ? ListFormat.id : index)}>
                      <span style={{
                          color: "rgb(110,110,110)"
                        }}>
                        <IoIosCloseCircleOutline size={28}/>
                      </span>
                  </button>
                  : <button disabled style={{
                      outline: 'none'
                    }} className="close">
                      <span style={{
                          color: "rgba(255,255,255,0)"
                        }}>
                        <IoIosCloseCircleOutline size={28}/>
                      </span>
                  </button>
                  }
                </div>
              </li>))
              : null
            }
          </ul>
      </PerfectScrollbar>
    </div>
)
};


export default TablePlus
