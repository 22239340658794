import React from 'react'
import $ from "jquery";
import ReactTable from 'react-table'
import find from 'lodash/find';
import GeneralModals from "./GeneralModals";
import ServeurAccessTab from "./ServeurAccessTab";
import ServeurAccessContextMenu from "./ServeurAccessContextMenu";
import MMAppErrorDashboardTab from "./MMAppErrorDashboardTab";
import StoreApplicationsVersion from "./StoreApplicationsVersion";
import {NavLink} from 'react-router-dom'
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {MdLens} from "react-icons/md";
import { MdClear } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

import {Doughnut, Bar} from 'react-chartjs-2';

import ReactTooltip from 'react-tooltip'

import LineGeneral from '../Components/LineGeneral';
import {varGeneral, url, varTerminaux} from '../variables';

const APIDevices = varGeneral.apiDevices;
const APIDashboard = varGeneral.apiDashboard;
const APIHost = varGeneral.apiHost;
const APIdevicesDetails = varTerminaux.apiDevicesDetailsApp;
const APImmappErrorDashboard = varGeneral.apiMMAppErrorDashboard;
const APIStoreNewVersionApplications = varGeneral.apiStoreNewVersionApplications;
const APIDevicesMMApp = varGeneral.apiDevicesMMApp;
const APIDangerousPermissions = varGeneral.apiDangerousPermissions;
const APIMDMConnection = varGeneral.apiMDMConnection;


var modelL = null;
var osL = null;
var graphModel = null;
var graphOS = null;
var newGraphModel = true;
var newGraphOS = true;
var time = 0;
var newRequete = false;

const labelDisplaySeuil = varGeneral.labelDisplaySeuil;
const schemaSeuil = varGeneral.schemaSeuil + 1;
const modeSeuilShow = varGeneral.modeSeuilShow;

const dognutHeight = 20;
const dognutWidth = 44;

var tabAutres = [];


const labels = ['01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h', '12h', '13h', '14h',
 '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h', '24h'];

export const options = {
  scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            min: 0
          }
        }]
      },
  responsive: true,

  legend: {
      display: false,
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

export const data = {
  labels,
  datasets: [
    {
      label: 'Nombre de terminaux connectés par heure',
      data: labels.map(() => Math.random() * (250)),
      backgroundColor: 'rgb(41,112,250)',
    },
  ],
};

// Fonction pour convertir les octets en Mo/Go
export function octetAdapt(value){
  var newValue = value;
  if(value > Math.pow(10, 9)){
    newValue = Number(parseInt(value)/Math.pow(10, 9)).toFixed(2) + " Go";
  } else {
    newValue = Number(parseInt(value)/Math.pow(10, 6)).toFixed(2) + " Mo";
  }
  return(newValue)
}



class dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      tableau: [],
      dataDashboard: [],
      SeuilAutres: [],
      seuilValue: schemaSeuil,
      seuilShowValue: modeSeuilShow,
      selected: null,
      hit: [],
      graphSelectMMApp: localStorage.getItem('graphSelectMMApp'),
      graphActivity: `${url}/Microsoft-Server-ActiveSync/graph_activity.html?lang=${this.props.intl.locale}&context=null`,
    };

    this.tick = this.tick.bind(this)

    this.contextMenu = this.contextMenu.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentDidMount() {



    newGraphModel = true;
    newGraphOS = true;

    if (varGeneral.enableTimer === "on")
      this.timer = setInterval(this.tick, 1000);
    if (process.env.NODE_ENV == "development")
      console.log("REFRESH AUTO dashboard : " + varGeneral.enableTimer + " , 1sec, " + varGeneral.timer + "sec");

    this.setState({isLoading: true});
    this.fetchAll();

  }

  componentDidUpdate(time) {
    document.title = this.props.intl.formatMessage({id: "app.menu.1"});

    newGraphModel = true;
    newGraphOS = true;

    if (time === varGeneral.timer) {
      this.fetchAll();
    }
  }

  componentWillUnmount() {
    time = 0;
    clearInterval(this.timer);
    if (this.state.graphSelectMMApp) {
      localStorage.setItem('graphSelectMMApp', this.state.graphSelectMMApp);
    }
  }




// ------------------------------- Action handle ------------------------------- //

    contextMenu(x){
      if(x === "empty"){
      this.setState({hit : "empty"});
    }else {
      this.setState({hit : x});
      }
    }

    selectedHandle(x){
      this.setState({selected : x});
    }

    handleGraphSelectChange(){
      this.setState({graphSelectMMApp : !this.state.graphSelectMMApp})
    }


// ------------------------------- Auto refresh table -------------------------- //

  tick() {
    if (newRequete === true) {
      if (time === varGeneral.timer) {
        time = 0;
        newRequete = false;
        this.fetchAll();
        this.setState({graphActivity: null});
        this.setState({graphActivity: `${url}/Microsoft-Server-ActiveSync/graph_activity.html?lang=${this.props.intl.locale}&context=null`});
      }
      time = time + 1;
    }
  }


// ------------------------------- DATA fetching ------------------------------- //

  fetchAll(){
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    if (process.env.NODE_ENV == "development")
      console.log("fetchAll : " + hours + ":" + min + ":" + sec);

    Promise.all([fetch(APIDevices ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(APIDashboard ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
        fetch(APIHost ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
      ]).then(([resAPIDevices, resAPIDashboard, resAPIHost]) => Promise.all([resAPIDevices.json(), resAPIDashboard.json(), resAPIHost.json()]))
        .then(([dataAPIDevices, dataAPIDashboard, dataAPIHost]) => (this.setState({
            tableau: dataAPIDevices, dataDashboard: dataAPIDashboard, dataHost: dataAPIHost, isLoading: false
        })));
        newRequete = true

        Promise.all([
            fetch(APIdevicesDetails ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(APImmappErrorDashboard ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(APIStoreNewVersionApplications ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(APIDevicesMMApp ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(APIDangerousPermissions ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(APIMDMConnection ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
          ]).then(([resAPIdevicesDetails, resAPImmappErrorDashboard, resAPIStoreNewVersionApplications, resAPIDevicesMMApp, resAPIDangerousPermissions, resAPIMDMConnection]) => Promise.all([resAPIdevicesDetails.json(), resAPImmappErrorDashboard.json(), resAPIStoreNewVersionApplications.json(), resAPIDevicesMMApp.json(), resAPIDangerousPermissions.json(), resAPIMDMConnection.json()]))
            .then(([dataAPIdevicesDetails, dataAPImmappErrorDashboard, dataAPIStoreNewVersionApplications, dataAPIDevicesMMApp, dataAPIDangerousPermissions, dataAPIMDMConnection]) => (this.setState({
                allDevicesNotCompliant: this.checkApp(dataAPIdevicesDetails),
                allDevicesRooted: this.checkRooted(dataAPIdevicesDetails),
                allDevicesStorage: this.checkStorage(dataAPIdevicesDetails),
                mmappErrorDashboard: dataAPImmappErrorDashboard,
                storeNewVersionApplications: dataAPIStoreNewVersionApplications,
                dataAPIDevicesMMApp: dataAPIDevicesMMApp,
                deviceOutOfGeofencingZone: this.checkDeviceOutOfGeofencing(dataAPIDevicesMMApp),
                deviceCloseToMobileDataLimit: this.checkDeviceMobileData(dataAPIDevicesMMApp),
                dataAPIDangerousPermissions,
                dataAPIMDMConnection,
                isLoading: false
            })));
  }

  checkApp(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
        if (data[i].compliant !== 1 && data[i].exception === false) {
          newTab.push(data[i]);
      }
    }
    return newTab;
  }

  checkRooted(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
        if (data[i].device_rooted === true) {
          newTab.push(data[i]);
      }
    }
    return newTab;
  }

  checkStorage(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
        if (data[i].percentage_used >= 80) {
          newTab.push(data[i]);
      }
    }
    return newTab;
  }

  checkDeviceOutOfGeofencing(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
      var geofencingZoneList = "";
      var notInASingleZone = true;
      if (data[i].geofencing_zone_infos && data[i].geofencing_zone_infos.indexOf("GEOFENCE_TRANSITION_EXIT") !== -1) {
        var hit = JSON.parse(data[i].geofencing_zone_infos);
        hit.map((x, index) => {
            if (x.state == "GEOFENCE_TRANSITION_ENTER") {
              notInASingleZone = false;
            }
            if (geofencingZoneList != "") {
              geofencingZoneList += ", "
            }
            geofencingZoneList += x.geofencingID;
    		})
        data[i].geofencing_zone_infos = geofencingZoneList;
        if (notInASingleZone) {
          newTab.push(data[i]);
        }
      }
    }
    return newTab;
  }

  checkDeviceMobileData(data){
    var newTab = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].mobile_data_usage && data[i].server_simcard_mobile_data_limit) {
        var value = data[i].mobile_data_usage;
        var max = data[i].server_simcard_mobile_data_limit;
        var affichedValue = value.substring(0, value.indexOf(" "));
        var newValue;
        var x = value.substring(value.indexOf(" ")+1);
        switch (x) {
          case "B":
                newValue = parseFloat(affichedValue.replace(',', '.')) * 1024;
            break;
          case "MB":
                newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024);
            break;
          case "GB":
                newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024);
            break;
          case "TB":
                newValue = parseFloat(affichedValue.replace(',', '.')) * (1024 * 1024 * 1024 * 1024);
            break;
          default:
        }
        var percentage = (newValue / max)*100;
        if (percentage >= 75) {
          var item = new Object();
          item = data[i];
    			item.percentage_mobile_data = percentage;
          newTab.push(item);
        }
      }
    }
    return newTab;
  }


  permissionsTrad(permissionName){
    switch (permissionName) {
      case "CAMERA":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.1"});
        break;
      case "CONTACTS":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.2"});
        break;
      case "LOCATION":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.3"});
        break;
      case "RECORD_AUDIO":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.4"});
        break;
      case "STORAGE":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.5"});
        break;
      case "PHONE":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.6"});
        break;
      case "CALENDAR":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.7"});
        break;
      case "SMS":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.8"});
        break;
      case "CALL_LOG":
          return this.props.intl.formatMessage({id: "terminauxDetails.permissions.9"});
        break;

      default:
        return permissionName;
    }
  }


// ------------------------------- Function with visual ------------------------ //

createProgessBar(name, percentage, availableValue, maxValue, index){

  var color = "#c4c4c4";
  var newAvailableValue = octetAdapt(parseInt(availableValue));
  var newMaxValue = octetAdapt(parseInt(maxValue));

  if(percentage <= 25){color = "#77e733";}
  else if(percentage <= 50){color = "#ffdc29";}
  else if(percentage <= 75){color = "#fc8d25";}
  else {color = "#fc2828";}

    return(
      <div key={index}>
        <div className="row mt-2 mb-3 ml-2">
        <div className="">
          <div className="" style={{ maxWidth: "7vw" }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
              textSize: '18px',
              pathTransitionDuration: 0.5,
              pathColor: color,
              textColor: color,
              trailColor: '#d6d6d6',
          })}/>

          </div>
        </div>
        <div className="mt-2 ml-3">

          {name.length > 0 ? <p><b className="text-info">{this.props.intl.formatMessage({id: "chemin"})} : </b>{name}</p> : null}
          {availableValue ? <p><b className="text-info">{this.props.intl.formatMessage({id: "disponible"})} : </b>{newAvailableValue}</p> : null}
          {maxValue ? <p><b className="text-info">Maximum : </b>{newMaxValue}</p> : null}

        </div>
      </div>
    </div>
  )
}


createModal(id, modalTitle, data, type) {

	var newTableau = [];

  if (data && type === "devicesToRenew") {
		data.map((x, index) => {
			var item = new Object();

			item.name = x.name;
			item.model = x.model;
			item.os = x.os;

			if (x.users.length > 0)
				item.users = x.users[0].login;

			newTableau.push(item);
		})
	}

	if (data && type === "devicesToUpdate") {
		data.map((x, index) => {
			var item = new Object();

			item.name = x.name;
			item.model = x.model;
			item.os = x.os;

			if (x.users.length > 0)
				item.users = x.users[0].login;

			newTableau.push(item);
		})
	}

  if (data && type === "noncompliantDevicesForOS") {
		data.map((x, index) => {
			var item = new Object();

			item.name = x.name;
			item.imei = x.imei;
			item.model = x.model;
			item.os = x.os;

			if (x.users.length > 0)
				item.users = x.users[0].login;

			newTableau.push(item);
		})
	}

  if (data && type === "allDevicesNotCompliant") {
		data.map((x, index) => {
			var item = new Object();

			item.email = x.email;
			item.model = x.model;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "allDevicesRooted") {
    console.log(data);
		data.map((x, index) => {
			var item = new Object();

			item.email = x.email;
			item.model = x.model;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "dataAPIDangerousPermissions") {
		data.map((x, index) => {
			var item = new Object();

			item.android_id = x.android_id;
			item.email = x.email;
			item.login = x.login;
			item.model = x.model;
			item.group_name = x.group_name;
			item.group_permission = this.permissionsTrad(x.group_permission);
			item.applist = x.applist;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "allDevicesStorage") {
		data.map((x, index) => {
			var item = new Object();

			item.android_id = x.android_id;
			item.email = x.email;
			item.login = x.login;
			item.model = x.model;
			item.storage = x.percentage_used;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "deviceOutOfGeofencingZone") {
		data.map((x, index) => {
			var item = new Object();

			item.android_id = x.android_id;
			item.email = x.email;
			item.login = x.login;
			item.model = x.model;
			item.geofencing_zone_infos = x.geofencing_zone_infos;

			newTableau.push(item);
		})
	}

	if (data && data.length > 0 && type === "deviceCloseToMobileDataLimit") {

		data.map((x, index) => {
			var item = new Object();

      item.data = x;
			item.model = x.model;
			item.email = x.email;
			item.percentage= x.percentage_mobile_data;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "mmappErrorDashboard") {

		data.map((x, index) => {
			var item = new Object();

			item.names = this.getApplicationName(x);
			item.packageName = x.packageName;
			item.applicationSystem = x.applicationSystem;
			item.latestKnownVersion = x.latestKnownVersion;
			item.latestKnownVersionCode = x.latestKnownVersionCode;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && type === "storeNewVersionApplications") {
		data.map((x, index) => {
			var item = new Object();

			item.names = this.getApplicationName(x);
			item.packageName = x.packageName;
			item.applicationSystem = x.applicationSystem;
			item.latestKnownVersion = x.latestKnownVersion;
			item.latestKnownVersionCode = x.latestKnownVersionCode;
			item.storeVersion = x.storeVersion;
			item.storeVersionCode = x.storeVersionCode;

			newTableau.push(item);
		})
	}

  if (data && data.length > 0 && (type === "errorGroupware" || type === "errorImap" || type === "errorLdap") ) {


		data.map((x, index) => {
			var item = new Object();
      item.date = Date.parse(x.date);
			item.account = x.account;
      item.email = x.email;

      switch (id) {
        case "imap":
          item.topic = this.props.intl.formatMessage({id: "general.modal.error.imap"});
          break;
        case "groupware":
          item.topic = this.props.intl.formatMessage({id: "general.modal.error.groupware"});
          break;
        case "ldap":
          item.topic = this.props.intl.formatMessage({id: "general.modal.error.ldap"});
          break;
        default:

      }
			item.occurrence = x.occurrence;

			newTableau.push(item);
		})
	}

  var modalSize = "modal-lg";

  if (type === "storeNewVersionApplications" || type === "dataAPIDangerousPermissions") {
    modalSize = "modal-xl";
  } else {
    modalSize = "modal-lg";
  }

	if (data) {
		return (<div className="modal fade" id={id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className={`modal-dialog ${modalSize} modal-dialog-centered`} role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">{modalTitle}</h5>


            						{this.props.userAdministration === "ADMINISTRATOR" ? this.getButtonError(id) : null}

						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">


            { type === "errorGroupware" || type === "errorImap" || type === "errorLdap" ?

              <div>
                <ServeurAccessTab
                data={newTableau}
                contextMenu={this.contextMenu}
                selected={this.state.selected}
                selectedHandle={this.selectedHandle}
                type={type}
                id={id}/>

                <ServeurAccessContextMenu
                hit={this.state.hit}
                type={type}/>

              </div>

              :

              type === "mmappErrorDashboard" ?
                <div>
                  <MMAppErrorDashboardTab
                  data={newTableau}
                  contextMenu={this.contextMenu}
                  selected={this.state.selected}
                  selectedHandle={this.selectedHandle}
                  type={type}
                  id={id}/>
                </div>

              :

              type === "storeNewVersionApplications" ?
                <div>
                  <StoreApplicationsVersion
                  data={newTableau}
                  selected={this.state.selected}
                  selectedHandle={this.selectedHandle}
                  type={type}
                  id={id}/>
                </div>

              :
              <GeneralModals id={id} data={newTableau} langue={this.props.intl.locale}/>
            }

						<div className="col text-center border-top small card rounded-0">
							<b className="text-dark"><FormattedMessage id={"nb.ligne"}/>{data.length}</b>
						</div>

					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal">
							<FormattedMessage id={"fermer"}/></button>
					</div>
				</div>
			</div>
		</div>)
	}
}

getButtonError(id) {

  var idDelete = "";

  switch (id) {
    case "imap":
        idDelete = "resetErrorImap";
      break;
    case "groupware":
        idDelete = "resetErrorGroupware";
      break;
    case "ldap":
        idDelete = "resetErrorLdap";
      break;
    default:

  }

  if (id === "imap" || id === "groupware" || id === "ldap") {
    return (<button type="button" className="btn btn-sm btn-info mb-3 ml-1" onClick={this.deleteErrorsMessages.bind(this, idDelete, id)}>
      <FormattedMessage id={"general.modal.errorbutton"}/>
    </button>)
  }
  if (id === "mmappErrorDashboard") {
    return (<button type="button" className="btn btn-sm btn-info ml-4" onClick={this.valideErrorDashboard.bind(this, id)}>
      <FormattedMessage id={"general.modal.mmappErrorDashboard"}/>
    </button>)
  }
}

  deleteErrorsMessages(idDelete, id){
  if(process.env.NODE_ENV == "development")console.log(url + "/api/dashboard/" + idDelete);

  fetch(url + "/api/dashboard/" + idDelete, {
      method: 'PUT',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': this.props.tokenCSRF
    },
      body: null
    })
    .then(response => {
      if(process.env.NODE_ENV == "development")console.log(response);
      var x = response;
      if(response.status === 200){
        $('#'+id).modal('hide');
        this.fetchAll();
      }
      else{
       return response.json().then(responseError => {
         console.log(responseError);
       })
     }
     })
  }

  valideErrorDashboard(id){


    fetch(APImmappErrorDashboard + "/valide/", {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
        body: JSON.stringify(this.state.mmappErrorDashboard.applicationList)
      })
      .then(response => {
        if(process.env.NODE_ENV == "development")console.log(response);
        var x = response;
        if(response.status === 200){
          $('#'+id).modal('hide');
          this.fetchAll();
        }
        else{
         return response.json().then(responseError => {
           console.log(responseError);
         })
       }
       })
  }


// ------------------------------- Function for Donut -------------------------- //

  showGraphDonut(tab, elem) {

    var data,
      list,
      graph;
    var devList = [];
    var devNumber = [];

    if (tab.length !== 0) {
      list = this.getDevicesList(tab, elem);
    }
    if (list) {
      data = this.getDevicesNumber(list);

      if (elem === "model") {
        if (data.length !== modelL) {
          newGraphModel = true;
        }
        modelL = data.length;
      } else {
        if (data.length !== osL) {
          newGraphOS = true;
        }
        osL = data.length;
      }

      if (data.length > this.state.seuilValue - 1) {
        const [newData, autresList] = this.triAutres(data);
        if (elem === "model")
          tabAutres[0] = autresList;
        if (elem === "os")
          tabAutres[1] = autresList;

        newData.sort((a, b) => a.value - b.value).map((x, index) => {
          devList.push(x.model)
          devNumber.push(x.value)
        })
      } else {
        data.sort((a, b) => a.value - b.value).map((x, index) => {
          devList.push(x.model)
          devNumber.push(x.value)
        })
      }

      if (elem === "model") {
        if (newGraphModel === true) {
          graphModel = this.getData(devList, devNumber, this.randomColorSize(devList.length, elem, false), this.randomColorSize(devList.length, elem, true));
          newGraphModel = false;
        }

        return (<Doughnut id={"DOGNUT_" + elem} data={graphModel} height={dognutHeight} width={dognutWidth} options={this.getOptions(devList.length, this.state.seuilShowValue)}/>)
      } else {
        if (newGraphOS === true) {
          graphOS = this.getData(devList, devNumber, this.randomColorSize(devList.length, elem, false), this.randomColorSize(devList.length, elem, true));
          newGraphOS = false;
        }

        return (<Doughnut id={"DOGNUT_" + elem} data={graphOS} height={dognutHeight} width={dognutWidth} options={this.getOptions(devList.length, this.state.seuilShowValue)}/>)

      }
    }
  }

  preGraphOSCheck(data, dataMMAPP){

    var checkedTab = data;
    var newTab = [];


    if (data && dataMMAPP) {

      for (var i = 0; i < data.length; i++) {
        var finded = false;
        var item = new Object();

        if (data[i].users[0] && data[i].users[0].email) {
          var checkUserEmail = data[i].users[0].email;
          var checkModel = data[i].model;


          for (var x = 0; x < dataMMAPP.length; x++) {
            if (dataMMAPP[x].email == checkUserEmail && dataMMAPP[x].model == checkModel) {
              finded = true;
              item.os = "Android " + dataMMAPP[x].os_Version;
            }
          }
        }

        if (!finded) {
          item.os = data[i].os;
        }

        newTab.push(item);
      }
      //console.log(newTab);

      return (this.showGraphDonut(newTab, "os"))

    } else if(data){
      return (this.showGraphDonut(data, "os"))
    }

  }

  triAutres(data) {
    var newData = [];
    var dataplus = [];
    var autres = [];
    data.sort((a, b) => b.value - a.value).map((x, index) => {
      newData.push(x);
    })

    for (var i = 0; i < newData.length; i++) {
      if (i < this.state.seuilShowValue) {
        dataplus.push(newData[i]);
      } else {
        autres.push(newData[i]);
      }
    }

    newData = dataplus;
    var autresValue = 0;
    var newDataValue = 0;
    for (var y = 0; y < autres.length; y++) {
      autresValue = autresValue + autres[y].value;
    }
    for (var z = 0; z < newData.length; z++) {
      newDataValue = newDataValue + newData[z].value;
    }

    var item = new Object();
    item.model = this.props.intl.formatMessage({id: "general.modal.autres.1"});
    item.value = newDataValue / 3;
    newData.push(item);

    return [newData, autres]
  }

  getOptions(length, seuilShowValue) {
    var otherName = this.props.intl.formatMessage({id: "general.modal.autres.1"});
    var state = true;
    if (length > labelDisplaySeuil) {
      state = false;
    }

    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.font = "12px Arial";
    var width = ctx.measureText(otherName).width;

    var options = {
      tooltips: {
        enabled: true,
        bodyFontSize: 12,
        "custom": (evt, tooltipModel) => {

          if (evt.body) {
            if (evt.body[0].lines[0].indexOf(otherName) !== -1) {
              evt.body[0].lines[0] = otherName;
              evt.width = width + 26;
            }
          }
        }
      },
      maintainAspectRatio: true,
      responsive: true,
      cutoutPercentage: varGeneral.graphPourcentage,
      onHover: (evt, chartElement) => {
        evt.target.style.cursor = 'default';
        if (chartElement[0]) {
          if (chartElement[0]._view.label.indexOf(otherName) !== -1) {
            evt.target.style.cursor = 'pointer';
          }
        }

      },
      onClick: function(evt, item) {
        if (item[0]) {
          //console.log ('legend onClick', item[0]._model.label);
          if (item[0]._model.label == otherName) {
            if (item[0]._chart.canvas.id === "DOGNUT_model") {
              $('#ModalSeuil' + 0).modal('show')
            }
            if (item[0]._chart.canvas.id === "DOGNUT_os") {
              $('#ModalSeuil' + 1).modal('show')
            }
          }
        }
      },
      legend: {
        position: 'bottom',
        display: state,
        labels: {
          boxWidth: 10
        }
      }
    }
    return (options)
  }

  getData(labels, data, backgroundColor, hoverBackgroundColor) {

    const graph = {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          hoverBackgroundColor,
          borderWidth: 1,
        }
      ]
    };
    return (graph)
  }

  getApplicationName(appli){
    var name = "";

    switch (this.props.intl.locale) {
      case "fr":
          if (appli.names.fr !== undefined) {
            name = appli.names.fr;
          } else if (appli.names.en !== undefined){
            name = appli.names.en + " (en)";
          }
        break;
      case "en":
          if (appli.names.en !== undefined) {
            name = appli.names.en;
          } else if (appli.names.fr !== undefined){
            name = appli.names.fr + " (fr)";
          }
        break;
      default:
          name = appli.packageName;
        break;
    }

    return name;
  }

  getRelais(tab){

    var relaisOn = 0;
    var relaisSize = 0;
    if(tab){relaisSize = tab.length;}
    var relaisOnFinal;
    var stateColor = "rgb(240,34,34)"

    if (tab) {
      tab.map((x) => {if(x.connected)relaisOn++})

      if (relaisOn > (tab.length/2))stateColor = "rgb(41,219,49)";
      else if(relaisOn > 0)stateColor = "rgb(240,157,34)";

      relaisOnFinal = (relaisOn + "/" + tab.length);
    }

    return (
      <div>
      <span className="col-auto" data-tip data-for='relaisList' style={{
          color: stateColor
        }}>
        <MdLens size={22}/>
        <b className="text-success">
           {this.props.intl.formatMessage({id: "general.title.5.relay"})}</b>
        <span className="text-success">
          {relaisOnFinal}
        </span>
      </span>
      {
        relaisSize > 0 ?
        <ReactTooltip className="relayClass" id='relaisList' delayHide={500} type={'light'} border={true} effect={'solid'} place={'bottom'}>
          {this.state.dataDashboard.relais ?
            this.state.dataDashboard.relais.map((x, index) =>
            <li key={index}>{x.name} : {x.connected ?
              <span style={{
                  color: "rgb(41,219,49)"
                }}>
                <IoMdCheckmark size={20}/>
              </span>
              :<span style={{
                  color: "rgb(233,28,28)"
                }}>
                <MdClear size={20}/>
              </span>

            }</li>
          )
            :null
          }
        </ReactTooltip>
        : null
      }

    </div>
    )
  }

  randomColorSize(length, elem, hover) {

    var tab = [];
    var tabHover = [];
    for (var i = 0; i < length; i++) {
      const color = this.randomColor();
      tab.push(color);
      tabHover.push(this.randomColor());
    }

    const tab2 = [
      "rgb(255,124,163)",
      "rgb(255,43,158)",
      "rgb(231,36,255)",
      "rgb(161,37,247)",
      "rgb(77,41,255)",
      "rgb(41,112,250)",
      "rgb(47,185,252)",
      "rgb(42,254,183)",
      "rgb(38,255,92)",
      "rgb(167,255,42)",
      "rgb(255,220,41)",
      "rgb(252,160,37)",
      "rgb(252,88,37)",
      "rgb(252,40,40)"
    ];

    const tabHover2 = [
      "rgba(255,124,163,.5)",
      "rgba(255,43,158,.5)",
      "rgba(231,36,255,.5)",
      "rgba(161,37,247,.5)",
      "rgba(77,41,255,.5)",
      "rgba(41,112,250,.5)",
      "rgba(47,185,252,.5)",
      "rgba(42,254,183,.5)",
      "rgba(38,255,92,.5)",
      "rgba(167,255,42,.5)",
      "rgba(255,220,41,.5)",
      "rgba(252,160,37,.5)",
      "rgba(252,88,37,.5)",
      "rgba(252,40,40,.5)"
    ];

    if (hover) {
      return (tabHover2)
    } else {
      return (tab2)
    }
  }

  randomColor() {
    var color = [];
    for (var i = 0; i < 3; i++) {
      color.push(25 + Math.random() * (230 - 1))
    }
    return ("rgb(" + color + ")")
  }

  getDevicesList(tab, elem) {
    if (tab && tab.length > 0) {
      var list = [];
      if (elem === "model") {
        tab.map((tab, index) => {
          if (tab.model) {
            list.push(tab.model);
          } else {
            list.push(this.props.intl.formatMessage({id: "general.6.2.undefined"}))
          }
        })
      } else if (elem === "os") {
        tab.map((tab, index) => {
          if (tab.os) {
            list.push(tab.os);
          } else {
            list.push(this.props.intl.formatMessage({id: "general.6.3.undefined"}))
          }
        })
      }
      return (list)
    }

  }

  getDevicesNumber(list) {
    var DevicesList = new Array();

    for (var i = 0; i < list.length; i++) {
      var item = new Object();
      item.model = list[i];
      item.value = 1;

      for (var i = 0; i < DevicesList.length; i++) {
        if (item.model === DevicesList[i].model) {
          item.value = DevicesList[i].value + 1;
          DevicesList[i].double = true;
        }
      }
      DevicesList.push(item);
    }

    DevicesList = this.removeDouble(DevicesList);
    return (DevicesList)
  }

  getMDMConnectionData(){
    var data = this.state.dataAPIMDMConnection;
    var adaptData = [];
    labels.map((label) =>
    {
      var item = new Object();
      item.numberOfConnection = 0;

      for (var i = 0; i < data.length; i++) {
        if (label === data[i].timeSlot) {
          item.numberOfConnection = data[i].numberOfConnection;
        }
      }
      item.timeSlot = label;
      adaptData.push(item);
    }

  )

    if (data) {
      var result = {labels,
      datasets: [
        {
          label: this.props.intl.formatMessage({id: "general.graph.mdm.hover"}),
          data: adaptData.map((hit) => hit.numberOfConnection),

          backgroundColor: 'rgb(41,112,250)',
        },
      ],}
      return result;
    } else {
      return null;
    }

  }


  removeDouble(tab) {
    var newTab = [];
    tab.map((x, index) => {
      if (x.double !== true) {
        newTab.push(x);
      }
    })
    return (newTab)
  }


// ------------------------------- Setting of the table ------------------------ //

  getColumns(index) {

    var columns = [
      {
        Header: () => (<span>
          <b>
            {
              index === 0
                ? <div>{this.props.intl.formatMessage({id: "general.modal.autres.2"})}</div>
                : <div>O.S.</div>
            }</b>
        </span>),
        accessor: 'model',
        minWidth: 240,
        maxWidth: 240
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "general.modal.autres.3"})}
          </b>
        </span>),
        accessor: 'value'
      }
    ]

    return (columns)
  }


// ------------------------------- Render -------------------------------------- //

  render() {

    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Erreur : {error.message}</span>
        </div>

        <div className="container small"/>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, {id: col.id});
      const boxShadow = sortedCol
        ? `inset 0px ${sortedCol.desc
          ? -2
          : 2}px 0px 0px white`
        : '';
      return {style: {
          boxShadow
        }};
    };

    return (<div className="container-fluid small mt-3">
      <div className="row">
        <div className="col" style={{maxHeight:"91vh"}}>
          <PerfectScrollbar>

          <div className="card rounded-0 bg-white">
            <div className="card-header border-info text-info bg-light p-2">
              <span className="">
                <b>
                  {this.props.intl.formatMessage({id: "general.title.1"})}
                </b>
              </span>

            </div>

            <div className="card border-0 ml-2 mr-1 mt-1 mb-1">

            {
              this.state.dataDashboard.users > 0
                ? <NavLink className="used" style={{
                      color: "rgba(0,0,0,1)"
                    }} to={{
                      pathname: "/Users"
                    }} title={this.props.intl.formatMessage({id: "general.1.1.tooltip"})}>
                    <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.1"})} valeur={this.state.dataDashboard.users}/>
                  </NavLink>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.1"})} valeur="0"/>
            }

              {
                this.state.dataDashboard.mobiles > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to={{
                        pathname: "/Devices"
                      }} title={this.props.intl.formatMessage({id: "general.1.2.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.2"})} valeur={this.state.dataDashboard.mobiles}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.2"})} valeur="0"/>
              }

              {
                this.state.dataDashboard.disabledUsers
                  ? this.createModal("nbuserdesac", this.props.intl.formatMessage({id: "general.modal.2"}))
                  : null
              }
              {
                this.state.dataDashboard.disabledUsers ? this.state.dataDashboard.disabledUsers.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to={{
                        pathname: "/Users",
                        defaultResearch: "state:disabled"
                      }} title={this.props.intl.formatMessage({id: "general.2.1.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.1"})} valeur={this.state.dataDashboard.disabledUsers.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.1"})} valeur={"0"}/>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.1"})} valeur={"0"}/>
              }

              {
                this.state.dataDashboard.disabledDevices
                  ? this.createModal("nbtermdesac", this.props.intl.formatMessage({id: "general.modal.3"}))
                  : null
              }
              {
                this.state.dataDashboard.disabledDevices ? this.state.dataDashboard.disabledDevices.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to={{
                        pathname: "/Devices",
                        defaultResearch: "state:disabled"
                      }} title={this.props.intl.formatMessage({id: "general.2.2.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.2"})} valeur={this.state.dataDashboard.disabledDevices.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.2"})} valeur="0"/>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.2"})} valeur="0"/>
              }

              {/*
                this.state.dataDashboard.groupes > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to={{
                        pathname: "/Groups"
                      }} title={this.props.intl.formatMessage({id: "general.1.6.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.6"})} valeur={this.state.dataDashboard.groupes}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.6"})} valeur="0"/>
              */}






            </div>
          </div>

          <div className="card rounded-0 bg-white mt-3">
            <div className="card-header border-info text-info bg-light p-2">
              <span className="">
                <b>
                  {this.props.intl.formatMessage({id: "general.title.2"})}
                </b>
              </span>
            </div>
            <div className="card border-0 ml-2 mr-1 mt-1 mb-1">



            <NavLink className="used" style={{
                    color: "rgba(0,0,0,1)"
                  }} to={{
                  pathname: "/Parametres",
                  tabActive: 5
                }} title={this.props.intl.formatMessage({id: "general.1.4.tooltip"})}>
              <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.4"})} etat={this.state.dataDashboard.requiredNewDeviceValidation > 0}/>
            </NavLink>

            <NavLink className="used" style={{
                color: "rgba(0,0,0,1)"
              }} to={{
                pathname: "/Parametres",
                tabActive: 5
              }} title={this.props.intl.formatMessage({id: "general.1.3.tooltip"})}>
              <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.3"})} etat={this.state.dataDashboard.singleAssociationDeviceUser > 0}/>
            </NavLink>

            <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.5"})} etat={this.state.dataDashboard.securityPolicies > 0}/>


                          <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to={{
                              pathname: "/Parametres",
                              tabActive: 5
                            }} title={this.props.intl.formatMessage({id: "general.1.7.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.7"})} etat={this.props.data ? this.props.data.enableLocation == true ? true : false : false}/>
                          </NavLink>

                          <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to={{
                              pathname: "/Parametres",
                              tabActive: 5
                            }} title={this.props.intl.formatMessage({id: "general.1.8.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.8"})} etat={this.props.data ? this.props.data.enableSaveDeviceData == true ? true : false : false}/>
                          </NavLink>

                          <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to={{
                              pathname: "/Parametres",
                              tabActive: 5
                            }} title={this.props.intl.formatMessage({id: "general.2.autoStorageManagement.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.autoStorageManagement"})} etat={this.props.data ? this.props.data.autoStorageManagement == true ? true : false : false}/>
                          </NavLink>

                          <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to={{
                              pathname: "/Parametres",
                              tabActive: 5
                            }} title={this.props.intl.formatMessage({id: "general.1.9.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.1.9"})} etat={this.props.data ? this.props.data.deleteDataOnceSaved == true ? true : false : false}/>
                          </NavLink>


                          {
                            //<LineGeneral texte={this.props.intl.formatMessage({id: "general.1.10"})} etat={false}/>
                          }



            </div>
          </div>

          <div className="card rounded-0 bg-white mt-3">
            <div className="card-header border-info text-info bg-light p-2">
              <span className="">
                <b>
                  {this.props.intl.formatMessage({id: "general.title.3"})}
                </b>
              </span>
            </div>
            <div className="card border-0 ml-2 mr-1 mt-1 mb-1">

              {
                this.state.dataDashboard.unvalidatedDevices ? this.state.dataDashboard.unvalidatedDevices.length > 0
                  ? this.createModal("nbtermval", this.props.intl.formatMessage({id: "general.modal.4"}))
                  : null : null
              }
              {
                this.state.dataDashboard.unvalidatedDevices ? this.state.dataDashboard.unvalidatedDevices.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to={{
                        pathname: "/Devices",
                        defaultResearch: "state:new"
                      }} title={this.props.intl.formatMessage({id: "general.2.3.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.3"})} valeur={this.state.dataDashboard.unvalidatedDevices.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.3"})} valeur="0"/>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.2.3"})} valeur="0"/>
              }

              {
                this.state.dataDashboard.devicesToRenew
                  ? this.createModal("termrenouv", this.props.intl.formatMessage({id: "general.modal.5"}), this.state.dataDashboard.devicesToRenew, "devicesToRenew")
                  : null
              }
              {
                this.state.dataDashboard.devicesToRenew ? this.state.dataDashboard.devicesToRenew.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#termrenouv" title={this.props.intl.formatMessage({id: "general.3.1.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.1"})} valeur={this.state.dataDashboard.devicesToRenew.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.1"})} valeur="0"/>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.1"})} valeur="0"/>
              }

              {
                this.state.dataDashboard.noncompliantDevicesForOS
                  ? this.createModal("termconf", this.props.intl.formatMessage({id: "general.modal.6"}), this.state.dataDashboard.noncompliantDevicesForOS, "noncompliantDevicesForOS")
                  : null
              }
              {
                this.state.dataDashboard.noncompliantDevicesForOS ? this.state.dataDashboard.noncompliantDevicesForOS.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#termconf" title={this.props.intl.formatMessage({id: "general.3.2.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.2"})} valeur={this.state.dataDashboard.noncompliantDevicesForOS.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.2"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.2"})} valeur="0"/>
              }

              {
                this.state.allDevicesNotCompliant
                  ? this.createModal("termconfapp", this.props.intl.formatMessage({id: "general.modal.7"}), this.state.allDevicesNotCompliant, "allDevicesNotCompliant")
                  : null
              }
              {
                this.state.allDevicesNotCompliant ? this.state.allDevicesNotCompliant.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#termconfapp" title={this.props.intl.formatMessage({id: "general.3.3.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.3"})} valeur={this.state.allDevicesNotCompliant.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.3"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.3"})} valeur="0"/>
              }


              {
                this.state.dataDashboard.devicesToUpdate
                  ? this.createModal("nbtermatt", this.props.intl.formatMessage({id: "general.modal.8"}), this.state.dataDashboard.devicesToUpdate, "devicesToUpdate")
                  : null
              }
              {
                this.state.dataDashboard.devicesToUpdate ? this.state.dataDashboard.devicesToUpdate.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#nbtermatt" title={this.props.intl.formatMessage({id: "general.3.4.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.4"})} valeur={this.state.dataDashboard.devicesToUpdate.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.4"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.4"})} valeur="0"/>
              }

              {
                this.state.deviceCloseToMobileDataLimit
                  ? this.createModal("deviceCloseToMobileDataLimit", this.props.intl.formatMessage({id: "general.modal.9"}), this.state.deviceCloseToMobileDataLimit, "deviceCloseToMobileDataLimit")
                  : null
              }
              {
                this.state.deviceCloseToMobileDataLimit ? this.state.deviceCloseToMobileDataLimit.length > 0
                      ? <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#deviceCloseToMobileDataLimit" title={this.props.intl.formatMessage({id: "general.3.5.tooltip"})}>
                          <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.5"})} valeur={this.state.deviceCloseToMobileDataLimit.length}/>
                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.5"})} valeur="0"/>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.5"})} valeur="0"/>
              }

              {
                this.state.allDevicesRooted
                  ? this.createModal("termrooted", this.props.intl.formatMessage({id: "general.modal.14"}), this.state.allDevicesRooted, "allDevicesRooted")
                  : null
              }
              {
                this.state.allDevicesRooted ? this.state.allDevicesRooted.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#termrooted" title={this.props.intl.formatMessage({id: "general.3.6.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.6"})} valeur={this.state.allDevicesRooted.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.6"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.6"})} valeur="0"/>
              }

              {
                this.state.dataAPIDangerousPermissions
                  ? this.createModal("termDangerousPermissions", this.props.intl.formatMessage({id: "general.modal.16"}), this.state.dataAPIDangerousPermissions, "dataAPIDangerousPermissions")
                  : null
              }
              {
                this.state.dataAPIDangerousPermissions ? this.state.dataAPIDangerousPermissions.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#termDangerousPermissions" title={this.props.intl.formatMessage({id: "general.3.7.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.7"})} valeur={this.state.dataAPIDangerousPermissions.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.7"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.7"})} valeur="0"/>
              }



              {
                this.state.allDevicesStorage
                  ? this.createModal("allDevicesStorage", this.props.intl.formatMessage({id: "general.modal.17"}), this.state.allDevicesStorage, "allDevicesStorage")
                  : null
              }
              {
                this.state.allDevicesStorage ? this.state.allDevicesStorage.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#allDevicesStorage" title={this.props.intl.formatMessage({id: "general.3.8.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.8"})} valeur={this.state.allDevicesStorage.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.8"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.8"})} valeur="0"/>
              }



              {
                this.state.deviceOutOfGeofencingZone
                  ? this.createModal("deviceOutOfGeofencingZone", this.props.intl.formatMessage({id: "general.modal.18"}), this.state.deviceOutOfGeofencingZone, "deviceOutOfGeofencingZone")
                  : null
              }
              {
                this.state.deviceOutOfGeofencingZone ? this.state.deviceOutOfGeofencingZone.length > 0
                  ? <NavLink className="used" style={{
                        color: "rgba(0,0,0,1)"
                      }} to="" data-toggle="modal" data-target="#deviceOutOfGeofencingZone" title={this.props.intl.formatMessage({id: "general.3.9.tooltip"})}>
                      <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.9"})} valeur={this.state.deviceOutOfGeofencingZone.length}/>
                    </NavLink>
                  : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.9"})} valeur="0"/>
                : <LineGeneral texte={this.props.intl.formatMessage({id: "general.3.9"})} valeur="0"/>
              }


            </div>
          </div>



          </PerfectScrollbar>
        </div>

        <div className="col-7 mr-2">

          <div className="row">
            <div className="col">
              <div className="card rounded-0 bg-white">
                <div className="card-header border-info text-info bg-light p-2">
                    <div className="row">
                      <span className="col">
                        <b>
                          {this.props.intl.formatMessage({id: "general.title.5"})}
                        </b>
                      </span>


                      {this.props.userAdministration === "REMOTE_ADMIN" ?
                        <div className="col">
                          <div className="row" style={{maxHeight:"2vh"}}>
                            MDM
                          </div>
                        </div>

                        :

                        this.state.dataAPIMDMConnection ?
                          <div className="col">
                            <div className="row" style={{maxHeight:"2vh"}}>
                              <label className=" mr-2" htmlFor="switchForGraph">{this.props.intl.formatMessage({id: "general.graph.mdm.button"})}</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switchForGraph" checked={this.state.graphSelectMMApp} onChange={this.handleGraphSelectChange.bind(this)}/>
                                <label className="custom-control-label" htmlFor="switchForGraph">MDM</label>
                              </div>
                            </div>
                          </div>
                          :
                          null

                      }



                      <div className="float-right card rounded-0 border bg-white small col-auto mr-3">

                        {this.getRelais(this.state.dataDashboard.relais)}

                      </div>
                  </div>
                </div>

                <div style={{height: "25vh"}}>



                  {this.props.userAdministration === "REMOTE_ADMIN" ?

                    this.state.dataAPIMDMConnection ?
                      <div className="m-3 p-2">
                        <Bar options={options} data={this.getMDMConnectionData()} height={1} width={5}/>
                      </div>
                    :
                      null

                    :

                    this.state.dataAPIMDMConnection && this.state.graphSelectMMApp ?

                      <div className="m-3 p-2">
                        <Bar options={options} data={this.getMDMConnectionData()} height={1} width={5}/>
                      </div>

                      :

                      <div className="col-auto mt-4">
                        <h6 className="d-flex justify-content-center text-info mt-2 mb-2">{this.props.intl.formatMessage({id: "general.5.1"})}</h6>
                        <div className="d-flex justify-content-center">
                          <iframe className="embed-responsive-item border-0" src= {this.state.graphActivity} width="800" height="170"/>

                        </div>
                      </div>

                  }
                </div>


              </div>
            </div>
          </div>

          <div className="card rounded-0 bg-white mt-2">




          <div className="tab-content" id="myTabContent">

            {<div className="tab-pane fade show active" id="terminaux" role="tabpanel" aria-labelledby="home-tab">

              <div className="row text-center ml-3">
                <div className="col-6 mt-2 mb-2">
                  <h6 className="text-info mb-3 mr-4 border">{this.props.intl.formatMessage({id: "general.6.2"})}</h6>
                  {this.showGraphDonut(this.state.tableau, "model")}
                  <div className="text-dark mt-2 mb-1 mr-4 card rounded-0">{this.props.intl.formatMessage({id: "general.6.2.nb"}) + modelL}</div>

                </div>
                <div className="col-6 mt-2" style={{width: "100%"}}>
                  <h6 className="text-info mb-3 mr-4 border">{this.props.intl.formatMessage({id: "general.6.3"})}</h6>
                  {this.preGraphOSCheck(this.state.tableau , this.state.dataAPIDevicesMMApp, "os")}
                  <div className="text-dark mt-2 mb-1 mr-4 card rounded-0">{this.props.intl.formatMessage({id: "general.6.3.nb"}) + osL}</div>
                </div>
              </div>

            </div>}

            <div className="tab-pane fade" id="serveur" role="tabpanel" aria-labelledby="profile-tab">


                <PerfectScrollbar>
              <div className="col mt-3 mb-4" style={{height: "44vh"}}>

                {this.state.dataHost ?
                  <div className="row">
                    <div className="col">

                      <b className="text-info text-center mb-2 card rounded-0">{this.props.intl.formatMessage({id: "general.6.4.memoryInfo"}) + " : "}</b>
                      <div className="col mb-2">
                        {this.createProgessBar("", Number(((this.state.dataHost.memoryInfo.maxMemory - this.state.dataHost.memoryInfo.availableMemory)/this.state.dataHost.memoryInfo.maxMemory)*100).toFixed(2), this.state.dataHost.memoryInfo.availableMemory, this.state.dataHost.memoryInfo.maxMemory)}
                      </div>

                    </div>

                    <div className="col mb-2">

                      <b className="text-info text-center mb-2 card rounded-0">{this.props.intl.formatMessage({id: "general.6.4.filesystemInfo"}) + " : "}</b>
                      <div className="col">
                        {this.state.dataHost.filesystemInfo.map((list,index) =>{
                          return(this.createProgessBar(list.path, Number(((list.totalSpace - list.freeSpace)/list.totalSpace)*100).toFixed(2), list.freeSpace, list.totalSpace, index))
                          })
                        }
                    </div>

                  </div>

                </div>
                  :null
                }

              </div>

            </PerfectScrollbar>
            </div>

          </div>

          </div>


          {this.props.userAdministration === "REMOTE_ADMIN" ?
          null
          :
          <div className="card rounded-0 bg-white mt-2">
            <div className="card-header border-info text-info bg-light p-2">
              <span className="">
                <b>
                  {this.props.intl.formatMessage({id: "general.title.4"})}
                </b>
              </span>
            </div>
            <div className="card border-0 ml-2 mr-1 mt-1 mb-1">

              <div className="row">
                <div className="col">
                  {
                    this.state.dataDashboard.errorImap
                      ? this.createModal("imap", this.props.intl.formatMessage({id: "general.modal.10"}), this.state.dataDashboard.errorImap, "errorImap")
                      : null
                  }
                  {
                    this.state.dataDashboard.errorImap ? this.state.dataDashboard.errorImap.length > 0
                      ? <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#imap" title={this.props.intl.formatMessage({id: "general.4.1.tooltip"})}>
                          <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.1"})} etat={false}/>
                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.1"})} etat={true}/>
                    : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.1"})} etat={true}/>
                  }
                </div>
                <div className="col border-left border-secondary">
                  {
                    this.state.dataDashboard.errorGroupware
                      ? this.createModal("groupware", this.props.intl.formatMessage({id: "general.modal.11"}), this.state.dataDashboard.errorGroupware, "errorGroupware")
                      : null
                  }
                  {
                    this.state.dataDashboard.errorGroupware ? this.state.dataDashboard.errorGroupware.length > 0
                      ? <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#groupware" title={this.props.intl.formatMessage({id: "general.4.2.tooltip"})}>
                          <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.2"})} etat={false}/>
                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.2"})} etat={true}/>
                    : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.2"})} etat={true}/>
                  }
                </div>
                <div className="col border-left border-secondary">
                  {
                    this.state.dataDashboard.errorLdap
                      ? this.createModal("ldap", this.props.intl.formatMessage({id: "general.modal.12"}), this.state.dataDashboard.errorLdap, "errorLdap")
                      : null
                  }
                  {
                    this.state.dataDashboard.errorLdap ? this.state.dataDashboard.errorLdap.length > 0
                      ? <NavLink className="used" style={{
                            color: "rgba(0,0,0,1)"
                          }} to="" data-toggle="modal" data-target="#ldap" title={this.props.intl.formatMessage({id: "general.4.3.tooltip"})}>
                          <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.3"})} etat={false}/>
                        </NavLink>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.3"})} etat={true}/>
                    : <LineGeneral texte={this.props.intl.formatMessage({id: "general.4.3"})} etat={true}/>
                  }
                </div>
              </div>


            </div>
          </div>
        }


          { this.state.mmappErrorDashboard ?
          <div className="card rounded-0 bg-white mb-3 mt-2">
            <div className="card-header border-info text-info bg-light p-2">
            <span className="">
              <b>
                {this.props.intl.formatMessage({id: "general.title.7"})}
              </b>
            </span>
          </div>
            <div className="card border-0 ml-2 mr-1 mt-1 mb-1">

              <div className="row">
                  <div className="col">
                    {
                      this.state.mmappErrorDashboard
                        ? this.createModal("mmappErrorDashboard", this.props.intl.formatMessage({id: "general.modal.15"}), this.state.mmappErrorDashboard.applicationList, "mmappErrorDashboard")
                        : null
                    }
                    {
                      this.state.mmappErrorDashboard ? this.state.mmappErrorDashboard.applicationList.length > 0
                        ? <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to="" data-toggle="modal" data-target="#mmappErrorDashboard" title={this.props.intl.formatMessage({id: "general.7.1.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.1"})} etat={false}/>
                          </NavLink>
                        : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.1"})} etat={true}/>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.1"})} etat={true}/>
                    }
                  </div>


                  <div className="col border-left border-secondary">
                    {
                      this.state.storeNewVersionApplications
                        ? this.createModal("storeNewVersionApplications", this.props.intl.formatMessage({id: "general.modal.StoreNewVersionApplications"}), this.state.storeNewVersionApplications, "storeNewVersionApplications")
                        : null
                    }
                    {
                      this.state.storeNewVersionApplications ? this.state.storeNewVersionApplications.length > 0
                        ? <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to="" data-toggle="modal" data-target="#storeNewVersionApplications" title={this.props.intl.formatMessage({id: "general.7.StoreNewVersionApplications.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.StoreNewVersionApplications"})} etat={false}/>
                          </NavLink>
                        : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.StoreNewVersionApplications"})} etat={true}/>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.StoreNewVersionApplications"})} etat={true}/>
                    }
                  </div>


                  <div className="col border-left border-secondary">
                    {
                      this.state.mmappErrorDashboard ? this.state.mmappErrorDashboard.storageOnServer / this.state.mmappErrorDashboard.limitStorageOnServer > 0.80
                        ? <NavLink className="used" style={{
                              color: "rgba(0,0,0,1)"
                            }} to={{
                              pathname: "/MM-App",
                              tabActive: 4
                            }} title={this.props.intl.formatMessage({id: "general.7.2.tooltip"})}>
                            <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.2"})} etat={false}/>
                          </NavLink>
                        : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.2"})} etat={true}/>
                      : <LineGeneral texte={this.props.intl.formatMessage({id: "general.7.2"})} etat={true}/>
                    }
                  </div>
              </div>


            </div>
          </div>
          :
          null
        }


          {
            tabAutres.map((x, index) => <div key={index} className="modal fade" id={"ModalSeuil" + index} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.intl.formatMessage({id: "general.modal.autres.1"})}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">

                    <ReactTable getTheadThProps={getTheadThProps} showPagination={false} data={x} columns={this.getColumns(index)} resizable={false} pageSize={x.length} style={{
                        maxHeight: "40vh",
                        width: 'auto'
                      }} className=" text-center border-0 small"/>

                    <div className="col text-center border-top small card rounded-0">
                      <b className="text-dark">{this.props.intl.formatMessage({id: "nb.ligne"}) + tabAutres[index].length}</b>
                    </div>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/></button>
                  </div>
                </div>
              </div>
            </div>)
          }

        </div>
      </div>
    </div>);
  }
}

dashboard.propTypes = {
  intl: intlShape.isRequired
};
dashboard = injectIntl(dashboard, {withRef: true});

export default dashboard;
