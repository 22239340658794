import React from 'react'
import ReactTable from 'react-table'
import $ from "jquery";
import find from 'lodash/find';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import DevicesDetailsNotificationsContextMenu from './DevicesDetailsNotificationsContextMenu';
import ReactTooltip from 'react-tooltip'
import { GoDeviceMobile } from "react-icons/go";
import {MdLens} from "react-icons/md";
import {IoIosCloseCircleOutline, IoIosInformationCircle, IoIosWarning, IoIosAlert} from "react-icons/io";
import { MenuProvider } from 'react-contexify';
import '../custom.css';
import { varTerminaux } from '../variables';

import classNames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


const APIdevicesNotifications = varTerminaux.apiMMAppServerNotifications;
const APIdevicesDetailsApp = varTerminaux.apiDevicesDetailsApp;


const CustomTbodyComponent = props => (
  <div {...props} className={classNames("rt-tbody", props.className || [])}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);

function changeToDate(int, langue){
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    var d = new Date(int);
    var ds = d.toLocaleDateString(`${langue}-${langue.toUpperCase()}`, options);
    return (ds)
}

function researchNotifications(array, valeur) {

  var testArray = [];

  if (valeur) {
    for (var i = 0; i < array.length; i++) {
        if(array[i].title.toLowerCase().indexOf(valeur.toLowerCase()) !== -1){
          testArray.push(array[i]);
        }
    }
  }
  if(valeur.length > 0){
    return (testArray)
  }

  return (array)
}

class DevicesDetailsNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      researchNotifications: "",
      notifToDelete: "",
    };

    this.researchNotificationsHandle = this.researchNotificationsHandle.bind(this);
  }

    researchNotificationsHandle(event) {
      this.setState({researchNotifications: event.target.value});
    }

    selectNotificationToBeDeleted(notification){
      this.setState({notifToDelete: notification});
      $('#modalConfirmSuprNotification').modal('show');
    }

    notificationCloseModalVerification(){
      $('#modalConfirmSuprNotification').modal('hide');
    }

    allNotificationShowModalVerification(){
      $('#modalConfirmSuprAllNotification').modal('show');
    }

    allNotificationCloseModalVerification(){
      $('#modalConfirmSuprAllNotification').modal('hide');
    }

    sendRequestToDeleteNotification(){

      fetch(APIdevicesNotifications + this.props.devicesDetails.android_id + "/" + this.state.notifToDelete.id, {
        method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: ""
        }).then(response => {
          var x = response;
          if (response.status === 200) {
            this.props.fetchDevicesDetails(APIdevicesDetailsApp + this.props.devicesDetails.model + "/" + this.props.devicesDetails.user.email);
            $('#modalConfirmSuprNotification').modal('hide');
          } else {
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })



    }

    sendRequestToDeleteAllNotification(){

      fetch(APIdevicesNotifications + this.props.devicesDetails.android_id, {
        method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: ""
        }).then(response => {
          var x = response;
          if (response.status === 200) {
            this.props.fetchDevicesDetails(APIdevicesDetailsApp + this.props.devicesDetails.model + "/" + this.props.devicesDetails.user.email);
            $('#modalConfirmSuprAllNotification').modal('hide');
          } else {
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })



    }

// ------------------------------- Render -------------------------------------- //

  render() {


// ------------------------------- Setting of the table ------------------------ //

    const getTheadThProps = (table, row, col) => {
      const sortedCol = find(table.sorted, {id: col.id});
      const boxShadow = sortedCol
        ? `inset 0px ${sortedCol.desc
          ? -2
          : 2}px 0px 0px white`
        : '';
        return {
          style: {
            boxShadow
          },
          onContextMenu: (e) => {
            this.props.contextMenuNotifications("empty");
          }
        };
    };


    const columns = [
      {
        Header: () => (
        <span>
          <b> </b>
        </span>
      ),
        accessor: 'state',
        minWidth: 32,
        Cell: row => (
            <div>
              <span style={{
                  color: row.value === "installation" ? "rgb(36,232,45)"
                  : row.value === "update" ? "rgb(255,184,46)"
                  : row.value === "uninstallation" ? "rgb(233,44,33)"
                  : row.value === "error" ? "rgb(250,31,31)"
                  : row.value === "warning" ? "rgb(255,159,47)"
                  : row.value === "info" ? "rgb(44,165,255)"
                  : "rgb(233,233,233)"
                }}
                title ={
                  row.value === "installation" ? this.props.intl.formatMessage({id: "terminauxDetails.notification_type.installation"})
                  : row.value === "update" ? this.props.intl.formatMessage({id: "terminauxDetails.notification_type.update"})
                  : row.value === "uninstallation" ? this.props.intl.formatMessage({id: "terminauxDetails.notification_type.uninstallation"})
                  : null
                }>

                {row.value === "error" ? <IoIosAlert size={30}/>
                : row.value === "warning" ? <IoIosWarning size={30}/>
                : row.value === "info" ? <IoIosInformationCircle size={30}/>
                : <MdLens size={30}/>

                }
              </span>
            </div>
          )

      },{
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.1"})}</b>
        </span>),
        accessor: 'title',
        minWidth: 200,
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.2"})}
          </b>
        </span>),
        accessor: 'version',
        minWidth: 160,
      },{
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.3"})}
          </b>
        </span>),
        accessor: 'versionCode',
        minWidth: 160,
      }, {
        Header: () => (<span>
          <b>
            {this.props.intl.formatMessage({id: "terminauxDetails.sendingDate"})}
          </b>
        </span>),
        accessor: 'date',
        minWidth: 200,

        Cell: row => (
            <div>
              <span>
                {changeToDate(row.value, this.props.intl.locale)}
              </span>
            </div>
          )
      }, {
        Header: () => (<span>
        </span>),
        accessor: 'date',
        minWidth: 50,
        maxWidth: 100,

        Cell: row => (
            <div>
              <button style={{
                  outline: 'none'
                }} className="close" onClick={this.selectNotificationToBeDeleted.bind(this, row.original)}>
                <span style={{
                    color: "rgb(110,110,110)"
                  }}>
                  <IoIosCloseCircleOutline size={28}/>
                </span>
              </button>
            </div>
          )
      }
    ]

    return (<div>
      <div className="card bg-light">
        <div className="card-header">

          <div className="form-inline row">
            <div className="col">
              <input className="form-control mr-sm-2 col-auto" style={{
                  height: "36px"
                }} type="search" placeholder={this.props.intl.formatMessage({id: "recherche"})} aria-label="Search" value={this.state.researchNotifications} onChange={this.researchNotificationsHandle}/>

                {this.props.applistFetching ?
                  <div className="col d-flex justify-content-center">
                    <div className="spinner-grow text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  : null
                }

                <button type="button" className="btn btn-danger float-right" onClick={this.allNotificationShowModalVerification.bind(this)}><FormattedMessage id="terminauxDetails.deleteAllNotifications.button"/></button>

            </div>


          </div>
        </div>

        <MenuProvider id="menu_DevicesDetailsNotificationsContextMenu">

        <ReactTable getTheadThProps={getTheadThProps}
        getTdProps={(state, rowInfo, column, instance) => {
          return {

            onContextMenu: (e) => {
                if(rowInfo){
                this.props.contextMenuNotifications(rowInfo.original)}
                else {this.props.contextMenuNotifications("empty")}
            }
          }
        }}
          showPagination={false}
          data={researchNotifications(this.props.deviceNotifications, this.state.researchNotifications).sort((a, b) => a.title.localeCompare(b.title))}
          noDataText={this.props.intl.formatMessage({id: "noElementsTab"})}
          TbodyComponent={CustomTbodyComponent}
          columns={columns}
          resizable={false}
          pageSize={this.props.deviceNotifications.length}
          style={{
            height: "41vh",
            width: 'auto'
          }} className=" text-center border-0 -clickable"/>

          </MenuProvider>

          <DevicesDetailsNotificationsContextMenu
            userAdministration={this.props.userAdministration}
            hit={this.props.notificationHit}
            refetch={this.props.fetchAll}/>

        <div className="col text-center border-top">
          <b className="text-dark"><FormattedMessage id="terminauxDetails.nbNotif"/> {this.props.deviceNotifications.length}</b>
        </div>
      </div>





      <div className="modal fade" id="modalConfirmSuprNotification" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalSuprNotif">
                <FormattedMessage id={"supprimer"}/>
              </h5>
              <button type="button" className="close" onClick={this.notificationCloseModalVerification.bind(this)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalSuprNotif">
                <FormattedMessage id={"confirmDelete"}/> "<b className="text-info">{this.state.notifToDelete.name}</b>" ?
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.notificationCloseModalVerification.bind(this)}><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} onClick={this.sendRequestToDeleteNotification.bind(this)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalConfirmSuprAllNotification" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className={`modal-content modalConfirm border-secondary`}>
            <div className="modal-header">
              <h5 className="modal-title" id="modalSuprNotif">
                <FormattedMessage id={"supprimer"}/>
              </h5>
              <button type="button" className="close" onClick={this.allNotificationCloseModalVerification.bind(this)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalSuprNotif">
                <FormattedMessage id={"terminauxDetails.modal.deleteAllNotifications"}/>
              </h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.allNotificationCloseModalVerification.bind(this)}><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className={`btn text-white btn-danger`} onClick={this.sendRequestToDeleteAllNotification.bind(this)}><FormattedMessage id="valider"/>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}

DevicesDetailsNotifications.propTypes = {
  intl: intlShape.isRequired
};
DevicesDetailsNotifications = injectIntl(DevicesDetailsNotifications, {withRef: true});

export default DevicesDetailsNotifications
